import React, { useContext } from "react";

import { Button, Input, Table, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../../contexts/CompanyContext";
const { Text } = Typography;
const { TextArea } = Input;

function PerformanceRevenue(props) {
  const [Reals, setReals] = useState("");
  const { TextArea } = Input;
  const { Company } = useContext(CompanyContext);

  const DisplayRevenueSummary = () => {
    let { t } = useTranslation();
    console.log("ttesstt 1");
    const items = [
      {
        title: `${t("Year")}`,
        width: 120,
        dataIndex: "year",
        align: "center",
        //   key: "year",
        render: (text, record) => {
          return <p style={{ textAlign: "center" }}>{text}</p>;
        },
      },
    ];

    const months = [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ];

    const startingMonthIndex = Company.startPeriod - 1;

    for (let i = 0; i < months.length; i++) {
      const monthIndex = (i + startingMonthIndex) % months.length;
      const monthName = months[monthIndex];

      items.push({
        title: monthName,
        children: [
          {
            title: <h3 style={{ textAlign: "center", width: 100 }}>Budget</h3>,
            dataIndex: "montantbudget",
            width: 150,
            align: "right",
            render: (text, record) => {
              let budget = 0;
              return (
                <>
                  {props.AssetBudgets.map((e) => {
                    if (e.year == record.year) {
                      budget = e.units[monthIndex].toFixed(0);
                    }
                  })}
                  <p style={{ textAlign: "right" }}>{budget}</p>
                </>
              );
            },
          },
          {
            title: (
              <h3 style={{ textAlign: "center", width: 100 }}>{t("Real")}</h3>
            ),

            dataIndex: "montantbudget",
            width: 150,
            align: "right",
            render: (text, record) => {
              return (
                <p style={{ textAlign: "right" }}>
                  {record.unitReals[monthIndex].toFixed(0)}
                </p>
              );
            },
          },
          {
            title: (
              <h3 style={{ textAlign: "center", width: 100 }}>
                {t("Difference")}
              </h3>
            ),
            dataIndex: "street",
            width: 150,
            align: "right",
            render: (text, record) => {
              let budget = 0;
              let difference = 0;

              props.AssetBudgets.forEach((e) => {
                if (e.year === record.year) {
                  budget = e.units[monthIndex].toFixed(0);
                }
              });

              difference = (record.unitReals[monthIndex] - budget).toFixed(0);

              return (
                <p
                  style={{
                    textAlign: "right",
                    color: difference >= 0 ? "blue" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {difference}
                </p>
              );
            },
          },
        ],
      });
    }

    items.push({
      title: <h1 style={{ textAlign: "center", width: 100 }}>Total </h1>,
      width: 120,
      render: (_, record) => {
        let performance = 0;
        return (
          <>
            {props.AssetBudgets.map((e) => {
              if (e.year == record.year) {
                performance = (
                  record.unitReals[0] -
                  e.units[0] +
                  record.unitReals[1] -
                  e.units[1] +
                  record.unitReals[2] -
                  e.units[2] +
                  record.unitReals[3] -
                  e.units[3] +
                  record.unitReals[4] -
                  e.units[4] +
                  record.unitReals[5] -
                  e.units[5] +
                  record.unitReals[6] -
                  e.units[6] +
                  record.unitReals[7] -
                  e.units[7] +
                  record.unitReals[8] -
                  e.units[8] +
                  record.unitReals[9] -
                  e.units[9] +
                  record.unitReals[10] -
                  e.units[10] +
                  record.unitReals[11] -
                  e.units[11]
                ).toFixed(0);
              }
            })}
            <p
              style={{
                textAlign: "right",
                color: performance >= 0 ? "blue" : "red",
                fontWeight: "bold",
              }}
            >
              {performance}
            </p>
          </>
        );
      },
    });

    return (
      <Table
        columns={items}
        dataSource={props.AssetReals}
        scroll={{
          x: 1300,
        }}
        pagination={true}
        bordered
        summary={(pageData) => {
          const items = [];

          const startingMonthIndex = Company.startPeriod - 1;

          let totalmontant = 0;
          let totalbudget = 0;
          let totalfevrierbudget = 0;
          let totalMarsbudget = 0;
          let totalavrilbudget = 0;
          let totalmaibudget = 0;
          let totalJuinbudget = 0;
          let totaljuilletbudget = 0;
          let totalaoutbudget = 0;
          let totalseptembrebudget = 0;
          let totaloctobrebudget = 0;
          let totalnovemberbudget = 0;
          let totaldecembrebudget = 0;
          let totaltotal = 0;
          let totalReal = 0;
          let totalfevrierReal = 0;
          let totalMarsReal = 0.0;
          let totalavrilReal = 0;
          let totalmaiReal = 0;
          let totalJuinReal = 0.0;
          let totaljuilletReal = 0;
          let totalaoutReal = 0;
          let totalseptembreReal = 0;
          let totaloctobreReal = 0;
          let totalnovemberReal = 0;
          let totaldecembreReal = 0;
          let totaltotalreal = 0;

          {
            props.AssetBudgets &&
              props.AssetBudgets.map(
                (e) => (
                  //console.log(e.budgets[1]),
                  //console.log(fevrierbudget),
                  (totalmontant = e.units[0]),
                  (totalbudget += totalmontant),
                  (totalmontant = e.units[1]),
                  (totalfevrierbudget += totalmontant),
                  (totalmontant = e.units[2]),
                  (totalMarsbudget += totalmontant),
                  (totalmontant = e.units[3]),
                  (totalavrilbudget += totalmontant),
                  (totalmontant = e.units[4]),
                  (totalmaibudget += totalmontant),
                  (totalmontant = e.units[5]),
                  (totalJuinbudget += totalmontant),
                  (totalmontant = e.units[6]),
                  (totaljuilletbudget += totalmontant),
                  (totalmontant = e.units[7]),
                  (totalaoutbudget += totalmontant),
                  (totalmontant = e.units[8]),
                  (totalseptembrebudget += totalmontant),
                  (totalmontant = e.units[9]),
                  (totaloctobrebudget += totalmontant),
                  (totalmontant = e.units[10]),
                  (totalnovemberbudget += totalmontant),
                  (totalmontant = e.units[11]),
                  (totaldecembrebudget += totalmontant),
                  (totalmontant = e.totalBudget),
                  (totaltotal -= totalmontant),
                  console.log()
                )
              );
          }
          props.AssetReals &&
            props.AssetReals.map((e) => {
              //console.log(e.Reals[1]),
              totalmontant = e.unitReals[0];
              totalReal += totalmontant;
              totalmontant = e.unitReals[1];
              totalfevrierReal += totalmontant;
              totalmontant = e.unitReals[2];
              totalMarsReal += totalmontant;
              totalmontant = e.unitReals[3];
              totalavrilReal += totalmontant;
              totalmontant = e.unitReals[4];
              totalmaiReal += totalmontant;
              totalmontant = e.unitReals[5];
              totalJuinReal += totalmontant;
              totalmontant = e.unitReals[6];
              totaljuilletReal += totalmontant;
              totalmontant = e.unitReals[7];
              totalaoutReal += totalmontant;
              totalmontant = e.unitReals[8];
              totalseptembreReal += totalmontant;
              totalmontant = e.unitReals[9];
              totaloctobreReal += totalmontant;
              totalmontant = e.unitReals[10];
              totalnovemberReal += totalmontant;
              totalmontant = e.unitReals[11];
              totaldecembreReal += totalmontant;
              totalmontant = e.totalReal;
              totaltotalreal += totalmontant;
            });

          const months = [
            totalbudget,
            totalfevrierbudget,
            totalMarsbudget,
            totalavrilbudget,
            totalmaibudget,
            totalJuinbudget,
            totaljuilletbudget,
            totalaoutbudget,
            totalseptembrebudget,
            totaloctobrebudget,
            totalnovemberbudget,
            totaldecembrebudget,
          ];
          const monthsReal = [
            totalReal,
            totalfevrierReal,
            totalMarsReal,
            totalavrilReal,
            totalmaiReal,
            totalJuinReal,
            totaljuilletReal,
            totalaoutReal,
            totalseptembreReal,
            totaloctobreReal,
            totalnovemberReal,
            totaldecembreReal,
          ];
          for (let i = 0; i < months.length; i++) {
            const monthIndex = (i + startingMonthIndex) % months.length;
            const monthNameReal = monthsReal[monthIndex];
            const monthNameBudget = months[monthIndex];

            items.push(
              <Table.Summary.Cell index={monthIndex}>
                <Text style={{ textAlign: "right" }}>
                  {monthNameBudget.toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            );
            items.push(
              <Table.Summary.Cell index={monthIndex}>
                <Text style={{ textAlign: "right" }}>
                  {monthNameReal.toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            );
            items.push(
              <Table.Summary.Cell index={monthIndex}>
                <Text style={{ textAlign: "right" }}>
                  {(monthNameReal - monthNameBudget).toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            );
          }

          return (
            <>
              <Table.Summary.Row>
                {/* <Table.Summary.Cell index={1} colSpan={1}>
                  <h3 style={{ textAlign: "center" }}>Total</h3>
                </Table.Summary.Cell>

                {items}

                <Table.Summary.Cell index={10}>
                  <Text>
                    <h4>
                      {totalReal -
                        totalbudget +
                        totalfevrierReal -
                        totalfevrierbudget +
                        totalMarsReal -
                        totalMarsbudget +
                        totalavrilReal -
                        totalavrilbudget +
                        totalmaiReal -
                        totalmaibudget +
                        totalJuinReal -
                        totalJuinbudget +
                        totaljuilletReal -
                        totaljuilletbudget +
                        totalaoutReal -
                        totalaoutbudget +
                        totalseptembreReal -
                        totalseptembrebudget +
                        totaloctobreReal -
                        totaloctobrebudget +
                        totalnovemberReal -
                        totalnovemberbudget +
                        totaldecembreReal -
                        totaldecembrebudget}
                    </h4>
                  </Text>
                </Table.Summary.Cell> */}
              </Table.Summary.Row>
            </>
          );
        }}
      />
    );
  };

  return <DisplayRevenueSummary />;
}

export default PerformanceRevenue;
