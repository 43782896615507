import {
  Row,
  Col,
  Button,
  Card,
  Empty,
  Input,
  Popconfirm,
  Divider,
  Space,
  Table,
  message,
  Switch,
  Modal,
  Dropdown,
  Menu,
  Checkbox,
  Tag,
  Form,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import {
  AppstoreOutlined,
  UserOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
  UnlockOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useAbility } from "../contexts/abilityContext";

const Roles = () => {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [roles, setRoles] = useState([]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [newRole, setNewRole] = useState({
    name: "",
    active: false,
    canBeSeenByIntegrator: false,
    canBeSeenByClient: false,
    enterpriseId: Company.id,
  });
  const { ability } = useAbility();

  const handleInputChange = (event) => {
    const { name } = event.target;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setNewRole({
      ...newRole,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const showUpdateModal = (record) => {
    setNewRole(record);
    setIsUpdateModalVisible(true);
  };

  const handleCreateOk = () => {
    setIsCreateModalVisible(false);
    createRole();
  };

  const handleUpdateOk = () => {
    setIsUpdateModalVisible(false);
    updateRole(newRole);
  };

  const updateRole = async (role) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_JSON_API}/Roles`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: role.id,
          name: role.name,
          canBeSeenByIntegrator: role.canBeSeenByIntegrator,
          canBeSeenByClient: role.canBeSeenByClient,
        }),
      });

      if (response.status !== 204) {
        const responseData = await response.json();
        throw new Error(responseData.message || "Failed to update role");
      }

      console.log("Role updated successfully.");
      message.success(t("RoleUpdatedSuccessfully"));
      fetchRoles();
    } catch (error) {
      console.error("Error updating role:", error.message);
      message.error(t("ErrorUpdatingRole"));
    }
  };

  const createRole = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_JSON_API}/Roles`, newRole);

      if (response.status === 200 || response.status === 201) {
        message.success(t("RoleCreatedSuccessfully"));
        setNewRole({
          name: "",
          active: false,
          canBeSeenByIntegrator: false,
          canBeSeenByClient: false,
          enterpriseId: Company.id,
        });
        fetchRoles();
      } else {
        console.error("Error creating role", response);
        message.error(t("ErrorCreatingRole"));
      }
    } catch (error) {
      console.error("Error creating role", error);
      message.error(t("ErrorCreatingRole"));
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/Roles/enterprise/${Company.id}`
      );
      console.log("Roles:", response.data);
      console.log("Company:", Company.id);
      setRoles(response.data);
      setLoading(false);
    } catch (error) {
      message.error(t("ErrorFetchingRoles"));
      setLoading(false);
    }
  };

  const handleDeactivate = async (id) => {
    console.log("Attempting to deactivate account:", id);
    const url = `${process.env.REACT_APP_JSON_API}/Roles/deactivate/${id}`;
    console.log("Deactivation URL:", url);

    try {
      const response = await axios.put(url);
      if (response.status === 204) {
        message.success(t("RoleDeactivated"));
        console.log(response.data);
        fetchRoles();
      }
    } catch (error) {
      message.error(t("AnErrorOccuredWhileDeactivatingRole"));
      console.log(error);
    }
  };

  const handleActivate = async (id) => {
    console.log("Attempting to activate role:", id);
    const url = `${process.env.REACT_APP_JSON_API}/Roles/activate/${id}`;
    console.log("activation URL:", url);

    try {
      const response = await axios.put(url);
      if (response.status === 204) {
        message.success(t("RoleActivatedSuccessfully"));
        console.log(response.data);
        fetchRoles();
      }
    } catch (error) {
      message.error(t("AnErrorOccuredWhileactivatingRole"));
      console.log(error);
    }
  };

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      align: "center",
      style: { fontWeight: "bold", textTransform: "uppercase" },
    },
    {
      title: t("Active"),
      dataIndex: "active",
      key: "active",
      align: "center",
      render: (active) =>
        active ? (
          <Tag
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            color="green"
          >
            {t("Activated")}
          </Tag>
        ) : (
          <Tag
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            color="red"
          >
            {t("Inactive")}
          </Tag>
        ),
    },
    {
      title: t("CanBeSeenByIntegrator"),
      dataIndex: "canBeSeenByIntegrator",
      align: "center",
      render: (text, record) => (
        <Tag
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            borderRadius: "5px",
            padding: "5px 10px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
          color={record.canBeSeenByIntegrator ? "green" : "red"}
        >
          {record.canBeSeenByIntegrator ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: t("CanBeSeenByClient"),
      dataIndex: "canBeSeenByClient",
      align: "center",
      render: (text, record) => (
        <Tag
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            borderRadius: "5px",
            padding: "5px 10px",
            textAlign: "center",
            textTransform: "uppercase",
          }}
          color={record.canBeSeenByClient ? "green" : "red"}
        >
          {record.canBeSeenByClient ? "Yes" : "No"}
        </Tag>
      ),
    },
  ];

  if (
    ability.can("update", "Roles") ||
    ability.can("activate", "Roles") ||
    ability.can("deactivate", "Roles")
  ) {
    columns.push({
      title: t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              {ability.can("update", "Roles") && (
                <Menu.Item
                  key="1"
                  icon={<EditOutlined />}
                  onClick={() => showUpdateModal(record)}
                >
                  {t("Edit")}
                </Menu.Item>
              )}
              {(ability.can("update", "Roles") ||
                ability.can("update", "Roles")) && (
                <Menu.Item
                  key="2"
                  icon={<UnlockOutlined />}
                  onClick={() =>
                    record.active
                      ? handleDeactivate(record.id)
                      : handleActivate(record.id)
                  }
                >
                  {record.active ? t("Desactivate") : t("Activate")}
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={["click"]}
        >
          <Button type="link" onClick={(e) => e.preventDefault()}>
            {t("Actions")} <DownOutlined />
          </Button>
        </Dropdown>
      ),
    });
  }

  return (
    <>
      <Modal
        title={
          <span>
            <UserAddOutlined /> {t("AddNewRole")}
          </span>
        }
        visible={isCreateModalVisible}
        onOk={handleCreateOk}
        onCancel={() => setIsCreateModalVisible(false)}
        width={600}
        okText={t("create")}
        cancelText={t("cancel")}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={t("RoleName")}>
                <Input
                  prefix={<UserAddOutlined />}
                  name="name"
                  value={newRole.name}
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">{t("RoleVisibility")}</Divider>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item>
                <Checkbox
                  name="active"
                  checked={newRole.active}
                  onChange={handleInputChange}
                >
                  {t("Active")}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Checkbox
                  name="canBeSeenByIntegrator"
                  checked={newRole.canBeSeenByIntegrator}
                  onChange={handleInputChange}
                >
                  {t("seenByIntegrator")}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Checkbox
                  name="canBeSeenByClient"
                  checked={newRole.canBeSeenByClient}
                  onChange={handleInputChange}
                >
                  {t("seenByClient")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={
          <span>
            <EditOutlined style={{ marginRight: "8px" }} />
            {t("EditRole")}
          </span>
        }
        visible={isUpdateModalVisible}
        onOk={handleUpdateOk}
        onCancel={() => setIsUpdateModalVisible(false)}
        width={600}
        footer={null}
        centered
      >
        <Form form={form} layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label={t("RoleName")} style={{ marginBottom: "24px" }}>
                <Input
                  prefix={<UserOutlined />}
                  name="name"
                  value={newRole.name}
                  onChange={handleInputChange}
                  placeholder={t("EnterRoleName")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left" style={{ color: "#333" }}>
            {t("RoleVisibility")}
          </Divider>
          <Row gutter={24} type="flex" justify="center" align="middle">
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Item>
                <Checkbox
                  name="canBeSeenByIntegrator"
                  checked={newRole.canBeSeenByIntegrator}
                  onChange={handleInputChange}
                >
                  {t("seenByIntegrator")}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Item>
                <Checkbox
                  name="canBeSeenByClient"
                  checked={newRole.canBeSeenByClient}
                  onChange={handleInputChange}
                >
                  {t("seenByClient")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginTop: "24px" }}>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button
                style={{ marginRight: "8px" }}
                onClick={() => setIsUpdateModalVisible(false)}
              >
                {t("Cancel")}
              </Button>
              <Button type="primary" onClick={handleUpdateOk}>
                {t("SaveChanges")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Card
        title={
          <span style={{ fontSize: "16px" }}>
            <AppstoreOutlined /> {t("Roles")}
          </span>
        }
        extra={
          <Space>
            {ability && ability.can("create", "Roles") && (
              <Button
                type="primary"
                size="medium"
                style={{
                  fontSize: "10px",
                  textTransform: "uppercase",
                }}
                icon={<PlusSquareOutlined />}
                onClick={showCreateModal}
              >
                {t("AddNewRole")}
              </Button>
            )}
          </Space>
        }
      >
        <Table
          form={form}
          locale={{
            emptyText: <Empty description={t("nodata")} />,
          }}
          dataSource={roles}
          columns={columns}
          rowClassName="editable-row"
          rowKey="id"
          pagination={{
            pageSize: 10,
          }}
        />
      </Card>
    </>
  );
};

Roles.acl = {
  subject: "Roles",
  action: "read",
};

export default Roles;
