import React, { useState, useEffect } from "react";
import { Collapse, Button, Input, Card, Popconfirm, Tooltip } from "antd";
import {
  CaretRightOutlined,
  PlusOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Sections from "./Section";

const { Panel } = Collapse;

const SubCategories = ({ initialSubCategories, isReadOnly, enterpriseId }) => {
  const [subCategories, setSubCategories] = useState(
    initialSubCategories || []
  );
  const [isEditing, setIsEditing] = useState(null);
  const [editedName, setEditedName] = useState("");
  const uniqueId = parseInt(
    `${new Date().getTime()}${Math.floor(Math.random() * 1000)}`,
    10
  );

  useEffect(() => {
    setSubCategories(
      initialSubCategories.sort((a, b) => a.position - b.position)
    );
  }, [initialSubCategories]);

  const handleEditClick = (idx) => {
    setIsEditing(idx);
    setEditedName(subCategories[idx].name);
  };

  const handleInputChange = (e) => {
    setEditedName(e.target.value);
  };

  const handleInputBlur = (idx) => {
    if (editedName) {
      const updatedSubCategories = [...subCategories];
      updatedSubCategories[idx].name = editedName;
      setSubCategories(updatedSubCategories);
    }
    setIsEditing(null);
  };

  const handleDeleteClick = (idx) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories.splice(idx, 1);
    setSubCategories(updatedSubCategories);
  };

  const handleAddSubCategory = (parentSubCategory) => {
    const newSubCategory = {
      // id:uniqueId,
      name: "New Subcategory",
      position: 0,
      subCategories: [],
      sections: [],
    };
    if (parentSubCategory) {
      parentSubCategory.subCategories.push(newSubCategory);
      setSubCategories([...subCategories]);
      // setIsEditing(subSections.length + parentSubSection.subSections.length - 1); // Launch edit mode for the newly added subsection
    }
  };
  const handleAddSection = (parentSubCategory) => {
    console.log("Adding new section to subcategory");

    const newSection = {
      id: uniqueId,
      name: "New Section",
      position: 0,
      chartAccountId: 0,
      subSections: [],
    };

    const updatedSubCategories = [...subCategories];
    const parentIndex = updatedSubCategories.findIndex(
      (sc) => sc.id === parentSubCategory.id
    ); // Assume each subCategory has a unique 'id'

    if (parentIndex > -1) {
      const newSubCategories = JSON.parse(JSON.stringify(subCategories)); // Deep copy
      newSubCategories[parentIndex].sections = [
        ...newSubCategories[parentIndex].sections,
        newSection,
      ];
      setSubCategories(newSubCategories);
    }

    setSubCategories(updatedSubCategories);
    console.log("Updated Sub Categories:", updatedSubCategories);
  };

  return (
    <Collapse
      accordion
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      {subCategories.map((subCategory, idx) => (
        <Panel
          key={subCategory.id || idx} // Using unique id or index as a fallback
          header={
            isEditing === idx ? (
              <Input
                value={editedName}
                onChange={handleInputChange}
                onBlur={() => handleInputBlur(idx)}
                autoFocus
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{subCategory.name}</span>
                <div>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(idx);
                    }}
                  />
                  <Popconfirm
                    title="Delete this sub-section and its children. Ok?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => handleDeleteClick(idx)}
                  >
                    <Button
                      type="link"
                      icon={<DeleteOutlined style={{ color: "red" }} />}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Popconfirm>
                </div>
              </div>
            )
          }
        >
          <Card bordered={false}>
            <Tooltip title="add section">
              <Button
                disabled={isReadOnly}
                onClick={() => handleAddSection(subCategory)}
                type="link"
                size="small"
                shape="circle"
                icon={
                  <PlusOutlined style={{ color: "#f0db4f", marginRight: -5 }} />
                }
                style={{
                  position: "absolute",
                  top: 20,
                  fontWeight: "bold",
                  backgroundColor: "#5D81D5",
                  top: 10,
                  right: 50,
                  zIndex: 1,
                }}
              />
            </Tooltip>
            <Tooltip title="add sub-sub-category">
              <Button
                disabled={isReadOnly}
                onClick={() => handleAddSubCategory(subCategory)}
                type="link"
                size="small"
                shape="circle"
                icon={
                  <PlusOutlined style={{ color: "#5D81D5", marginRight: -5 }} />
                }
                style={{
                  position: "absolute",
                  top: 20,
                  fontWeight: "bold",
                  backgroundColor: "#f0db4f",
                  top: 10,
                  right: 20,
                  zIndex: 1,
                }}
              />
            </Tooltip>
            {subCategory.subCategories && (
              <SubCategories initialSubCategories={subCategory.subCategories} />
            )}
            {subCategory.sections && (
              <Sections
                initialSections={subCategory.sections}
                isReadOnly={isReadOnly}
                enterpriseId={enterpriseId}
              />
            )}
          </Card>
        </Panel>
      ))}
    </Collapse>
  );
};

export default SubCategories;
