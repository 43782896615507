import { RightOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Card,
  DatePicker,
  Table,
  Space,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useAbility } from "../../contexts/abilityContext";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";

const PerformanceMonitoring = () => {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [orderBookPerformance, setOrderBookPerformance] = useState([]);

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const columns = [
    {
      title: `${t("OrderbookDate")}`,
      dataIndex: "orderBookDate",
      key: "orderBookDate",
      align: "center",
    },
    {
      title: `${t("OrderbookName")}`,
      dataIndex: "orderBookName",
      key: "orderBookName",
      align: "center",
      render: (text) => <strong>{text}</strong>,
    },
    ...Array.from({ length: 12 }, (_, monthIndex) => ({
      title: months[monthIndex],
      children: [
        {
          title: "Budget",
          dataIndex: `budgetMonth${monthIndex + 1}`,
          key: `budgetMonth${monthIndex + 1}`,
          align: "center",
          render: (budget) => `${Number(budget).toLocaleString()}`,
        },
        {
          title: "Real",
          dataIndex: `realMonth${monthIndex + 1}`,
          key: `${months[monthIndex]}-real`,
          align: "center",
          render: (real) => `${Number(real ?? 0).toLocaleString()}`,
        },
        {
          title: `${t("Difference")}`,
          dataIndex: `differenceMonth${monthIndex + 1}`,
          key: `${months[monthIndex]}-difference`,
          align: "center",
          render: (difference) => {
            const color = Number(difference) >= 0 ? "blue" : "red";
            return (
              <span style={{ color: color, fontWeight: "bold" }}>
                {`${Number(difference ?? 0).toLocaleString()}`}
              </span>
            );
          },
        },
        {
          title: `${t("Percentage")}`,
          dataIndex: `percentageMonth${monthIndex + 1}`,
          key: `${months[monthIndex]}-percentage`,
          align: "center",
          render: (percentage) => {
            const color = Number(percentage) >= 0 ? "blue" : "red";
            return (
              <span style={{ color: color, fontWeight: "bold" }}>
                {`${Number(percentage ?? 0).toFixed(2)}%`}
              </span>
            );
          },
        },
      ],
    })),
    {
      title: "Totals",
      children: [
        {
          title: "Budget",
          dataIndex: "totalBudget",
          key: "totalBudget",
          align: "center",
          render: (text) => `${Number(text ?? 0).toLocaleString()}`,
        },
        {
          title: "Real",
          dataIndex: "totalReal",
          key: "totalReal",
          align: "center",
          render: (text) => `${Number(text ?? 0).toLocaleString()}`,
        },
        {
          title: "Difference",
          dataIndex: "totalDifference",
          key: "totalDifference",
          align: "center",
          render: (text) => {
            const color = Number(text) >= 0 ? "blue" : "red";
            return (
              <span style={{ color: color, fontWeight: "bold" }}>
                {`${Number(text ?? 0).toLocaleString()}`}
              </span>
            );
          },
        },
        {
          title: "Percentage",
          dataIndex: "totalPercentage",
          key: "totalPercentage",
          render: (percentage) => {
            const color = Number(percentage) >= 0 ? "blue" : "red";
            return (
              <span style={{ color: color, fontWeight: "bold" }}>
                {`${Number(percentage ?? 0).toFixed(2)} %`}
              </span>
            );
          },
        },
      ],
    },
  ];

  var date;

  const onChangee = (date) => {
    date?date.$y?setYear(date.$y):setYear(null):setYear(null)
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const yearToFetch = year || new Date().getFullYear();
        const response = await axios.get(
          `${process.env.REACT_APP_JSON_API}/SalesBudgets/OrderBookPerformanceSummary/${yearToFetch}/${Company.id}`
        );
        console.log("API response data:", response.data);

        if (
          response.data &&
          Array.isArray(response.data.orderBookPerformances)
        ) {
          const transformedData = transformDataForTable(
            response.data.orderBookPerformances
          );
          console.log("Transformed data:", transformedData);
          setOrderBookPerformance(transformedData);
        } else {
          console.error(
            "Data fetched does not have the expected array structure."
          );
          setOrderBookPerformance([]);
        }
      } catch (error) {
        console.error("Error fetching order book performances:", error);
        setOrderBookPerformance([]);
      }
    };

    fetchData();
  }, [Company.id, year]);

  const safeToFixed = (value, decimals) => {
    const num = parseFloat(value);
    return isNaN(num) ? "0.00" : num.toFixed(decimals);
  };

  const dataSource = orderBookPerformance.map((performance, idx) => ({
    key: idx,
    orderBookDate: performance.orderBookDate,
    orderBookName: performance.orderBookName,
    monthlyPerformances: performance.monthlyPerformances,
    totalBudget: performance.totalBudget,
    totalReal: performance.totalReal,
    totalDifference: performance.totalDifference,
    totalPercentage: safeToFixed(performance.totalPercentage, 2),
  }));

  const transformDataForTable = (orderBookPerformanceArray) => {
    return orderBookPerformanceArray.map((obp, index) => {
      const monthlyData = obp.monthlyPerformances.reduce(
        (acc, curr, monthIndex) => {
          acc[`budgetMonth${monthIndex + 1}`] = curr.budget;
          acc[`realMonth${monthIndex + 1}`] = curr.real;
          acc[`differenceMonth${monthIndex + 1}`] = curr.difference;
          acc[`percentageMonth${monthIndex + 1}`] = curr.percentage.toFixed(2); // Ensure percentage is a number
          return acc;
        },
        {}
      );

      return {
        key: index.toString(),
        orderBookDate: obp.orderBookDate,
        orderBookName: obp.orderBookName,
        ...monthlyData,
        totalBudget: obp.totalBudget,
        totalReal: obp.totalReal,
        totalDifference: obp.totalDifference,
        totalPercentage: Number(obp.totalPercentage).toFixed(2), // Ensure this is a number before calling toFixed
      };
    });
  };

  useEffect(() => {
    console.log("Data source for table:", dataSource);
  }, [dataSource]);

  return (
    <>
      <Card>
        <Breadcrumb
          style={{
            marginBottom: "1rem",
            fontSize: "1.2rem",
            textShadow: "1px 1px 1px #aaa",
          }}
        >
          <Breadcrumb.Item style={{ hover: "none" }}>
            <Link to="/sales-budget" style={{ hover: "none" }}>
              <Typography.Text>{t("SalesBudget")}</Typography.Text>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Typography.Text>{t("PerformanceMonitoring")}</Typography.Text>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div
          style={{ textAlign: "center", marginBottom: 25, fontSize: "20px" }}
        >
          <h1>{t("PerformanceMonitoring")}</h1>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Space>
            <DatePicker
              name="year"
              picker="year"
              placeholder={t("Selectyear")}
              style={{ width: 200, height: 35 }}
              onChange={onChangee}
              defaultValue={dayjs(date)}
              suffixIcon={<RightOutlined />}
            />
          </Space>
        </div>
        <Table
          style={{ marginBottom: 45, marginTop: 25, color: "#059BFF" }}
          scroll={{
            x: 5000,
          }}
          dataSource={orderBookPerformance}
          columns={columns}
          bordered
        />
      </Card>
    </>
  );
};

export default PerformanceMonitoring;
