import {
  ArrowLeftOutlined,
  DeleteFilled,
  EditFilled,
  PlusCircleFilled,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { useAbility } from "../contexts/abilityContext";
import { JSON_API } from "../services/Constants";

dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text, Link, Title } = Typography;
const { TextArea } = Input;

const ChartofAccountDetails = () => {
  const { ability } = useAbility();
  const { Company } = useContext(CompanyContext);

  const canUpdate = ability.can("update", "Chart of Accounts");
  const canDelete = ability.can("delete", "Chart of Accounts");
  const [messageApi, contextHolder] = message.useMessage();
  const [statementcategory, setStatementCategory] = useState([]);
  const [statementtype, setStatementType] = useState([]);
  const [statementclass, setStatementClass] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const history = useHistory();
  const { id } = useParams();
  const stateParamVal = useLocation().state.stateParam;
  const [form] = Form.useForm();
  const [editingRow, setEditingRow] = useState(null);
  const [openHyphothese, setopenHyphothese] = useState(false);
  const [form2] = Form.useForm();
  const [form4] = Form.useForm();
  const [editingRow2, setEditingRow2] = useState(null);
  const [disabledYears, setDisabledYears] = useState([]); // Array of years to disable
  const [HypothesesDataSource, sethypothesesDataSource] = useState(null);
  const [DepartmentT, setDepartmentT] = useState([{}]);

  const [datasource, setDatasource] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  console.log("props parametre value", id);
  console.log("props state value", stateParamVal);

  useEffect(() => {
    getStatementClass();
    getDepartments();
    getData();
  }, []);

  const getDepartments = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments?enterpriseId=${Company.id}`)
      .then((response) => {
        setDepartmentT(response.data);
        console.log("entreprise Departments:", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getStatementClass = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementClass`)
      .then((res) => {
        console.log(res);
        setStatementClass(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleclass = async (e) => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementCategory/class/${e}`)

      .then((res) => {
        console.log(res.data);
        setStatementCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementType/classe/${e}`)

      .then((res) => {
        console.log(res);
        setStatementType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlecategory = async (e) => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementType/category/${e}`)

      .then((res) => {
        console.log(res);
        setStatementType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/ChartAccounts/${id}`)
      .then((response) => {
        handleclass(response.data.financialStatementClass.id);
        setDatasource(response.data);

        setDisabledYears([]);

        const data = response.data;
        data.glAccount.hypotheses.map((o) => {
          o.key = o.id;
          setDisabledYears((current) => [...current, Number(o.beginsOnYear)]);
        });

        console.log("data:", data);

        // setDataSource(response.data);
        sethypothesesDataSource(data.glAccount.hypotheses);
        console.log("Datasource:(chart of account): ", response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const disabledDate = (current) => {
    // Disable dates that match any year in the disabledYears array
    // console.log("disabledYears:",disabledYears);

    return disabledYears.some((year) => current.year() === year);
  };
  const CollectionCreateHyphothesis = ({
    openHyphothese,
    OnopenHyphothese,
    onCancel,
  }) => {
    return (
      <Modal
        forceRender
        open={openHyphothese}
        title={t("Createanewhypothesis")}
        okText={t("create")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form2
            .validateFields()
            .then((values) => {
              form2.resetFields();
              OnopenHyphothese(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form2}
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="beginsOnYear"
            label={t("Year")}
            rules={[
              {
                required: true,
                message: t("Pleaseinputtheyear"),
              },
            ]}
          >
            <DatePicker
              placeholder={t("Selectyear")}
              picker="year"
              disabledDate={disabledDate}
              style={{
                width: "50%",
                textAlign: "center",
              }}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            name="annualIncrease"
            label={t("Annualincrease")}
            rules={[
              {
                required: true,
                message: t("Pleaseinputtheannualincrease"),
              },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              size={"large"}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              // onChange={e=>setShareHolderShares(e)}
            />
          </Form.Item>

          <Form.Item
            // value={nom}
            name="beginsOnMonth"
            label={t("Beginon")}
            rules={[
              {
                required: true,
                message: t("Selectmonth"),
              },
            ]}
          >
            <Select
              notFoundContent={t("nodata")}
              placeholder={t("Selectmonth")}
              // onChange={handleStartPeriodChange}
              size={"large"}
              // style={{
              //   width: "50%",
              // }}
            >
              <Option value={1}>{t("January")}</Option>
              <Option value={2}>{t("February")}</Option>
              <Option value={3}>{t("March")}</Option>
              <Option value={4}>{t("April")}</Option>
              <Option value={5}>{t("May")}</Option>
              <Option value={6}>{t("June")}</Option>
              <Option value={7}>{t("July")}</Option>
              <Option value={8}>{t("August")}</Option>
              <Option value={9}>{t("September")}</Option>
              <Option value={10}>{t("October")}</Option>
              <Option value={11}>{t("November")}</Option>
              <Option value={12}>{t("December")}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="hypothesis"
            label={t("Hypotheses")}
            rules={[
              {
                required: true,
                message: t("Pleaseinputthehypothesis"),
              },
            ]}
          >
            <TextArea rows={2}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const OnopenHyphothese = async (values) => {
    const obj = {
      glAccountId: datasource.glAccount.id,
      annualIncrease: values.annualIncrease,
      beginsOnYear: values.beginsOnYear.$y + "",
      beginsOnMonth: values.beginsOnMonth,
      hypothesis: values.hypothesis,
    };

    console.log("obj:", obj);
    await axios
      .post(`${process.env.REACT_APP_JSON_API}/Hypothesis`, obj)
      .then((res) => {
        getData();
        // getGlaccount();
      })
      .catch((err) => {
        console.log(err);
      });
    setopenHyphothese(false);
  };

  const columnsHyphothese = [
    {
      title: t("Year"),
      dataIndex: "beginsOnYear",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.key) {
          return (
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputtheyear"),
                },
              ]}
            >
              <DatePicker disabled picker="year" />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{text}</div>;
        }
      },
      sorter: (a, b) => a.beginsOnYear - b.beginsOnYear,
      defaultSortOrder: "ascend",
    },
    {
      title: t("Annualincrease"),
      dataIndex: "annualIncrease",
      align: "center",

      render: (text, record) => {
        if (editingRow2 === record.key) {
          return (
            <Form.Item
              name="annualIncrease"
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputtheannualincrease"),
                },
              ]}
            >
              <InputNumber
                // disabled={SHselected}
                min={0}
                max={100}
                size={"large"}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                // onChange={e=>setShareHolderShares(e)}
              />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{text}%</div>;
        }
      },
    },
    {
      title: t("Beginon"),
      dataIndex: "beginsOnMonth",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.key) {
          return (
            <Form.Item
              name="begin"
              rules={[
                {
                  required: true,
                  message: t("Selectmonth"),
                },
              ]}
            >
              <Select
                notFoundContent={t("nodata")}
                placeholder={t("Selectmonth")}
                // onChange={handleStartPeriodChange}
                size={"large"}
                // style={{
                //   width: "50%",
                // }}
              >
                <Option value={1}>{t("January")}</Option>
                <Option value={2}>{t("February")}</Option>
                <Option value={3}>{t("March")}</Option>
                <Option value={4}>{t("April")}</Option>
                <Option value={5}>{t("May")}</Option>
                <Option value={6}>{t("June")}</Option>
                <Option value={7}>{t("July")}</Option>
                <Option value={8}>{t("August")}</Option>
                <Option value={9}>{t("September")}</Option>
                <Option value={10}>{t("October")}</Option>
                <Option value={11}>{t("November")}</Option>
                <Option value={12}>{t("December")}</Option>
              </Select>
            </Form.Item>
          );
        } else {
          return record.beginsOnMonth + "/" + record.beginsOnYear;
        }
      },
    },
    {
      title: t("Hypotheses"),
      dataIndex: "hypothesis",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.key) {
          return (
            <Form.Item
              name="HyphotheseofGl"
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputthehypothesis"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(text)}>{text}</div>;
        }
      },
    },
  ];

  if (canUpdate || canDelete) {
    columnsHyphothese.push({
      title: "Actions",
      align: "center",
      render: (_, record) =>
        HypothesesDataSource.length >= 1 ? (
          <Space size="middle">
            {editingRow2 === record.key ? (
              <>
                <Button type="link" onClick={() => setEditingRow2(null)}>
                  {t("cancel")}
                </Button>
                <Button type="link" htmlType="submit">
                  {t("save")}
                </Button>
              </>
            ) : (
              <>
                {canUpdate && (
                  <Button
                    type="link"
                    icon={<EditFilled />}
                    onClick={() => {
                      setEditingRow2(record.key);
                      form4.setFieldsValue({
                        year: dayjs(record.beginsOnYear),
                        annualIncrease: record.annualIncrease,
                        begin: record.beginsOnMonth,
                        HyphotheseofGl: record.hypothesis,
                      });
                    }}
                  >
                    {t("edit")}
                  </Button>
                )}
                {canDelete && (
                  <Popconfirm
                    title={t("deleterow")}
                    cancelText={t("no")}
                    okText={t("yes")}
                    onConfirm={() => handlehypoDelete(record.key)}
                  >
                    <Button
                      type="link"
                      danger
                      icon={<DeleteFilled />}
                      style={{ marginLeft: ".5rem" }}
                    >
                      {t("Delete")}
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
          </Space>
        ) : null,
    });
  }

  const handlehypoDelete = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_JSON_API}/Hypothesis/${id}`)
      .then(() => {
        getData();
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  const onFinishEditHypo = async (e) => {
    const obj = {
      id: editingRow2,
      annualIncrease: e.annualIncrease,
      beginsOnYear: e.year.$y + "",
      beginsOnMonth: e.begin,
      hypothesis: e.HyphotheseofGl,
    };

    await axios
      .put(`${process.env.REACT_APP_JSON_API}/Hypothesis`, obj)
      .then((res) => {
        // getGlaccount();
        getData();
        setEditingRow2(null);
        messageApi.open({
          type: "success",
          content: t("UpdatedSuccessfully"),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //   const handlecategory = async (e) => {
  //     // console.log(e);
  //     await axios
  //       .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementType/category/${e}`)

  //       .then((res) => {
  //         console.log(res);
  //         setStatementType(res.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  const Updateform = async (values) => {
    console.log("updating...:", values);

    const obj = {
      id: id,
      note: values.note,
      description: values.description,
      financialStatementCategoryId: values.category,
      financialStatementTypeGIFI: values.type,
      financialStatementClassId: values.class,
      enterpriseDepartmentId: values.entreprisedepartement,
    };
    console.log("updated data:", obj);

    await axios
      .put(`${process.env.REACT_APP_JSON_API}/ChartAccounts`, obj)
      .then((res) => {
        getData();
        messageApi.open({
          type: "success",
          content: t("UpdatedSuccessfully"),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  function handleGoBack() {
    history.goBack();
  }
  return (
    <>
      {contextHolder}

      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <h2 className="font-semibold">
            {t("Chartofaccount")} :{" "}
            {datasource && datasource.glAccount.description}
          </h2>
        }
        extra={
          <Button type="primary" danger onClick={handleGoBack}>
            <ArrowLeftOutlined />
            {t("Goback")}
          </Button>
        }
      >
        {datasource && (
          <Form
            form={form}
            layout="horizontal"
            name="form_in_modal"
            onFinish={Updateform}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 14,
            }}
            initialValues={{
              class: datasource.financialStatementClass.id,
              category: datasource.financialStatementCategoryForReporting.id,
              type: datasource.financialStatementType.gifi,
              glAccountId: datasource.glAccount.glNumber,
              description: datasource.glAccount.description,
              annualBudget: datasource.annualBudget,
              budgetBreakdown: datasource.budgetBreakdown,
              note: datasource.note,
              entreprisedepartement: datasource.enterpriseDepartmentId,
            }}
          >
            <Divider orientation="left"> {t("Account")}</Divider>
            <Form.Item
              name="glAccountId"
              label={t("GLaccount")}
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputtheaccount"),
                },
              ]}
            >
              <Input disabled></Input>
            </Form.Item>
            <Form.Item
              name="class"
              label={t("Class")}
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputtheclass"),
                },
              ]}
            >
              <Select
                disabled={!canUpdate}
                notFoundContent={t("nodata")}
                onChange={(e) => {
                  form.setFieldsValue({
                    category: null,
                    type: null,
                  });
                  handleclass(e);
                }}
              >
                {statementclass &&
                  statementclass.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {currentLang === "en" ? option.label : option.labelFr}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              // value={nom}
              name="category"
              label={t("Category")}
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputthecategory"),
                },
              ]}
            >
              <Select
                disabled={!canUpdate}
                notFoundContent={t("nodata")}
                onChange={(e) => {
                  form.setFieldsValue({
                    type: null,
                  });
                  handlecategory(e);
                }}
              >
                {statementcategory &&
                  statementcategory.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {currentLang === "en" ? option.label : option.labelFr}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              // value={nom}
              name="type"
              label={t("Gifi")}
              rules={[
                {
                  required: true,
                  message: t("Pleaseinputthetype"),
                },
              ]}
            >
              <Select disabled={!canUpdate} notFoundContent={t("nodata")}>
                {statementtype &&
                  statementtype.map((option) => (
                    <Option
                      key={option.id || option.gifi}
                      value={option.id || option.gifi}
                    >
                      {currentLang === "en"
                        ? option.label ||
                          "(" +
                            t("Gifi") +
                            ": " +
                            option.gifi +
                            ") " +
                            option.description
                        : option.labelFr ||
                          "(" +
                            t("Gifi") +
                            ": " +
                            option.gifi +
                            ") " +
                            option.descriptionFr}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              // value={conditions}
              name="description"
              label={t("Description")}
            >
              <Input disabled={!canUpdate} />
            </Form.Item>
            <Divider orientation="left">{t("Additionalinformation")}</Divider>
            <Col span={24}>
              <Form.Item name="entreprisedepartement" label={t("Departements")}>
                <Select
                  notFoundContent={t("nodata")}
                  label={t("Departments")}
                  size="large"
                  style={{ width: "100%" }}
                  min={0}
                >
                  {DepartmentT.map((d) => (
                    <Option key={d.id} value={d.id}>
                      {d.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                // value={nom}
                name="annualBudget"
                label={t("Annualbudget")}
                rules={[
                  {
                    required: true,
                    message: t("Pleaseinputtheannualbudget"),
                  },
                ]}
              >
                <InputNumber
                  // disabled={SHselected}
                  min={0}
                  size={"large"}
                  disabled

                  // onChange={e=>setShareHolderShares(e)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="budgetBreakdown">
                <Checkbox
                  disabled
                  //   checked={componentDisabled}
                >
                  {t("Budgetbreakdown")}
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="note" label={t("Note")}>
                <Input disabled={!canUpdate} />
              </Form.Item>
            </Col>
            <div style={{ textAlign: "center" }}>
              {ability.can("update", "Chart of Accounts") && (
                <Col span={24}>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    style={{
                      width: 850,
                      marginBottom: 20,
                      marginTop: 20,
                      color: "#000",
                      backgroundColor: "#F7AC00",
                      borderColor: "#F7AC00",
                    }}
                    htmlType="submit"
                  >
                    {t("Update")}
                  </Button>
                </Col>
              )}
            </div>
          </Form>
        )}
        <Divider></Divider>
        <Title level={3}>{t("Hypotheses")}</Title>
        <Row justify="end" gutter={[16, 16]}>
          <Space style={{ display: "flex", marginBottom: 18 }} align="baseline">
            {ability.can("create", "Chart of Accounts") && (
              <Button
                className="Create-button"
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => setopenHyphothese(true)}
              >
                {t("Createanewhypothesis")}
              </Button>
            )}
          </Space>
        </Row>

        <CollectionCreateHyphothesis
          openHyphothese={openHyphothese}
          OnopenHyphothese={OnopenHyphothese}
          onCancel={() => {
            setopenHyphothese(false);
            form2.resetFields();
          }}
        />

        <Form form={form4} onFinish={onFinishEditHypo}>
          <div style={{ overflowX: "auto",backgroundColor:'white' }}>
          <Table
            locale={{ emptyText: <Empty description={t("nodata")} /> }}
            bordered
            columns={columnsHyphothese}
            dataSource={HypothesesDataSource}
          />
          </div>
          <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
        </Form>
      </Card>
    </>
  );
};
ChartofAccountDetails.acl = {
  subject: "Chart of Accounts",
  action: "read",
};

export default ChartofAccountDetails;
