import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useAbility } from "../../contexts/abilityContext";
import { useTranslation } from "react-i18next";
import {
  DeleteFilled,
  PlusCircleFilled,
  EditFilled,
  SaveFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

const { Option } = Select;

const SalesBudgetHypothesis = ({ salesBudgetId }) => {
  let { t } = useTranslation();
  const { ability } = useAbility();
  const [open, setOpen] = useState(false);
  const canUpdate = ability.can("update", "Hypothesis");
  const canDelete = ability.can("delete", "Hypothesis");
  const [openHyphothese, setopenHyphothese] = useState(false);
  const [hypotheses, setHypotheses] = useState([]);

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form4] = Form.useForm();

  const [editingRow2, setEditingRow2] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  useEffect(() => {
    if (salesBudgetId) {
      console.log("Fetching hypotheses for Sales Budget ID:", salesBudgetId);
      getSalesBudgetHypotheses(salesBudgetId);
    } else {
      console.error("SalesBudgetId is undefined or null on component mount.");
    }
  }, [salesBudgetId]);

  const getSalesBudgetHypotheses = async (id) => {
    if (!id) {
      console.error("Attempted to fetch hypotheses without a valid ID.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/SalesBudgetHypothesiss/${id}`
      );

      if (response.data && Array.isArray(response.data)) {
        console.log(response)
        const sortedHypotheses = response.data.sort((p1, p2) =>
          p1.beginsOnYear < p2.beginsOnYear
            ? 1
            : p1.beginsOnYear > p2.beginsOnYear
            ? -1
            : 0
        );
        setHypotheses(sortedHypotheses);
        console.log(sortedHypotheses)
      } else if (response.data && !Array.isArray(response.data)) {
        if (
          response.data.hypotheses &&
          Array.isArray(response.data.hypotheses)
        ) {
          const sortedHypotheses = response.data.hypotheses.sort((p1, p2) =>
            p1.beginsOnYear < p2.beginsOnYear
              ? 1
              : p1.beginsOnYear > p2.beginsOnYear
              ? -1
              : 0
          );
          setHypotheses(sortedHypotheses);
        } else {
          console.log("Unexpected data structure:", response.data);
          setHypotheses([]);
        }
      } else {
        console.log("No data received.");
        setHypotheses([]);
      }
    } catch (error) {
      console.error("Error fetching sales budget hypotheses:", error);
    }
  };

  const OnopenHyphothese = async (values) => {
    if (!salesBudgetId) {
      console.error(
        "SalesBudgetId is undefined or null. Cannot create hypothesis."
      );
      message.error(t("ErrorCreatingHyphothesis"));
      return;
    }

    const hypothesisObject = {
      salesBudgetId,
      annualIncrease: values.annualIncrease,
      beginsOnYear: values.beginsOnYear
        ? values.beginsOnYear.format("YYYY")
        : "",
      beginsOnMonth: values.beginsOnMonth,
      hypothesis: values.hypothesis,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_JSON_API}/SalesBudgetHypothesiss`,
        hypothesisObject
      );
      console.log("Hypothesis created:", response.data);
      getSalesBudgetHypotheses(salesBudgetId);
      setOpen(false);
      message.success(t("HyphothesisCreatedSuccessfully"));
    } catch (err) {
      console.error("Error creating hypothesis:", err);
      message.error(t("ErrorCreatingHyphothesis"));
    }
    setopenHyphothese(false);
  };

  const deleteHyphothese = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_JSON_API}/SalesBudgetHypothesiss/${id}`);
      message.success(t("HyphothesisDeletedSuccessfully"));
      getSalesBudgetHypotheses(salesBudgetId);
    } catch (error) {
      console.error("Error deleting hypothesis:", error);
      message.error(t("ErrorDeletingHyphothesis"));
    }
  };

  const columnsHyphothese = [
    {
      title: <span style={{ textAlign: "center" }}>{t("Year")}</span>,
      dataIndex: "beginsOnYear",
      align: "center",
      sorter: (a, b) => a.beginsOnYear - b.beginsOnYear,
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseinputtheyear")}`,
                },
              ]}
            >
              <DatePicker disabled picker="year" />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{record.beginsOnYear}</div>;
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("AnnualIncrease")}</span>,
      dataIndex: "annualIncrease",
      align: "center",

      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="annualIncrease"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheAnnualIncrease")}`,
                },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                size={"large"}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{text}%</div>;
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("BeginOn")}</span>,
      dataIndex: "beginsOnMonth",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="begin"
              value="beginmonth"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheBeginMonth")}`,
                },
              ]}
            >
              <Select
                placeholder={t("Selectmonth")}
                size={"large"}
                style={{
                  width: "100%",
                }}
              >
                <Option value={1}>{t("January")}</Option>
                <Option value={2}>{t("February")}</Option>
                <Option value={3}>{t("March")}</Option>
                <Option value={4}>{t("April")}</Option>
                <Option value={5}>{t("May")}</Option>
                <Option value={6}>{t("June")}</Option>
                <Option value={7}>{t("July")}</Option>
                <Option value={8}>{t("August")}</Option>
                <Option value={9}>{t("September")}</Option>
                <Option value={10}>{t("October")}</Option>
                <Option value={11}>{t("November")}</Option>
                <Option value={12}>{t("December")}</Option>
              </Select>
            </Form.Item>
          );
        } else {
          return record.beginsOnMonth + "/" + record.beginsOnYear;
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("Hyphothesis")}</span>,
      dataIndex: "hypothesis",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="HyphotheseofGl"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheHyphothesis")}`,
                },
              ]}
            >
              <Input label={t("Hyphothesis")} />
            </Form.Item>
          );
        } else {
          return <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(text)}>{text}</div>;
        }
      },
    },

    {},
  ];
  if (canUpdate || canDelete) {
    columnsHyphothese.push({
      title: "Actions",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {editingRow2 === record.id ? (
            <>
              <Button
                icon={<CloseCircleFilled />}
                type="link"
                danger
                onClick={() => setEditingRow2(null)}
              >
                {t("cancel")}
              </Button>
              <Button icon={<SaveFilled />} type="link" htmlType="submit">
                {t("save")}
              </Button>
            </>
          ) : (
            <>
              {canUpdate && (
                <Popconfirm
                  type="link"
                  onClick={() => {
                    setEditingRow2(record.id);
                    form4.setFieldsValue({
                      year: dayjs(record.beginsOnYear),
                      annualIncrease: record.annualIncrease,
                      begin: record.beginsOnMonth,
                      HyphotheseofGl: record.hypothesis,
                    });
                  }}
                >
                  <Button type="link" icon={<EditFilled />}>
                    {t("Edit")}
                  </Button>
                </Popconfirm>
              )}
              {canDelete && (
                <Popconfirm
                  title={t("deleterowHypo")}
                  cancelText={t("no")}
                  okText={t("yes")}
                  onConfirm={() => deleteHyphothese(record.id)}
                >
                  <Button
                    type="link"
                    danger
                    icon={<DeleteFilled />}
                    style={{ marginLeft: ".5rem" }}
                  >
                    {t("Delete")}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </Space>
      ),
    });
  }

  const onFinishEditHypo = async (e) => {
    const obj = {
      id: editingRow2,
      annualIncrease: e.annualIncrease,
      beginsOnYear: e.year.$y + "",
      beginsOnMonth: e.begin,
      hypothesis: e.HyphotheseofGl,
    };

    console.log("updated data:", obj);

    try {
      const updateResponse = await axios.put(
        `${process.env.REACT_APP_JSON_API}/SalesBudgetHypothesiss`,
        obj
      );
      console.log("Update response:", updateResponse);

      if (salesBudgetId) {
        await getSalesBudgetHypotheses(salesBudgetId);
        setEditingRow2(null);
        message.success(t("HyphothesisUpdatedSuccessfully"));
      } else {
        console.error("No valid salesBudgetId available after update.");
      }
    } catch (error) {
      console.error("Error updating hypothesis:", error);
      message.error(t("ErrorUpdatingHyphothesis"));
    }
  };

  const CollectionCreateHyphothesis = ({
    openHyphothese,
    OnopenHyphothese,
    onCancel,
  }) => {
    return (
      <Modal
        forceRender
        open={openHyphothese}
        title={t("CreateHypothesisss")}
        okText={t("save")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form2
            .validateFields()
            .then((values) => {
              form2.resetFields();
              OnopenHyphothese(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form2}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          <Form.Item
            name="beginsOnYear"
            label={t("Year")}
            tooltip="Select the year the hypothesis starts."
            rules={[{ required: true, message: t("Pleaseinputtheyear") }]}
          >
            <DatePicker
              placeholder={t("Selectyear")}
              picker="year"
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            name="annualIncrease"
            label={t("AnnualIncrease")}
            tooltip="Define the annual percentage increase."
            rules={[
              { required: true, message: t("PleaseinputtheAnnualIncrease") },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              style={{ width: "100%" }}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>

          <Form.Item
            name="beginsOnMonth"
            label={t("BeginOnMonth")}
            tooltip="Select the month the hypothesis starts."
            rules={[{ required: true, message: t("PleaseinputtheBeginMonth") }]}
          >
            <Select placeholder={t("Selectmonth")} style={{ width: "100%" }}>
              <Option value={1}>{"January"}</Option>
              <Option value={2}>{"February"}</Option>
              <Option value={3}>{"March"}</Option>
              <Option value={4}>{"April"}</Option>
              <Option value={5}>{"May"}</Option>
              <Option value={6}>{"June"}</Option>
              <Option value={7}>{"July"}</Option>
              <Option value={8}>{"August"}</Option>
              <Option value={9}>{"September"}</Option>
              <Option value={10}>{"October"}</Option>
              <Option value={11}>{"November"}</Option>
              <Option value={12}>{"December"}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="hypothesis"
            label={t("Hyphothesis")}
            tooltip="Describe the hypothesis in detail."
            rules={[
              { required: true, message: t("PleaseinputtheHyphothesis") },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      <Card bordered={false} className="header-solid mb-24">
        <Row justify="end" gutter={[16, 16]}>
          <Space
            style={{
              display: "flex",
              marginBottom: 8,
            }}
            align="baseline"
          >
            {ability.can("create", "Hypothesis") && (
              <Button
                className="Create-button"
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => {
                  setopenHyphothese(true);
                }}
                style={{
                  textAlign: "right",
                  marginBottom: "14px",
                }}
              >
                {t("CreateanewHyphothesis")}
              </Button>
            )}
          </Space>
        </Row>

        <CollectionCreateHyphothesis
          openHyphothese={openHyphothese}
          OnopenHyphothese={OnopenHyphothese}
          onCancel={() => {
            setopenHyphothese(false);
            form2.resetFields();
          }}
        />

        <Form form={form4} onFinish={onFinishEditHypo}>
          <div style={{ overflowX: "auto" }}>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            bordered
            columns={columnsHyphothese}
            dataSource={hypotheses}
          />
          </div>
            <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
        </Form>
      </Card>
    </>
  );
};

export default SalesBudgetHypothesis;
