import { ArrowLeftOutlined, ExportOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Empty,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Statistic,
  Table,
  Text,
  Typography,
  message,
} from "antd";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { JSON_API } from "../services/Constants";
import Reportoption from "./Reportoption";

const ReportViewer = () => {
  const { reportId } = useParams();
  const [standardreport, setStandardreport] = useState([]);
  const stateParamVal = useLocation().state.stateParam;
  const { Text } = Typography;

  return (
    <>
      <Card>
        <Reportoption reportid={reportId} />
      </Card>
    </>
  );
};

export default ReportViewer;
