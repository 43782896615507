import React from "react";
import "../../assets/styles/SharedSpinner.css";

const SharedSpinner = () => {
  return (
    <div className="custom-spinner">
      <div className="spinner"></div>
    </div>
  );
};

export default SharedSpinner;
