import {
  HeartFilled,
  SettingOutlined,
  SolutionOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Col, Drawer, FloatButton, Layout, Row } from "antd";
import { useState } from "react";
import Departments from "../../pages/Human Resources/Departments";
import EnterpriseTitles from "../../pages/Human Resources/EnterpriseTitles";
import PostClassification from "../../pages/Human Resources/PostClassification";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const [openDep, setOpenDep] = useState(false);
  const [openPostClass, setOpenPostClass] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);

  const hndleDepartmentDrawer = () => {
    setOpenDep(true);
  };

  const hndleTitlesDrawer = () => {
    setOpenTitle(true);
  };

  const hndlePostClassificationDrawer = () => {
    setOpenPostClass(true);
  };
  const onClose = () => {
    setOpenDep(false);
    setOpenPostClass(false);
    setOpenTitle(false);
  };
  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            © 2022, made with
            {<HeartFilled />} by
            <a href="#" className="font-weight-bold" target="_blank">
              Agilience
            </a>
            .
          </div>
        </Col>
        {/* <Col xs={24} md={12} lg={12}>
          <FloatButton.Group
            trigger="hover"
            type="primary"
            style={{
              right: 24,
            }}
            icon={<SettingOutlined />}
          >
            <FloatButton
              onClick={hndleDepartmentDrawer}
              icon={<ToolOutlined />}
            />
            <FloatButton
              onClick={hndlePostClassificationDrawer}
              icon={<SolutionOutlined />}
            />
            <FloatButton
              onClick={hndleTitlesDrawer}
              icon={<SolutionOutlined />}
            />
          </FloatButton.Group>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">
                <a href="#" className="nav-link text-muted" target="_blank">
                  Agilience
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link text-muted" target="_blank">
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </Col> */}
      </Row>
      {/* <Drawer
        title="Department Configuration"
        width={500}
        onClose={onClose}
        open={openDep}
      >
        <Departments />
      </Drawer>
      <Drawer
        align="center"
        title="Post Classifications Configuration"
        width={500}
        onClose={onClose}
        open={openPostClass}
      >
        <PostClassification />
      </Drawer>
      <Drawer
        title="Enterprise Titles Configuration"
        width={500}
        onClose={onClose}
        open={openTitle}
      >
        <EnterpriseTitles />
      </Drawer> */}
    </AntFooter>
  );
}

export default Footer;
