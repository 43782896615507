import {
  Card,
  DatePicker,
  Table,
  Tabs,
  Empty,
  Space,
  Button,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { countmonth, summary } from "../Financial Statement/Utils/Function";
import {
  CalendarOutlined,
  RightOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
const { TabPane } = Tabs;
const SalesBudgetSummary = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [SalesBudget, setSalesBudget] = useState([]);

  useEffect(() => {
    const defaultYear = new Date().getFullYear();
    setYear(defaultYear);
  }, []);

  const fetchData = async () => {
    if (!year) {
      message.warning(t("Selectyear"));
      return;
    }
    await axios
      .get(
        `${process.env.REACT_APP_JSON_API}/SalesBudgets/enterprise/${Company.id}?year=${year}`
      )
      .then((res) => {
        console.log(res);
        setSalesBudget(res.data);

        console.log("DetailsByEnterpriseAndYear :", SalesBudget);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  useEffect(() => {
    if (year) {
      fetchData();
    } else {
      setSalesBudget([]);
    }
  }, [Company.id, year]);

  const onChangee = (date) => {
    if (!date) {
      setYear(null);
      setSalesBudget([]);
      message.info(t("PleaseSelectAyearToDisplayTheData"));
      return;
    }
    setYear(date.year());
  };

  const renderMainInfo = (key, record) => {
    console.log(record)
    const info = record[key];
    return (
      <div style={{ textAlign: "center", fontWeight: "bold" }}>{info}</div>
    );
  };

  const mainInfoColumns = [
    {
      title: `${t("Class")}`,
      align: "center",
      render: (_, record) =>
        renderMainInfo(
          currentLang === "en"
            ? "financialStatementClassLabelEn"
            : "financialStatementClassLabelFr",
          record
        ),
    },
    {
      title: `${t("Category")}`,
      align: "center",
      render: (_, record) =>
        renderMainInfo(
          currentLang === "en"
            ? "financialStatementCategoryLabelEn"
            : "financialStatementCategoryLabelFr",
          record
        ),
    },
    {
      title: "Description",
      align: "center",
      render: (_, record) => renderMainInfo("description", record),
    },
  ];

  const findMonthDetails = (monthDetails, month) => {
    return (
      monthDetails.find((detail) => detail.month === month) || {
        budget: 0,
        real: 0,
        performance: 0,
      }
    );
  };

  const createMonthColumns = (type) => {
    return months.map((month,index) => ({
      title: month,
      dataIndex: month,
      key: month,
      align: "center",
      render: (text, record) => {
        console.log('index',index,record,text)
        if(type=='budget'){
        return `${record.budgets[index]}`;
        }else{return `${record.reals[index]}`;}
      },
    }));
  };

  const budgetMonthColumns = createMonthColumns("budget");
  const realMonthColumns = createMonthColumns("real");
  const performanceMonthColumns = months.map((month,index) => ({
    title: month,
    children: [
      {
        title: t("Budget"),
        dataIndex: month,
        key: `${month}-budget`,
        align: "center",
        render: (text, record) => {
          
          return `${record.budgets[index]}`;
        },
      },
      {
        title: t("Real"),
        dataIndex: month,
        key: `${month}-real`,
        align: "center",
        render: (text, record) => {
          return `${record.reals[index]}`;
        },
      },
      {
        title: t("Difference"),
        dataIndex: month,
        key: `${month}-performance`,
        align: "center",
        render: (_, record) => {
          const color = record.performances[index] >= 0 ? "blue" : "red";
          return (
            <span style={{ color, fontWeight: "bold" }}>
              {record.performances[index]}
            </span>
          );
        },
      },
    ],
  }));

  const columnsBudget = [...mainInfoColumns, ...budgetMonthColumns];
  const columnsReal = [...mainInfoColumns, ...realMonthColumns];
  const columnsPerformance = [...mainInfoColumns, ...performanceMonthColumns];

  columnsBudget.push({
    title: t("Total"),
    dataIndex: "budgetTotal",
    key: "budgetTotal",
    align: "center",
    render: (_, record) => {
      const budgetTotal = record.budgets.reduce((total, budget) => total + budget, 0);//record.yearSalesBudgets[0];
      const color = budgetTotal >= 0 ? "blue" : "red";
      return (
        <span style={{ color, fontWeight: "bold" }}>
          {budgetTotal}
        </span>
      );
    },
  });

  columnsReal.push({
    title: t("Total"),
    dataIndex: "realTotal",
    key: "realTotal",
    align: "center",
    render: (_, record) => {
      const realTotal  = record.reals.reduce((total, budget) => total + budget, 0);//record.yearSalesBudgets[0];
      const color = realTotal >= 0 ? "blue" : "red";
      return (
        <span style={{ color, fontWeight: "bold" }}>
          {realTotal}
        </span>
      );
    },
  });

  columnsPerformance.push({
    title: t("Total"),
    children: [
      {
        title: t("Budget"),
        dataIndex: "budgetTotal",
        key: "budgetTotal",
        align: "center",
        render: (_, record) => {
          const budgetTotal=record.budgets.reduce((total, budget) => total + budget, 0);//record.yearSalesBudgets[0];
          return budgetTotal;
        },
      },
      {
        title: t("Real"),
        dataIndex: "realTotal",
        key: "realTotal",
        align: "center",
        render: (_, record) => {
          const realTotal = record.reals.reduce((total, budget) => total + budget, 0);//record.yearSalesBudgets[0];
          return realTotal;
        },
      },
      {
        title: t("Difference"),
        dataIndex: "performanceTotal",
        key: "performanceTotal",
        align: "center",
        render: (_, record) => {
          const performanceTotal = record.performances.reduce((total, budget) => total + budget, 0);//record.yearSalesBudgets[0];
          const color = performanceTotal >= 0 ? "blue" : "red";
          return (
            <span style={{ color, fontWeight: "bold" }}>
              {performanceTotal}
            </span>
          );
        },
      },
    ],
  });

  return (
    <Card
      title={
        <span>
          <CalendarOutlined style={{ marginRight: 8 }} />
          {t("SalesBudgetSummary")}
        </span>
      }
      extra={
        <div style={{ display: "flex", alignItems: "center" }}>
          <Space>
            <DatePicker
              name="year"
              picker="year"
              placeholder={t("Select year")}
              style={{ width: 250, height: 35 }}
              onChange={onChangee}
              value={year ? dayjs().year(year) : null}
              suffixIcon={<RightOutlined />}
            />

            <Link
              to={{
                pathname: `/sales-budget`,
              }}
            >
              <Button
                className="Create-button"
                type="primary"
                danger
                icon={<RollbackOutlined style={{ color: "#fff" }} />}
                style={{
                  marginLeft: "flex",
                  width: 300,
                  height: 35,
                }}
              >
                {t("GoBackToSalesBudget")}
              </Button>
            </Link>
          </Space>
        </div>
      }
    >
      <Tabs
        defaultActiveKey="Budget"
        style={{ marginBottom: 35, width: "100%" }}
        type="card"
        centered
      >
        <TabPane tab={t("Budget")} key="Budget">
          <Table
            columns={columnsBudget}
            dataSource={SalesBudget.length ? SalesBudget : undefined}
            locale={{ emptyText: <Empty description={t("NoData")} /> }}
            scroll={{ x: 1000 }}
            pagination={false}
            bordered
          />
        </TabPane>
        <TabPane tab={t("Real")} key="Real">
          <Table
            columns={columnsReal}
            dataSource={SalesBudget.length ? SalesBudget : undefined}
            locale={{ emptyText: <Empty description={t("NoData")} /> }}
            scroll={{ x: 1000 }}
            pagination={false}
            bordered
          />
        </TabPane>
        <TabPane tab={t("Performance")} key="Performance">
          <Table
            columns={columnsPerformance}
            dataSource={SalesBudget.length ? SalesBudget : undefined}
            locale={{ emptyText: <Empty description={t("NoData")} /> }}
            scroll={{ x: 1000 }}
            pagination={false}
            bordered
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

SalesBudgetSummary.acl = {
  subject: "Sales Budget",
  action: "read",
};

export default SalesBudgetSummary;
