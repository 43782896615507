import React, { useContext } from "react";

import { Button, Input, Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CompanyContext } from "../../../contexts/CompanyContext";
const { Text } = Typography;
const { TextArea } = Input;

function PerformanceRevenue(props) {
  let { t } = useTranslation();
  const [Reals, setReals] = useState("");
  const { TextArea } = Input;

  const { Company } = useContext(CompanyContext);

  const DisplayRevenueSummary = () => {
    console.log("ttesstt 1");
    const items = [
      {
        title: `${t("Year")}`,
        width: 120,
        dataIndex: "year",
        align: "right",
        //   key: "year",
        render: (text, record) => {
          return <p style={{ textAlign: "right" }}>{text}</p>;
        },
      },
    ];

    const months = [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ];

    const startingMonthIndex = Company.startPeriod - 1;

    for (let i = 0; i < months.length; i++) {
      const monthIndex = (i + startingMonthIndex) % months.length;
      const monthName = months[monthIndex];

      items.push({
        title: monthName,
        children: [
          {
            title: <p style={{ textAlign: "right", width: 100 }}>Budget</p>,
            dataIndex: "montantbudget",
            width: 150,
            align: "right",
            render: (text, record) => {
              let budget = 0;
              return (
                <>
                  {props.AssetBudgets.map((e) => {
                    if (e.year == record.year) {
                      budget = e.budgets[monthIndex].toFixed(0);
                    }
                  })}
                  <p style={{ textAlign: "right" }}>{budget}</p>
                </>
              );
            },
          },
          {
            title: (
              <p style={{ textAlign: "right", color: "blue", width: 100 }}>
                {t("Real")}
              </p>
            ),

            dataIndex: "montantbudget",
            aligne: "left",
            width: 150,
            align: "right",
            render: (text, record) => {
              return (
                <p style={{ textAlign: "right" }}>
                  {record.reals[monthIndex].toFixed(0)}
                </p>
              );
            },
          },
          {
            title: (
              <p style={{ textAlign: "right", width: 100 }}>
                {t("Difference")}
              </p>
            ),

            dataIndex: "street",
            width: 150,
            align: "right",
            render: (text, record) => {
              let budget = 0;
              return (
                <>
                  {props.AssetBudgets.map((e) => {
                    if (e.year == record.year) {
                      budget = e.budgets[monthIndex].toFixed(0);
                    }
                  })}
                  <p style={{ textAlign: "right" }}>
                    {(record.reals[monthIndex] - budget).toFixed(0)}
                  </p>
                </>
              );
            },
            render: (text, record) => {
              let budget = 0;
              let performance = 0;
              return (
                <>
                  {props.AssetBudgets.map((e) => {
                    if (e.year == record.year) {
                      budget = e.budgets[monthIndex].toFixed(0);
                      performance = record.reals[monthIndex] - budget;
                    }
                  })}
                  {performance < 0 ? (
                    <p
                      style={{
                        textAlign: "right",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      ( {(record.reals[monthIndex] - budget).toFixed(0)} )
                    </p>
                  ) : (
                    <p
                      style={{
                        textAlign: "right",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      {(record.reals[monthIndex] - budget).toFixed(0)}
                    </p>
                  )}
                </>
              );
            },
          },
        ],
      });
    }

    items.push({
      title: <h1 style={{ textAlign: "right", width: 100 }}>Total </h1>,
      width: 120,
      render: (_, record) => {
        let performance = 0;
        props.AssetBudgets.forEach((e) => {
          if (e.year === record.year) {
            performance += record.reals.reduce(
              (acc, val, index) => acc + (val - e.budgets[index]),
              0
            );
          }
        });

        performance = performance.toFixed(0);

        const performanceStyle = {
          textAlign: "right",
          fontWeight: "bold",
          color: performance >= 0 ? "blue" : "red",
        };

        return (
          <>
            <p style={performanceStyle}>{performance}</p>
          </>
        );
      },
    });

    return (
      <Table
        columns={items}
        dataSource={props.AssetReals}
        scroll={{
          x: 1300,
        }}
        pagination={true}
        bordered
        summary={(pageData) => {
          const items = [];

          const startingMonthIndex = Company.startPeriod - 1;

          let totalmontant = 0;
          let totalbudget = 0;
          let totalfevrierbudget = 0;
          let totalMarsbudget = 0;
          let totalavrilbudget = 0;
          let totalmaibudget = 0;
          let totalJuinbudget = 0;
          let totaljuilletbudget = 0;
          let totalaoutbudget = 0;
          let totalseptembrebudget = 0;
          let totaloctobrebudget = 0;
          let totalnovemberbudget = 0;
          let totaldecembrebudget = 0;
          let totaltotal = 0;

          let totalbudgetcount = 0;
          let totalfevrierbudgetcount = 0;
          let totalMarsbudgetcount = 0;
          let totalavrilbudgetcount = 0;
          let totalmaibudgetcount = 0;
          let totalJuinbudgetcount = 0;
          let totaljuilletbudgetcount = 0;
          let totalaoutbudgetcount = 0;
          let totalseptembrebudgetcount = 0;
          let totaloctobrebudgetcount = 0;
          let totalnovemberbudgetcount = 0;
          let totaldecembrebudgetcount = 0;

          {
            props.AssetBudgets &&
              props.AssetBudgets.map((e) => {
                totalmontant = e.budgets[0];
                if (totalmontant.toFixed(0) != 0) {
                  totalbudgetcount += 1;
                }
                totalbudget += totalmontant;
                totalmontant = e.budgets[1];
                if (totalmontant.toFixed(0) != 0) {
                  totalfevrierbudgetcount += 1;
                }
                totalfevrierbudget += totalmontant;
                totalmontant = e.budgets[2];
                if (totalmontant.toFixed(0) != 0) {
                  totalMarsbudgetcount += 1;
                }
                totalMarsbudget += totalmontant;
                totalmontant = e.budgets[3];
                if (totalmontant.toFixed(0) != 0) {
                  totalavrilbudgetcount += 1;
                }
                totalavrilbudget += totalmontant;
                totalmontant = e.budgets[4];
                if (totalmontant.toFixed(0) != 0) {
                  totalmaibudgetcount += 1;
                }
                totalmaibudget += totalmontant;
                totalmontant = e.budgets[5];
                if (totalmontant.toFixed(0) != 0) {
                  totalJuinbudgetcount += 1;
                }
                totalJuinbudget += totalmontant;
                totalmontant = e.budgets[6];
                if (totalmontant.toFixed(0) != 0) {
                  totaljuilletbudgetcount += 1;
                }
                totaljuilletbudget += totalmontant;
                totalmontant = e.budgets[7];
                if (totalmontant.toFixed(0) != 0) {
                  totalaoutbudgetcount += 1;
                }
                totalaoutbudget += totalmontant;
                totalmontant = e.budgets[8];
                if (totalmontant.toFixed(0) != 0) {
                  totalseptembrebudgetcount += 1;
                }
                totalseptembrebudget += totalmontant;
                totalmontant = e.budgets[9];
                if (totalmontant.toFixed(0) != 0) {
                  totaloctobrebudgetcount += 1;
                }
                totaloctobrebudget += totalmontant;
                totalmontant = e.budgets[10];
                if (totalmontant.toFixed(0) != 0) {
                  totalnovemberbudgetcount += 1;
                }
                totalnovemberbudget += totalmontant;
                totalmontant = e.budgets[11];
                if (totalmontant != 0) {
                  totaldecembrebudgetcount += 1;
                }
                totaldecembrebudget += totalmontant;
                totalmontant = e.totalBudget;
                totaltotal += totalmontant;
              });
          }

          const months = [
            totalbudget,
            totalfevrierbudget,
            totalMarsbudget,
            totalavrilbudget,
            totalmaibudget,
            totalJuinbudget,
            totaljuilletbudget,
            totalaoutbudget,
            totalseptembrebudget,
            totaloctobrebudget,
            totalnovemberbudget,
            totaldecembrebudget,
          ];

          const monthscount = [
            totalbudgetcount,
            totalfevrierbudgetcount,
            totalMarsbudgetcount,
            totalavrilbudgetcount,
            totalmaibudgetcount,
            totalJuinbudgetcount,
            totaljuilletbudgetcount,
            totalaoutbudgetcount,
            totalseptembrebudgetcount,
            totaloctobrebudgetcount,
            totalnovemberbudgetcount,
            totaldecembrebudgetcount,
          ];
          let totalReal = 0;
          let totalfevrierReal = 0;
          let totalMarsReal = 0.0;
          let totalavrilReal = 0;
          let totalmaiReal = 0;
          let totalJuinReal = 0.0;
          let totaljuilletReal = 0;
          let totalaoutReal = 0;
          let totalseptembreReal = 0;
          let totaloctobreReal = 0;
          let totalnovemberReal = 0;
          let totaldecembreReal = 0;
          let totaltotalreal = 0;

          props.AssetReals &&
            props.AssetReals.map((e) => {
              //console.log(e.Reals[1]),
              totalmontant = e.reals[0];
              if (totalmontant.toFixed(0) != 0) {
                totalbudgetcount += 1;
              }
              totalReal += totalmontant;
              totalmontant = e.reals[1];
              if (totalmontant.toFixed(0) != 0) {
                totalfevrierbudgetcount += 1;
              }
              totalfevrierReal += totalmontant;
              totalmontant = e.reals[2];
              if (totalmontant.toFixed(0) != 0) {
                totalMarsbudgetcount += 1;
              }
              totalMarsReal += totalmontant;
              totalmontant = e.reals[3];
              if (totalmontant.toFixed(0) != 0) {
                totalavrilbudgetcount += 1;
              }
              totalavrilReal += totalmontant;
              totalmontant = e.reals[4];
              if (totalmontant.toFixed(0) != 0) {
                totalmaibudgetcount += 1;
              }
              totalmaiReal += totalmontant;
              totalmontant = e.reals[5];
              if (totalmontant.toFixed(0) != 0) {
                totalJuinbudgetcount += 1;
              }
              totalJuinReal += totalmontant;
              totalmontant = e.reals[6];
              if (totalmontant.toFixed(0) != 0) {
                totaljuilletbudgetcount += 1;
              }
              totaljuilletReal += totalmontant;
              totalmontant = e.reals[7];
              if (totalmontant.toFixed(0) != 0) {
                totalaoutbudgetcount += 1;
              }
              totalaoutReal += totalmontant;
              totalmontant = e.reals[8];
              if (totalmontant.toFixed(0) != 0) {
                totalseptembrebudgetcount += 1;
              }
              totalseptembreReal += totalmontant;
              totalmontant = e.reals[9];
              if (totalmontant.toFixed(0) != 0) {
                totaloctobrebudgetcount += 1;
              }
              totaloctobreReal += totalmontant;
              totalmontant = e.reals[10];
              if (totalmontant.toFixed(0) != 0) {
                totalnovemberbudgetcount += 1;
              }
              totalnovemberReal += totalmontant;
              totalmontant = e.reals[11];
              if (totalmontant.toFixed(0) != 0) {
                totaldecembrebudgetcount += 1;
              }
              totaldecembreReal += totalmontant;
              totalmontant = e.totalBudget;
              totaltotal += totalmontant;
            });
          const monthsReal = [
            totalReal,
            totalfevrierReal,
            totalMarsReal,
            totalavrilReal,
            totalmaiReal,
            totalJuinReal,
            totaljuilletReal,
            totalaoutReal,
            totalseptembreReal,
            totaloctobreReal,
            totalnovemberReal,
            totaldecembreReal,
          ];

          for (let i = 0; i < months.length; i++) {
            const monthIndex = (i + startingMonthIndex) % months.length;
            const monthName = months[monthIndex];
            const monthNameReal = monthsReal[monthIndex];
            const monthNameBudget = months[monthIndex];
            let monthNamecount = monthscount[monthIndex];

            if (monthNamecount === 0) {
              monthNamecount = 1;
            }

            const difference = monthNameReal - monthNameBudget;
            const differenceFormatted = (difference / monthNamecount).toFixed(
              0
            );
            const differenceStyle = {
              textAlign: "right",
              color: difference >= 0 ? "blue" : "red",
              fontWeight: "bold",
            };

            items.push(
              <Table.Summary.Cell index={monthIndex}>
                <Text style={{ textAlign: "right" }}>
                  {(monthName / monthNamecount).toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            );

            items.push(
              <Table.Summary.Cell index={monthIndex}>
                <Text style={{ textAlign: "right" }}>
                  {(monthNameReal / monthNamecount).toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            );

            items.push(
              <Table.Summary.Cell index={monthIndex}>
                <Text style={differenceStyle}>{differenceFormatted}</Text>
              </Table.Summary.Cell>
            );
          }

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} colSpan={1}>
                  <h3 style={{ textAlign: "right" }}>{t("Average")}</h3>
                </Table.Summary.Cell>

                {items}

                <Table.Summary.Cell index={10}>
                  <Text>
                    <h4>
                      {totalReal -
                        totalbudget +
                        totalfevrierReal -
                        totalfevrierbudget +
                        totalMarsReal -
                        totalMarsbudget +
                        totalavrilReal -
                        totalavrilbudget +
                        totalmaiReal -
                        totalmaibudget +
                        totalJuinReal -
                        totalJuinbudget +
                        totaljuilletReal -
                        totaljuilletbudget +
                        totalaoutReal -
                        totalaoutbudget +
                        totalseptembreReal -
                        totalseptembrebudget +
                        totaloctobreReal -
                        totaloctobrebudget +
                        totalnovemberReal -
                        totalnovemberbudget +
                        totaldecembreReal -
                        totaldecembrebudget}
                    </h4>
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    );
  };

  return <DisplayRevenueSummary />;
}

export default PerformanceRevenue;
