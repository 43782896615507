import {
  DownloadOutlined,
  RollbackOutlined,
  WalletTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Tag,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text, Title } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

// ON CHANGE SELECT

const SelectBox = React.memo(({ value, options, onChange }) => {
  return (
    <Select value={value} onChange={onChange}>
      {options.map((option) => (
        <Option
          key={option.id ? option.id : option.gifi}
          value={option.id ? option.id : option.gifi}
        >
          {option.label
            ? option.label
            : "(GIFI: " + option.gifi + ") " + option.description}
        </Option>
      ))}
    </Select>
  );
});

function BudgetRealSummary() {
  const { Company } = useContext(CompanyContext);
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [employees, setEmployees] = useState([]);
  const [employeesFinancialDetails, setEmployeesFinancialDetails] = useState(
    []
  );
  const [employeeId, setEmployeeId] = useState(null);
  const [newEmployeeDetais, setNewEmployeeDetails] = useState({});
  const [rowAdded, setRowAdded] = useState(false);
  const [year, setYear] = useState("");
  const [enterpriseDepartmentId, setEnterpriseDepartmentId] = useState("");
  const [enterpriseDepartments, setEnterpriseDepartments] = useState([]);
  const [tabIndex, setTabIndex] = useState(1);

  const renderSwitch = (param) => {
    switch (param) {
      case "Weekly":
        return { text: t("Weekly"), color: "green" };
      case "Monthly":
        return { text: t("Monthly"), color: "blue" };
      case "15 Days":
        return { text: t("In15Days"), color: "orange" };
      default:
        return { text: null, color: null };
    }
  };

  const predefinedColors = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];

  function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % predefinedColors.length;
    return predefinedColors[index];
  }

  const getEnterpriseDepartments = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments/Filter/${Company.id}`)
      .then((res) => {
        setEnterpriseDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getEnterpriseDepartments();
    getEmployeesByEnterpriseAndDeparmtent();
  }, [Company.id, enterpriseDepartmentId, year]);

  const getEmployeesByEnterpriseAndDeparmtent = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_JSON_API}/Employees/enterprise/${Company.id}/${enterpriseDepartmentId}`
      )
      .then((response) => {
        setEmployees(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        setEmployees(null);
      });
  };

  const getData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_JSON_API}/EmployeeFinancialDetails/enterprise?enterpriseId=${Company.id}&departmentId=${enterpriseDepartmentId}&year=${year}`
      )
      .then((response) => {
        setEmployeesFinancialDetails(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setEmployeesFinancialDetails([]);
      });
  };

  //handle Changing Year
  const handleChangeYear = (date, dateString) => {
    // setChartofAccounts(null);
    console.log(date, dateString);
    setYear(dateString);
  };

  const Departments =
    enterpriseDepartments == null
      ? []
      : enterpriseDepartments.map((ed) => ({ value: ed.id, label: ed.label }));
  //find the first month
  const date = new Date();
  date.setMonth(0);
  const startingMonthIndex = Company.startPeriod - 1;
  const onTabChange = (index) => {
    setTabIndex(index);
  };
  const performanceColumns = [
    {
      align: "center",
      title: <b>{t("Employee")}</b>,
      dataIndex: "fullName",
      render: (fullName, record) => {
        return (
          <div
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {fullName}
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Title")}</b>
        </div>
      ),
      dataIndex: "title",
      key: "title",
      render: (title, record) => {
        const color = stringToColor(title);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {title}
            </Tag>
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Department")}</b>
        </div>
      ),
      dataIndex: "department",
      render: (_, record) => {
        const color = stringToColor(record.department);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {record.department}
            </Tag>
          </div>
        );
      },
    },
    {
      textAlign: "center",
      title: <b>{t("AnnualSalary")}</b>,
      dataIndex: "annualSalary",
      render: (annualSalary, record) => (
        <div style={{ textAlign: "center" }}>{annualSalary}</div>
      ),
    },
    {
      textAlign: "center",
      title: <b>{t("SocialAdvantages")}</b>,
      dataIndex: "socialAdvantages",
      render: (socialAdvantages, record) => (
        <div style={{ textAlign: "center" }}>
          {socialAdvantages ? (
            <span style={{ fontWeight: "bold" }}>{socialAdvantages}</span>
          ) : (
            <span
              style={{ fontWeight: "bold", color: "red", fontSize: "11px" }}
            >
              {t("NoSocialAdvantages")}
            </span>
          )}
        </div>
      ),
    },

    {
      title: <b>Total</b>,
      render: (_, record) => (
        <div style={{ textAlign: "center", width: 60 }}>
          {record.annualSalary + record.socialAdvantages}
        </div>
      ),
    },
    {
      textAlign: "center",
      title: <b>{t("PayPeriod")}</b>,
      dataIndex: "payPeriod",
      render: (_, record) => {
        const { text, color } = renderSwitch(record.payPeriod);
        return (
          <div style={{ textAlign: "center" }}>
            {text && (
              <Tag
                style={{ fontWeight: "bold", fontSize: "12px" }}
                color={color}
              >
                {text}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  Array.from({ length: 12 }).forEach((_, i) => {
    const monthIndex = (i + startingMonthIndex) % 12;
    date.setMonth(monthIndex);
    const monthName = date.toLocaleString("en-US", { month: "long" });

    performanceColumns.push({
      title: <b>{t(monthName)}</b>,
      align: "center",
      children: [
        {
          title: "Budget",
          width: 150,
          align: "center",
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {
                record.budgetRealsPerformances.find(
                  (e) => e.month - 1 === monthIndex
                ).budget
              }
            </div>
          ),
        },
        {
          title: "Real",
          width: 150,
          align: "center",
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {
                record.budgetRealsPerformances.find(
                  (e) => e.month - 1 === monthIndex
                ).real
              }
            </div>
          ),
        },
        {
          title: "Difference",
          dataIndex: "street",
          width: 150,
          align: "center",
          render: (text, record) => {
            const difference = record.budgetRealsPerformances.find(
              (e) => e.month - 1 === monthIndex
            ).performance;

            return (
              <div
                style={{
                  textAlign: "center",
                  color: difference >= 0 ? "blue" : "red",
                  fontWeight: "bold",
                }}
              >
                {difference}
              </div>
            );
          },
        },
      ],
    });
  });

  performanceColumns.push({
    title: <b>Total</b>,
    key: "total",
    align: "center",
    width: 120,
    render: (_, record) => {
      const total = record.budgetRealsPerformances.reduce(
        (sum, elem) => sum + elem.performance,
        0
      );
      return (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Impact",
            fontSize: "15px",
            color: total >= 0 ? "blue" : "red",
          }}
        >
          {total}
        </div>
      );
    },
  });

  const budgetsSummaryClomuns = [
    {
      align: "center",
      title: <b>{t("Employee")}</b>,
      dataIndex: "fullName",
      render: (_, record) => {
        return (
          <div
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {record.fullName}
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Title")}</b>
        </div>
      ),
      dataIndex: "title",
      key: "title",
      render: (title, record) => {
        const color = stringToColor(title);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {title}
            </Tag>
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Department")}</b>
        </div>
      ),
      dataIndex: "department",
      render: (_, record) => {
        const color = stringToColor(record.department);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {record.department}
            </Tag>
          </div>
        );
      },
    },

    {
      textAlign: "center",
      title: <b>{t("AnnualSalary")}</b>,
      dataIndex: "annualSalary",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.annualSalary}</div>
      ),
    },
    {
      textAlign: "center",
      title: <b>{t("SocialAdvantages")}</b>,
      dataIndex: "socialAdvantages",
      render: (socialAdvantages, record) => (
        <div style={{ textAlign: "center" }}>
          {socialAdvantages ? (
            <span style={{ fontWeight: "bold" }}>{socialAdvantages}</span>
          ) : (
            <span
              style={{ fontWeight: "bold", color: "red", fontSize: "11px" }}
            >
              {t("NoSocialAdvantages")}
            </span>
          )}
        </div>
      ),
    },
    {
      title: <b>Total</b>,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center", width: 60 }}>
          {record.annualSalary + record.socialAdvantages}
        </div>
      ),
    },
    {
      align: "center",
      title: <b>{t("PayPeriod")}</b>,
      dataIndex: "payPeriod",
      render: (_, record) => {
        const { text, color } = renderSwitch(record.payPeriod);
        return (
          <div style={{ textAlign: "center" }}>
            {text && (
              <Tag
                style={{ fontWeight: "bold", fontSize: "12px" }}
                color={color}
              >
                {text}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];
  Array.from({ length: 12 }).forEach((_, i) => {
    const monthIndex = (i + startingMonthIndex) % 12;
    date.setMonth(monthIndex);
    const monthName = date.toLocaleString("en-US", { month: "long" });
    budgetsSummaryClomuns.push({
      title: <b>{t(monthName)}</b>,
      align: "center",
      render: (text, record) => {
        return (
          <div style={{ textAlign: "center", width: 100 }}>
            {tabIndex === 1
              ? record.budgetRealsPerformances.find(
                  (e) => e.month - 1 === monthIndex
                ).budget
              : record.budgetRealsPerformances.find(
                  (e) => e.month - 1 === monthIndex
                ).real}
          </div>
        );
      },
    });
  });

  budgetsSummaryClomuns.push({
    title: <b>Total</b>,
    key: "total",
    align: "center",
    width: 120,
    render: (_, record) => {
      return (
        <div style={{ textAlign: "center" }}>
          {tabIndex == 1
            ? record.budgetRealsPerformances.reduce(
                (total, elem) => total + elem.budget,
                0
              )
            : record.budgetRealsPerformances.reduce(
                (total, elem) => total + elem.real,
                0
              )}
        </div>
      );
    },
  });

  const summaryItems = [
    {
      key: "1",
      label: (
        <h1
          style={{
            width: 300,
            textAlign: "center",
            color: "#87807a",
            fontFamily: "Arial",
          }}
        >
          <b>{t("Budget")}</b>
        </h1>
      ),
      children: (
        <div>
          <Table
            columns={budgetsSummaryClomuns}
            dataSource={employeesFinancialDetails.map((row, index) => ({
              ...row,
              key: index,
            }))} //:employeesFinancialDetails.filter(e=>e.id!=0)}
            scroll={{ x: 1300 }}
            bordered
            summary={() => {
              const bugetTotals = [];
              const items = [];
              for (let i = 0; i < 12; i++) {
                const monthIndex = (i + startingMonthIndex) % 12;
                var total = 0;
                for (let j = 0; j < employeesFinancialDetails.length; j++) {
                  total +=
                    employeesFinancialDetails[j].budgetRealsPerformances[i]
                      .budget;
                }
                bugetTotals.push(total);
                items.push(
                  <Table.Summary.Cell index={i}>
                    <div style={{ textAlign: "center" }}>{bugetTotals[i]}</div>
                  </Table.Summary.Cell>
                );
              }
              var tototal = 0;
              for (let j = 0; j < employeesFinancialDetails.length; j++) {
                tototal += employeesFinancialDetails[j].budgetTotal;
              }
              items.push(
                <Table.Summary.Cell index={13}>
                  <div style={{ textAlign: "center" }}>{tototal}</div>
                </Table.Summary.Cell>
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={7}>
                      <h3 style={{ textAlign: "center" }}>{t("Total")}</h3>
                    </Table.Summary.Cell>
                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <h1
          style={{
            width: 300,
            textAlign: "center",
            color: "#87807a",
            fontFamily: "Arial",
          }}
        >
          <b>{t("Real")}</b>
        </h1>
      ),
      children: (
        <div>
          <Table
            columns={budgetsSummaryClomuns}
            dataSource={employeesFinancialDetails}
            scroll={{
              x: 1300,
            }}
            bordered
            summary={() => {
              const bugetTotals = [];
              const items = [];
              Array.from({ length: 12 }).forEach((_, i) => {
                const monthIndex = (i + startingMonthIndex) % 12;
                let total = 0;

                employeesFinancialDetails.forEach((employee) => {
                  total += employee.budgetRealsPerformances[i].real;
                });

                bugetTotals.push(total);
                items.push(
                  <Table.Summary.Cell index={i}>
                    <div style={{ textAlign: "center" }}>{bugetTotals[i]}</div>
                  </Table.Summary.Cell>
                );
              });

              var tototal = 0;
              for (let j = 0; j < employeesFinancialDetails.length; j++) {
                tototal += employeesFinancialDetails[j].realTotal;
              }
              items.push(
                <Table.Summary.Cell index={13}>
                  <div style={{ textAlign: "center" }}>{tototal}</div>
                </Table.Summary.Cell>
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={7}>
                      <h3 style={{ textAlign: "center" }}>Total</h3>
                    </Table.Summary.Cell>
                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },

    {
      key: "3",
      label: (
        <h1
          style={{
            width: 300,
            textAlign: "center",
            color: "#87807a",
            fontFamily: "Arial",
          }}
        >
          <b>{t("Performance")}</b>
        </h1>
      ),
      children: (
        <div>
          <Table
            columns={performanceColumns}
            dataSource={employeesFinancialDetails}
            scroll={{ x: 1300 }}
            bordered
            summary={() => {
              const budgetTotals = [];
              const items = [];
              Array.from({ length: 12 }).forEach((_, i) => {
                const monthIndex = (i + startingMonthIndex) % 12;
                let total = 0;

                employeesFinancialDetails.forEach((employee) => {
                  const performanceData = employee.budgetRealsPerformances.find(
                    (e) => e.month - 1 === monthIndex
                  );
                  if (performanceData) {
                    total += performanceData.performance;
                  }
                });

                budgetTotals.push(total);
                items.push(
                  <Table.Summary.Cell index={3} colSpan={3}>
                    <div
                      style={{
                        textAlign: "center",
                        color: budgetTotals[i] >= 0 ? "blue" : "red",
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontFamily: "Impact",
                      }}
                    >
                      {budgetTotals[i]}
                    </div>
                  </Table.Summary.Cell>
                );
              });

              const totalPerformance = budgetTotals.reduce(
                (sum, current) => sum + current,
                0
              );

              items.push(
                <Table.Summary.Cell index={13}>
                  <div
                    style={{
                      textAlign: "center",
                      color: totalPerformance >= 0 ? "blue" : "red",
                      fontWeight: "bold",
                      fontSize: "15px",
                      fontFamily: "Impact",
                    }}
                  >
                    {totalPerformance}
                  </div>
                </Table.Summary.Cell>
              );

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={7}>
                      <h3
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          fontSize: "15px",
                          fontFamily: "Impact",
                        }}
                      >
                        Total
                      </h3>
                    </Table.Summary.Cell>
                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Card
      title={
        <span style={{ fontSize: "20px" }}>
          <WalletTwoTone /> {t("HumanResourcesSummary")}
        </span>
      }
    >
      <Space style={{ marginBottom: 20 }}>
        <Link
          to={{
            pathname: `/human_resources_planning`,
          }}
        >
          <Button
            style={{ width: 150, height: 35 }}
            icon={<RollbackOutlined />}
            type="primary"
            danger
          >
            {t("Goback")}
          </Button>
        </Link>
      </Space>
      <Space style={{ marginBottom: 20, float: "right" }}>
        <Select
          placeholder={t("SelectDepartmentPlaceHolder")}
          onChange={(val) => setEnterpriseDepartmentId(val)}
          style={{ width: 240 }}
          options={Departments}
        />
        <DatePicker
          disabled={enterpriseDepartmentId == null ? true : false}
          name="year"
          picker="year"
          placeholder={t("SelectYearPlaceHolder")}
          style={{ width: 200 }}
          onChange={handleChangeYear}
        />
      </Space>

      <Tabs
        style={{ marginTop: 30, marginBottom: 50 }}
        type="card"
        centered
        defaultActiveKey={[tabIndex]}
        items={summaryItems}
        onChange={(index) => onTabChange(index)}
      />
    </Card>
  );
}
export default BudgetRealSummary;
