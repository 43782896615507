import { Result } from "antd";
import React from "react";

const NotfoundPage = () => {
  return (
    <Result
      status="404"
      title="404 Not Found"
      subTitle="Sorry, Page not found."
    />
  );
};

export default NotfoundPage;
