import { DisconnectOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Form, Popconfirm, Select, Table, message } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

dayjs.extend(customParseFormat);

const { Option } = Select;

function LinkingDepartements() {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [year, setYear] = useState(null);
  const currentLang = i18n.language;
  const [DepartmentT, setDepartmentT] = useState([]);
  const [fnClass, setfnClass] = useState("");
  const [statementcategory, setStatementCategory] = useState([]);
  const [statementtype, setStatementType] = useState([]);
  const [statementclass, setStatementClass] = useState([]);
  const [fnCategory, setfnCategory] = useState("");
  const [fnType, setfnType] = useState("");
  const [ChartofAccounts, setChartofAccounts] = useState(null);
  const [DeptChartofAccounts, setDeptChartofAccounts] = useState(null);
  const [DeptChosen, setDeptChosen] = useState(null);
  const [ChartaccountChosen, setChartaccountChosen] = useState(null);

  const [glaccount, setGLaccount] = useState([]);

  const currentYear = dayjs().year();
  const { Company } = useContext(CompanyContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [departmentsRes, statementClassRes, ChartaccountsRes] =
        await Promise.all([
          axios.get(`${process.env.REACT_APP_JSON_API}/ChartAccountGroups`),
          axios.get(`${process.env.REACT_APP_JSON_API}/FinancialStatementClass`),
          axios.get(
            `${process.env.REACT_APP_JSON_API}/ChartAccounts/main-info?enterpriseId=${Company.id}`
          ),
        ]);
      setDepartmentT(departmentsRes.data);
      setStatementClass(statementClassRes.data);
      setChartofAccounts(ChartaccountsRes.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterChartofaccounts = async (value) => {
    console.log("filterChartofaccounts", value);
    var endpoint;
    const c = value.class ? value.class : fnClass ? fnClass : null;
    const cat = value.category
      ? value.category
      : fnCategory
      ? fnCategory
      : null;
    if (value.class) {
      endpoint = `${process.env.REACT_APP_JSON_API}/ChartAccounts/main-info?enterpriseId=${Company.id}&financialStatementClassId=${value.class}`;
    } else if (value.category) {
      endpoint = `${process.env.REACT_APP_JSON_API}/ChartAccounts/main-info?enterpriseId=${Company.id}&financialStatementClassId=${c}&financialStatementCategoryId=${value.category}`;
    } else if (value.type) {
      endpoint = `${process.env.REACT_APP_JSON_API}/ChartAccounts/main-info?enterpriseId=${Company.id}&financialStatementClassId=${c}&financialStatementCategoryId=${cat}&gifi=${value.type}`;
    }

    try {
      const ChartaccountsRes = await axios.get(endpoint);
      setChartofAccounts(ChartaccountsRes.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeptChange = async (value) => {
    console.log("dept changed", value);
    setDeptChosen(value);
    try {
      const chartaccountsRes = await axios.get(
        `${process.env.REACT_APP_JSON_API}/ChartAccounts/${Company.id}/grouping/${value}`
      );
      console.log("chartaccounts", chartaccountsRes);
      setDeptChartofAccounts(chartaccountsRes.data);
    } catch (error) {
      console.error("Error fetching category and type:", error);
    }
  };
  const Associatechartaccountwithdept = async () => {
    console.log("associating ...");
    const obj = {
      groupCode: DeptChosen,
      chartAccountId: ChartaccountChosen,
    };
    try {
      await axios
        .post(`${process.env.REACT_APP_JSON_API}/ChartAccounts/grouping`, obj)
        .then((response) => {
          console.log("chartaccounts of dept", response);
          handleDeptChange(DeptChosen);
          message.success(`${t("linkedsuccessfully")}`);
        });
    } catch (error) {
      console.error("Error associating chart account with department", error);
    }
  };

  const handleClassChange = async (value) => {
    setfnClass(value);
    setfnCategory(null);
    setfnType(null);
    filterChartofaccounts({ class: value });

    form.setFieldsValue({ category: null, type: null });
    try {
      const [categoryRes, typeRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_JSON_API}/FinancialStatementCategory/class/${value}`),
        axios.get(`${process.env.REACT_APP_JSON_API}/FinancialStatementType/classe/${value}`),
      ]);
      setStatementCategory(categoryRes.data);
      setStatementType(typeRes.data);
    } catch (error) {
      console.error("Error fetching category and type:", error);
    }
  };

  const handleCategoryChange = async (value) => {
    setfnCategory(value);
    filterChartofaccounts({ category: value });
    form.setFieldsValue({ type: null });
    try {
      const typeRes = await axios.get(
        `${process.env.REACT_APP_JSON_API}/FinancialStatementType/category/${value}`
      );
      setStatementType(typeRes.data);
    } catch (error) {
      console.error("Error fetching types:", error);
    }
  };

  const handleTypeChange = async (value) => {
    setfnType(value);
    filterChartofaccounts({ type: value });

    try {
      const glAccountRes = await axios.get(
        `${process.env.REACT_APP_JSON_API}/GLAccount/FinancialStatementType/${Company.id}/${value}`
      );
      setGLaccount(glAccountRes.data);
    } catch (error) {
      console.error("Error fetching GL accounts:", error);
    }
  };

  const handleChartAccountChange = async (value) => {
    setChartaccountChosen(value);
  };

  const handleDelete = async (value) => {
    try {
      await axios.put(`${process.env.REACT_APP_JSON_API}/ChartAccounts/grouping/${value}`).then(() => {
        handleDeptChange(DeptChosen);
        message.success(`${t("unlinkedsuccessfully")}`);
      });
    } catch (error) {
      console.error("Error updating chartaccounts accounts", error);
    }
  };

  const columns = [
    {
      title: `${t("glnumber")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.glNumber}</div>
      ),
    },
    {
      title: "Description",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "left" }}>{record.description}</div>
      ),
    },
    {
      title: `${t("group")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.groupCode}</div>
      ),
    },
    {
      title: "Actions",
      align: "center",
      render: (_, record) => (
        <Popconfirm
          title={t("deleterow")}
          onConfirm={() => handleDelete(record.chartAccountId)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" danger>
            <DisconnectOutlined /> {t("unlink")}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Card
      title={t("LinkinggroupwithChartofAccounts")}
      style={{ width: "100%", margin: "auto" }}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="department" label={t("group")}>
          <Select
            placeholder={loading ? t("Loading...") : t("Selectgroup")}
            loading={loading}
            onChange={handleDeptChange}
          >
            {DepartmentT.map((d) => (
              <Option key={d.code} value={d.code}>
                {currentLang === "en" ? d.labelEn : d.labelFr}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="class" label={t("Class")}>
          <Select value={fnClass} onChange={handleClassChange}>
            {statementclass.map((option) => (
              <Option key={option.id} value={option.id}>
                {currentLang === "en" ? option.label : option.labelFr}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="category" label={t("Category")}>
          <Select value={fnCategory} onChange={handleCategoryChange}>
            {statementcategory.map((option) => (
              <Option key={option.id} value={option.id}>
                {currentLang === "en" ? option.label : option.labelFr}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="type" label={t("GIFI")}>
          <Select value={fnType} onChange={handleTypeChange}>
            {statementtype.map((option) => (
              <Option
                key={option.id || option.gifi}
                value={option.id || option.gifi}
              >
                {currentLang === "en"
                  ? option.label ||
                    `(${t("GIFI")}: ${option.gifi}) ${option.description}`
                  : option.labelFr ||
                    `(${t("GIFI")}: ${option.gifi}) ${option.descriptionFr}`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="chartaccount" label={t("Chart Account")}>
          <Select onChange={handleChartAccountChange}>
            {ChartofAccounts?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.description + " (#" + option.glNumber + ")"}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button onClick={Associatechartaccountwithdept}>
            <PlusOutlined /> {t("Addgroup")}
          </Button>
        </Form.Item>
        <Form.Item>
          <p>
            {t("Financialratio")} :
          </p>
          <ul>
        {DeptChosen && DepartmentT.find(department => department.code === DeptChosen).financialRatios.map((d) => (
    <li>
         {currentLang === "en" ? d.nameEn : d.nameFr}
    </li>
))}
</ul>
            </Form.Item>
      </Form>

      <Table columns={columns} dataSource={DeptChartofAccounts} bordered style={{ overflowX: "auto",backgroundColor:'white' }}/>
    </Card>
  );
}

export default LinkingDepartements;
