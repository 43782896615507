import React, { useContext } from "react";

import { Button, Empty, Input, Select, Table, Typography, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../../contexts/CompanyContext";
const { Text } = Typography;
const { TextArea } = Input;

function Performance(props) {
  let { t } = useTranslation();
  const { Option } = Select;
  const [Reals, setReals] = useState("");
  const [marketingtype, setmarketingtype] = useState([]);
  const { TextArea } = Input;

  const { Company, Actionstate, setActionstate } = useContext(CompanyContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const DisplayRevenueSummary = () => {
    console.log("ttesstt 1");
    const items = [
      {
        title: "Description",
        // width: "auto",
        dataIndex: "description",
        align: "center",
        //   key: "year",
        render: (text, record) => {
          return <p style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(text)}>{text}</p>;
        },
      },
      {
        title: "Type",
        width: 120,
        dataIndex: "typeId",
        align: "center",
        //   key: "year",
        render: (text, record) => {
          return <p style={{ textAlign: "left", width: 200 }}>{record.type}</p>;
        },
      },

      {
        title: t("Annualbudget"),
        width: 120,
        dataIndex: "annualBudget",
        align: "center",
        //   key: "year",
        render: (text, record) => {
          return <p style={{ textAlign: "center", width: 120 }}>{text}</p>;
        },
      },
    ];

    const months = [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ];

    const startingMonthIndex = Company.startPeriod - 1;

    months.forEach((month, index) => {
      const monthIndex = (index + startingMonthIndex) % months.length;
      const monthName = months[monthIndex];

      items.push({
        title: monthName,

        children: [
          {
            title: <p style={{ textAlign: "center", width: 100 }}>Budget</p>,
            dataIndex: "montantbudget",
            width: 150,
            align: "center",
            render: (text, record) => {
              return (
                <>
                  <p style={{ textAlign: "center" }}>
                    {record.budgets[monthIndex].toFixed(0)}
                  </p>
                </>
              );
            },
          },
          {
            title: (
              <p style={{ textAlign: "center", color: "blue", width: 100 }}>
                {t("Real")}
              </p>
            ),

            dataIndex: "montantbudget",

            width: 150,
            align: "center",
            render: (text, record) => {
              return (
                <p style={{ textAlign: "center" }}>
                  {record.reals[monthIndex].toFixed(0)}
                </p>
              );
            },
          },
          {
            title: (
              <p style={{ textAlign: "center", width: 100 }}>
                {t("Difference")}
              </p>
            ),

            dataIndex: "street",
            width: 150,
            align: "center",
            render: (text, record) => {
              return (
                <>
                  <p style={{ textAlign: "center" }}>
                    {(
                      record.reals[monthIndex] - record.budgets[monthIndex]
                    ).toFixed(0)}
                  </p>
                </>
              );
            },
            render: (text, record) => {
              return (
                <>
                  {(
                    record.reals[monthIndex] - record.budgets[monthIndex]
                  ).toFixed(0) < 0 ? (
                    <p style={{ textAlign: "center", color: "red" }}>
                      ({" "}
                      {(
                        record.reals[monthIndex] - record.budgets[monthIndex]
                      ).toFixed(0)}
                      )
                    </p>
                  ) : (
                    <p style={{ textAlign: "center", color: "blue" }}>
                      {(
                        record.reals[monthIndex] - record.budgets[monthIndex]
                      ).toFixed(0)}
                    </p>
                  )}
                </>
              );
            },
          },
        ],
      });
    });

    items.push({
      title: "Total",
      width: 120,
      align: "center",

      render: (_, record) => {
        let performance = 0;
        return (
          <>
            {props.AssetBudgets.map((e) => {
              if (e.year == record.year) {
                performance = (
                  record.reals[0] -
                  e.budgets[0] +
                  record.reals[1] -
                  e.budgets[1] +
                  record.reals[2] -
                  e.budgets[2] +
                  record.reals[3] -
                  e.budgets[3] +
                  record.reals[4] -
                  e.budgets[4] +
                  record.reals[5] -
                  e.budgets[5] +
                  record.reals[6] -
                  e.budgets[6] +
                  record.reals[7] -
                  e.budgets[7] +
                  record.reals[8] -
                  e.budgets[8] +
                  record.reals[9] -
                  e.budgets[9] +
                  record.reals[10] -
                  e.budgets[10] +
                  record.reals[11] -
                  e.budgets[11]
                ).toFixed(0);
              }
            })}
            <div style={{ fontWeight: "bold", textAlign: "center" }}>
              {performance}
            </div>
          </>
        );
      },
    });

    return (
      <>
      <Table
        locale={{
          emptyText: <Empty description={t("nodata")} />,
        }}
        columns={items}
        dataSource={props.AssetReals}
        scroll={{
          x: 1300,
        }}
        pagination={true}
        bordered
        summary={(pageData) => {
          const items = [];

          const startingMonthIndex = Company.startPeriod - 1;

          let totalmontant = 0;
          let totalbudget = 0;
          let totalfevrierbudget = 0;
          let totalMarsbudget = 0;
          let totalavrilbudget = 0;
          let totalmaibudget = 0;
          let totalJuinbudget = 0;
          let totaljuilletbudget = 0;
          let totalaoutbudget = 0;
          let totalseptembrebudget = 0;
          let totaloctobrebudget = 0;
          let totalnovemberbudget = 0;
          let totaldecembrebudget = 0;
          let totaltotal = 0;

          let totalbudgetcount = 0;
          let totalfevrierbudgetcount = 0;
          let totalMarsbudgetcount = 0;
          let totalavrilbudgetcount = 0;
          let totalmaibudgetcount = 0;
          let totalJuinbudgetcount = 0;
          let totaljuilletbudgetcount = 0;
          let totalaoutbudgetcount = 0;
          let totalseptembrebudgetcount = 0;
          let totaloctobrebudgetcount = 0;
          let totalnovemberbudgetcount = 0;
          let totaldecembrebudgetcount = 0;

          {
            props.AssetBudgets &&
              props.AssetBudgets.map((e) => {
                //console.log(e.budgets[1]),
                totalmontant = e.budgets[0];
                totalbudget += totalmontant;
                totalmontant = e.budgets[1];
                totalfevrierbudget += totalmontant;
                totalmontant = e.budgets[2];
                totalMarsbudget += totalmontant;
                totalmontant = e.budgets[3];
                totalavrilbudget += totalmontant;
                totalmontant = e.budgets[4];
                totalmaibudget += totalmontant;
                totalmontant = e.budgets[5];
                totalJuinbudget += totalmontant;
                totalmontant = e.budgets[6];
                totaljuilletbudget += totalmontant;
                totalmontant = e.budgets[7];
                totalaoutbudget += totalmontant;
                totalmontant = e.budgets[8];
                totalseptembrebudget += totalmontant;
                totalmontant = e.budgets[9];
                totaloctobrebudget += totalmontant;
                totalmontant = e.budgets[10];
                totalnovemberbudget += totalmontant;
                totalmontant = e.budgets[11];
                totaldecembrebudget += totalmontant;
                totalmontant = e.totalBudget;
                totaltotal += totalmontant;
              });
          }

          const months = [
            totalbudget,
            totalfevrierbudget,
            totalMarsbudget,
            totalavrilbudget,
            totalmaibudget,
            totalJuinbudget,
            totaljuilletbudget,
            totalaoutbudget,
            totalseptembrebudget,
            totaloctobrebudget,
            totalnovemberbudget,
            totaldecembrebudget,
          ];
          const monthscount = [
            totalbudgetcount,
            totalfevrierbudgetcount,
            totalMarsbudgetcount,
            totalavrilbudgetcount,
            totalmaibudgetcount,
            totalJuinbudgetcount,
            totaljuilletbudgetcount,
            totalaoutbudgetcount,
            totalseptembrebudgetcount,
            totaloctobrebudgetcount,
            totalnovemberbudgetcount,
            totaldecembrebudgetcount,
          ];

          let totalReal = 0;
          let totalfevrierReal = 0;
          let totalMarsReal = 0.0;
          let totalavrilReal = 0;
          let totalmaiReal = 0;
          let totalJuinReal = 0.0;
          let totaljuilletReal = 0;
          let totalaoutReal = 0;
          let totalseptembreReal = 0;
          let totaloctobreReal = 0;
          let totalnovemberReal = 0;
          let totaldecembreReal = 0;
          let totaltotalreal = 0;

          props.AssetReals &&
            props.AssetReals.map((e) => {
              //console.log(e.budgets[1]),
              totalmontant = e.reals[0];
              totalReal += totalmontant;
              totalmontant = e.reals[1];
              totalfevrierReal += totalmontant;
              totalmontant = e.reals[2];
              totalMarsReal += totalmontant;
              totalmontant = e.reals[3];
              totalavrilReal += totalmontant;
              totalmontant = e.reals[4];
              totalmaiReal += totalmontant;
              totalmontant = e.reals[5];
              totalJuinReal += totalmontant;
              totalmontant = e.reals[6];
              totaljuilletReal += totalmontant;
              totalmontant = e.reals[7];
              totalaoutReal += totalmontant;
              totalmontant = e.reals[8];
              totalseptembreReal += totalmontant;
              totalmontant = e.reals[9];
              totaloctobreReal += totalmontant;
              totalmontant = e.reals[10];
              totalnovemberReal += totalmontant;
              totalmontant = e.reals[11];
              totaldecembreReal += totalmontant;
              totalmontant = e.totalBudget;
              totaltotal += totalmontant;
            });

          const monthsReal = [
            totalReal,
            totalfevrierReal,
            totalMarsReal,
            totalavrilReal,
            totalmaiReal,
            totalJuinReal,
            totaljuilletReal,
            totalaoutReal,
            totalseptembreReal,
            totaloctobreReal,
            totalnovemberReal,
            totaldecembreReal,
          ];

          for (let i = 0; i < months.length; i++) {
            const monthIndex = (i + startingMonthIndex) % months.length;
            const monthName = months[monthIndex];
            const monthNameReal = monthsReal[monthIndex];
            const monthNameBudget = months[monthIndex];
            let monthNamecount = monthscount[monthIndex];
            {
              if (monthNamecount == 0) {
                monthNamecount = 1;
              }
            }

            items.push(
              <Table.Summary.Cell index={monthIndex}>
                <Text style={{ fontWeight: "bold", textAlign: "center" }}>
                  {monthName.toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            );
            items.push(
              <Table.Summary.Cell index={monthIndex}>
                <Text style={{ fontWeight: "bold", textAlign: "center" }}>
                  {monthNameReal.toFixed(0)}
                </Text>
              </Table.Summary.Cell>
            );
            items.push(
              <Table.Summary.Cell index={monthIndex}>
                {monthNameReal < monthNameBudget ? (
                  <Text
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    ( {(monthNameReal - monthNameBudget).toFixed(0)})
                  </Text>
                ) : (
                  <Text
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "blue",
                    }}
                  >
                    {(monthNameReal - monthNameBudget).toFixed(0)}
                  </Text>
                )}
              </Table.Summary.Cell>
            );
          }

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} colSpan={3}>
                  <h3 style={{ textAlign: "center" }}>Total</h3>
                </Table.Summary.Cell>

                {items}

                {/* <Table.Summary.Cell index={10}>
                  <Text>{totalReal-totalbudget+
                      totalfevrierReal-totalfevrierbudget+
                      totalMarsReal-totalMarsbudget+
                      totalavrilReal-totalavrilbudget+
                      totalmaiReal-totalmaibudget+
                      totalJuinReal-totalJuinbudget+
                      totaljuilletReal-totaljuilletbudget+
                      totalaoutReal-totalaoutbudget+
                      totalseptembreReal-totalseptembrebudget+
                      totaloctobreReal-totaloctobrebudget+
                      totalnovemberReal-totalnovemberbudget+
                      totaldecembreReal-totaldecembrebudget}</Text>
                </Table.Summary.Cell> */}
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
        </>
    );
  };

  return <DisplayRevenueSummary />;
}

export default Performance;
