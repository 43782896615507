import {
  DollarTwoTone,
  InfoCircleOutlined,
  UploadOutlined,
  EyeFilled,
} from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Row, Space, Table, Modal } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

const onChangee = (date, dateString) => {
  console.log(date, dateString);
};

function Liability() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [liabilities, setliabilities] = useState(null);
  const [form2] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  var date;
  useEffect(() => {
    console.log("year" + date);
    getLiabilities();
  }, [Company.id, year]);
  const onChangeyear = (date, dateString) => {
    setliabilities(null);
    console.log(date, dateString);
    setYear(dateString);
  };

  const columns = [
    {
      title: `${t("glnumber")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.chartAccount.glAccount.glNumber}
        </div>
      ),
    },
    {
      title: `${t("Class")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {i18n.language === "en"
            ? record.financialStatementClass.label
            : record.financialStatementClass.labelFr}
        </div>
      ),
    },
    {
      title: t("Category"),
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {i18n.language === "en"
            ? record.financialStatementCategory.label
            : record.financialStatementCategory.labelFr}
        </div>
      ),
    },

    {
      title: `${t("Gifi")}`,
      // dataIndex: "financialStatementTypeId",
      align: "center",
      // render: (text) => <a>{text}</a>,
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.financialStatementType.gifi}
        </div>
      ),
    },
    {
      title: `${t("Description")}`,
      // dataIndex: "note",
      align: "center",
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.chartAccount.glAccount.description)}>
          {record.chartAccount.glAccount.description}
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",

      render: (_, record) => {
        return (
          <Link
            to={{
              pathname: `/liabilityDetail/${record.id}`,
              state: { stateParam: record.id },
            }}
          >
            <Button type="link" icon={<EyeFilled />}>
              {t("details")}
            </Button>
          </Link>
        );
      },
    },
  ];
  const getLiabilities = async () => {
    if (year == null) {
      await axios
        .get(`${process.env.REACT_APP_JSON_API}/Liability/filter/${Company.id}`)
        .then((res) => {
          console.log(res);
          setliabilities(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(`${process.env.REACT_APP_JSON_API}/Liability/filter/${Company.id}?year=${year}`)
        .then((res) => {
          console.log(res);
          setliabilities(res.data);
          console.log(liabilities);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <h3 className="font-semibold">
          <DollarTwoTone /> {t("liabilities")}
        </h3>
      }
    >
      <Row
        justify="space-between"
        style={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Space
          style={{
            display: "flex",
            marginLeft: "flex",
          }}
          align="baseline"
        >
          <DatePicker
            name="year"
            picker="year"
            placeholder={t("Selectyear")}
            style={{ width: 220, height: 35 }}
            onChange={onChangeyear}
          />
        </Space>

        <Space>
          <Link
            to={{
              pathname: `/LiabilitySummary`,
            }}
          >
            <Button
              className="Create-button"
              type="primary"
              style={{
                textAlign: "right",
                marginLeft: "flex",
              }}
            >
              <InfoCircleOutlined />
              {t("Summary")}
            </Button>
          </Link>

          {/* <Button
            className="Create-button"
            type="primary"
            // disabled
            style={{
              textAlign: "right",
              marginLeft: "flex",
            }}
            onClick={() => {
              // setOpenupdate(true);
            }}
          >
            <UploadOutlined />
            {t("Upload")}
          </Button> */}
        </Space>
      </Row>
      <div style={{ overflowX: "auto",backgroundColor:'white' }}>
        <Table columns={columns} dataSource={liabilities} bordered />
      </div>
      <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
    </Card>
  );
}

export default Liability;
