import {
  DollarTwoTone,
  EyeFilled,
  InfoCircleOutlined,
  UploadOutlined,
  BoxPlotTwoTone,
} from "@ant-design/icons";
import { Button, Card, DatePicker, Empty, Form, Row, Space, Table,Modal } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { useAbility } from "../../contexts/abilityContext";
import { JSON_API } from "../../services/Constants";

const SalesBudget = () => {
  const { ability } = useAbility();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);

  const [SalesBudget, setSalesBudget] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const getSalesBudgets = async () => {
    if (year == null) {
      await axios
        .get(`${process.env.REACT_APP_JSON_API}/SalesBudgets/${Company.id}`)
        .then((res) => {
          console.log(res);
          setSalesBudget(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(`${process.env.REACT_APP_JSON_API}/SalesBudgets/${Company.id}?year=${year}`)
        .then((res) => {
          console.log(res);
          setSalesBudget(res.data);
          console.log(SalesBudget);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  var date;
  useEffect(() => {
    console.log("year" + date);
    getSalesBudgets();
  }, [Company.id, year]);

  const onChangeyear = (date, dateString) => {
    setSalesBudget(null);
    console.log(date, dateString);
    setYear(dateString);
  };

  const columns = [
    {
      title: `${t("glnumber")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          {record.glNumber}
        </div>
      ),
    },
    {
      title: `${t("Class")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {currentLang === "en"
            ? record.financialStatementClassLabelEn
            : record.financialStatementClassLabelFr}
        </div>
      ),
    },
    {
      title: `${t("Category")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {currentLang === "en"
            ? record.financialStatementCategoryLabelEn
            : record.financialStatementCategoryLabelFr}
        </div>
      ),
    },
    {
      title: `${t("Gifi")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          {record.gifi}
        </div>
      ),
    },
    {
      title: "Description",
      align: "center",
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.description)}>{record.description}</div>
      ),
    },
    {
      title: "Actions",
      align: "center",
      render: (_, record) => {
        return (
          <Link
            to={{
              pathname: `/sales-budget-details/${record.id}`,
              state: { stateParam: record.id },
            }}
          >
            <Button type="link" icon={<EyeFilled />}>
              {t("details")}
            </Button>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <Card
        className="header-solid mb-24"
        title={
          <>
            <BoxPlotTwoTone />
            <span style={{ fontSize: "19px", marginLeft: "5px" }}>
              {t("SalesBudgetDetails")}
            </span>
          </>
        }
      >
        <Row justify="space-between">
          <Space
            style={{
              display: "flex",
              marginLeft: "flex",
            }}
            align="baseline"
          >
            <DatePicker
              name="year"
              picker="year"
              placeholder={t("Selectyear")}
              style={{ width: 200, height: 35 }}
              onChange={onChangeyear}
            />
          </Space>

          <Space
            style={{
              marginBottom: 24,
            }}
          >
            <Link
              to={{
                pathname: `/sales-budget-summary`,
              }}
            >
              <Button
                className="Create-button"
                type="primary"
                style={{
                  textAlign: "right",
                  marginLeft: "flex",
                }}
              >
                <InfoCircleOutlined />
                {t("Summary")}
              </Button>
            </Link>
          </Space>
        </Row>
        <div style={{ overflowX: "auto" }}>
          <Table
            locale={{
              emptyText: <Empty description={t("nodata")} />,
            }}
            columns={columns}
            dataSource={SalesBudget}
            bordered
          />
        </div>
            <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
      </Card>
    </>
  );
};

SalesBudget.acl = {
  subject: "Sales Budget",
  action: "read",
};
export default SalesBudget;
