import { Button, Result } from "antd";
import React from "react";

const UnauthorizedPage = () => {
  function Logout() {
    localStorage.clear();
    console.log("test");
    window.location.assign("/");
  }

  return (
    <Result
      status="403"
      title="403 Forbidden"
      subTitle="Sorry, you are not authorized to access this page."
      extra={<Button onClick={Logout}>Back to Home</Button>}
    />
  );
};

export default UnauthorizedPage;
