import React, { useState, useEffect } from "react";
import Part from "./Part";
import {
  CheckOutlined,
  PlusCircleFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";
export const ModelDataContext = React.createContext();

const simpleCashFlowModelParts = [
  {
    id: 1,
    partType: 0,
    name: "InitialCash",
    position: 0,
    categories: [],
    sections: [
      {
        id: 1,
        name: "Initial Cash",
        chartAccountId:0,
        isImmutable: false,
        position: 0,
      },
    ],
  },
  {
    id: 2,
    partType: 1,
    name: "Inflow",
    position: 1,
    categories: [
      {
        id: 1,
        name: "Inflow",
        position: 1,
        subCategories: [],
        sections: [],
      },
    ],
    sections: [],
  },
  {
    id: 3,
    partType: 2,
    name: "Outflow",
    position: 2,
    categories: [
      {
        id: 1,
        name: "Outflow",
        position: 1,
        subCategories: [],
        sections: [],
      },
    ],
    sections: [],
  },
  {
    id: 4,
    partType: 3,
    name: "Summary",
    position: 3,
    categories: [],
    sections: [],
  },
];

const intermediateCashFlowModelParts = [
  {
    id: 1,
    partType: 0,
    name: "InitialCash",
    position: 0,
    categories: [],
    sections: [
      {
        id: 1,
        name: "Initial Cash",
        chartAccountId:0,
        isImmutable: false,
        position: 0,
      },
    ],
  },
  {
    id: 2,
    partType: 1,
    name: "Inflow",
    position: 1,
    categories: [
      {
        id: 1,
        name: "Inflow",
        position: 1,
        subCategories: [],
        sections: [],
      },
    ],
    sections: [
      // {
      //   id: 1,
      //   name: "Inflow Total",
      //   position: 1,
      //   subSections: [],
      //   isImmutable: true,
      // },
    ],
  },
  {
    id: 3,
    partType: 2,
    name: "Outflow",
    position: 2,
    categories: [
      {
        id: 1,
        name: "Outflow",
        position: 1,
        subCategories: [],
        sections: [],
      },
    ],
    sections: [
      // {
      //   id: 1,
      //   name: "Outflow Total",
      //   position: 1,
      //   subSections: [],
      //   isImmutable: true,
      // },
    ],
  },
  {
    id: 4,
    partType: 3,
    name: "Summary",
    position: 3,
    categories: [],
    sections: [
      // {
      //   id: 1,
      //   name: "Difference",
      //  position: 0,
      //   subSections: [],
      //   isImmutable: true,
      // },
      // {
      //   id: 2,
      //   name: "Final Cash",
      //   position:0,
      //   subSections: [],
      //   isImmutable: true,
      // },
    ],
  },
];
const advancedCashFlowModelParts = [
  {
    id: 1,
    partType: 0,
    name: "InitialCash",
    position: 0,
    categories: [],
    sections: [
      {
        id: 1,
        name: "Initial Cash",
        chartAccountId:0,
        isImmutable: false,
        position: 0,
      },
    ],
  },
  {
    id: 2,
    partType: 1,
    name: "Inflow",
    position: 1,
    categories: [
      {
        id: 1,
        name: "Inflow",
        position: 1,
        subCategories: [],
        sections: [],
      },
    ],
    sections: [],
  },
  {
    id: 3,
    partType: 2,
    name: "Outflow",
    position: 2,
    categories: [
      {
        id: 1,
        name: "Outflow",
        position: 1,
        subCategories: [],
        sections: [],
      },
    ],
    sections: [],
  },
  {
    id: 4,
    partType: 3,
    name: "Summary",
    position: 3,
    categories: [],
    sections: [],
  },
];
const CreateCashFlow = ({ handleStep2, CreateCashFlowModel, enterpriseId,complexity }) => {
  let { t } = useTranslation();
  const getInitialTemplate = (complexity) => {
    switch (complexity) {
      case 0:
        return simpleCashFlowModelParts;
      case 1:
        return intermediateCashFlowModelParts;
      default:
        return advancedCashFlowModelParts;
    }
  };
  const [parts, setParts] = useState(getInitialTemplate(complexity));
  const [isDone, setIsDone] = useState(false);

  var uniqueId = parseInt( `${new Date().getTime()}${Math.floor(Math.random() * 1000)}`,10);
  var position = 0;
  useEffect(() => {}, [parts]);
  const handleSubmit = () => {
    console.log("Form submitted with:", parts);
    console.log("here is the enterprise Id goood :", enterpriseId);
    handleStep2(parts);
    setIsDone(true);
  };

  const addSection = (partId, categoryId = null) => {
    const newParts = JSON.parse(JSON.stringify(parts));
    const part = newParts.find((p) => p.id === partId);
    const newSection = {
      id: uniqueId++,
      name: "New Section",
      chartAccountId: 0,
      isImmutable: false,
      subSections: [],
      position: 0,
    };

    if (part) {
      if (categoryId) {
        const category = part.categories.find((c) => c.id === categoryId);
        if (category) {
          if (!category.sections) {
            category.sections = [];
          }
          category.sections.push(newSection);
          console.log("updated category ", category);
        } else {
          console.error(`Category with ID ${categoryId} not found.`);
        }
      } else {
        // If no categoryId is provided, add the section directly to the part.
        part.sections.push(newSection);
      }
      setParts(newParts);
    } else {
      console.error(`Part with ID ${partId} not found.`);
    }
  };

  //Add category
  const addCategory = (partId) => {
    // Make a deep copy of the current parts
    const newParts = JSON.parse(JSON.stringify(parts));
    // Find the part by its id
    const part = newParts.find((p) => p.id === partId);
    if (part) {
      // Add a new category
      const newCategory = {
        id: uniqueId++,
        name: "New Category",
        position: position++,
        sections: [],
        subCategories: [],
      };
      part.categories.push(newCategory);
      setParts(newParts);
    }
  };
  //Reordering
  const moveElement = (partId, index, direction) => {
    console.log("moveElement called", partId, direction, index);
    // Deep clone the parts
    const newParts = JSON.parse(JSON.stringify(parts));
    // Find the target part by its ID
    const part = newParts.find((p) => p.id === partId);
    if (part) {
      // Merge and sort categories and sections by their positions
      const markedCategories = part.categories.map((c) => ({
        ...c,
        type: "category",
      }));
      const markedSections = part.sections.map((s) => ({
        ...s,
        type: "section",
      }));
      const combined = [...markedCategories, ...markedSections].sort(
        (a, b) => a.position - b.position
      );
      if (
        (direction === "up" && index > 0) ||
        (direction === "down" && index < combined.length - 1)
      ) {
        // Swap positions
        const swapIndex = direction === "up" ? index - 1 : index + 1;
        [combined[index], combined[swapIndex]] = [
          combined[swapIndex],
          combined[index],
        ];
        // Update positions
        combined.forEach((el, idx) => {
          el.position = idx;
        });
        // Update the part's categories and sections based on the new ordering
        part.categories = combined.filter((e) => e.type === "category");
        part.sections = combined.filter((e) => e.type === "section");
      }
      // Update the parts
      setParts(newParts);
    }
  };
  const editCategory = (partId, categoryId, newName) => {
    console.log(
      `edit a category partId : ${partId} category id :${categoryId} new name : ${newName}`
    );
    const newParts = [...parts];
    const part = newParts.find((p) => p.id === partId);
    if (part) {
      const categoryToEdit = part.categories.find((s) => s.id === categoryId);
      if (categoryToEdit) {
        console.log("Found category to edit:", JSON.stringify(categoryToEdit));
        categoryToEdit.name = newName;
        setParts(newParts);
        console.log("Updated parts:", JSON.stringify(newParts));
      } else {
        console.error(`category with id ${categoryId} not found.`);
      }
    } else {
      console.error(`Part with id ${partId} not found.`);
    }
  };

  const deleteCategory = (partId, categoryId) => {
    console.log(`delete a category partId ${partId} category id ${categoryId}`);
    const partToEdit = parts.find((part) => part.id === partId);
    if (!partToEdit) return;
    const newCategories = partToEdit.categories.filter(
      (cateogory) => cateogory.id !== categoryId
    );
    const newParts = parts.map((part) => {
      if (part.id === partId) {
        return {
          ...part,
          categories: newCategories,
        };
      }
      return part;
    });
    setParts(newParts);
  };

  //more general function
  // Helper function that finds and updates a section recursively
  const findAndUpdateSection = (sections, sectionToUpdate) => {
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].id === sectionToUpdate.id) {
        sections[i] = { ...sections[i], ...sectionToUpdate }; // Merge old and new section properties
        return true;
      }
      if (
        sections[i].subSections &&
        findAndUpdateSection(sections[i].subSections, sectionToUpdate)
      ) {
        return true;
      }
    }
    return false;
  };
  const editSection = (partId, sectionToUpdate, categoryId = null) => {
    console.log("Initial parts:", JSON.stringify(parts));
    const newParts = [...parts];
    const part = newParts.find((p) => p.id === partId);
    if (!part) {
      console.error(`Part with id ${partId} not found.`);
      return;
    }
    let targetSections;
    if (categoryId !== null) {
      const category = part.categories.find((c) => c.id === categoryId);
      if (!category) {
        console.error(`Category with id ${categoryId} not found.`);
        return;
      }
      targetSections = category.sections;
    } else {
      targetSections = part.sections;
    }
    if (findAndUpdateSection(targetSections, sectionToUpdate)) {
      console.log("Updated section:", JSON.stringify(sectionToUpdate));
      setParts(newParts);
      console.log("Updated parts:", JSON.stringify(newParts));
    } else {
      console.error(`Section with id ${sectionToUpdate.id} not found.`);
    }
  };

  const deleteSection = (partId, sectionId) => {
    console.log(`delete a section partId ${partId} section id ${sectionId}`);
    const partToEdit = parts.find((part) => part.id === partId);
    if (!partToEdit) return;
    const newCategories = [...partToEdit.categories];
    const categoryWithSection = newCategories.find((category) =>
      category.sections.some((section) => section.id === sectionId)
    );
    // If such a category
    if (categoryWithSection) {
      categoryWithSection.sections = categoryWithSection.sections.filter(
        (section) => section.id !== sectionId
      );
    }
    // Directly update the sections within the part if the section is not within a category
    const newSections = partToEdit.sections.filter(
      (section) => section.id !== sectionId
    );
    // Construct the new parts array
    const newParts = parts.map((part) => {
      if (part.id === partId) {
        return {
          ...part,
          categories: newCategories,
          sections: newSections,
        };
      }
      return part;
    });
    // Update the state
    setParts(newParts);
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1>
          {t("Customization")} - {t("Step")}
        </h1>
        {parts.map((part, index) => (
          <Part
            key={part.id}
            partId={part.id}
            part={part}
            addSection={addSection}
            addCategory={addCategory}
            moveElement={moveElement}
            editCategory={editCategory}
            deleteCategory={deleteCategory}
            editSection={editSection}
            deleteSection={deleteSection}
            enterpriseId={enterpriseId}
            isReadOnly={false}
            isDetail={false}
            complexity={complexity}
          />
        ))}
        <Space
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <Link to="/CashFlowModels">
            <Button
              type="primary"
              danger
              icon={<ArrowLeftOutlined />}
              style={{ float: "center", width: 420 }}
            >
              {t("Cancel")}
            </Button>
          </Link>
          <Button
            onClick={handleSubmit}
            type="primary"
            icon={<CheckOutlined />}
            style={{ float: "center", width: 420 }}
          >
            {t("Done")}
          </Button>
          <Button
            disabled={!isDone}
            onClick={CreateCashFlowModel}
            icon={<PlusCircleFilled />}
            type="primary"
            style={{ float: "center", width: 420 }}
          >
            {t("create")}
          </Button>
        </Space>
      </div>
    </>
  );
};
export default CreateCashFlow;
