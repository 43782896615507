
import { DeleteFilled, EyeFilled, PlusCircleFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import axios from "axios";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAbility } from "../contexts/abilityContext";
import ChartAccountsImportModal from "./ChartAccountsImportModal";
dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text, Title } = Typography;
const { TextArea } = Input;
const { TabPane } = Tabs;

function ChartofAcount() {
  const { ability } = useAbility();
  const { Company } = useContext(CompanyContext);
  const canDelete = ability.can("delete", "Chart of Accounts");
  const [size, setSize] = useState("large");
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [statementclass, setStatementClass] = useState([]);
  const [statementcategory, setStatementCategory] = useState([]);
  const [statementtype, setStatementType] = useState([]);
  const [glaccount, setGLaccount] = useState([]);
  const [componentDisabled, setComponentDisabled] = useState(true);
  const [editingRowbook, setEditingRowbook] = useState(null);
  const [hypothesis, sethypothesis] = useState([]);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenupdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fnClass, setfnClass] = useState("");
  const [fnCategory, setfnCategory] = useState("");
  const [fnType, setfnType] = useState("");
  const [ChartofAccounts, setChartofAccounts] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const { Panel } = Collapse;
  const [year, setYear] = useState(null);
  const colors = ["red", "lime", "green", "blue", "geekblue", "purple"];
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [DepartmentT, setDepartmentT] = useState([{}]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const getStatementClass = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementClass`)
      .then((res) => {
        console.log(res);
        setStatementClass(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDepartments = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments?enterpriseId=${Company.id}`)
      .then((response) => {
        setDepartmentT(response.data);
        console.log(DepartmentT, "Departments");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleclass = async (e) => {
    setfnClass(e);
    form.setFieldsValue({
      category: null,
      type: null,
    });
    // console.log(e);
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementCategory/class/${e}`)

      .then((res) => {
        console.log(res.data);
        setStatementCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementType/classe/${e}`)

      .then((res) => {
        console.log(res);
        setStatementType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlecategory = async (e) => {
    // console.log(e);
    setfnCategory(e);
    form.setFieldsValue({
      type: null,
    });
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementType/category/${e}`)

      .then((res) => {
        console.log(res);
        setStatementType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handletype = async (e) => {
    // console.log(e);
    setfnType(e);
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/GLAccount/FinancialStatementType/${Company.id}/${e}`)

      .then((res) => {
        console.log(res);
        setGLaccount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangee = (date, dateString) => {
    setChartofAccounts(null);
    console.log(date, dateString);
    setYear(dateString);
  };

  const currentYear = dayjs().year();

  // Checkbox
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  var date;

  useEffect(() => {
    date = new Date().getFullYear();
    if (year == null) {
      setYear(date);
    }
    console.log("year" + date);
    getStatementClass();
    getDepartments();
    getData();
  }, [Company.id, year]);

  const getData = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/ChartAccounts?enterpriseId=${Company.id}&year=${year}`)
      .then((response) => {
        setChartofAccounts(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        setChartofAccounts(null);
      });
  };

  const confirmDelete = async (chartAccountId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_JSON_API}/ChartAccounts/${chartAccountId}`
      );

      if (response.status === 204) {
        console.log("chart of account successfully deleted.");
        message.success(t("ChartAccountDeletedSuccessfully"));
        setLoading(true);

        getData();
      } else {
        console.log("Failed to delete chart of account.");
        message.error(t("FailedToDeleteChartAccount"));
        setLoading(false);
      }
    } catch (error) {
      console.error(
        "An error occurred while deleting the chart of account :",
        error
      );
      message.error(t("ErrorDeletingChartAccount"));
      setLoading(false);
    }
  };

  const Submit = async (e) => {
    console.log("Initial form data: ", e);

    const yearOnly = new Date(e.year).getFullYear();

    const formData = {
      enterpriseId: Company.id,
      note: e.note,
      year: yearOnly,
      annualBudget: e.annualBudget,
      budgetBreakdown: componentDisabled,
      glNumber: e.glAccountId.toString(),
      description: e.description,
      financialStatementTypeGIFI: e.type,
      enterpriseDepartmentId: e.entreprisedepartement,
    };

    console.log("Processed form data: ", formData);

    try {
      const res = await axios.post(`${process.env.REACT_APP_JSON_API}/ChartAccounts`, formData);

      console.log("Response Data: ", res.data);

      getData();
      setOpen(!open);
      message.success(
        `${t("ChartAccountCreatedSuccessfullyForTheyear")} ${yearOnly}`
      );
    } catch (error) {
      console.log("Error during Axios call: ", error);
      message.error(t("ErrorMessage"));
    }
  };

  const renderClassTag = (record) => {
    const label =
      currentLang === "en"
        ? record.financialStatementClass.label
        : record.financialStatementClass.labelFr;

    const colorIndex = record.financialStatementClass.id % colors.length;
    const color = colors[colorIndex];

    return (
      <Tag
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          borderRadius: "5px",
          padding: "5px 10px",
          textAlign: "center",
          textTransform: "uppercase",
          marginRight: "8px",
          marginBottom: "8px",
        }}
        color={color}
      >
        {label}
      </Tag>
    );
  };

  const columns = [
    {
      title: t("Year"),
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.year}</div>
      ),
    },
    {
      title: t("Gifi"),
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.financialStatementType.gifi}
        </div>
      ),
    },
    {
      title: "Class",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{renderClassTag(record)}</div>
      ),
    },
    {
      title: t("Category"),
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "left" }}>
          {record.financialStatementCategoryForReporting
            ? currentLang === "en"
              ? record.financialStatementCategoryForReporting.label
              : record.financialStatementCategoryForReporting.labelFr
            : currentLang === "en"
            ? record.financialStatementCategory.label
            : record.financialStatementCategory.labelFr}
        </div>
      ),
    },
    {
      title: t("GLnumber"),
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {Number(record.glAccount.glNumber)}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.glAccount.glNumber) - Number(b.glAccount.glNumber),
    },
    {
      title: t("Description"),
      align: "center",
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.glAccount.description)}>{record.glAccount.description}</div>
      ),
    },
    {
      title: "Actions",
      align: "center",
      render: (_, record) => (
        <Row>
          <Col>
            <Link
              to={{
                pathname: `/chartofaccount/${record.id}`,
                state: { stateParam: record.id },
              }}
            >
              <Button type="link" icon={<EyeFilled />}>
                {t("Details")}
              </Button>
            </Link>
          </Col>
          {canDelete && (
            <Col>
              <Popconfirm
                title={t("AreYouSureToDeleteThisChartAccount")}
                onConfirm={() => confirmDelete(record.id)}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <Button type="link" danger>
                  <DeleteFilled /> {t("Delete")}
                </Button>
              </Popconfirm>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={<h3 className="font-semibold"> {t("Chartaccounts")}</h3>}
    >
      <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
        <Col flex="auto">
          <Row gutter={16} align="middle">
            <Col>
              <DatePicker
                picker="year"
                placeholder={t("Selectyear")}
                style={{ width: 240, height: 35 }}
                onChange={onChangee}
                disabledDate={(currentDate) =>
                  currentDate && currentDate.year() > currentYear
                }
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={16} align="middle" style={{ marginBottom: 15 }}>
            <Col>
              {ability.can("create", "Chart of Accounts") && (
                <Button
                  className="Create-button"
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                  }}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <PlusCircleFilled /> {t("Createanewaccount")}
                </Button>
              )}
              <Button
                className="Create-button"
                type="primary"
                onClick={() => setImportModalVisible(true)}
              >
                <PlusCircleFilled />
                {t("ImportCharaccounts")}
              </Button>

              <ChartAccountsImportModal
                visible={importModalVisible}
                onClose={() => setImportModalVisible(false)}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        open={open}
        width={1200}
        style={{ textAlign: "center" }}
        title={<h3> {t("Createanewaccount")}</h3>}
        okText={t("create")}
        cancelText={t("cancel")}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              Submit(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="horizontal"
          name="form_in_modal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{ modifier: "public" }}
        >
          <Divider orientation="left">{t("Account")}</Divider>
          <Row
            gutter={[16, 16]}
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Col span={12}>
              <Form.Item
                name="glAccountId"
                label={t("GLaccount")}
                rules={[
                  { required: true, message: t("Pleaseinputtheaccount") },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min={0} step={1} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="year" label={t("Year")}>
                <DatePicker
                  style={{ width: "100%" }}
                  picker="year"
                  placeholder={t("Selectyear")}
                  disabledDate={(currentDate) =>
                    currentDate && currentDate.year() > currentYear
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="class"
                label={t("Class")}
                rules={[{ required: true, message: t("Pleaseinputtheclass") }]}
              >
                <Select value={fnClass} onChange={handleclass}>
                  {statementclass?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {currentLang === "en" ? option.label : option.labelFr}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="category" label={t("Category")}>
                <Select value={fnCategory} onChange={handlecategory}>
                  {statementcategory?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {currentLang === "en" ? option.label : option.labelFr}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label={t("Gifi")}
                rules={[{ required: true, message: t("Pleaseinputthetype") }]}
              >
                <Select value={fnType} onChange={handletype}>
                  {statementtype?.map((option) => (
                    <Option
                      key={option.id || option.gifi}
                      value={option.id || option.gifi}
                    >
                      {currentLang === "en"
                        ? option.label ||
                          "(" +
                            t("Gifi") +
                            ": " +
                            option.gifi +
                            ") " +
                            option.description
                        : option.labelFr ||
                          "(" +
                            t("Gifi") +
                            ": " +
                            option.gifi +
                            ") " +
                            option.descriptionFr}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="description" label={t("Description")}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left">{t("Additionalinformation")}</Divider>

          <Row
            gutter={[16, 16]}
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Col span={16}>
              <Form.Item name="entreprisedepartement" label={t("Departements")}>
                <Select
                  notFoundContent={t("nodata")}
                  label={t("Departments")}
                  size="large"
                  style={{ width: "100%" }}
                  min={0}
                >
                  {DepartmentT.map((d) => (
                    <Option key={d.id} value={d.id}>
                      {d.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="annualBudget"
                label={t("Annualbudget")}
                rules={[
                  {
                    message: t("Pleaseinputtheannualbudget"),
                    required: componentDisabled,
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            </Col>

            <Col span={16}>
              <Form.Item name="note" label={t("Note")}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={16}>
              <Form.Item name="budgetBreakdown">
                <Checkbox
                  checked={componentDisabled}
                  onChange={(e) => setComponentDisabled(e.target.checked)}
                >
                  {t("Budgetbreakdown")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <div style={{ overflowX: "auto",backgroundColor:'white' }}>
      <Table
        locale={{
          emptyText: <Empty description={t("nodata")} />,
        }}
        columns={columns}
        dataSource={ChartofAccounts}
        bordered
      />
      </div>
      <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
    </Card>
  );
}
ChartofAcount.acl = {
  subject: "Chart of Accounts",
  action: "read",
};
export default ChartofAcount;

// Chart of Accounts"
