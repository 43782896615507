import {
  Space,
  Card,
  Button,
  Select,
  Table,
  Checkbox,
  Form,
  Row,
  Col,
  Typography,
  message,
} from "antd";
import {
  UnlockOutlined,
  SaveOutlined,
  MinusSquareFilled,
  CheckSquareFilled,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { JSON_API } from "../services/Constants";
import { CompanyContext } from "../contexts/CompanyContext";
import { useContext } from "react";
import { useAbility } from "../contexts/abilityContext";

const { Text } = Typography;

const ManageAccess = () => {
  const { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [Roles, setRoles] = React.useState([]);
  const [Sections, setSections] = React.useState([]);
  const [accessList, setAccessList] = useState([{}]);
  const [selectedRole, setSelectedRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedParentLabel, setSelectedParentLabel] = useState("");
  const [accessDataCache, setAccessDataCache] = useState({});
  const { ability } = useAbility();
  const [form] = Form.useForm();

  const handleParentLabelChange = (value) => {
    setSelectedParentLabel(value);
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/Roles/activated/enterprise/${Company.id}`
      );
      setRoles(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchSections = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_JSON_API}/sections`);

      const sectionsWithSubsections = await Promise.all(
        response.data.map(async (section) => {
          const subSections = await getSubsections(section.id);
          return [
            { ...section, isSubsection: false },
            ...subSections.map((subSection) => ({
              ...subSection,
              isSubsection: true,
            })),
          ];
        })
      );

      setSections(sectionsWithSubsections.flat());
    } catch (error) {
      console.error("Error while fetching sections:", error);
    }
  };

  const getSubsections = async (parentId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/sections/parent/${parentId}/subsections`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Failed to fetch subsections.");
        return [];
      }
    } catch (error) {
      console.error("Error while fetching subsections:", error);
      return [];
    }
  };

  const fetchAccessListForRole = async (roleId) => {
    const response = await axios.get(`${process.env.REACT_APP_JSON_API}/accesses/role/${roleId}`);
    const data = await response.data;

    const accessListWithAllSections = Sections.map((section) => {
      const sectionAccess = data.find((a) => a.section.id === section.id);
      console.log("sectionAccess", sectionAccess);
      return sectionAccess
        ? { ...sectionAccess, ...section, accessId: sectionAccess.id }
        : {
            ...section,
            roleId,
            insert: false,
            read: false,
            update: false,
            delete: false,
          };
    }).flat();

    return accessListWithAllSections;
  };

  const updateAccessListForRole = async (roleId) => {
    let accessListForRole;

    if (accessDataCache[roleId]) {
      accessListForRole = accessDataCache[roleId];
    } else {
      accessListForRole = await fetchAccessListForRole(roleId);
      setAccessDataCache((prevState) => ({
        ...prevState,
        [roleId]: accessListForRole,
      }));
    }

    const filteredAccessList = accessListForRole.filter((access) => {
      if (selectedParentLabel === "") {
        return !access.isSubsection && !access.parentSectionId;
      }

      const parentSection = Sections.find(
        (section) => section.id === access.parentSectionId
      );

      if (access.isSubsection) {
        return parentSection && parentSection.label === selectedParentLabel;
      }

      return (
        !access.isSubsection &&
        access.label === selectedParentLabel &&
        !access.parentSectionId
      );
    });

    console.log("filteredAccessList", filteredAccessList);
    setAccessList(filteredAccessList);
  };

  const handleRoleChange = async (value) => {
    if (selectedRole) {
      setAccessDataCache((prevState) => ({
        ...prevState,
        [selectedRole]: accessList,
      }));
    }

    setSelectedRole(value);
  };

  const saveAccessList = async () => {
    setLoading(true);
    try {
      const updatedAccessList = [...accessList];

      for (const access of accessList) {
        const accessUpdateDto = {
          roleId: selectedRole,
          sectionId: access.id,
          insert: access.insert,
          read: access.read,
          update: access.update,
          delete: access.delete,
        };

        if (access.hasOwnProperty("accessId")) {
          await axios.put(
            `${process.env.REACT_APP_JSON_API}/accesses/${access.accessId}`,
            accessUpdateDto
          );
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_JSON_API}/accesses`,
            accessUpdateDto
          );
          const newAccessId = response.data.id;
          const index = updatedAccessList.findIndex((a) => a.id === access.id);
          if (index !== -1) {
            updatedAccessList[index].accessId = newAccessId;
          }
        }
      }

      const updatedCache = {
        ...accessDataCache,
        [selectedRole]: updatedAccessList,
      };

      setAccessDataCache(updatedCache);
      localStorage.setItem("accessDataCache", JSON.stringify(updatedCache));

      message.success(t("AccessSavedSuccessfully"));
      setLoading(false);
    } catch (error) {
      console.error("Error while saving access list:", error);
      message.error(t("ErrorSavingAccess"));
    }
  };

  const handleCheckAll = async (e, record) => {
    const newAccessList = accessList.map((access) => {
      if (access.id === record.id) {
        return {
          ...access,
          insert: e.target.checked,
          read: e.target.checked,
          update: e.target.checked,
          delete: e.target.checked,
        };
      }
      return access;
    });

    setAccessList(newAccessList);
  };

  const handleCheckboxChange = (e, record, key) => {
    const newAccessList = accessList.map((access) => {
      if (access.id === record.id) {
        return {
          ...access,
          [key]: e.target.checked,
        };
      }
      return access;
    });

    setAccessList(newAccessList);
  };

  const columns = [
    {
      title: t("Section"),
      dataIndex: "label",
      key: "label",
      render: (text, record) => (
        <span
          style={{
            marginLeft: record.isSubsection ? "20px" : "0px",
            color: record.isSubsection ? "#999" : "#000",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: t("Insert"),
      dataIndex: "insert",
      key: "insert",
      align: "center",
      render: (text, record) => (
        <Checkbox
          disabled={ability && !ability.can("update", "Manage Access")}
          checked={record.insert}
          onChange={(e) => handleCheckboxChange(e, record, "insert")}
        />
      ),
    },
    {
      title: t("Read"),
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (text, record) => (
        <Checkbox
          disabled={ability && !ability.can("update", "Manage Access")}
          checked={record.read}
          onChange={(e) => handleCheckboxChange(e, record, "read")}
        />
      ),
    },
    {
      title: t("Update"),
      dataIndex: "update",
      key: "update",
      align: "center",
      render: (text, record) => (
        <Checkbox
          disabled={ability && !ability.can("update", "Manage Access")}
          checked={record.update}
          onChange={(e) => handleCheckboxChange(e, record, "update")}
        />
      ),
    },
    {
      title: t("Delete"),
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (text, record) => (
        <Checkbox
          disabled={ability && !ability.can("update", "Manage Access")}
          checked={record.delete}
          onChange={(e) => handleCheckboxChange(e, record, "delete")}
        />
      ),
    },

    {
      title: t("CheckAll"),
      dataIndex: "checkAll",
      key: "checkAll",
      align: "center",
      render: (_, record) => (
        <Checkbox
          disabled={ability && !ability.can("update", "Manage Access")}
          checked={
            record.insert && record.read && record.update && record.delete
          }
          onChange={(e) => handleCheckAll(e, record)}
        />
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await fetchRoles();
      await fetchSections();

      // Récupérer les données du localStorage
      const localData = localStorage.getItem("accessDataCache");
      if (localData) {
        setAccessDataCache(JSON.parse(localData));
      }

      if (selectedRole) {
        await updateAccessListForRole(selectedRole);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedRole && Sections.length > 0) {
      updateAccessListForRole(selectedRole);
    }
  }, [selectedRole, Sections, selectedParentLabel, accessDataCache]);

  const checkAllAccess = () => {
    const updatedAccessList = accessList.map((access) => ({
      ...access,
      insert: true,
      read: true,
      update: true,
      delete: true,
    }));

    setAccessList(updatedAccessList);
  };

  const UncheckAllAccess = () => {
    const updatedAccessList = accessList.map((access) => ({
      ...access,
      insert: false,
      read: false,
      update: false,
      delete: false,
    }));

    setAccessList(updatedAccessList);
  };
  return (
    <>
      <Card
        title={
          <span style={{ fontSize: "16px" }}>
            <UnlockOutlined /> {t("ManageAccess")}
          </span>
        }
      >
        <Row align="middle" gutter={16} style={{ marginBottom: "35px" }}>
          <Col>
            <Typography.Text style={{ fontSize: "14px" }}>
              {t("ChooseRoleToManageAccess")}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Select
              suffixIcon={<CaretDownOutlined />}
              style={{ width: "100%" }}
              placeholder={t("SelectRole")}
              onChange={handleRoleChange}
            >
              {Roles.map((role) => (
                <Select.Option key={role.id} value={role.id}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {selectedRole && (
          <>
            <Row
              align="middle"
              justify="space-between"
              gutter={[16, 16]}
              style={{
                marginBottom: "1.5rem",
                background: "#f1f5f9",
                padding: "1rem",
                borderRadius: "8px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Col style={{ marginRight: "1rem" }}>
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  style={{ width: "500px" }}
                  placeholder={t("SelectParentSection")}
                  onChange={handleParentLabelChange}
                >
                  <Select.Option key="all" value="">
                    {t("All")}
                  </Select.Option>
                  {Sections.filter((section) => !section.isSubsection).map(
                    (section) => (
                      <Select.Option key={section.id} value={section.label}>
                        {section.label}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Col>
              <Col style={{ marginLeft: "44rem" }}>
                <Space>
                  <Button
                    onClick={checkAllAccess}
                    type="primary"
                    icon={<CheckSquareFilled />}
                    style={{
                      background: "linear-gradient(45deg, #007bff, #0056b3)",
                      borderColor: "#0056b3",
                    }}
                  >
                    {t("SelectAll")}
                  </Button>
                  <Button
                    onClick={UncheckAllAccess}
                    type="danger"
                    icon={<MinusSquareFilled />}
                    style={{
                      background: "linear-gradient(45deg, #ff4d4f, #b32931)",
                      borderColor: "#b32931",
                      color: "#fff",
                    }}
                  >
                    {t("UnselecteAll")}
                  </Button>
                </Space>
              </Col>
            </Row>

            <Table
              className="custom-table"
              dataSource={accessList}
              columns={columns}
              rowKey="id"
              loading={loading}
            />

            {ability && ability.can("update", "Manage Access") && (
              <Button
                type="primary"
                size="medium"
                className="uppercase"
                style={{
                  borderRadius: "20px",
                  fontSize: "10px",
                  textTransform: "uppercase",
                  marginBottom: "20px",
                }}
                icon={<SaveOutlined />}
                onClick={saveAccessList}
              >
                {t("SaveAccess")}
              </Button>
            )}
          </>
        )}
      </Card>
    </>
  );
};
ManageAccess.acl = {
  subject: "Manage Access",
  action: "read",
};

export default ManageAccess;
