import React from "react";
import { Card, Space, Button, Badge } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import SectionDetail from "./SectionDetail";
import CategoryDetail from "./CategoryDetail";
import Section from "./Section";
import { useTranslation } from "react-i18next";
const DetailPart = ({
  part,
  addSection,
  addSectionToCategory,
  addCategory,
  deleteCategory,
  editCategory,
  editSection,
  deleteSection,
  partId,
  enterpriseId,
  isReadOnly,
 complexity
}) => {
  let { t } = useTranslation();
  const getDefaultSections = (partName) => {
    switch(complexity){
      case 0:
        switch (partName) {
          case "InitialCash":
            return [];
          case "Inflow":
            return [
              {
                id: "default1",
                name: "Inflow Total",
                chartAccountId: 0,
                isImmutable: true,
              },
            ];
          case "Outflow":
            return [
              {
                id: "default",
                name: "Outflow Total",
                chartAccountId: 0,
                isImmutable: true,
              },
            ];
          case "Summary":
            return [
              {
                id: "default1",
                name: "Difference",
                chartAccountId: 0,
                isImmutable: true,
              },
              {
                id: "default2",
                name: "Final Cash",
                chartAccountId: 1,
                isImmutable: true,
              },
            ];
          default:
            return [
              {
                id: "default",
                name: "Default Section",
                chartAccountId: 0,
                isImmutable: true,
              },
            ];
        }
        case 1:
          switch (partName) {
            case "InitialCash":
              return [];
            case "Inflow":
              return [
                {
                  id: "default1",
                  name: "Inflow Total",
                  chartAccountId: 0,
                  isImmutable: true,
                },
              ];
            case "Outflow":
              return [
                {
                  id: "default",
                  name: "Outflow Total",
                  chartAccountId: 0,
                  isImmutable: true,
                },
              ];
            case "Summary":
              return [
                {
                  id: "default1",
                  name: "Difference",
                  chartAccountId: 0,
                  isImmutable: true,
                },
                {
                  id: "default2",
                  name: "Final Cash",
                  chartAccountId: 1,
                  isImmutable: true,
                },
              ];
            default:
              return [
                {
                  id: "default",
                  name: "Default Section",
                  chartAccountId: 0,
                  isImmutable: true,
                },
              ];
          }
          default:
            switch (partName) {
              case "InitialCash":
                return [];
              case "Inflow":
                return [
                  {
                    id: "default1",
                    name: "Inflow Total",
                    chartAccountId: 0,
                    isImmutable: true,
                  },
                    {
                    id: "default2",
                    name: "Interim Financing (DIP)",
                    chartAccountId: 0,
                    isImmutable: true,
                  },
                  {
                    id: "default3",
                    name: "Inflow Global Total",
                    chartAccountId: 0,
                    isImmutable: true,
                  }
                ];
              case "Outflow":
                return [
                  {
                    id: "default",
                    name: "Outflow Total",
                    chartAccountId: 0,
                    isImmutable: true,
                  },
                ];
              case "Summary":
                return [
                  {
                    id: "default4",
                    name: "Final Cash",
                    chartAccountId: 1,
                    isImmutable: true,
                  },
                  {
                    id: "default5",
                    name: "Line Of Credit At The Start",
                    chartAccountId: 1,
                    isImmutable: true,
                  },
                  {
                    id: "default6",
                    name: "Reimbursement Of The Period",
                    chartAccountId: 1,
                    isImmutable: true,
                  },
                  {
                    id: "default7",
                    name: "Line Of Credit At The End",
                    chartAccountId: 1,
                    isImmutable: true,
                  },
                  {
                    id: "default8",
                    name: "Interim Financing At The Start (DIP)",
                    chartAccountId: 1,
                    isImmutable: true,
                  },
                  {
                    id: "default9",
                    name: "Reimbursement Of The Period",
                    chartAccountId: 1,
                    isImmutable: true,
                  },
                  {
                    id: "default10",
                    name: "Interim Financing At The End",
                    chartAccountId: 1,
                    isImmutable: true,
                  }
                ];
              default:
                return [
                  {
                    id: "default",
                    name: "Default Section",
                    chartAccountId: 0,
                    isImmutable: true,
                  },
                ];
            }
    }
  };
  const getFirstSections = (partName) => {
    switch(complexity){
      case 0:
        switch (partName) {
          case "InitialCash":
            return [];
          case "Inflow":
            return [ ];
          case "Outflow":
            return [];
          case "Summary":
            return [];
          default:
            return [
              {
                id: "default",
                name: "Default Section",
                chartAccountId: 0,
                isImmutable: true,
              },
            ];
        }
        case 1:
          switch (partName) {
            case "InitialCash":
              return [];
            case "Inflow":
              return [ ];
            case "Outflow":
              return [];
            case "Summary":
              return [];
            default:
              return [
                {
                  id: "default",
                  name: "Default Section",
                  chartAccountId: 0,
                  isImmutable: true,
                },
              ];
          }
          default:
            switch (partName) {
              case "InitialCash":
                return [];
              case "Inflow":
                return [];
              case "Outflow":
                return [];
              case "Summary":
                return [
                  {
                    id: "default1",
                    name: "Initial Cash",
                    chartAccountId: 0,
                    isImmutable: true,
                  },
                  {
                    id: "default2",
                    name: "Difference",
                    chartAccountId: 1,
                    isImmutable: true,
                  },
                  {
                    id: "default3",
                    name: "Intermediate Cash",
                    chartAccountId: 1,
                    isImmutable: true,
                  },
                ];
              default:
                return [
                  {
                    id: "default",
                    name: "Default Section",
                    chartAccountId: 0,
                    isImmutable: true,
                  },
                ];
            }
    }
  };
  const firstSections=getFirstSections(part.name);
  const lastSections = getDefaultSections(part.name);
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Badge.Ribbon
        text={t(part.name)}
        color="#4E4E4E"
        placement="start"
        style={{ width: 100 }} >
        <Card>
        {part.name !== "InitialCash"&&part.name !== "Summary" &&  (
            <>
              <Button
                type="link"
                icon={<PlusOutlined style={{marginRight:-10}} />}
                style={{
                  position: "absolute",
                  color: "#cccc5a",
                  top: 10,
                  right: 0,
                  zIndex: 1,
                  marginRight: 102,
                }}
                onClick={() => addCategory(partId)}
              >
              {t("AddCategory")}
              </Button>
              <Button
                type="link"
                icon={<PlusOutlined style={{color:"#2a98db",marginRight:-10 }} />}
                style={{ position: "absolute", top: 10, right: 0, zIndex: 1,color:"#2a98db" }}
                onClick={() => addSection(partId)}
              >
              {t("AddSection")}
              </Button>
            </>)}     
          {complexity==2 && part.name == "Summary" &&  (
              <Button
                type="link"
                icon={<PlusOutlined style={{color:"#2a98db"}} />}
                style={{ position: "absolute", top: 10, right: 0, zIndex: 1,color:"#2a98db" }}
                onClick={() => addSection(partId)}>
              {t("AddMinusSection")}
              </Button> )}
          <br />
          {part.categories &&
            part.categories.map((category, idx) => (
              <CategoryDetail
                key={idx}
                category={category}
                partId={part.id}
                addCategory={addCategory}
                deleteCategory={deleteCategory}
                addSection={addSection}
                addSectionToCategory={addSectionToCategory}
                editCategory={editCategory}
                editSection={editSection}
                deleteSection={deleteSection}
                isReadOnly={isReadOnly}
                enterpriseId={enterpriseId}
               complexity={complexity}

              />
            ))}
          {part.sections &&
            part.sections.map((section, idx) => (
              <SectionDetail
                key={idx}
                initialSection={section} 
                partId={partId}
                addSection={addSection}
                deleteSection={deleteSection}
                editSection={editSection}
                categoryId={null}
                isReadOnly={isReadOnly}
                enterpriseId={enterpriseId}
                complexity={complexity}
              />
            ))}
        </Card>
      </Badge.Ribbon>
    </Space>
  );
};

export default DetailPart;
