import {
  ArrowLeftOutlined,
  ProfileFilled,
  SaveFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";

import axios from "axios";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API, JSON_API_Report } from "../services/Constants";
dayjs.extend(customParseFormat);
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const Reportoption = (props) => {
  const { Company } = useContext(CompanyContext);
  const [active, setActive] = useState(false);
  const history = useHistory();
  const [indeterminate, setIndeterminate] = useState(false);
  const [summaries, setSummaries] = useState(null);

  const [save, setSave] = useState(false);

  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState(null);
  const [checkedList1, setCheckedList1] = useState(null);
  const [checkedList2, setCheckedList2] = useState(null);
  const [entDepts, setEntDepts] = useState([]);
const [CashFlow, setCashFlow] = useState([]);
  const [selectedCashFlow, setSelectedCashFlow] = useState(null);
  const [name, setName] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const [yearVal, setyearVal] = useState(false);
  const [open, setOpen] = useState(false);

  const { reportid, endpoint } = props;
  const stateParamVal = useLocation().state.stateParam;
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const { Title, Text } = Typography;
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const { reportId, reportEndPoint } = useParams();
  const [pdfData, setPdfData] = useState(null);
  const [standardreport, setStandardreport] = useState([]);
  const [reload, setreload] = useState(false);
  console.log(Company)
  const getEntDeptartments = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments?enterpriseId=${Company.id}`)
      .then((response) => {
        setEntDepts(response.data);
      });
  };
const getCashFlow = async () => {
    axios
      .get(`${process.env.REACT_APP_JSON_API}/aaCashFlows/enterprise/${Company.id}`)
      .then((res) => {
        setCashFlow(res.data);
        console.log('cashflow',res.data)
        res.data[0]? setSelectedCashFlow(res.data[0].id):setSelectedCashFlow(null)
      })
      .catch((err) => {
      });
  };
  const getReport = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_JSON_API}/Reporting/standardReports/${reportId}?language=${t(
          "lang"
        )}`
      )
      .then((response) => {
        setStandardreport(response.data);
      });
  };
  var date;
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "(404) - No Data Found to Dislay!",
    });
  };
  useEffect(() => {
    date = new Date().getFullYear();
    setCheckedList(groupOptions.map((option) => option.value));
    setIndeterminate(false);
    setCheckAll(true);
    if (stateParamVal == 3 || stateParamVal == 4) {
      setyearVal(true);
      setCheckedList1(groupOptions1.map((option) => option.value));
      setCheckedList2(groupOptions2.map((option) => option.value));
    }

    if (stateParamVal == 24 || stateParamVal == 25 || stateParamVal == 26) {
      setSummaries([]);
    } else {
      if(stateParamVal==27 || stateParamVal === 28 || stateParamVal === 29){
        setSummaries(["Averages"]);
      }else{
        setSummaries(["Totals", "Averages"]);
      }
      
    }
    getReport();
    getEntDeptartments();
  }, [t("lang")]);
  useEffect(()=>{
    //window.location.reload();
    if(reload){
      window.location.reload();
    }else{
      getCashFlow();
      setreload(true)
    }
//getCashFlow();
  },[Company.id])

  console.log("stateParamVal", stateParamVal);
  let groupOptions = [];
  let groupOptions1 = [];
  let groupOptions2 = [];
  const options = () => {
    switch (stateParamVal) {
      // Strategic Planning ----------------------------------------------------------------
      case 1:
        groupOptions = [
          { label: t("Year"), value: "Year" },
          { label: t("name"), value: "Name" },
          { label: t("Description"), value: "Description" },
          { label: t("OverallProgress"), value: "Overall Progress" },
        ];
        // setCheckedList(groupOptions.map((option) => option.value));

        // form.setFieldsValue({
        //   checkboxGroupField: [
        //     "Year",
        //     "Name",
        //     "Description",
        //     "Overall Progress",
        //   ],
        // });
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 2 */}
            <Col span={8}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />

              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                {t("checkall")}
              </Checkbox>
              <Divider />

              <CheckboxGroup
                // options={groupOptions}

                value={checkedList}
                onChange={onChange}
              >
                <Row>
                  {groupOptions.map((o) => (
                    <Col key={o.value}>
                      <Checkbox value={o.value}>{o.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </CheckboxGroup>
            </Col>

            <Col span={8}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                  <Option value="Portrait">{t("Portrait")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 3 */}

            <Col span={8}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>
            <Divider />
            {/* Column 4 */}
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 2:
        groupOptions = [
          { label: t("Year"), value: "Year" },
          { label: t("name"), value: "Name" },
          { label: t("Description"), value: "Description" },
          { label: t("OverallProgress"), value: "Overall Progress" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={6}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* Column 2 */}
            <Col span={6}>
              <Title level={5}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("displaydetails")}
                </Checkbox>
              </Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row>
                    {groupOptions.map((o) => (
                      <Col align="left" key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                  <Option value="Portrait">{t("Portrait")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={6}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      // Sales ----------------------------------------------------------------
      case 3:
        groupOptions1 = [
          { label: t("Name"), value: "Name" },
          { label: t("TotalRevenue"), value: "Total Revenue" },
        ];
        groupOptions2 = [
          { label: t("Client"), value: "Client" },
          { label: t("Product"), value: "Product" },
          { label: t("FromAnOpportunity"), value: "From an Opportunity" },
          { label: t("OrderRevenueTotal"), value: "Order Revenue Total" },
        ];

        return (
          <Row justify="center" gutter={[16, 16]}>
            <Col span={6}>
              <Title level={5}> {t("Period")}</Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item {...formItemLayout}>
                    <Checkbox
                      defaultChecked
                      onChange={(e) => setyearVal(e.target.checked)}
                    >
                      {t("Year")}
                    </Checkbox>
                    <CheckboxGroup value={checkedList1} onChange={onChange1}>
                      <Row>
                        {groupOptions1.map((o) => (
                          <Col align="left" key={o.value}>
                            <Checkbox value={o.value}>{o.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </CheckboxGroup>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item {...formItemLayout}>
                    <CheckboxGroup value={checkedList2} onChange={onChange2}>
                      <Row>
                        {groupOptions2.map((o) => (
                          <Col align="left" key={o.value}>
                            <Checkbox value={o.value}>{o.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </CheckboxGroup>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                  <Option value="Portrait">{t("Portrait")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={6}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 4:
        groupOptions1 = [
          { label: t("Name"), value: "Name" },
          { label: t("ExpectedRevenueTotal"), value: "Expected Revenue Total" },
        ];
        groupOptions2 = [
          { label: t("Client"), value: "Client" },
          { label: t("Product"), value: "Product" },
          { label: t("Status"), value: "Status" },
          {
            label: t("OpportunityExpectedRevenueTotal"),
            value: "Opportunity Expected Revenue Total",
          },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={6}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item {...formItemLayout}>
                    <Checkbox
                      defaultChecked
                      onChange={(e) => setyearVal(e.target.checked)}
                    >
                      {t("Year")}
                    </Checkbox>
                    <CheckboxGroup value={checkedList1} onChange={onChange1}>
                      <Row>
                        {groupOptions1.map((o) => (
                          <Col align="left" key={o.value}>
                            <Checkbox value={o.value}>{o.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </CheckboxGroup>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item {...formItemLayout}>
                    <CheckboxGroup value={checkedList2} onChange={onChange2}>
                      <Row>
                        {groupOptions2.map((o) => (
                          <Col align="left" key={o.value}>
                            <Checkbox value={o.value}>{o.label}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </CheckboxGroup>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* <Col span={8}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item>
                <Checkbox
                  value="Year"
                  onChange={(e) => setyearVal(e.target.checked)}
                >
                  Year
                </Checkbox>
              </Form.Item>
              <Row
                justify="center"
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}
              >
                <Col span={12}>
                  <Text strong>Opportunity book details:</Text>
                  <Divider />

                  <Form.Item {...formItemLayout}>
                    <CheckboxGroup
                      // options={groupOptions}
                      value={checkedList1}
                      onChange={onChange1}
                    >
                      <Row>
                        {groupOptions1.map((o) => (
                          <Col key={o}>
                            <Checkbox value={o}>{o}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </CheckboxGroup>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Text strong>Opportunities details:</Text>

                  <Divider />

                  <Form.Item {...formItemLayout}>
                    <CheckboxGroup
                      // options={groupOptions}
                      value={checkedList2}
                      onChange={onChange2}
                    >
                      <Row>
                        {groupOptions2.map((o) => (
                          <Col key={o}>
                            <Checkbox value={o}>{o}</Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </CheckboxGroup>
                  </Form.Item>
                </Col>
              </Row>
            </Col> */}
            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                  <Option value="Portrait">{t("Portrait")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={6}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 5:
        groupOptions = [
          { label: t("Revenue"), value: "Revenue" },
          { label: t("Glnumber"), value: "GL Number" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 6:
        groupOptions = [
          { label: t("Revenue"), value: "Revenue" },
          { label: t("Glnumber"), value: "GL Number" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 7:
        groupOptions = [
          { label: t("Revenue"), value: "Revenue" },
          { label: t("Glnumber"), value: "GL Number" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 8:
        groupOptions = [
          { label: t("Expense"), value: "Expense" },
          { label: t("Glnumber"), value: "GL Number" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 9:
        groupOptions = [
          { label: t("Expense"), value: "Expense" },
          { label: t("Glnumber"), value: "GL Number" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 10:
        groupOptions = [
          { label: t("Expense"), value: "Expense" },
          { label: t("Glnumber"), value: "GL Number" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 11:
        groupOptions = [
          { label: t("Description"), value: "Description" },
          { label: t("Type"), value: "Type" },
          { label: t("AnnualBudget"), value: "Annual Budget" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={5}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={5}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 12:
        groupOptions = [
          { label: t("Description"), value: "Description" },
          { label: t("Type"), value: "Type" },
          { label: t("AnnualBudget"), value: "Annual Budget" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={5}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={5}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 13:
        groupOptions = [
          { label: t("Description"), value: "Description" },
          { label: t("Type"), value: "Type" },
          { label: t("AnnualBudget"), value: "Annual Budget" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={5}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={5}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      case 14:
        groupOptions = [
          { label: t("Employee"), value: "Employee" },
          { label: t("AnnualSalary"), value: "Annual Salary" },
          { label: t("SocialAdvantages"), value: "Social Advantages" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
                label={t("orientation")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="size"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
                label={t("size")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 15:
        groupOptions = [
          { label: t("Employee"), value: "Employee" },
          { label: t("AnnualSalary"), value: "Annual Salary" },
          { label: t("SocialAdvantages"), value: "Social Advantages" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
                label={t("orientation")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="size"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
                label={t("size")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 16:
        groupOptions = [
          { label: t("Employee"), value: "Employee" },
          { label: t("AnnualSalary"), value: "Annual Salary" },
          { label: t("SocialAdvantages"), value: "Social Advantages" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
                label={t("orientation")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="size"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
                label={t("size")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 17:
        groupOptions = [
          { label: t("Year"), value: "Year" },
          { label: t("GIFI"), value: "GIFI" },
          { label: t("Class"), value: "Class" },
          { label: t("Category"), value: "Category" },
          { label: t("Glnumber"), value: "GL Number" },
          { label: t("Description"), value: "Description" },
          { label: t("AnnualBudget"), value: "Annual Budget" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={6}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={6}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                name="orientation"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
                label={t("orientation")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                  <Option value="Portrait">{t("Portrait")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="size"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
                label={t("size")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={6}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      case 18:
        groupOptions = [
          { label: t("IncomeStatement"), value: "Income Statement" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      case 19:
        groupOptions = [
          { label: t("IncomeStatement"), value: "Income Statement" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      case 20:
        groupOptions = [
          { label: t("IncomeStatement"), value: "Income Statement" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={3}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      case 21:
        groupOptions = [
          { label: t("ProjectNumber"), value: "Project Number" },
          { label: t("Title"), value: "Title" },
          { label: t("Category"), value: "Category" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={5}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={5}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
                label={t("orientation")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="size"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
                label={t("size")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={5}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 22:
        groupOptions = [
          { label: t("ProjectNumber"), value: "Project Number" },
          { label: t("Title"), value: "Title" },
          { label: t("Category"), value: "Category" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={5}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={5}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
                label={t("orientation")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="size"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
                label={t("size")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={5}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 23:
        groupOptions = [
          { label: t("ProjectNumber"), value: "Project Number" },
          { label: t("Title"), value: "Title" },
          { label: t("Category"), value: "Category" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={5}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={5}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Totals"}>{t("Totals")}</Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
                label={t("orientation")}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="size"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
                label={t("size")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={5}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      case 24:
        groupOptions = [{ label: t("IncomeStatement"), value: "Statement" }];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      case 25:
        groupOptions = [{ label: t("IncomeStatement"), value: "Statement" }];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  defaultValue="EN"
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      case 26:
        groupOptions = [{ label: t("IncomeStatement"), value: "Statement" }];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            
            <Col span={4}>
              <Title level={5}>{t("departements")}</Title>
              <Divider />
              <Form.Item name="EnterpriseDepartments">
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("SelectDepartment")}
                >
                  {entDepts &&
                    entDepts.map((element) => (
                      <Option value={element.id}>{element.label}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
case 27:
        groupOptions = [
          { label: t("Name"), value: "Name" },
          { label: t("Hypothesis"), value: "Hypothesis" },
          { label: t("Glnumber"), value: "GL Number" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={24}>
              <Title level={5}>{t("CashFlow")}</Title>
              <Divider />
              <Form.Item name="CashFlow">
                {CashFlow[0] && 
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue={CashFlow[0].name}
                  value={selectedCashFlow}
                  placeholder={t("CashFlow")}
                  onChange={e=>setSelectedCashFlow(e)}
                >
                  {CashFlow && 
                    CashFlow.map((element) => (
                      <Option key={element.id} value={element.id}>{element.name}</Option>
                    ))}
                </Select>}
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={9}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={7}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 28:
        groupOptions = [
          { label: t("Name"), value: "Name" },
          { label: t("Hypothesis"), value: "Hypothesis" },
          { label: t("Glnumber"), value: "GL Number" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={24}>
              <Title level={5}>{t("CashFlow")}</Title>
              <Divider />
              <Form.Item name="CashFlow">
                {CashFlow[0] && 
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue={CashFlow[0].name}
                  value={selectedCashFlow}
                  placeholder={t("CashFlow")}
                  onChange={e=>setSelectedCashFlow(e)}
                >
                  {CashFlow && 
                    CashFlow.map((element) => (
                      <Option key={element.id} value={element.id}>{element.name}</Option>
                    ))}
                </Select>}
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={9}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={7}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
      case 29:
        groupOptions = [
          { label: t("Name"), value: "Name" },
          { label: t("Hypothesis"), value: "Hypothesis" },
          { label: t("Glnumber"), value: "GL Number" },
        ];
        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={24}>
              <Title level={5}>{t("CashFlow")}</Title>
              <Divider />
              <Form.Item name="CashFlow">
                {CashFlow[0] && 
                <Select
                  style={{
                    width: "100%",
                  }}
                  defaultValue={CashFlow[0].name}
                  value={selectedCashFlow}
                  placeholder={t("CashFlow")}
                  onChange={e=>setSelectedCashFlow(e)}
                >
                  {CashFlow && 
                    CashFlow.map((element) => (
                      <Option key={element.id} value={element.id}>{element.name}</Option>
                    ))}
                </Select>}
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={4}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col span={24} key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={4}>
              <Title level={5}>{t("summaries")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup value={summaries} onChange={HandleSummaries}>
                  <Row>
                    <Col>
                      <Checkbox value={"Averages"}>{t("Averages")}</Checkbox>
                    </Col>
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={9}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={7}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );
        


        case 30:
        groupOptions = [
          { label: t("PM"), value: "PM" },
          { label: t("OP"), value: "OP" },
          { label: t("AME"), value: "AME" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={7}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );




        case 31:
        groupOptions = [
          { label: t("PM"), value: "PM" },
          { label: t("OP"), value: "OP" },
          { label: t("AME"), value: "AME" },
        ];

        return (
          <Row
            justify="center"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {/* Column 1 */}
            <Col span={4}>
              <Title style={{ padding: "auto" }} level={5}>
                {t("Period")}
              </Title>
              <Divider />

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("from")}
                name="from"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("to")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: `${t("Selectyear")}`,
                  },
                ]}
              >
                <DatePicker
                  placeholder={t("Selectyear")}
                  picker="year"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Divider />

              <Form.Item
                label={t("months")}
                rules={[
                  {
                    required: true,
                    message: `${t("pleaseselectamonth")}`,
                  },
                ]}
                name="months"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("selectmonth")}
                >
                  <Option value={"1"}>{t("January")}</Option>
                  <Option value={"2"}>{t("February")}</Option>
                  <Option value={"3"}>{t("March")}</Option>
                  <Option value={"4"}>{t("April")}</Option>
                  <Option value={"5"}>{t("May")}</Option>
                  <Option value={"6"}>{t("June")}</Option>
                  <Option value={"7"}>{t("July")}</Option>
                  <Option value={"8"}>{t("August")}</Option>
                  <Option value={"9"}>{t("September")}</Option>
                  <Option value={"10"}>{t("October")}</Option>
                  <Option value={"11"}>{t("November")}</Option>
                  <Option value={"12"}>{t("December")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 2 */}
            <Col span={7}>
              <Title level={5}>{t("displaydetails")}</Title>
              <Divider />
              <Form.Item {...formItemLayout}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {t("checkall")}
                </Checkbox>
              </Form.Item>
              <Divider />
              <Form.Item {...formItemLayout}>
                <CheckboxGroup
                  // options={groupOptions}
                  value={checkedList}
                  onChange={onChange}
                >
                  <Row justify="start">
                    {groupOptions.map((o) => (
                      <Col key={o.value}>
                        <Checkbox value={o.value}>{o.label}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </Form.Item>
            </Col>


            
            <Col span={6}>
              <Title level={5}>{t("Documentsizenorientation")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("orientation")}
                name="orientation"
                rules={[
                  {
                    required: true,
                    message: `${t("selectoption")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Landscape">{t("Landscape")}</Option>
                </Select>
              </Form.Item>

              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={t("size")}
                name="size"
                rules={[
                  {
                    required: true,
                    message: `${t("selectsize")}`,
                  },
                ]}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="A4">A4</Option>
                  <Option value="A3">A3</Option>
                  <Option value="Letter">{t("letter")}</Option>
                </Select>
              </Form.Item>
            </Col>
            {/* Column 3 */}
            <Col span={4}>
              <Title level={5}>{t("other")}</Title>
              <Divider />
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="language"
                label={t("languages")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="FR">Français</Option>
                  <Option value="EN">English</Option>
                </Select>
              </Form.Item>
              <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                name="currency"
                label={t("currency")}
              >
                <Select
                  placeholder={t("selectoption")}
                  style={{ width: "100%" }}
                >
                  <Option value="Euro">Euro €</Option>
                  <Option value="Dollar">Dollar $</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Column 4 */}
            <Divider />
            <Col span={8} style={{ margin: "auto" }}>
              <Form.Item>
                <Button
                  loading={active}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<ProfileFilled />}
                >
                  {t("generatereport")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => {
                    setSave(true);
                    setOpen(true);
                  }}
                  style={{ width: "100%" }}
                  icon={<SaveFilled />}
                >
                  {t("savereportoptions")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        );

      default:
        return null; // Or handle other cases accordingly
    }
  };

  function handleNameChange(e) {
    setName(e.target.value);
  }
  const HandleSummaries = (list) => {
    setSummaries(list);
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < groupOptions.length);
    setCheckAll(list.length === groupOptions.length);
  };
  const onChange1 = (list) => {
    setCheckedList1(list);
  };
  const onChange2 = (list) => {
    setCheckedList2(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked ? groupOptions.map((option) => option.value) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const PdfGenerator = async (values) => {
    console.log("Executing pdf generator method...", values);
    const yearsArray = [];
    let multidetailslist = [];
    if (values.from && values.to) {
      if (values.from.$y && values.to.$y) {
        const start = parseInt(values.from.$y);
        const end = parseInt(values.to.$y);

        for (let year = start; year <= end; year++) {
          yearsArray.push(year.toString());
        }
      }
    }

    if (checkedList1 !== null) {
      if (checkedList1.length !== 0) {
        if (stateParamVal === 3) {
          multidetailslist.push("OrderBooks");
        } else if (stateParamVal === 4) {
          multidetailslist.push("Opportunity Books");
        }
        checkedList1.forEach((element) => {
          multidetailslist.push(element);
        });
      }
    }

    if (checkedList2 !== null) {
      if (checkedList2.length !== 0) {
        if (stateParamVal === 3) {
          multidetailslist.push("Orders");
        } else if (stateParamVal === 4) {
          multidetailslist.push("Opportunities");
        }

        checkedList2.forEach((element) => {
          multidetailslist.push(element);
        });
      }
    }

    if (yearVal) {
      multidetailslist.push("Year");
    }

    // console.log("Details : ", multidetailslist);

    const obj = {
      years: yearsArray.length === 0 ? ["ALL"] : yearsArray,
      language: values.language,
      currency: values.currency,
      details: multidetailslist.length === 0 ? checkedList : multidetailslist,
      documentSize: values.size,
      orientation: values.orientation,
      sections:multidetailslist.length === 0 ? checkedList : multidetailslist,
    };

    if (
      stateParamVal === 5 ||
      stateParamVal === 8 ||
      stateParamVal === 14 ||
      stateParamVal === 11 ||
      stateParamVal === 21 ||
      stateParamVal === 18 ||
      stateParamVal === 24 ||
      stateParamVal === 27 ||
      stateParamVal === 30
    ) {
      obj.months = values.months;
      obj.dataTypes = ["Budgets"];
      obj.summaries =
        summaries !== null ? (summaries.length > 0 ? summaries : []) : [];
    }
    if (
      stateParamVal === 6 ||
      stateParamVal === 9 ||
      stateParamVal === 15 ||
      stateParamVal === 12 ||
      stateParamVal === 22 ||
      stateParamVal === 19 ||
      stateParamVal === 25 ||
      stateParamVal === 28 ||
      stateParamVal === 31
    ) {
      obj.months = values.months;
      obj.dataTypes = ["Reals"];
      obj.summaries =
        summaries !== null ? (summaries.length > 0 ? summaries : []) : [];
    }
    if (
      stateParamVal === 7 ||
      stateParamVal === 10 ||
      stateParamVal === 16 ||
      stateParamVal === 13 ||
      stateParamVal === 20 ||
      stateParamVal === 23 ||
      stateParamVal === 26 ||
      stateParamVal === 29
    ) {
      obj.months = values.months;
      obj.dataTypes = ["Budgets", "Reals", "Variances"];
      obj.summaries =
        summaries !== null ? (summaries.length > 0 ? summaries : []) : [];
    }
    if (
      stateParamVal === 24 ||
      stateParamVal === 25 ||
      stateParamVal === 26 ||
      stateParamVal === 18 ||
      stateParamVal === 19 ||
      stateParamVal === 20 ||
      stateParamVal === 8 ||
      stateParamVal === 9 ||
      stateParamVal === 10 ||
      stateParamVal === 5 ||
      stateParamVal === 6 ||
      stateParamVal === 7 ||
      stateParamVal === 14 ||
      stateParamVal === 15 ||
      stateParamVal === 16 ||
      stateParamVal === 30 ||
      stateParamVal === 31
    ) {
      obj.EnterpriseDepartments = values.EnterpriseDepartments;
    }

    if (save) {
      const objsave = {
        name: name,
        standardReportId: stateParamVal,
        enterpriseId: Company.id,
        endPointParameterValues: [
          {
            endPointParameterName: standardreport.endPointParameters[0] ? standardreport.endPointParameters[0].name:'enterpriseId',
            value: Company.id,
          },
        ],
        options: obj,
      };
      console.log("NOT READY YET!");

      console.log("Options:", objsave);
      await axios
        .post(`${process.env.REACT_APP_JSON_API}/Reporting/customReports`, objsave)
        .then((res) => {
          console.log(res.data);
        })
        .catch(function (error) {
          if (error.response.status == 404) {
            warning();
          }
          console.log(error.response.status);
        });
      setSave(false);
      setOpen(false);
    } else {
      console.log("Options:", obj);
      console.log("Save:", save);
      setActive(true);
      let cashFlow = ''
      if(stateParamVal==27 || stateParamVal === 28 || stateParamVal === 29){
        cashFlow = `?cashFlowId=${selectedCashFlow}`
      }
      await axios
        .post(
          `${process.env.REACT_JSON_API_REPORT}${standardreport.endpoint}${Company.id}${cashFlow}`,
          obj,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          console.log(res.data);
          const blob = new Blob([res.data], { type: "application/pdf" });

          // Generate a URL for the blob object
          const url = window.URL.createObjectURL(blob);
          setActive(false);

          // Set the URL in the state to display the download link
          setPdfData(url);
        })
        .catch(function (error) {
          setActive(false);
          if (error.response.status == 404) {
            warning();
          }
          console.log(error.response.status);
        });
    }
  };

  function handleGoBack() {
    history.goBack();
  }
  return (
    <>
      {contextHolder}

      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <h3 className="font-semibold">
            <ProfileFilled
              style={{
                marginRight: "10px",
              }}
            />
            {currentLang === "en"
              ? standardreport.nameEn
              : standardreport.nameFr}
          </h3>
        }
        extra={
          <Button type="primary" danger onClick={handleGoBack}>
            <ArrowLeftOutlined />
            {t("Goback")}
          </Button>
        }
      >
        <Modal
          open={open}
          title={t("namerepport")}
          okText={t("save")}
          cancelText={t("cancel")}
          onCancel={() => setOpen(false)}
          onOk={() => {
            form1.validateFields().then((values) => {
              form1.resetFields();
              setOpen(false);
            });
            form
              .validateFields()
              .then((values) => {
                PdfGenerator(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form
            form={form1}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: "public",
            }}
          >
            <Form.Item
              name="name"
              label={t("name")}
              rules={[
                {
                  required: true,
                  message: t("entername"),
                },
              ]}
            >
              <Input
                value={name}
                placeholder={t("entername")}
                onChange={handleNameChange}
              />
            </Form.Item>
          </Form>
        </Modal>

        <Form
          form={form}
          initialValues={{
            language: (Company.languages && Company.languages.length > 0 && Company.languages[0].shortLabel) ? Company.languages[0].shortLabel : 'EN',
            orientation: "Landscape",
            currency: (Company.currencies && Company.currencies.length > 0 && Company.currencies[0].label)?Company.currencies[0].label:"Dollar",
            size: "Letter",
            from: dayjs(date),
            to: dayjs(date),
            months: [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ],
          }}
          onFinish={PdfGenerator}
        >
          {options()}
        </Form>
        {pdfData ? (
          <>
            <Divider />
            <div style={{ width: "100%", height: "1000px" }}>
              <embed
                src={pdfData}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            </div>
          </>
        ) : (
          active && (
            <>
              <Divider />
              <Skeleton.Button
                block={true}
                style={{
                  width: "100%",
                  height: "1000px",
                }}
                active={active}
              />
            </>
          )
        )}
      </Card>
    </>
  );
};

export default Reportoption;
