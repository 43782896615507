import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Col,
  Select,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { JSON_API } from "../../../services/Constants";

dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text, Title } = Typography;
const { TextArea } = Input;

const PretDetails = () => {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [Object, setObject] = useState({});
  const [data, setData] = useState([{}]);
  const [messageApi, contextHolder] = message.useMessage();
  const [termestype, settermestype] = useState([{}]);
  const [Capital, setCapital] = useState([{}]);

  useEffect(() => {
    getdata();
    gettermesType();
    getCapitalType();
  }, []);

  const getdata = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Finance/Pret/${Company.id}/${id}`)

      .then((res) => {
        console.log("FinancementDetails", res);

        setObject(res.data);

        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCapitalType = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Finance/Capital`)
      .then((res) => {
        console.log("Financecpital", res);
        setCapital(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const gettermesType = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Finance/Term`)
      .then((res) => {
        console.log("Financeterme", res);
        settermestype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Update = async () => {
    console.log("bje", Object);

    const formData = {
      id: Object.id,
      name: Object.name,
      creditor: Object.creditor,
      loanAmount: Object.loanAmount,
      termId: Object.termsId,
      capitalId: Object.capitalPeriodId,
      loanDate: Object.loanDate,
      interestRates: Object.interestRates,
      specialConditions: Object.specialConditions,
    };

    console.log("formdata", formData);
    axios
      .put(`${process.env.REACT_APP_JSON_API}/Finance/Pret`, formData)
      .then((res) => {
        console.log("res", res);
        message.success(t("LoanUpdatedSuccessfully"));
        //history.push("/loan");
      })
      .catch(function (error) {
        console.log(error);
        message.error(t("AnerrorhasoccuredWhileUpdatingtheLoan"));
      });
  };

  return (
    <>
      {contextHolder}

      <Card
        bordered={false}
        className="header-solid mb-24"
        title={<h2 className="font-semibold">{t("pret")}</h2>}
      >
        {Object.name && (
          <Form
            form={form}
            s
            layout="horizontal"
            name="form_in_modal"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 14,
            }}
          >
            <Divider orientation="left"> {t("pret")}</Divider>

            <Form.Item
              name="name"
              label={t("Institutionname")}
              rules={[
                {
                  required: false,
                  validateTrigger: [],
                },
              ]}
            >
              <Input
                style={{ width: 500 }}
                onChange={(e) => {
                  Object.name = e.target.value;
                }}
                defaultValue={Object.name}
              ></Input>
            </Form.Item>

            <Form.Item
              name="creditor"
              label={t("Creditor")}
              rules={[
                {
                  required: true,
                  message: `${t("VeuillersaisirletitredeCreancier")}`,
                },
              ]}
            >
              <Input
                style={{ width: 500 }}
                onChange={(e) => {
                  Object.creditor = e.target.value;
                }}
                defaultValue={Object.creditor}
              ></Input>
            </Form.Item>

            <Form.Item
              name="loanAmount"
              label={t("Loanamount")}
              rules={[
                {
                  required: true,
                  message: `${t("chooseaterm")}`,
                },
              ]}
            >
              <InputNumber
                type="number"
                addonBefore="+"
                style={{
                  width: 470,
                  textAlign: "right",
                }}
                onChange={(e) => {
                  Object.loanAmount = e;
                }}
                defaultValue={Object.loanAmount}
              />
            </Form.Item>

            <Form.Item
              name="termsId"
              label={t("Terms")}
              rules={[
                {
                  required: true,
                  message: `${t("chooseaterm")}`,
                },
              ]}
            >
              <Select
                style={{
                  width: 470,
                }}
                onChange={(e) => {
                  Object.termsId = e;
                }}
                defaultValue={Object.termName}
              >
                {termestype &&
                  termestype.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.termName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="loanDate"
              label={t("Loandate")}
              rules={[
                {
                  required: true,
                  message: `${t("selectdate")}`,
                },
              ]}
            >
              <DatePicker
                placeholder={t("selectdate")}
                style={{
                  width: 500,
                  textAlign: "center",
                }}
                defaultValue={Object.loanDate ? dayjs(Object.loanDate) : null}
                onChange={(date, dateString) => {
                  setObject((prevObject) => ({
                    ...prevObject,
                    loanDate: dateString,
                  }));
                }}
              />
            </Form.Item>

            <Form.Item
              // value={conge}
              name="capitalPeriodId"
              label={t("Capitalfreeperiod")}
              rules={[
                {
                  required: true,
                  message: `${t("CapitalfreeperiodSelect")}`,
                },
              ]}
            >
              <Select
                style={{
                  width: 470,
                }}
                onChange={(e) => {
                  Object.capitalPeriodId = e;
                }}
                defaultValue={Object.capitalPeriodId}
              >
                {Capital &&
                  Capital.map((option) => (
                    <Option key={option.id} selected value={option.id}>
                      {option.capitalName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              // value={taux}
              name="interestRates"
              label={t("Interestrates")}
              rules={[
                {
                  required: true,
                  message: `${t("Veuillezsaisiruntauxdintéret")}`,
                },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                style={{ textalign: "right" }}
                onChange={(e) => {
                  Object.interestRates = e;
                }}
                defaultValue={Object.interestRates}
              />
            </Form.Item>

            <Form.Item
              // value={conditions}
              name="specialConditions"
              label={t("Particularconditions")}
            >
              <TextArea
                onChange={(e) => {
                  Object.specialConditions = e.target.value;
                }}
                style={{ width: 500 }}
                defaultValue={Object.specialConditions}
                rows={2}
              ></TextArea>
            </Form.Item>

            <Form.Item
              name="modifier"
              className="collection-create-form_last-form-item"
            ></Form.Item>
          </Form>
        )}

        <Row gutter={16} justify="center">
          <Col xs={24} sm={8}>
            <Form.Item>
              <Link to="/loan">
                <Button type="primary" danger block>
                  <ArrowLeftOutlined /> {t("GoBack")}
                </Button>
              </Link>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item>
              <Button type="primary" onClick={Update} htmlType="submit" block>
                <EditOutlined /> {t("Update")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};
PretDetails.acl = {
  subject: "Loans",
  action: "update",
};

export default PretDetails;
