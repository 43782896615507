// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-tabs-nav-wrap{
    
    height: 40px;
    
  

}
.ant-tabs-nav-list{
    transform: translate(0px, 0px);
    height: 40px;
     
      
}
.ant-tabs-tab{
     transform: translate(0px, 0px);
    height: 40px;
    
}

.element.style{
     height: 40px;
     width: 100px;

}
.ant-table-cell{
    text-align: right;
    /* background-color: rgb(238, 218, 238); */
    /* color: white; */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Financial Statement/tabscard.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;;;;AAIhB;AACA;IACI,8BAA8B;IAC9B,YAAY;;;AAGhB;AACA;KACK,8BAA8B;IAC/B,YAAY;;AAEhB;;AAEA;KACK,YAAY;KACZ,YAAY;;AAEjB;AACA;IACI,iBAAiB;IACjB,0CAA0C;IAC1C,kBAAkB;AACtB","sourcesContent":[".ant-tabs-nav-wrap{\r\n    \r\n    height: 40px;\r\n    \r\n  \r\n\r\n}\r\n.ant-tabs-nav-list{\r\n    transform: translate(0px, 0px);\r\n    height: 40px;\r\n     \r\n      \r\n}\r\n.ant-tabs-tab{\r\n     transform: translate(0px, 0px);\r\n    height: 40px;\r\n    \r\n}\r\n\r\n.element.style{\r\n     height: 40px;\r\n     width: 100px;\r\n\r\n}\r\n.ant-table-cell{\r\n    text-align: right;\r\n    /* background-color: rgb(238, 218, 238); */\r\n    /* color: white; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
