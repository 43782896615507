import React from "react";
import { Card, Space, Typography } from "antd";
import { useHistory } from "react-router-dom";
import LogoIntegrator from "../../src/assets/images/integrator.png";
import LogoEmployee from "../../src/assets/images/employee.png";
import { useTranslation } from "react-i18next";
import { useAbility } from "../contexts/abilityContext";
const { Meta } = Card;
const { Title, Paragraph } = Typography;

const Accounts = () => {
  let { t } = useTranslation();
  const history = useHistory();
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Accounts");

  const handleCardClick = (path) => {
    history.push(path);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Title
        style={{
          marginBottom: "20px",
        }}
      >
        {t("Accounts")}
      </Title>

      <Space size={16} wrap>
        <Card
          hoverable
          style={{
            margin: "0 10px",
            maxWidth: 400,
            borderRadius: "15px",
            transition: "0.3s",
            ":hover": {
              transform: "scale(1.03)",
              boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
            },
          }}
          cover={
            <img
              alt="Integrators"
              src={LogoIntegrator}
              style={{ borderRadius: "15px 15px 0 0" }}
            />
          }
          onClick={() => handleCardClick("/Integrators")}
        >
          <Meta title={<Title level={3}>{t("Integrators")}</Title>} />
        </Card>

        <Card
          hoverable
          style={{
            margin: "0 10px",
            maxWidth: 400,
            borderRadius: "15px",
            transition: "0.3s",
            ":hover": {
              transform: "scale(1.03)",
              boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
            },
          }}
          cover={
            <img
              alt="Users"
              src={LogoEmployee}
              style={{ borderRadius: "15px 15px 0 0" }}
            />
          }
          onClick={() => handleCardClick("/Users")}
        >
          <Meta
            title={
              <div style={{ textAlign: "center" }}>
                <Title level={3}>{t("Users")}</Title>
              </div>
            }
          />
        </Card>
      </Space>
    </div>
  );
};
Accounts.acl = {
  subject: "Accounts",
  action: "read",
};

export default Accounts;
