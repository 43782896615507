import { Route, Switch } from "react-router-dom";
import Main from "./components/layout/Main";
import { CompanyContext } from "./contexts/CompanyContext";
import AddCompany from "./pages/AddCompany";
import ComingSoon from "./pages/ComingSoon";
import Details from "./pages/Details";
import GeneralInformations from "./pages/GeneralInformations";
import Home from "./pages/Home";
import SalesOrderBook from "./pages/SalesOrderBook";
import SignIn from "./pages/SignIn";
// import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import RouteGuard from "./components/layout/RouteGuard";
import OrderBookDetails from "./pages/OrderBookDetails";
import Testpage from "./pages/Testpage";
import UpdateCompany from "./pages/UpdateCompany";
// import StrategicPlanning from "./pages/StrategicPlanning";
import ChartofAccountDetails from "./pages/ChartofAccountDetails";
import ChartofAcount from "./pages/ChartofAcount";
// import Chartofaccounts from "./pages/Chartofaccounts";
import HyphotheseofGl from "./pages/HyphotheseofGl";
import Newchartofaccounts from "./pages/Newchartofaccounts";
import OpportunityDetails from "./pages/OpportunityDetails";
import SalesOpportunities from "./pages/SalesOpportunities";
import StrategicPlans from "./pages/StrategicPlans";
import Strategic_Planning from "./pages/Strategic_planning";

import Assets from "./pages/Asset/Asset";
import AssetsDetail from "./pages/Asset/AssetDetail";
import AssetSummary from "./pages/Asset/AssetSummary";
import Expense from "./pages/Expensive/Expense";
import ExpenseDetails from "./pages/Expensive/ExpenseDetails";
import ExpensiveSummary from "./pages/Expensive/ExpensiveSummary";
import Equity from "./pages/Financial Statement/Equity";
import EquityDetails from "./pages/Financial Statement/EquityDetails";
import EquitySummary from "./pages/Financial Statement/EquitySummary";
import Liabilities from "./pages/Financial Statement/Liability";
import LiabilityDetail from "./pages/Financial Statement/LiabilityDetail";
import LiabilitySummary from "./pages/Financial Statement/LiabilitySummary";
import Investment from "./pages/Investment";
import InvestmentCategory from "./pages/InvestmentCategory";
import InvestmentSummary from "./pages/InvestmentSummary";
import Revenue from "./pages/Revenue/Revenue";
import RevenueDetails from "./pages/Revenue/RevenueDetails";
import RevenueSummary from "./pages/Revenue/RevenueSummary";

import SalesBudget from "./pages/SalesBudget/SalesBudget";
import SalesBudgetSummary from "./pages/SalesBudget/SalesBudgetSummary";
import SalesBudgetDetails from "./pages/SalesBudget/SalesBudgetDetails";
import PerformanceMonitoring from "./pages/SalesBudget/PerformanceMonitoring";

import { default as BudgetRealSummary } from "./pages/Human Resources/BudgetRealSummary";
import EmployeeDetail from "./pages/Human Resources/EmployeeDetail";
import EmployeesManagment from "./pages/Human Resources/EmployeesManagment";
import EnterpriseDepartment from "./pages/Human Resources/EnterpriseDepartment";
import HumanResourcePlanning from "./pages/Human Resources/HumanResourcePlanning";

import Devis from "./pages/Devis";
import RHDashboard from "./pages/RHDashboard";
import SalesDashboard from "./pages/SalesDashboard";

import Marketing from "./pages/Marketing/Marketing";
import Settings from "./pages/Settings";

import ReportViewer from "./pages/ReportViewer";
import Reporting from "./pages/Reporting";

import MargeDetails from "./pages/Financement/FinancementDetail/MargeDetails";
import PretDetails from "./pages/Financement/FinancementDetail/PretDetails";
import Marge from "./pages/Financement/Marge";
import Pret from "./pages/Financement/Pret";
import SettingsMenu from "./pages/SettingsMenu";

import { AbilityContext } from "./contexts/abilityContext";
import Accounts from "./pages/Accounts";
import AddIntegrator from "./pages/AddIntegrator";
import CreatePlan from "./pages/CreatePlan";
import CreateUser from "./pages/CreateUser";
import EditIntegrator from "./pages/EditIntegrator";
import EditPlan from "./pages/EditPlan";
import Integrators from "./pages/Integrators";
import ManageAccess from "./pages/ManageAccess";
import ManageSections from "./pages/ManageSections";
import Plans from "./pages/Plans";
import Roles from "./pages/Roles";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import UpdateEmployeeAccount from "./pages/UpdateEmployeeAccount";
import Users from "./pages/Users";

// import CreateModel from "./pages/CashFlow/CreateModel/CreateModelSteps";
// import Model from "./pages/CashFlow/Tables/ModelTable";
import CashFlowInstanceDetails from "./pages/CashFlow/Instances/CashFlowInstanceDetails";
import CashFlowInstance from "./pages/CashFlow/Instances/CashFlowInstances";

import { SelectedChartAccountProvider } from "./pages/Parametre/CashFlowModels/ChartAccountContext";
import CashFlowModels from "./pages/Parametre/CashFlowModels/CashflowModels";
import CashFlow from "./pages/Parametre/CashFlowModels/CreateCashflowWizard";
import ModelDetail from "./pages/Parametre/CashFlowModels/ModelDetail";

import ExpenseDashboard from "./pages/ExpenseDashboard";
import InvestmentDashboard from "./pages/InvestmentDashboard";
import MarketingDashboard from "./pages/MarketingDashboard";
import NotfoundPage from "./pages/NotfoundPage";
import RevenueDashboard from "./pages/RevenueDashboard";
import { Spin } from "antd";
import React, { useState, useEffect } from "react";
function App() {
  const [Company, setCompany] = useState(null);
  const [Companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [TempCompany, setTempCompany] = useState({});

  const [Actionstate, setActionstate] = useState(true);
  const [Edited, setEdited] = useState(true);

  const [TypeIndustries, setTypeIndustries] = useState([]);
  const [Market, setMarket] = useState([]);
  const [RevenueModel, setRevenueModel] = useState([]);
  const [MainCustomer, setMainCustomer] = useState([]);
  const [BusinessPartner, setBusinessPartner] = useState([]);
  const [StrategicTarget, setStrategicTarget] = useState([]);
  const [ActivityType, setActivityType] = useState([]);
  const [Product, setProduct] = useState([]);
  const [ShareHolders, setShareHolders] = useState([]);
  const [Shares, setShares] = useState([]);
  const [Lang, setLang] = useState("en");
  const [submitted, setSubmitted] = useState(false);
  const [ability, setAbility] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" tip="Chargement des données..."></Spin>
      </div>
    );
  }

  return (
    <React.StrictMode>
      <div className="App">
        <CompanyContext.Provider
          value={{
            submitted,
            setSubmitted,
            Lang,
            setLang,
            TempCompany,
            setTempCompany,
            Shares,
            setShares,
            ShareHolders,
            setShareHolders,
            Product,
            setProduct,
            ActivityType,
            setActivityType,
            StrategicTarget,
            setStrategicTarget,
            BusinessPartner,
            setBusinessPartner,
            MainCustomer,
            setMainCustomer,
            RevenueModel,
            setRevenueModel,
            Companies,
            setCompanies,
            Company,
            setCompany,
            Actionstate,
            setActionstate,
            Edited,
            setEdited,
            TypeIndustries,
            setTypeIndustries,
            Market,
            setMarket,
          }}
        >
          <AbilityContext.Provider
            value={{
              ability,
              setAbility,
            }}
          >
            <Switch>
              <Route path="/sign-in" exact component={SignIn} />
              <Route path="/403" exat component={UnauthorizedPage} />

              <Main>
                <Switch>
                  <RouteGuard exact path="/">
                    <Home />
                  </RouteGuard>
                  <RouteGuard exact path="/dashboard">
                    <Home />
                  </RouteGuard>
                  <RouteGuard exact path="/orderbooks">
                    <SalesOrderBook />
                  </RouteGuard>
                  <RouteGuard exact path="/opportunities">
                    <SalesOpportunities />
                  </RouteGuard>
                  <RouteGuard exact path="/orderbook/:id">
                    <OrderBookDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/opportunitybook/:id">
                    <OpportunityDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/generalinformations">
                    <GeneralInformations />
                  </RouteGuard>
                  <RouteGuard exact path="/addcompany">
                    <AddCompany />
                  </RouteGuard>
                  <RouteGuard exact path="/updatecompany">
                    <UpdateCompany />
                  </RouteGuard>
                  <RouteGuard exact path="/comingsoon">
                    <ComingSoon />
                  </RouteGuard>
                  {/* <RouteGuard exact path='/strategicplanning'><StrategicPlanning/></RouteGuard> */}
                  <RouteGuard exact path="/strategic_planning">
                    <Strategic_Planning />
                  </RouteGuard>
                  <RouteGuard exact path="/strategicplans/:id/:year">
                    <StrategicPlans />
                  </RouteGuard>
                  {/* <RouteGuard exact path="/chartofaccounts1">
              <Chartofaccounts />
            </RouteGuard> */}
                  <RouteGuard exact path="/chartofaccounts">
                    <ChartofAcount />
                  </RouteGuard>
                  <RouteGuard exact path="/chartofaccount/:id">
                    <ChartofAccountDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/glaccountsandhyphotheses">
                    <HyphotheseofGl />
                  </RouteGuard>
                  <RouteGuard exact path="/testpage">
                    <Testpage />
                  </RouteGuard>
                  <RouteGuard exact path="/new_chart_of_accounts">
                    <Newchartofaccounts />
                  </RouteGuard>
                  <RouteGuard exact path="/generalinformations/:id">
                    <Details />
                  </RouteGuard>
                  <RouteGuard exact path="/liabilities">
                    <Liabilities />
                  </RouteGuard>
                  <RouteGuard exact path="/liabilityDetail/:id">
                    <LiabilityDetail />
                  </RouteGuard>
                  <RouteGuard exact path="/Assets">
                    <Assets />
                  </RouteGuard>
                  <RouteGuard exact path="/AssetDetail/:id">
                    <AssetsDetail />
                  </RouteGuard>
                  <RouteGuard exact path="/AssetSummary">
                    <AssetSummary />
                  </RouteGuard>
                  <RouteGuard exact path="/LiabilitySummary">
                    <LiabilitySummary />
                  </RouteGuard>
                  <RouteGuard exact path="/equityDetail/:id">
                    <EquityDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/equity">
                    <Equity />
                  </RouteGuard>
                  <RouteGuard exact path="/summaryequity">
                    <EquitySummary />
                  </RouteGuard>
                  <RouteGuard exact path="/expense">
                    <Expense />
                  </RouteGuard>
                  <RouteGuard exact path="/expenseDetails/:id">
                    <ExpenseDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/summaryexpense">
                    <ExpensiveSummary />
                  </RouteGuard>
                  <RouteGuard exact path="/revenue">
                    <Revenue />
                  </RouteGuard>
                  <RouteGuard exact path="/sales-budget">
                    <SalesBudget />
                  </RouteGuard>
                  <RouteGuard exact path="/sales-budget-summary">
                    <SalesBudgetSummary />
                  </RouteGuard>
                  <RouteGuard exact path="/sales-budget-details/:id">
                    <SalesBudgetDetails />
                  </RouteGuard>

                  <RouteGuard exact path="/performance-monitoring">
                    <PerformanceMonitoring />
                  </RouteGuard>

                  <RouteGuard exact path="/revenueDetails/:id">
                    <RevenueDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/summaryrevenue">
                    <RevenueSummary />
                  </RouteGuard>
                  <RouteGuard exact path="/Investments">
                    <Investment />
                  </RouteGuard>
                  <RouteGuard exact path="/summaryInvestments">
                    <InvestmentSummary />
                  </RouteGuard>
                  <RouteGuard exact path="/categoryInvestments">
                    <InvestmentCategory />
                  </RouteGuard>
                  <RouteGuard exact path="/human_resources">
                    <EmployeesManagment />
                  </RouteGuard>
                  <RouteGuard exact path="/employee_detail/:employeeId">
                    <EmployeeDetail />
                  </RouteGuard>
                  <RouteGuard exact path="/human_resources_planning">
                    <HumanResourcePlanning />
                  </RouteGuard>
                  <RouteGuard exact path="/human_resources/summaries">
                    <BudgetRealSummary />
                  </RouteGuard>
                  <RouteGuard exact path="/enterprise_departments">
                    <EnterpriseDepartment />
                  </RouteGuard>
                  <RouteGuard exact path="/RevenueDashboard">
                    <RevenueDashboard />
                  </RouteGuard>
                  <RouteGuard exact path="/RHDashboard">
                    <RHDashboard />
                  </RouteGuard>
                  <RouteGuard exact path="/devis">
                    <Devis />
                  </RouteGuard>
                  <RouteGuard exact path="/settings">
                    <Settings />
                  </RouteGuard>
                  <RouteGuard exact path="/marketing">
                    <Marketing />
                  </RouteGuard>
                  <RouteGuard exact path="/report_section">
                    <Reporting />
                  </RouteGuard>
                  <RouteGuard exact path="/report_viewer/:reportId">
                    <ReportViewer />
                  </RouteGuard>
                  <RouteGuard exact path="/loan">
                    <Pret />
                  </RouteGuard>
                  <RouteGuard exact path="/margin">
                    <Marge />
                  </RouteGuard>
                  <RouteGuard exact path="/pretdetails/:id">
                    <PretDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/margedetails/:id">
                    <MargeDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/SettingsMenu">
                    <SettingsMenu />
                  </RouteGuard>
                  <RouteGuard exact path="/manageSections">
                    <ManageSections />
                  </RouteGuard>
                  <RouteGuard exact path="/manageAccess">
                    <ManageAccess />
                  </RouteGuard>
                  <RouteGuard exact path="/Integrators">
                    <Integrators />
                  </RouteGuard>
                  <RouteGuard exact path="/Roles">
                    <Roles />
                  </RouteGuard>
                  <RouteGuard exact path="/Users">
                    <Users />
                  </RouteGuard>
                  <RouteGuard exact path="/update-employee-account">
                    <UpdateEmployeeAccount />
                  </RouteGuard>
                  <RouteGuard exact path="/accounts">
                    <Accounts />
                  </RouteGuard>
                  <RouteGuard exact path="/addIntegrator">
                    <AddIntegrator />
                  </RouteGuard>
                  <RouteGuard
                    exact
                    path="/update-integrator-account/:integratorId/:accountId"
                  >
                    <EditIntegrator />
                  </RouteGuard>
                  <RouteGuard exact path="/update-plan/:planId">
                    <EditPlan />
                  </RouteGuard>
                  <RouteGuard exact path="/createUser">
                    <CreateUser />
                  </RouteGuard>
                  <RouteGuard exact path="/plans">
                    <Plans />
                  </RouteGuard>
                  <RouteGuard exact path="/createPlan">
                    <CreatePlan />
                  </RouteGuard>
                  <RouteGuard exact path="/CashFlowInstances">
                    <CashFlowInstance />
                  </RouteGuard>
                  <RouteGuard exact path="/cashflowInstanceDetails/:id">
                    <CashFlowInstanceDetails />
                  </RouteGuard>
                  <RouteGuard exact path="/MarketingDashboard">
                    <MarketingDashboard />
                  </RouteGuard>
                  <RouteGuard exact path="/SalesDashboard">
                    <SalesDashboard />
                  </RouteGuard>
                  <RouteGuard exact path="/InvestmentDashboard">
                    <InvestmentDashboard />
                  </RouteGuard>
                  <RouteGuard exact path="/ExpenseDashboard">
                    <ExpenseDashboard />
                  </RouteGuard>
                  <RouteGuard exact path="/CashFlowModels">
                    <CashFlowModels />
                  </RouteGuard>
                  <SelectedChartAccountProvider>
                    <RouteGuard exact path="/cash-flow">
                      <CashFlow />
                    </RouteGuard>
                    <RouteGuard
                      path="/model-detail/:id"
                      component={ModelDetail}
                    />
                  </SelectedChartAccountProvider>
                  <RouteGuard>
                    <NotfoundPage />
                  </RouteGuard>
                </Switch>
              </Main>
            </Switch>
          </AbilityContext.Provider>
        </CompanyContext.Provider>
      </div>
    </React.StrictMode>
  );
}

export default App;
