import {
  CheckCircleOutlined,
  FileExcelFilled,
  PlusCircleFilled,
  SaveFilled,
  WalletTwoTone,
  CloseOutlined,
  CheckOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
  message,
  Tag,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { useAbility } from "../../contexts/abilityContext";
import { JSON_API } from "../../services/Constants";

const { Text } = Typography;
dayjs.extend(customParseFormat);
const { Option } = Select;

function HumanResourcePlanning() {
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "HR planning");
  const canDelete = ability.can("delete", "HR planning");
  const readOnly = !ability.can("update", "HR planning");
  const { Company } = useContext(CompanyContext);
  const { t } = useTranslation();
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [employeesFinancialDetails, setEmployeesFinancialDetails] = useState(
    []
  );
  const [rowAdded, setRowAdded] = useState(false);
  const [year, setYear] = useState("");
  const [enterpriseDepartments, setEnterpriseDepartments] = useState([]);
  const [tabIndex, setTabIndex] = useState(1);

  const [employees, setEmployees] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [enterpriseDepartmentId, setEnterpriseDepartmentId] = useState("");

  const [selectedYear, setSelectedYear] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getEnterpriseDepartments = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments/Filter/${Company.id}`)
      .then((res) => {
        setEnterpriseDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    console.log(
      "length !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! " +
        employeesFinancialDetails.length
    );
    getData();
    getEnterpriseDepartments();
    getEmployeesByEnterpriseAndDepartment();
  }, [Company.id, enterpriseDepartmentId, selectedYear]);

  const predefinedColors = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];

  function stringToColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % predefinedColors.length;
    return predefinedColors[index];
  }

  const getEmployeesByEnterpriseAndDepartment = async () => {
    if (!Company || enterpriseDepartmentId === null) return;
    let endpoint = `${process.env.REACT_APP_JSON_API}/Employees/enterprise/filter?enterpriseId=${Company.id}`;

    if (enterpriseDepartmentId !== "") {
      endpoint += `&departmentId=${enterpriseDepartmentId}`;
    }

    await axios
      .get(endpoint)
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((error) => {
        console.log("Error fetching employees:", error);
        setEmployees([]);
      });
  };

  const renderSwitch = (param) => {
    switch (param) {
      case "Weekly":
        return { text: t("Weekly"), color: "green" };
      case "Monthly":
        return { text: t("Monthly"), color: "blue" };
      case "15 Days":
        return { text: t("In15Days"), color: "orange" };
      default:
        return { text: null, color: null };
    }
  };

  const handleChangeYear = (date, dateString) => {
    console.log(date, dateString);
    setSelectedYear(dateString);
    getData();
  };

  useEffect(() => {
    const currentYear = dayjs().format("YYYY");
    setSelectedYear(currentYear);
  }, []);

  const getData = async () => {
    setIsLoading(true);
    setEmployeesFinancialDetails([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/EmployeeFinancialDetails/enterprise?enterpriseId=${Company.id}&departmentId=${enterpriseDepartmentId}&year=${selectedYear}`
      );
      setEmployeesFinancialDetails(response.data);
      console.log("Employee Financial Details :", response.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      setEmployeesFinancialDetails([]);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteEmployee = async (e) => {
    await axios
      .delete(`${process.env.REACT_APP_JSON_API}/EmployeeFinancialDetails?employeeId=${e}`)
      .then((res) => {
        Modal.success({
          content: "Deleted successfully !",
        });
        getData();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Departments = enterpriseDepartments
    ? [
        { value: "", label: t("Alldepartments") },
        ...enterpriseDepartments.map((ed) => ({
          value: ed.id,
          label: ed.label,
        })),
      ]
    : [{ value: "", label: t("Alldepartments") }];

  const date = new Date();

  date.setMonth(0);

  const startingMonthIndex = Company.startPeriod - 1;

  const handleInputChange = (event, recordId, monthIndex) => {
    const inputValue = parseFloat(event.target.value) || 0;
    console.log(
      `New Value: ${inputValue}, Record Id: ${recordId}, Month Index: ${monthIndex}`
    );

    const newBudget = employeesFinancialDetails.map((record) => {
      if (record.id !== recordId) {
        return record;
      }

      const updatedPerformance = record.budgetRealsPerformances.map(
        (performance) => {
          if (performance.month - 1 !== monthIndex) {
            return performance;
          }

          return {
            ...performance,
            ...(tabIndex == 1 ? { budget: inputValue } : { real: inputValue }),
          };
        }
      );

      return { ...record, budgetRealsPerformances: updatedPerformance };
    });

    console.log(`New budget details: ${JSON.stringify(newBudget)}`);
    setEmployeesFinancialDetails(newBudget);
  };

  const budgetsColumns = [
    {
      align: "center",
      title: <b>{t("Employee")}</b>,
      dataIndex: "fullName",
      render: (_, record) => {
        return (
          <div
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {record.fullName}
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Title")}</b>
        </div>
      ),
      dataIndex: "title",
      key: "title",
      render: (title, record) => {
        const color = stringToColor(title);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {title}
            </Tag>
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Department")}</b>
        </div>
      ),
      dataIndex: "department",
      render: (_, record) => {
        const color = stringToColor(record.department);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {record.department}
            </Tag>
          </div>
        );
      },
    },
    {
      title: <b>{t("AnnualSalary")}</b>,
      align: "center",
      dataIndex: "annualSalary",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.annualSalary}</div>
      ),
    },
    {
      align: "center",
      title: <b>{t("SocialAdvantages")}</b>,
      dataIndex: "socialAdvantages",
      render: (socialAdvantages, record) => (
        <div style={{ textAlign: "center" }}>
          {socialAdvantages ? (
            <span style={{ fontWeight: "bold" }}>{socialAdvantages}</span>
          ) : (
            <span
              style={{ fontWeight: "bold", color: "red", fontSize: "11px" }}
            >
              {t("NoSocialAdvantages")}
            </span>
          )}
        </div>
      ),
    },
    {
      title: <b>Total</b>,
      align: "center",

      render: (_, record) => (
        <div style={{ textAlign: "center", width: 60, fontWeight: "bold" }}>
          {record.annualSalary + record.socialAdvantages}
        </div>
      ),
    },
    {
      title: <b>{t("PayPeriod")}</b>,
      align: "center",
      dataIndex: "payPeriod",
      render: (_, record) => {
        const { text, color } = renderSwitch(record.payPeriod);
        return (
          <div style={{ textAlign: "center" }}>
            {text && (
              <Tag
                style={{ fontWeight: "bold", fontSize: "12px" }}
                color={color}
              >
                {text}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  Array.from({ length: 12 }).forEach((_, i) => {
    const monthIndex = (i + startingMonthIndex) % 12;
    date.setMonth(monthIndex);
    const monthName = date.toLocaleString("en-US", { month: "long" });

    budgetsColumns.push({
      title: <b>{t(monthName)}</b>,
      align: "center",
      render: (text, record) => {
        const budgetOrRealValue = record.budgetRealsPerformances.find(
          (e) => e.month - 1 === monthIndex
        );

        let inputValue =
          budgetOrRealValue && budgetOrRealValue.budget !== undefined
            ? budgetOrRealValue.budget
            : 0;

        return (
          <Input
            type="number"
            value={inputValue}
            disabled={record.budgetConfirmed || readOnly}
            onChange={(e) => handleInputChange(e, record.id, monthIndex)}
            style={{ textAlign: "center", width: 100 }}
          />
        );
      },
    });
  });

  budgetsColumns.push({
    title: <b>Total</b>,
    align: "center",
    key: "total",
    width: 120,
    render: (_, record) => {
      const totalBudget = record.budgetRealsPerformances.reduce(
        (total, elem) => total + elem.budget,
        0
      );
      return (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "14px",
          }}
        >
          {totalBudget}
        </div>
      );
    },
  });

  budgetsColumns.push({
    title: <b>{t("Confirmed")}</b>,
    align: "center",
    width: 120,
    render: (_, record) => {
      if (record.budgetConfirmed) {
        return (
          <Tag
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            color="green"
            icon={<CheckCircleOutlined />}
          >
            {t("Confirmed")}
          </Tag>
        );
      } else if (canUpdate) {
        return (
          <Popconfirm
            title={t("ConfirmBudget")}
            onConfirm={() => confirm(record.id)}
            disabled={record.budgetConfirmed}
            okButtonProps={{
              type: "primary",
              danger: true,
              icon: <CheckOutlined />,
              style: { backgroundColor: "#f5222d", borderColor: "#f5222d" },
            }}
            cancelButtonProps={{
              icon: <CloseOutlined />,
            }}
          >
            <Button
              style={{ borderRadius: "20px", textTransform: "uppercase" }}
              type="primary"
              icon={<CheckCircleFilled />}
            >
              {t("Confirm")}
            </Button>
          </Popconfirm>
        );
      }
      return null;
    },
  });

  if (canDelete) {
    budgetsColumns.push({
      title: <b>Actions</b>,
      align: "center",
      width: 120,
      render: (_, record) => (
        <>
          {record.id < 0 ? (
            <Button
              type="link"
              danger
              icon={<CloseCircleFilled />}
              onClick={() => discardCreation(record.employeeId)}
            >
              {t("cancel")}
            </Button>
          ) : (
            <Popconfirm
              title={t("AreYouSureToDeleteTheEmployee")}
              onConfirm={() => deleteEmployee(record.id)}
              okButtonProps={{
                type: "primary",
                danger: true,
                icon: <CheckOutlined />,
                style: { backgroundColor: "#f5222d", borderColor: "#f5222d" },
              }}
              cancelButtonProps={{
                icon: <CloseOutlined />,
              }}
            >
              <Button type="link" danger icon={<CloseCircleFilled />}>
                {t("Delete")}
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    });
  }

  const realsColumns = [
    {
      align: "center",
      title: <b>{t("Employee")}</b>,
      dataIndex: "fullName",
      render: (_, record) => {
        return (
          <div
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {record.fullName}
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Title")}</b>
        </div>
      ),
      dataIndex: "title",
      key: "title",
      render: (title, record) => {
        const color = stringToColor(title);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {title}
            </Tag>
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Department")}</b>
        </div>
      ),
      dataIndex: "department",
      render: (_, record) => {
        const color = stringToColor(record.department);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {record.department}
            </Tag>
          </div>
        );
      },
    },
    {
      title: <b>{t("AnnualSalary")}</b>,
      align: "center",
      dataIndex: "annualSalary",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.annualSalary}</div>
      ),
    },
    {
      align: "center",
      title: <b>{t("SocialAdvantages")}</b>,
      dataIndex: "socialAdvantages",
      render: (socialAdvantages, record) => (
        <div style={{ textAlign: "center" }}>
          {socialAdvantages ? (
            <span style={{ fontWeight: "bold" }}>{socialAdvantages}</span>
          ) : (
            <span
              style={{ fontWeight: "bold", color: "red", fontSize: "11px" }}
            >
              {t("NoSocialAdvantages")}
            </span>
          )}
        </div>
      ),
    },
    {
      title: <b>Total</b>,
      align: "center",

      render: (_, record) => (
        <div style={{ textAlign: "center", width: 60 }}>
          {record.annualSalary + record.socialAdvantages}
        </div>
      ),
    },
    {
      title: <b>{t("PayPeriod")}</b>,
      align: "center",
      dataIndex: "payPeriod",
      render: (_, record) => {
        const { text, color } = renderSwitch(record.payPeriod);
        return (
          <div style={{ textAlign: "center" }}>
            {text && (
              <Tag
                style={{ fontWeight: "bold", fontSize: "12px" }}
                color={color}
              >
                {text}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];
  Array.from({ length: 12 }).forEach((_, i) => {
    const monthIndex = (i + startingMonthIndex) % 12;
    date.setMonth(monthIndex);
    const monthName = date.toLocaleString("en-US", { month: "long" });

    realsColumns.push({
      title: <b>{t(monthName)}</b>,
      align: "center",
      render: (text, record) => {
        const realValue = record.budgetRealsPerformances.find(
          (e) => e.month - 1 === monthIndex
        )?.real;

        return (
          <Input
            type="number"
            value={realValue !== undefined ? realValue : 0}
            disabled={!record.budgetConfirmed || readOnly}
            onChange={(e) => handleInputChange(e, record.id, monthIndex)}
            style={{ textAlign: "center", width: 100 }}
          />
        );
      },
    });
  });

  realsColumns.push({
    title: <b>Total</b>,
    align: "center",
    key: "total",
    width: 120,
    render: (_, record) => {
      const totalReal = record.budgetRealsPerformances.reduce(
        (total, elem) => total + elem.real,
        0
      );
      return <div style={{ textAlign: "center" }}>{totalReal}</div>;
    },
  });

  if (canDelete) {
    realsColumns.push({
      title: <b>Actions</b>,
      align: "center",
      width: 120,
      render: (_, record) => (
        <>
          {record.id < 0 ? (
            <Button
              type="link"
              danger
              icon={<CloseCircleFilled />}
              onClick={() => discardCreation(record.employeeId)}
            >
              {t("cancel")}
            </Button>
          ) : (
            <Popconfirm
              title={t("AreYouSureToDeleteTheEmployee")}
              onConfirm={() => deleteEmployee(record.id)}
              okButtonProps={{
                type: "primary",
                danger: true,
                icon: <CheckOutlined />,
                style: { backgroundColor: "#f5222d", borderColor: "#f5222d" },
              }}
              cancelButtonProps={{
                icon: <CloseOutlined />,
              }}
            >
              <Button type="link" danger icon={<CloseCircleFilled />}>
                {t("Delete")}
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    });
  }
  const confirm = (id) => {
    const newData = [...employeesFinancialDetails];
    const index = newData.findIndex((elem) => elem.id == id);
    newData[index].budgetConfirmed = true;
    newData[index].budgetRealsPerformances.forEach(
      (element) => (element.real = element.budget)
    );
    setEmployeesFinancialDetails(newData);
    message.success("Budget Confirmed successfully!");
  };

  const discardCreation = (id) => {
    const updatedDataSource = employeesFinancialDetails.filter(
      (row) => row.employeeId !== id
    );
    setEmployeesFinancialDetails(updatedDataSource);
  };

  const onTabChange = (index) => {
    setTabIndex(index);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    const updatedData = [...employeesFinancialDetails];
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_JSON_API}/EmployeeFinancialDetails`,
        updatedData
      );
      setEmployeesFinancialDetails(response.data);
      setEnterpriseDepartmentId("");
      message.success(t("ChangesSavedSuccessfully"));
      await getData();
    } catch (error) {
      console.log("Updating Details Error" + error);
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
      message.error(t("ErrorWhileSavingChanges"));
    } finally {
      setIsLoading(false);
      setRowAdded(false);
    }
  };

  const performanceColumns = [
    {
      align: "center",
      title: <b>{t("Employee")}</b>,
      dataIndex: "fullName",
      render: (_, record) => {
        return (
          <div
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {record.fullName}
          </div>
        );
      },
    },
    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Title")}</b>
        </div>
      ),
      dataIndex: "title",
      key: "title",
      render: (title, record) => {
        const color = stringToColor(title);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
              }}
            >
              {title}
            </Tag>
          </div>
        );
      },
    },

    {
      title: (
        <div style={{ textAlign: "center" }}>
          <b>{t("Department")}</b>
        </div>
      ),
      dataIndex: "department",
      render: (_, record) => {
        const color = stringToColor(record.department);
        return (
          <div style={{ textAlign: "center" }}>
            <Tag
              color={color}
              style={{
                fontWeight: "bold",
                fontSize: "13px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {record.department}
            </Tag>
          </div>
        );
      },
    },
    {
      align: "center",
      title: `${t("AnnualSalary")}`,
      dataIndex: "annualSalary",
      render: (annualSalary, record) => (
        <div style={{ textAlign: "center" }}>{annualSalary}</div>
      ),
    },
    {
      align: "center",
      title: <b>{t("SocialAdvantages")}</b>,
      dataIndex: "socialAdvantages",
      render: (socialAdvantages, record) => (
        <div style={{ textAlign: "center" }}>
          {socialAdvantages ? (
            <span style={{ fontWeight: "bold" }}>{socialAdvantages}</span>
          ) : (
            <span
              style={{ fontWeight: "bold", color: "red", fontSize: "11px" }}
            >
              {t("NoSocialAdvantages")}
            </span>
          )}
        </div>
      ),
    },
    {
      align: "center",
      title: <b>Total</b>,
      render: (_, record) => (
        <div style={{ textAlign: "center", width: 60 }}>
          {record.annualSalary + record.socialAdvantages}
        </div>
      ),
    },
    {
      title: <b>{t("PayPeriod")}</b>,
      align: "center",
      dataIndex: "payPeriod",
      render: (_, record) => {
        const { text, color } = renderSwitch(record.payPeriod);
        return (
          <div style={{ textAlign: "center" }}>
            {text && (
              <Tag
                style={{ fontWeight: "bold", fontSize: "12px" }}
                color={color}
              >
                {text}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  Array.from({ length: 12 }).forEach((_, i) => {
    const monthIndex = (i + startingMonthIndex) % 12;
    date.setMonth(monthIndex);
    const monthName = date.toLocaleString("en-US", { month: "long" });

    performanceColumns.push({
      title: <b>{t(monthName)}</b>,
      align: "center",
      children: [
        {
          title: "Budget",
          width: 150,
          align: "center",
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {
                record.budgetRealsPerformances.find(
                  (e) => e.month - 1 === monthIndex
                ).budget
              }
            </div>
          ),
        },
        {
          title: "Real",
          width: 150,
          align: "center",
          render: (text, record) => (
            <div style={{ textAlign: "center" }}>
              {
                record.budgetRealsPerformances.find(
                  (e) => e.month - 1 === monthIndex
                ).real
              }
            </div>
          ),
        },
        {
          title: "Difference",
          dataIndex: "street",
          width: 150,
          align: "center",
          render: (text, record) => {
            const difference = record.budgetRealsPerformances.find(
              (e) => e.month - 1 === monthIndex
            ).performance;

            return (
              <div
                style={{
                  textAlign: "center",
                  color: difference >= 0 ? "blue" : "red",
                  fontWeight: "bold",
                }}
              >
                {difference}
              </div>
            );
          },
        },
      ],
    });
  });

  performanceColumns.push({
    title: <b>Total</b>,
    key: "total",
    align: "center",
    width: 120,
    render: (_, record) => {
      const total = record.budgetRealsPerformances.reduce(
        (sum, elem) => sum + elem.performance,
        0
      );
      return (
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontFamily: "Impact",
            fontSize: "15px",
            color: total >= 0 ? "blue" : "red",
          }}
        >
          {total}
        </div>
      );
    },
  });

  const headerStyle = {
    width: 500,
    textAlign: "center",
    color: "#3374FF",
    fontSize: 14,
    padding: 10,
  };

  const items = [
    {
      key: "1",
      label: <span style={headerStyle}>{t("Budget")}</span>,
      children: (
        <div>
          <Table
            columns={budgetsColumns}
            dataSource={employeesFinancialDetails.map((row, index) => ({
              ...row,
              key: index,
            }))}
            scroll={{ x: 3000 }}
            pagination={true}
            bordered
            summary={() => {
              const bugetTotals = [];
              const items = [];
              for (let i = 0; i < 12; i++) {
                // const monthIndex = (i+ startingMonthIndex) % 12;
                var total = 0;
                for (let j = 0; j < employeesFinancialDetails.length; j++) {
                  total +=
                    employeesFinancialDetails[j].budgetRealsPerformances[i]
                      .budget; //.reduce((total,elem)=>total+elem.budget,0)
                }
                bugetTotals.push(total);
                items.push(
                  <Table.Summary.Cell index={i}>
                    <div style={{ textAlign: "center" }}>{bugetTotals[i]}</div>
                  </Table.Summary.Cell>
                );
              }
              var tototal = 0;
              for (let j = 0; j < employeesFinancialDetails.length; j++) {
                tototal += employeesFinancialDetails[j].budgetTotal;
              }
              items.push(
                <Table.Summary.Cell index={12}>
                  <div style={{ textAlign: "center" }}>{tototal}</div>
                </Table.Summary.Cell>
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell span={1} colSpan={7}>
                      <h3 style={{ textAlign: "center" }}>Total</h3>
                    </Table.Summary.Cell>
                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <span style={headerStyle}>{t("Real")}</span>,
      children: (
        <div>
          <Table
            columns={realsColumns}
            dataSource={employeesFinancialDetails}
            scroll={{ x: 3000 }}
            bordered
            summary={() => {
              const bugetTotals = [];
              const items = [];
              for (let i = 0; i < 12; i++) {
                const monthIndex = (i + startingMonthIndex) % 12;
                var total = 0;
                for (let j = 0; j < employeesFinancialDetails.length; j++) {
                  total +=
                    employeesFinancialDetails[j].budgetRealsPerformances[i]
                      .real;
                }
                bugetTotals.push(total);
                items.push(
                  <Table.Summary.Cell index={i}>
                    <div style={{ textAlign: "center" }}>{bugetTotals[i]}</div>
                  </Table.Summary.Cell>
                );
              }
              var tototal = 0;
              for (let j = 0; j < employeesFinancialDetails.length; j++) {
                tototal += employeesFinancialDetails[j].realTotal;
              }
              items.push(
                <Table.Summary.Cell index={12}>
                  <div style={{ textAlign: "center" }}>{tototal}</div>
                </Table.Summary.Cell>
              );
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={7}>
                      <h3 style={{ textAlign: "center" }}>Total</h3>
                    </Table.Summary.Cell>
                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },

    {
      key: "3",
      label: <span style={headerStyle}>{t("Performance")}</span>,
      children: (
        <div>
          <Table
            columns={performanceColumns}
            dataSource={employeesFinancialDetails}
            scroll={{ x: 3000 }}
            bordered
            summary={() => {
              const budgetTotals = [];
              const items = [];
              for (let i = 0; i < 12; i++) {
                const monthIndex = (i + startingMonthIndex) % 12;
                var total = 0;
                employeesFinancialDetails.forEach((employee) => {
                  const performanceData = employee.budgetRealsPerformances.find(
                    (e) => e.month - 1 === monthIndex
                  );
                  if (performanceData) {
                    total += performanceData.performance;
                  }
                });
                budgetTotals.push(total);
                items.push(
                  <Table.Summary.Cell index={3} colSpan={3}>
                    <div
                      style={{
                        textAlign: "center",
                        color: budgetTotals[i] >= 0 ? "blue" : "red",
                        fontWeight: "bold",
                        fontFamily: "Impact",
                        fontSize: "15px",
                      }}
                    >
                      {budgetTotals[i]}
                    </div>
                  </Table.Summary.Cell>
                );
              }

              const totalAnnualPerformance = budgetTotals.reduce(
                (sum, current) => sum + current,
                0
              );

              items.push(
                <Table.Summary.Cell>
                  <div
                    style={{
                      textAlign: "center",
                      color: totalAnnualPerformance >= 0 ? "blue" : "red",
                      fontWeight: "bold",
                      fontFamily: "Impact",
                      fontSize: "15px",
                    }}
                  >
                    {totalAnnualPerformance}
                  </div>
                </Table.Summary.Cell>
              );

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={7}>
                      <h3 style={{ textAlign: "center" }}>Total</h3>
                    </Table.Summary.Cell>
                    {items}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <Card
      title={
        <span style={{ fontSize: "16px" }}>
          <WalletTwoTone /> {t("HumanResourcesPlanningHeader")}
        </span>
      }
    >
      <Space style={{ marginBottom: 20 }}>
        {ability.can("update", "HR planning") && (
          <Button
            type="primary"
            onClick={() => saveChanges()}
            size="medium"
            style={{
              width: 150,
            }}
            icon={<SaveFilled />}
          >
            {t("SaveChanges")}
          </Button>
        )}

        <Select
          style={{ width: 240 }}
          placeholder={t("SelectDepartmentPlaceHolder")}
          onChange={(val) => {
            if (val === "all") {
              setSelectedDepartment(null);
              setEnterpriseDepartmentId(null);
            } else {
              setSelectedDepartment(val);
              setEnterpriseDepartmentId(val);
            }
          }}
          value={selectedDepartment}
          options={Departments}
        />

        <DatePicker
          name="year"
          picker="year"
          placeholder={t("SelectYearPlaceHolder")}
          style={{ width: 220 }}
          onChange={handleChangeYear}
          value={selectedYear ? dayjs(selectedYear) : null}
        />
      </Space>
      <Space style={{ marginBottom: 20, float: "right" }}>
        <Link
          to={{
            pathname: `/human_resources/summaries`,
          }}
        >
          <Button
            style={{
              width: 200,
            }}
            type="primary"
            size="medium"
            onClick={() => {}}
            icon={<FileExcelFilled />}
          >
            {t("Summaries")}
          </Button>
        </Link>
        {/* {ability.can("create", "HR planning") && (
          <Button
            className="Create-button"
            type="primary"
            disabled={tabIndex == 1 ? false : true}
            onClick={handleAddRow}
            style={{
              width: 200,
            }}
            icon={<PlusCircleFilled />}
          >
            {t("Create")}
          </Button>
        )} */}
      </Space>

      <Tabs
        style={{
          marginBottom: 35,
          width: "100%",
        }}
        type="card"
        centered
        defaultActiveKey={[tabIndex]}
        items={items}
        onChange={(index) => onTabChange(index)}
      />
    </Card>
  );
}
HumanResourcePlanning.acl = {
  subject: "HR planning",
  action: "read",
};

export default HumanResourcePlanning;
