// ** React Imports
import React, { useState, useEffect } from "react";
import { AbilityContext, useAbility } from "../../contexts/abilityContext";
import { Spin } from "antd";

import { defineAbilitiesFor } from "../../contexts/ability";
import axios from "axios";
import { JSON_API } from "../../services/Constants";

import { Redirect } from "react-router-dom";

const AclGuard = (props) => {
  // ** Props
  const { children } = props;

  const [aclAbilities, setAclAbilities] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { ability, setAbility } = useAbility();

  useEffect(() => {
    if (!ability) {
      getData();
    }
  }, []);

  useEffect(() => {
    let aclFound = false;

    React.Children.forEach(children, (child) => {
      if (child && child.type && child.type.acl) {
        aclFound = true;
        setAclAbilities(child.type.acl);
      }
    });

    if (!aclFound) {
      setAclAbilities(null);
    }
  }, [children]);
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const getRoleId = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const { RoleId } = parseJwt(token);
      return RoleId;
    }
    return null;
  };
  const getData = async () => {
    const roleId = getRoleId();
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/accesses/role/${roleId}`)
      .then((response) => {
        console.log("avilites", aclAbilities);
        setAbility(defineAbilitiesFor(response.data));
      })
      .catch(function (error) {
        if (error.response) {
        }
      });
  };

  useEffect(() => {
    if (ability && aclAbilities !== undefined) {
      setLoading(false);
    }
  }, [ability, aclAbilities]);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin />
      </div>
    );
  }

  if (!aclAbilities) {
    return <>{children}</>;
  }

  // Check the access of current user and render pages
  if (
    ability &&
    aclAbilities &&
    ability.can(aclAbilities.action, aclAbilities.subject)
  ) {
    return children;
  }

  // Render Not Authorized component if the current user has limited access
  return <Redirect from="*" to="/403" />;
};

export default AclGuard;
