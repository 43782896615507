import { FilePdfOutlined } from "@ant-design/icons";
import { Card, Collapse, List, Tabs } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

const Reporting = () => {
  const { Company } = useContext(CompanyContext);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  // const standardreports = [];
  const [standardreports, setStandardreports] = useState([]);
  const [customreports, setCustomreports] = useState([]);

  const getStandardreports = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Reporting/standardReports`)
      .then((response) => {
        setStandardreports(response.data);
        console.log("standardreports:", standardreports);
        console.log("standardreports:", response.data);
      });
  };
  const getCustomreports = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Reporting/customReports/enterprise/${Company.id}`)
      .then((response) => {
        setCustomreports(response.data);
        console.log("customreports:", customreports);
      });
  };
  useEffect(() => {
    getStandardreports();

    getCustomreports();
  }, [t("lang"), Company.id]);

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={<h3 className="font-semibold">{t("Reports")}</h3>}
      >
        {standardreports && (
          <Tabs centered type="card" defaultActiveKey="1">
            <TabPane
              style={{
                marginBottom: 35,
                width: "100%",
              }}
              type="card"
              tab={t("Standard")}
              key="1"
            >
              <Collapse>
                {standardreports.map((item) => {
                  return (
                    <Panel
                      header={
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#000000",
                          }}
                        >
                          {currentLang === "en" ? item.labelEn : item.labelFr}
                        </span>
                      }
                      key={item.id}
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={item.standardReports}
                        renderItem={(report) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <Link
                                  to={{
                                    pathname: `/report_viewer/${report.id}`,
                                    state: { stateParam: report.id },
                                  }}
                                  noStyle
                                >
                                  <FilePdfOutlined
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  />
                                  {currentLang === "en"
                                    ? report.nameEn
                                    : report.nameFr}
                                </Link>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            </TabPane>
            {/* <TabPane tab={t("Personalized")} key="2">
              <Collapse>
                {customreports.map((item) => {
                  return (
                    <Panel forceRender={true} header={item.label} key={item.id}>
                      <List
                        itemLayout="horizontal"
                        dataSource={item.customReports}
                        renderItem={(report) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <Link
                                  to={{
                                    pathname: `/report_viewer/${report.id}/${report.endpoint}`,
                                    state: { stateParam: report.id },
                                  }}
                                >
                                  <FilePdfOutlined />
                                  {" " + report.name}
                                </Link>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            </TabPane> */}
          </Tabs>
        )}
      </Card>
    </>
  );
};

export default Reporting;
