import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PicCenterOutlined,
  SaveFilled,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import BgProfile from "../assets/images/bg-profile.jpg";
import { CompanyContext } from "../contexts/CompanyContext";
import { useAbility } from "../contexts/abilityContext";
import { JSON_API, JSON_API_logo } from "../services/Constants";

dayjs.extend(customParseFormat);

const { confirm } = Modal;
const { Title, Text } = Typography;
const { Meta } = Card;

const GeneralInformations = () => {
  const { ability } = useAbility();
  const history = useHistory();
  const [messageApi, contextHolder] = message.useMessage();

  let { t } = useTranslation();
  const {
    setCompanies,
    Company,
    setCompany,
    Actionstate,

    TypeIndustries,
  } = useContext(CompanyContext);

  console.log("Company:", Company);

  const defaultshareholderColumns = [
    {
      title: "Id",
      dataIndex: "id",
      align: "center",
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: `${t("Nameshareholders")}`,
      dataIndex: "name",
      width: "30%",
      align: "center",
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.name)}>{record.name}</div>
      ),
    },
    {
      title: `${t("percentshares")}`,
      dataIndex: "shares",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "right" }}>{record.shares}%</div>
      ),
    },
    {
      title: `${t("Startdate")}`,
      dataIndex: "startedAt",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {dayjs(record.startedAt).format("YYYY/MM/DD")}
        </div>
      ),
    },
  ];
  const defaultmanagerColumns = [
    {
      title: "Id",
      dataIndex: "id",
      align: "center",
      render: (text, record) => (
        <div style={{ textAlign: "right" }}>{text}</div>
      ),
    },
    {
      title: `${t("Lastname")}`,
      dataIndex: "name",
      width: "30%",
      align: "center",
      render: (text, record) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: `${t("Firstname")}`,
      dataIndex: "firstName",
      align: "center",
      render: (text, record) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: `${t("Titles")}`,
      dataIndex: "titles",
      align: "center",
      render: (_, record) => {
        return record.titles.map((o) => <Tag>{o.title.label}</Tag>);
      },
    },
    // {
    //   title: `${t("Yearsofexperience")}`,
    //   dataIndex: "yearsOfExperience",
    //   align: "center",
    //   render: (text, record) => (
    //     <div style={{ textAlign: "right" }}>{record}</div>
    //   ),
    // },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };
  const [Products, setProducts] = useState([{}]);
  const [Customers, setCustomers] = useState([{}]);

  const showDeleteConfirm = () => {
    confirm({
      title: `${t("deletecompanytext")} ${Company.name} ?`,
      icon: <ExclamationCircleOutlined />,
      okText: `${t("yes")}`,
      okType: "danger",
      cancelText: `${t("no")}`,
      onOk() {
        deleteCompany();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteCompany = async () => {
    await axios
      .delete(`${process.env.REACT_APP_JSON_API}/Enterprises/${Company.id}`)
      .then((response) => {
        sessionStorage.setItem("IdCompany", null);

        setCompany({});
        getCompanies();
        window.location.reload();

        messageApi.open({
          type: "success",
          content: `${t("Companydeleted")}`,
        });
      });
  };
  const getProduct = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Products/enterprise/${Company.id}`)
      .then((response) => {
        setProducts(response.data);
        console.log("Products", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCustomers = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Customers/enterprise/${Company.id}`)
      .then((response) => {
        setCustomers(response.data);
        console.log(TypeIndustries, "Customers");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProduct();
    getCustomers();
  }, []);

  const getCompanies = async () => {
    await axios.get(`${process.env.REACT_APP_JSON_API}/Enterprises`).then((response) => {
      console.log("entreprise data:", response.data);
      setCompanies(response.data);
    });
  };
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const generateUpdateformdata = () => {
    // setEdited(false);
    let path = `/updatecompany`;
    history.push(path);
  };

  return (
    <>
      {contextHolder}

      {Company.name && (
        <Card
          bordered={false}
          style={{
            backgroundColor: "#f5f5f5",
            padding: "24px",
            borderRadius: "10px",
          }}
          className="header-solid mb-24"
        >
          <Row justify="space-between" align="middle">
            <Col span={24} md={12} className="col-info">
              <Meta
                avatar={
                  Company.logoPath ? (
                    <Avatar size={100} src={process.env.REACT_APP_JSON_API_LOGO + Company.logoPath} />
                  ) : (
                    <Avatar size={100} style={{ backgroundColor: "#f56a00" }}>
                      {Company.name.charAt(0)}
                    </Avatar>
                  )
                }
                title={Company.name}
                description={Company.industryTypes.map((e) =>
                  TypeIndustries.map((type) => {
                    return type.id === e.id && <Tag>{type.label}</Tag>;
                  })
                )}
              />
            </Col>

            <Col
              span={12}
              md={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              {ability && ability.can("update", "General Informations") && (
                <Button
                  disabled={Actionstate}
                  icon={<EditOutlined />}
                  onClick={() => generateUpdateformdata()}
                >
                  {t("editcompany")}
                </Button>
              )}

              {ability.can("delete", "General Informations") && (
                <Popconfirm
                  title={`${t("deletecompanytext")} ${Company.name} ?`}
                  icon={<ExclamationCircleOutlined />}
                  okText={t("yes")}
                  okType="danger"
                  cancelText={t("no")}
                  onConfirm={() => deleteCompany()}
                >
                  <Button type="primary" danger icon={<DeleteOutlined />}>
                    {t("deletecompany")}
                  </Button>
                </Popconfirm>
              )}
            </Col>
          </Row>
        </Card>
      )}

      <Card bordered={false} className="header-solid mb-24">
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          // autoComplete="off"
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t("generalinf")} key="1">
              <Card bordered={false} style={{ width: "100%" }}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Title level={5}>{t("companyname")}</Title>
                    <Text>{Company.name || "-"}</Text>
                  </Col>
                  <Col span={12}>
                    <Title level={5}>{t("Businessnumber")}</Title>
                    <Text>{Company.businessNumber || "-"}</Text>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Title level={5}>{t("Typeofindustry")}</Title>
                    {Company.industryTypes
                      ? Company.industryTypes.map((e) => (
                          <Tag
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginRight: "8px",
                              marginBottom: "8px",
                            }}
                            color="blue"
                            key={e.label}
                          >
                            {e.label}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                  <Col span={12}>
                    <Title level={5}>{t("address")}</Title>
                    <Text>{Company.address || "-"}</Text>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Title level={5}>{t("city")}</Title>
                    <Text>{Company.city ? Company.city.name : "-"}</Text>
                  </Col>
                  <Col span={12}>
                    <Title level={5}>{t("Postalcode")}</Title>
                    <Text>{Company.postalCode || "-"}</Text>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Title level={5}>{t("Foundingdate")}</Title>
                    <Text>
                      {Company.startingDate
                        ? dayjs(Company.startingDate).format("YYYY/MM/DD")
                        : "-"}
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Title level={5}>{t("Yearenddate")}</Title>
                    <Text>
                      {Company.endDate
                        ? dayjs(Company.endDate).format("YYYY/MM/DD")
                        : "-"}
                    </Text>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Title level={5}>{t("Numberofemployees")}</Title>
                    <Text>{Company.employeesCount || "-"}</Text>
                  </Col>
                  <Col span={12}>
                    <Title level={5}>Budget</Title>
                    <Text>
                      {Company.budgetRange === 0
                        ? "50 - 100"
                        : Company.budgetRange === 1
                        ? "100 - 1000"
                        : Company.budgetRange > 1
                        ? "+1000"
                        : "-"}
                    </Text>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5}>{t("Estimatedannualtaxrate")}</Title>
                    <Text>{Company.taxes ? `${Company.taxes}%` : "-"}</Text>
                  </Col>
                </Row>
              </Card>
            </Tabs.TabPane>

            <Tabs.TabPane tab={t("Targetcustomers")} key="2">
              <Card bordered={false} style={{ width: "100%" }}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Title level={5}>{t("Market")}</Title>
                    {Company.markets
                      ? Company.markets.map((e) => (
                          <Tag
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginRight: "8px",
                              marginBottom: "8px",
                            }}
                            color="blue"
                            key={e.label}
                          >
                            {e.label}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                  <Col span={12}>
                    <Title level={5}>{t("Themaincustomers")}</Title>
                    {Customers
                      ? Customers.map((e) => (
                          <Tag
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginRight: "8px",
                              marginBottom: "8px",
                            }}
                            color="blue"
                            key={e.name}
                          >
                            {e.name}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Title level={5}>{t("RevenueModel")}</Title>
                    {Company.revenueModelItems
                      ? Company.revenueModelItems.map((e) => (
                          <Tag
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginRight: "8px",
                              marginBottom: "8px",
                            }}
                            color="blue"
                            key={e.label}
                          >
                            {e.label}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                  <Col span={12}>
                    <Title level={5}>{t("Businesspartners")}</Title>
                    {Company.businessPartners
                      ? Company.businessPartners.map((e) => (
                          <Tag
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginRight: "8px",
                              marginBottom: "8px",
                            }}
                            color="blue"
                            key={e.name}
                          >
                            {e.name}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                </Row>
              </Card>
            </Tabs.TabPane>

            <Tabs.TabPane tab={t("Descriptionofservicesandproducts")} key="3">
              <Card bordered={false} style={{ width: "100%" }}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Title level={5}>{t("Strategictargets")}</Title>

                    {Company.strategicTargets
                      ? Company.strategicTargets.map((e) => (
                          <Tag
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginRight: "8px",
                              marginBottom: "8px",
                            }}
                            color="blue"
                            key={e.type}
                          >
                            {e.type}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                  <Col span={12}>
                    <Title level={5}>{t("Typeofactivities")}</Title>
                    {Company.activityTypes
                      ? Company.activityTypes.map((e) => (
                          <Tag
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginRight: "8px",
                              marginBottom: "8px",
                            }}
                            color="blue"
                            key={e.label}
                          >
                            {e.label}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5}>
                      {t("Descriptionofservicesandproducts")}
                    </Title>

                    {Products
                      ? Products.map((e) => (
                          <Tag
                            style={{
                              fontSize: "10px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              textAlign: "center",
                              textTransform: "uppercase",
                              marginRight: "8px",
                              marginBottom: "8px",
                            }}
                            color="blue"
                            key={e.label}
                          >
                            {e.label}
                          </Tag>
                        ))
                      : "-"}
                  </Col>
                </Row>
              </Card>
            </Tabs.TabPane>

            {/* <Tabs.TabPane tab={t("Managementteam")} key="4">
              <Card bordered={false} style={{ width: "100%" }}>
                <Title level={5}>{t("Managementteam")}</Title>
                {Company.managers && Company.managers.length > 0 ? (
                  <Table
                    locale={{
                      emptyText: <Empty description={t("nodata")} />,
                    }}
                    bordered
                    dataSource={Company.managers}
                    columns={defaultmanagerColumns}
                  />
                ) : (
                  <Empty description={t("nodata")} />
                )}
              </Card>
            </Tabs.TabPane> */}

            <Tabs.TabPane tab={t("Legalstructure")} key="4">
              <Card bordered={false} style={{ width: "100%" }}>
                <Title level={5}>{t("Legalstructure")}</Title>
                {Company.shareHolders && Company.shareHolders.length > 0 ? (
                  <Table
                    locale={{
                      emptyText: <Empty description={t("nodata")} />,
                    }}
                    bordered
                    dataSource={Company.shareHolders}
                    columns={defaultshareholderColumns}
                  />
                ) : (
                  <Empty description={t("nodata")} />
                )}
                <Modal
          title={t("Name")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
              </Card>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </Card>
    </>
  );
};

GeneralInformations.acl = {
  subject: "General Informations",
  action: "read",
};

export default GeneralInformations;
