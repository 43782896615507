import React, { useContext, useEffect, useState } from "react";
import { useAbility } from "../../../contexts/abilityContext";
import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { JSON_API } from "../../../services/Constants";
import { EditFilled, DeleteFilled, PlusCircleFilled } from "@ant-design/icons";

function HyphotheseofGl(props) {
  let { t } = useTranslation();
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Hypothesis");
  const canDelete = ability.can("delete", "Hypothesis");
  const { Company } = useContext(CompanyContext);
  const { Option } = Select;
  const { TextArea } = Input;

  const [form4] = Form.useForm();

  const [editingRow2, setEditingRow2] = useState(null);
  const [Hypotheses, setHypotheses] = useState(null);

  const [open, setOpen] = useState(false);
  const [openHyphothese, setopenHyphothese] = useState(false);

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  useEffect(() => {
    getHypotheses();
  }, [Company.id]);
  const getHypotheses = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/GLAccount/${props.HypothesesDataSource.id}`)
      .then((res) => {
        console.log("hypothesis: ", res.data);
        setHypotheses(
          res.data.hypotheses.sort((p1, p2) =>
            p1.beginsOnYear < p2.beginsOnYear
              ? 1
              : p1.beginsOnYear > p2.beginsOnYear
              ? -1
              : 0
          )
        );
        console.log(props.HypothesesDataSource);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const OnopenHyphothese = async (values) => {
    console.log("Received values of form: ", props);

    const obj = {
      glAccountId: props.HypothesesDataSource.id,
      annualIncrease: values.annualIncrease,
      beginsOnYear: values.beginsOnYear.$y + "",
      beginsOnMonth: values.beginsOnMonth,
      hypothesis: values.hypothesis,
    };

    console.log("obj:", obj);
    await axios
      .post(`${process.env.REACT_APP_JSON_API}/Hypothesis`, obj)
      .then((res) => {
        console.log(res);
        getHypotheses();
        setOpen(!open);
        message.success(t("HyphothesisCreatedSuccessfully"));
      })
      .catch((err) => {
        console.log(err);
        message.error(t("ErrorCreatingHyphothesis"));
      });
    setopenHyphothese(false);
  };

  const handlehypoDelete = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_JSON_API}/Hypothesis/${id}`)
      .then(() => {
        getHypotheses();
        message.success(t("HyphothesisDeletedSuccessfully"));
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const columnsHyphothese = [
    {
      title: <span style={{ textAlign: "center" }}>{t("Year")}</span>,
      dataIndex: "beginsOnYear",
      align: "center",
      sorter: (a, b) => a.beginsOnYear - b.beginsOnYear,
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: `${t("Pleaseinputtheyear")}`,
                },
              ]}
            >
              <DatePicker disabled picker="year" />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{text}</div>;
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("AnnualIncrease")}</span>,
      dataIndex: "annualIncrease",
      align: "center",

      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="annualIncrease"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheAnnualIncrease")}`,
                },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                size={"large"}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
          );
        } else {
          return <div style={{ textAlign: "right" }}>{text}%</div>;
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("BeginOn")}</span>,
      dataIndex: "beginsOnMonth",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="begin"
              value="beginmonth"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheBeginMonth")}`,
                },
              ]}
            >
              <Select
                placeholder={t("Selectmonth")}
                size={"large"}
                style={{
                  width: "100%",
                }}
              >
                <Option value={1}>{t("January")}</Option>
                <Option value={2}>{t("February")}</Option>
                <Option value={3}>{t("March")}</Option>
                <Option value={4}>{t("April")}</Option>
                <Option value={5}>{t("May")}</Option>
                <Option value={6}>{t("June")}</Option>
                <Option value={7}>{t("July")}</Option>
                <Option value={8}>{t("August")}</Option>
                <Option value={9}>{t("September")}</Option>
                <Option value={10}>{t("October")}</Option>
                <Option value={11}>{t("November")}</Option>
                <Option value={12}>{t("December")}</Option>
              </Select>
            </Form.Item>
          );
        } else {
          return record.beginsOnMonth + "/" + record.beginsOnYear;
        }
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("Hyphothesis")}</span>,
      dataIndex: "hypothesis",
      align: "center",
      render: (text, record) => {
        if (editingRow2 === record.id) {
          return (
            <Form.Item
              name="HyphotheseofGl"
              rules={[
                {
                  required: true,
                  message: `${t("PleaseinputtheHyphothesis")}`,
                },
              ]}
            >
              <Input label={t("Hyphothesis")} />
            </Form.Item>
          );
        } else {
          return <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(text)}>{text}</div>;
        }
      },
    },

    {},
  ];
  if (canUpdate || canDelete) {
    columnsHyphothese.push({
      title: "Actions",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {editingRow2 === record.id ? (
            <>
              <Button type="link" onClick={() => setEditingRow2(null)}>
                {t("cancel")}
              </Button>
              <Button type="link" htmlType="submit">
                {t("save")}
              </Button>
            </>
          ) : (
            <>
              {canUpdate && (
                <Popconfirm
                  type="link"
                  onClick={() => {
                    setEditingRow2(record.id);
                    form4.setFieldsValue({
                      year: dayjs(record.beginsOnYear),
                      annualIncrease: record.annualIncrease,
                      begin: record.beginsOnMonth,
                      HyphotheseofGl: record.hypothesis,
                    });
                  }}
                >
                  <Button type="link" icon={<EditFilled />}>
                    {t("Edit")}
                  </Button>
                </Popconfirm>
              )}
              {canDelete && (
                <Popconfirm
                  title={t("deleterowHypo")}
                  cancelText={t("no")}
                  okText={t("yes")}
                  onConfirm={() => handlehypoDelete(record.id)}
                >
                  <Button
                    type="link"
                    danger
                    icon={<DeleteFilled />}
                    style={{ marginLeft: ".5rem" }}
                  >
                    {t("Delete")}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </Space>
      ),
    });
  }

  const onFinishEditHypo = async (e) => {
    const obj = {
      id: editingRow2,
      annualIncrease: e.annualIncrease,
      beginsOnYear: e.year.$y + "",
      beginsOnMonth: e.begin,
      hypothesis: e.HyphotheseofGl,
    };
    console.log("updated data:", obj);

    await axios
      .put(`${process.env.REACT_APP_JSON_API}/Hypothesis`, obj)
      .then((res) => {
        setEditingRow2(null);
        getHypotheses();
        message.success(t("HyphothesisUpdatedSuccessfully"));
      })
      .catch(function (error) {
        console.log(error);
        message.error(t("ErrorUpdatingHyphothesis"));
      });
  };

  const CollectionCreateHyphothesis = ({
    openHyphothese,
    OnopenHyphothese,
    onCancel,
  }) => {
    return (
      <Modal
        forceRender
        open={openHyphothese}
        title={t("CreateHypothesisss")}
        okText={t("save")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form2
            .validateFields()
            .then((values) => {
              form2.resetFields();
              OnopenHyphothese(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form2}
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="beginsOnYear"
            label={t("Year")}
            rules={[
              {
                required: true,
                message: `${t("Pleaseinputtheyear")}`,
              },
            ]}
          >
            <DatePicker
              placeholder={t("Selectyear")}
              picker="year"
              style={{
                width: "60%",
                textAlign: "center",
              }}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            name="annualIncrease"
            label={t("AnnualIncrease")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheAnnualIncrease")}`,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              size={"large"}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>

          <Form.Item
            name="beginsOnMonth"
            value="begin"
            label={t("BeginOnMonth")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheBeginMonth")}`,
              },
            ]}
          >
            <Select placeholder={t("Selectmonth")} size={"large"}>
              <Option value={1}>{"January"}</Option>
              <Option value={2}>{"February"}</Option>
              <Option value={3}>{"March"}</Option>
              <Option value={4}>{"April"}</Option>
              <Option value={5}>{"May"}</Option>
              <Option value={6}>{"June"}</Option>
              <Option value={7}>{"July"}</Option>
              <Option value={8}>{"August"}</Option>
              <Option value={9}>{"September"}</Option>
              <Option value={10}>{"October"}</Option>
              <Option value={11}>{"November"}</Option>
              <Option value={12}>{"December"}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="hypothesis"
            value="hypo"
            label={t("Hyphothesis")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheHyphothesis")}`,
              },
            ]}
          >
            <TextArea rows={2}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <Card bordered={false} className="header-solid mb-24">
      <Row justify="end" gutter={[16, 16]}>
        <Space
          style={{
            display: "flex",
            marginBottom: 8,
          }}
          align="baseline"
        >
          {ability.can("create", "Hypothesis") && (
            <Button
              className="Create-button"
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => {
                setopenHyphothese(true);
              }}
              style={{
                textAlign: "right",
                marginBottom: "14px",
              }}
            >
              {t("CreateanewHyphothesis")}
            </Button>
          )}
        </Space>
      </Row>

      <CollectionCreateHyphothesis
        openHyphothese={openHyphothese}
        OnopenHyphothese={OnopenHyphothese}
        onCancel={() => {
          setopenHyphothese(false);
          form2.resetFields();
        }}
      />

      <Form form={form4} onFinish={onFinishEditHypo}>
        <div style={{ overflowX: "auto",backgroundColor:'white' }}>
        <Table
          locale={{
            emptyText: <Empty description={t("nodata")} />,
          }}
          bordered
          columns={columnsHyphothese}
          dataSource={Hypotheses}
        />
        </div>
        <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
      </Form>
    </Card>
  );
}

export default HyphotheseofGl;
