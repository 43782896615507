import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { JSON_API } from "../../../services/Constants";

dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text, Link, Title } = Typography;
const { TextArea } = Input;

const MargeDetails = () => {
  const { Companies, setCompanies, Company, Actionstate, setActionstate } =
    useContext(CompanyContext);
  const [messageApi, contextHolder] = message.useMessage();

  let { t } = useTranslation();
  const history = useHistory();

  const { id } = useParams();
  const stateParamVal = useLocation().state.stateParam;
  const [form] = Form.useForm();

  const [object, setObject] = useState({});

  console.log("props parametre value", id);
  console.log("props state value", stateParamVal);

  useEffect(async () => {
    await getdata();
  }, []);

  const getdata = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Finance/Marge/${Company.id}/${id}`)

      .then((res) => {
        console.log("FinancementDetailsMarge", res);

        setObject(res.data);
        console.log("object213", res.data, object["name"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Update = async (values) => {
    console.log("eee", values.name);

    console.log("form", object);
    axios
      .put(`${process.env.REACT_APP_JSON_API}/Finance/Marge`, object)
      .then((res) => {
        getdata();
        message.success(t("MarginUpdatedSuccessfully"));
        //history.push("/margin");
      })
      .catch(function (error) {
        console.log(error);
        message.error(t("ErrorWhileUpdatingMargin"));
      });
  };

  return (
    <>
      {contextHolder}

      <Card
        bordered={false}
        className="header-solid mb-24"
        title={<h2 className="font-semibold">{t("marge")}:</h2>}
      >
        {object.name && (
          <Form
            form={form}
            layout="horizontal"
            name="form_in_modal"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 14,
            }}
          >
            <Divider orientation="left"> {t("marge")}</Divider>
            <Form.Item
              name="name"
              label={t("Institutionname")}
              rules={[
                {
                  required: true,
                  message: "Please input the title of collection!",
                },
              ]}
            >
              {object.name && (
                <Input
                  style={{
                    width: 500,
                  }}
                  onChange={(e) => {
                    object.name = e.target.value;
                  }}
                  defaultValue={object.name}
                ></Input>
              )}
            </Form.Item>

            <Form.Item
              // value={Creancier}
              name="creditor"
              label={t("Creditor")}
              rules={[
                {
                  required: true,
                  message: `${t("VeuillersaisirletitredeCreancier")}`,
                },
              ]}
            >
              {object.creditor && (
                <Input
                  style={{
                    width: 500,
                  }}
                  onChange={(e) => {
                    object.creditor = e.target.value;
                  }}
                  defaultValue={object.creditor}
                ></Input>
              )}
            </Form.Item>

            <Form.Item
              // value={montant}
              name="authorizedAmount"
              label={t("Authorizedamount")}
              rules={[
                {
                  required: true,
                  message: `${t("VeuillersaisirleMontant")}`,
                },
              ]}
            >
              <InputNumber
                type="number"
                addonBefore="+"
                addonAfter={(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"}
                style={{
                  width: 500,
                  alignItems: "center",
                }}
                onChange={(e) => {
                  object.authorizedAmount = e;
                }}
                defaultValue={object.authorizedAmount}
              />
            </Form.Item>

            <Form.Item
              // value={taux}
              name="interestRates"
              label={t("Interestrates")}
              rules={[
                {
                  required: true,
                  message: `${t("Veuillezsaisiruntauxdintéret")}`,
                },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                // onChange={onChange}
                style={{ textalign: "right" }}
                defaultValue={object.interestRates}
                onChange={(e) => {
                  object.interestRates = e;
                }}
              />
            </Form.Item>

            <Form.Item
              // value={conditions}
              name="specialConditions"
              label={t("Particularconditions")}
            >
              <TextArea
                style={{ width: 500 }}
                defaultValue={object.specialConditions}
                onChange={(e) => {
                  object.specialConditions = e.target.value;
                }}
                rows={2}
              ></TextArea>
            </Form.Item>
            <Form.Item
              name="modifier"
              className="collection-create-form_last-form-item"
            ></Form.Item>
          </Form>
        )}
        <Row gutter={16} justify="center">
          <Col xs={24} sm={8}>
            <Form.Item>
              <Link to="/margin">
                <Button type="primary" danger block>
                  <ArrowLeftOutlined /> {t("GoBack")}
                </Button>
              </Link>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item>
              <Button type="primary" onClick={Update} htmlType="submit" block>
                <EditOutlined /> {t("Update")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};

MargeDetails.acl = {
  subject: "Margins",
  action: "update",
};

export default MargeDetails;
