import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAbility } from "../../../contexts/abilityContext";
import { Button, Input, Popconfirm, Tag, Table, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { CompanyContext } from "../../../contexts/CompanyContext";
const { Text } = Typography;
const { TextArea } = Input;

function BudgetEquity(props) {
  let { t } = useTranslation();
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Retained Earnings");
  const canDelete = ability.can("delete", "Retained Earnings");
  const readOnly = !ability.can("update", "Retained Earnings");
  const [equityBudgets, setequityBudgets] = useState(props.equityBudgets);
  const { TextArea } = Input;

  function handleinputchange(ee, i, n) {
    const newBudget = [...equityBudgets];
    newBudget.map((e) => {
      if (e.id == i) {
        e.budgets[n] = parseFloat(ee.target.value) || 0;
      }
    });
    setequityBudgets(newBudget);
    props.onBudgetChange(newBudget);
  }

  const cancel = (e) => {
    console.log(e);
  };

  function confirm(i) {
    const newBudget = [...equityBudgets];
    const updatedBudget = newBudget.map((e) => {
      if (e.id === i) {
        return { ...e, confirmed: true };
      }
      return e;
    });
    setequityBudgets(updatedBudget);
    props.onBudgetChange(updatedBudget);
  }

  const { Company } = useContext(CompanyContext);

  console.log("ttesstt 1");
  const items = [
    {
      title: `${t("Year")}`,
      width: 120,
      dataIndex: "year",
      align: "center",
      render: (text, record) => {
        return <p style={{ textAlign: "center" }}>{text}</p>;
      },
    },
  ];

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const startingMonthIndex = Company.startPeriod - 1;

  for (let i = 0; i < months.length; i++) {
    const monthIndex = (i + startingMonthIndex) % months.length;
    const monthName = months[monthIndex];

    items.push({
      title: monthName,
      width: 120,
      align: "center",
      render: (ID, record) => {
        return (
          <Input
            value={record.budgets[monthIndex].toFixed(0)}
            disabled={record.confirmed || readOnly}
            onChange={(e) => handleinputchange(e, record.id, monthIndex)}
            style={{ textAlign: "right", width: 120 }}
          />
        );
      },
    });
  }

  if (!ability.can("read", "Retained Earnings")) {
    items.push({
      title: "Actions",
      align: "center",
      width: 100,
      render: (_, record) => (
        <>
          {ability.can("update", "Revenue") &&
            (record.confirmed ? (
              <div className="subsections-container">
                <Tag color="green" icon={<CheckCircleOutlined />}>
                  {t("Confirmed")}
                </Tag>
              </div>
            ) : (
              <Popconfirm
                type="link"
                title={t("Areyousuretoconfirm")}
                onConfirm={() => confirm(record.id)}
                onCancel={cancel}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button
                  size="medium"
                  type="primary"
                  style={{ borderRadius: "20px", textTransform: "uppercase" }}
                >
                  {t("Confirm")}
                </Button>
              </Popconfirm>
            ))}
        </>
      ),
    });
  }

  if (
    ability.can("update", "Retained Earnings") ||
    ability.can("delete", "Retained Earnings")
  ) {
    items.push({
      title: "Actions",
      align: "center",
      width: 100,
      render: (_, record) => (
        <>
          {ability.can("update", "Revenue") &&
            (record.confirmed ? (
              <div className="subsections-container">
                <Tag color="green" icon={<CheckCircleOutlined />}>
                  {t("Confirmed")}
                </Tag>
              </div>
            ) : (
              <Popconfirm
                type="link"
                title={t("Areyousuretoconfirm")}
                onConfirm={() => confirm(record.id)}
                onCancel={cancel}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Button
                  size="medium"
                  type="primary"
                  style={{ borderRadius: "20px", textTransform: "uppercase" }}
                >
                  {t("Confirm")}
                </Button>
              </Popconfirm>
            ))}
        </>
      ),
    });
  }

  return (
    <Table
      columns={items}
      dataSource={props.equityBudgets}
      scroll={{
        x: 1300,
      }}
      bordered
      summary={(pageData) => {
        const items = [];
        const startingMonthIndex = Company.startPeriod - 1;
        let totalmontant = 0;
        let totalbudget = 0;
        let totalfevrierbudget = 0;
        let totalMarsbudget = 0.0;
        let totalavrilbudget = 0;
        let totalmaibudget = 0;
        let totalJuinbudget = 0.0;
        let totaljuilletbudget = 0;
        let totalaoutbudget = 0;
        let totalseptembrebudget = 0;
        let totaloctobrebudget = 0;
        let totalnovemberbudget = 0;
        let totaldecembrebudget = 0;
        let totaltotal = 0;

        let totalbudgetcount = 0;
        let totalfevrierbudgetcount = 0;
        let totalMarsbudgetcount = 0;
        let totalavrilbudgetcount = 0;
        let totalmaibudgetcount = 0;
        let totalJuinbudgetcount = 0;
        let totaljuilletbudgetcount = 0;
        let totalaoutbudgetcount = 0;
        let totalseptembrebudgetcount = 0;
        let totaloctobrebudgetcount = 0;
        let totalnovemberbudgetcount = 0;
        let totaldecembrebudgetcount = 0;

        props.equityBudgets &&
          props.equityBudgets.map((e) => {
            //console.log(e.budgets[1]),
            totalmontant = e.budgets[0];
            if (totalmontant.toFixed(0) != 0) {
              totalbudgetcount += 1;
            }
            totalbudget += totalmontant;
            totalmontant = e.budgets[1];
            if (totalmontant.toFixed(0) != 0) {
              totalfevrierbudgetcount += 1;
            }
            totalfevrierbudget += totalmontant;
            totalmontant = e.budgets[2];
            if (totalmontant.toFixed(0) != 0) {
              totalMarsbudgetcount += 1;
            }
            totalMarsbudget += totalmontant;
            totalmontant = e.budgets[3];
            if (totalmontant.toFixed(0) != 0) {
              totalavrilbudgetcount += 1;
            }
            totalavrilbudget += totalmontant;
            totalmontant = e.budgets[4];
            if (totalmontant.toFixed(0) != 0) {
              totalmaibudgetcount += 1;
            }
            totalmaibudget += totalmontant;
            totalmontant = e.budgets[5];
            if (totalmontant.toFixed(0) != 0) {
              totalJuinbudgetcount += 1;
            }
            totalJuinbudget += totalmontant;
            totalmontant = e.budgets[6];
            if (totalmontant.toFixed(0) != 0) {
              totaljuilletbudgetcount += 1;
            }
            totaljuilletbudget += totalmontant;
            totalmontant = e.budgets[7];
            if (totalmontant.toFixed(0) != 0) {
              totalaoutbudgetcount += 1;
            }
            totalaoutbudget += totalmontant;
            totalmontant = e.budgets[8];
            if (totalmontant.toFixed(0) != 0) {
              totalseptembrebudgetcount += 1;
            }
            totalseptembrebudget += totalmontant;
            totalmontant = e.budgets[9];
            if (totalmontant.toFixed(0) != 0) {
              totaloctobrebudgetcount += 1;
            }
            totaloctobrebudget += totalmontant;
            totalmontant = e.budgets[10];
            if (totalmontant.toFixed(0) != 0) {
              totalnovemberbudgetcount += 1;
            }
            totalnovemberbudget += totalmontant;
            totalmontant = e.budgets[11];
            if (totalmontant != 0) {
              totaldecembrebudgetcount += 1;
            }
            totaldecembrebudget += totalmontant;
            totalmontant = e.totalBudget;
            totaltotal += totalmontant;
          });

        const months = [
          totalbudget,
          totalfevrierbudget,
          totalMarsbudget,
          totalavrilbudget,
          totalmaibudget,
          totalJuinbudget,
          totaljuilletbudget,
          totalaoutbudget,
          totalseptembrebudget,
          totaloctobrebudget,
          totalnovemberbudget,
          totaldecembrebudget,
        ];
        const monthscount = [
          totalbudgetcount,
          totalfevrierbudgetcount,
          totalMarsbudgetcount,
          totalavrilbudgetcount,
          totalmaibudgetcount,
          totalJuinbudgetcount,
          totaljuilletbudgetcount,
          totalaoutbudgetcount,
          totalseptembrebudgetcount,
          totaloctobrebudgetcount,
          totalnovemberbudgetcount,
          totaldecembrebudgetcount,
        ];
        for (let i = 0; i < months.length; i++) {
          const monthIndex = (i + startingMonthIndex) % months.length;
          const monthName = months[monthIndex];
          let monthNamecount = monthscount[monthIndex];
          {
            if (monthNamecount == 0) {
              monthNamecount = 1;
            }
          }

          items.push(
            <Table.Summary.Cell index={monthIndex}>
              <Text style={{ textAlign: "right" }}>
                {(monthName / monthNamecount).toFixed(0)}
              </Text>
            </Table.Summary.Cell>
          );
        }

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={1} colSpan={1}>
                <h3 style={{ textAlign: "right" }}>{t("Average")}</h3>
              </Table.Summary.Cell>

              {items}

              {/* <Table.Summary.Cell index={10}>
                  <Text style={{ textAlign: "right" }}>{totaltotal}</Text>
                </Table.Summary.Cell> */}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}

BudgetEquity.acl = {
  subject: "Retained Earnings",
  action: "read",
};

export default BudgetEquity;
