import React, { useState, useEffect } from "react";
import FirstStep from "./FirstStep";
import CreateCashFlow from "./CreateCashFlow";
// import "../../assets/styles/CustomSpinner.css";
import { JSON_API } from "../../../services/Constants";
import { message, Steps, notification } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom"; // Import useHistory
import { useTranslation } from "react-i18next";

const CustomSpinner = () => (
  <div className="custom-spinner">
    <div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

const { Step } = Steps;
const CreateCashFlowWizard = () => {
  const history = useHistory(); // Initialize useHistory
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  let { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    complexity: 0,
    frequency: 0,
    enterpriseId: 0,
    cashFlowModelParts: [],
  });
  useEffect(() => {
    console.log("formData updated:", formData);
  }, [formData]);

  const handleStep2 = async (newParts) => {
    console.log("handleStep2 called");
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, cashFlowModelParts: newParts };
      console.log("Final form data:", updatedFormData);
      return updatedFormData;
    });
  };
  const CreateCashFlowModel = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_JSON_API}/abCashFlowModels`, formData);
      message.success(t("ModelCreatedSuccessfully"));
      history.push(`/CashFlowModels`);
    } catch (error) {
      notification["error"]({
        message: "Error",
        description: `An error occurred: ${error.message}! Try again`,
        placement: "bottomRight",
      });
    }
    setLoading(false);
  };
  const steps = [
    {
      title: t("Step1"),
      content: (
        <FirstStep
          setCurrent={setCurrent}
          setFormData={setFormData}
          formData={formData}
        />
      ),
    },
    {
      title: t("Step2"),
      content: (
        <CreateCashFlow
          enterpriseId={formData.enterpriseId}
          handleStep2={handleStep2}
          CreateCashFlowModel={CreateCashFlowModel}
          complexity={formData.complexity}
        />
      ),
    },
  ];
  return (
    <div>
      {loading && <CustomSpinner />}
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current]?.content}</div>
      <div className="steps-action"> </div>
    </div>
  );
};

export default CreateCashFlowWizard;
