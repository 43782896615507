import {
  NotificationOutlined,
  RightOutlined,
  LeftOutlined,
  SaveFilled,
  PlusCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import {
  Col,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  message,
} from "antd";

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../contexts/CompanyContext";
import { useAbility } from "../../contexts/abilityContext";
import { JSON_API } from "../../services/Constants";
import Budget from "../Marketing/Tables/Budget";

import Performance from "../Marketing/Tables/Performance";
import Reals from "../Marketing/Tables/Reals";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const { Option } = Select;

const { TextArea } = Input;
const { Text } = Typography;

const onChange = (key) => {
  console.log(key);
};

function Marketing() {
  const { id } = useParams();
  const { ability } = useAbility();
  let { t } = useTranslation();
  const [form] = Form.useForm();
  const { Company } = useContext(CompanyContext);
  const [Asset, setAsset] = useState([]);
  const [Descreption, setDescreption] = useState("");
  const [CategoryName, setCategoryName] = useState();
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState(null);
  const [marketingtype, setmarketingtype] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  var date;
  useEffect(() => {
    date = new Date().getFullYear();
    if (year == null) {
      setYear(date);
    }
    console.log("year" + date);
    getMarketing();
    getMarketingType();

    console.log(CategoryName);
  }, [Company.id, year]);

  const getMarketingType = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Marketing/MarketingType`)
      .then((res) => {
        console.log("marketing type: ", res.data);
        setmarketingtype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeyear = (date, dateString) => {
    console.log(date, dateString);
    setYear(dateString);
    getMarketing();
  };

  const getMarketing = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Marketing/MarketingBudgets/${Company.id}/${year}`)
      .then((res) => {
        console.log("lala", res);
        setAsset(res.data);
        console.log(Descreption);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAsset(null);
        setLoading(false);
      });
  };

  const Submit = async (e) => {
    const formData = {
      enterpriseId: Company.id,
      budgetAnnual: e.annualBudget,
      description: e.description,
      year: year,
      budgetTotal: e.budgetTotal,
      marketingTypeId: e.type,
    };

    console.log("HOH", formData);
    await axios
      .post(`${process.env.REACT_APP_JSON_API}/Marketing/CreateMarketing`, formData)
      .then((res) => {
        getMarketing();
        console.log("DATA", res.data);
        setOpen(!open);
        message.success(`${t("MarketingCreatedSuccessfully")}`);
      })
      .catch(function (error) {
        console.log(error);
        message.error(`${t("SomethingWentWrongWhileCreatingMarketing")}`);
      });
  };

  const UpdateFinancialStatement = async () => {
    console.log("Asset", Asset);
    console.log("des", Descreption);

    const obj = {
      budgets: Asset.budgets,
    };
    console.log(obj);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_JSON_API}/Marketing/MarketingUpdate`,
        obj
      );
      getMarketing();
      message.success(`${t("MarketingUpdatedSuccessfully")}`);

      console.log(response.data);
    } catch (error) {
      message.error(`${t("SomethingWentWrongWhileUpdatingMarketing")}`);
      console.log(error);
    }
  };

  function handleBudgetChange(budget) {
    setAsset((prevState) => {
      return { ...prevState, budgets: budget };
    });

    console.log(Asset.budgets);
  }
  function handleRealChange(real) {
    setAsset((prevState) => {
      return { ...prevState, reals: real };
    });
  }

  const items = [
    {
      key: "1",
      label: <span style={{ width: 300, textAlign: "center" }}>Budget</span>,
      children: (
        <div>
          {Asset && Asset.budgets && (
            <Budget
              AssetBudgets={Asset.budgets}
              onRealChange={handleRealChange}
              year={year}
              onBudgetChange={handleBudgetChange}
            />
          )}
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>{t("Real")}</span>
      ),
      children: (
        <div>
          {Asset && Asset.budgets && (
            <Reals
              AssetReals={Asset.budgets}
              AssetBudgets={Asset.budgets}
              onRealChange={handleRealChange}
            />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>Performance</span>
      ),
      children: (
        <div>
          {Asset && Asset.budgets && (
            <Performance
              AssetReals={Asset.budgets}
              AssetBudgets={Asset.budgets}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <h3 className="font-semibold">
            <Divider>
              <NotificationOutlined style={{ color: "#3947C4" }} />{" "}
              {t("Marketingexpenses")}
            </Divider>
          </h3>
        }
      >
        <Row justify="space-between">
          <Space style={{ marginBottom: 20 }}>
            {ability.can("update", "Marketing Expenses") && (
              <Button
                style={{ width: 150 }}
                type="primary"
                size="medium"
                onClick={UpdateFinancialStatement}
              >
                <SaveFilled /> {t("save")}
              </Button>
            )}
            <DatePicker
              defaultValue={dayjs(date)}
              name="year"
              picker="year"
              placeholder={t("SelectedYear")}
              style={{ width: 200, height: 35, marginLeft: 20 }}
              onChange={onChangeyear}
            />
          </Space>

          <Space>
            {ability.can("create", "Marketing Expenses") && (
              <Button
                size="medium"
                type="primary"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <PlusCircleFilled /> {t("create")}
              </Button>
            )}
          </Space>

          <Modal
            open={open}
            width={1200}
            title={
              <h2
                style={{
                  marginBottom: 50,
                  marginTop: 10,
                }}
              >
                {t("Marketing")}
              </h2>
            }
            cancelButtonProps={{
              danger: true,
              type: "primary",
            }}
            okText={
              <>
                <PlusCircleFilled /> {t("Create")}
              </>
            }
            cancelText={
              <>
                <CloseCircleFilled /> {t("Cancel")}
              </>
            }
            onCancel={() => {
              form.resetFields();
              setOpen(false);
            }}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  Submit(values);
                  console.log("tatww", values);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
          >
            <Form
              form={form}
              layout="horizontal"
              name="form_in_modal"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 14,
              }}
              initialValues={{
                modifier: "public",
              }}
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="annualBudget"
                    label={t("Annualbudget")}
                    rules={[
                      {
                        required: true,
                        message: t("Pleaseinputtheannualbudget"),
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      size={"large"}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="type"
                    label={t("Type")}
                    rules={[
                      {
                        required: true,
                        message: t("pleaseinputthetypeee"),
                      },
                    ]}
                  >
                    <Select style={{ width: "100%" }}>
                      {marketingtype &&
                        marketingtype.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="budgetTotal"
                    label={t("budgetTotal")}
                    rules={[
                      {
                        required: true,
                        message: t("pleaseinputthetotal"),
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      style={{ width: "100%" }}
                      min={0}
                      size={"large"}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="year"
                    label={t("Year")}
                    rules={[
                      {
                        required: true,
                        message: t("pleaseinputtheyear"),
                      },
                    ]}
                  >
                    <DatePicker
                      name="year"
                      picker="year"
                      placeholder={t("SelectedYear")}
                      style={{ width: 330, height: 35 }}
                      onChange={onChangeyear}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="description"
                label={t("Description")}
                rules={[
                  {
                    required: true,
                    message: t("pleaseinputthedes"),
                  },
                ]}
              >
                <Input
                  onChange={(e) => setDescreption(e.target.value)}
                  style={{ width: "100%" }}
                ></Input>
              </Form.Item>
            </Form>
          </Modal>
        </Row>

        <Tabs
          style={{ marginBottom: 45, Color: "#059BFF" }}
          type="card"
          centered
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </Card>
    </div>
  );
}
Marketing.acl = {
  subject: "Marketing Expenses",
  action: "read",
};

export default Marketing;
