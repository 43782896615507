import React, { useContext, useEffect, useState } from "react";

import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Progress,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  Typography,
  icon,
  message,
} from "antd";

import {
  CloseCircleFilled,
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  NotificationOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "antd";
import axios from "axios";
// import "./Tableau.css";
//
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAbility } from "../../contexts/abilityContext";
dayjs.extend(customParseFormat);
const { Option } = Select;

function Pret() {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const [termestype, settermestype] = useState([{}]);
  const [Capital, setCapital] = useState([{}]);
  const [Object, setObject] = useState([{}]);
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Loans");
  const canDelete = ability.can("delete", "Loans");
  const [data, setData] = useState([{}]);
  const [editingRow, setEditingRow] = useState(null);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  useEffect(() => getdata(), []);
  useEffect(() => {
    getdata();
    gettermesType();
    getCapitalType();
  }, []);

  const getdata = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/Finance/Pret/${Company.id}`
      );
      setObject(response.data);

      setData(response.data);
    } catch (error) {
      console.log(error);
      message.error(t("ErrorFetchingLoans"));
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_JSON_API}/Finance/Pret/${id}`);
      message.success(t("LoanDeletedSuccessfully"));
      getdata();
    } catch (error) {
      message.error(t("ErrorDeletingLoan"));
    }
  };

  const onChange = (value) => {
    console.log("changed", value);
  };

  const { TextArea } = Input;

  const submite = async (e) => {
    const formData = {
      enterpriseId: Company.id,
      name: e.name,
      creditor: e.creditor,
      loanAmount: e.loanAmount,
      termsId: e.termes,
      capitalPeriodId: e.conge,
      loanDate: e.date,
      interestRates: e.interestRates,
      specialConditions: e.specialConditions,
    };

    console.log("form", formData);
    axios
      .post(`${process.env.REACT_APP_JSON_API}/Finance/Pret`, formData)
      .then((res) => {
        message.success(t("LoanAddedSuccessfully"));
        getdata();
        console.log(res.data);
        setOpen(!open);
      })
      .catch(function (error) {
        console.log(error);
        message.error(t("ErrorAddingLoan"));
      });
  };

  const getCapitalType = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Finance/Capital`)
      .then((res) => {
        console.log("Financecpital", res);
        setCapital(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const gettermesType = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Finance/Term`)
      .then((res) => {
        console.log("Financeterme", res);
        settermestype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
    return (
      <Modal
        forceRender
        open={open}
        title={t("Addaloan")}
        okText={
          <>
            <PlusCircleFilled /> {t("Create")}
          </>
        }
        cancelText={
          <>
            <CloseCircleFilled /> {t("Cancel")}
          </>
        }
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            // value={type}
            name="name"
            label={t("Institutionname")}
            rules={[
              {
                required: true,
                message: `${t("VeuillersaisirletitredePrêt")}`,
              },
            ]}
          >
            <Input
              style={{
                width: 470,
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            name="creditor"
            label={t("Creditor")}
            rules={[
              {
                required: true,
                message: `${t("VeuillersaisirletitredeCreancier")}`,
              },
            ]}
          >
            <Input
              style={{
                width: 470,
              }}
            ></Input>
          </Form.Item>

          <Form.Item
            // value={montant}
            name="loanAmount"
            label={t("Loanamount")}
            rules={[
              {
                required: true,
                message: `${t("VeuillersaisirleMontant")}`,
              },
            ]}
          >
            <InputNumber
              type="number"
              addonBefore="+"
              style={{
                width: 470,
                textAlign: "right",
              }}
            />
          </Form.Item>

          <Form.Item
            // value={termes}
            name="termes"
            label={t("Terms")}
            rules={[
              {
                required: true,
                message: `${t("chooseaterm")}`,
              },
            ]}
          >
            <Select
              style={{
                width: 470,
              }}
            >
              {termestype &&
                termestype.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.termName}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            // value={date}
            name="date"
            label={t("Loandate")}
            rules={[
              {
                required: true,
                message: `${t("selectdate")}`,
              },
            ]}
          >
            <DatePicker
              // format="YYYY, DD, MM"
              style={{
                width: 470,
              }}
            ></DatePicker>
          </Form.Item>

          <Form.Item
            // value={conge}
            name="conge"
            label={t("Capitalfreeperiod")}
            rules={[
              {
                required: true,
                message: `${t("CapitalfreeperiodSelect")}`,
              },
            ]}
          >
            <Select
              style={{
                width: 470,
              }}
            >
              {Capital &&
                Capital.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.capitalName}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            // value={taux}
            name="interestRates"
            label={t("Interestrates")}
            rules={[
              {
                required: true,
                message: `${t("Veuillezsaisiruntauxdintéret")}`,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={onChange}
              style={{ textalign: "right" }}
            />
          </Form.Item>

          <Form.Item
            // value={conditions}
            name="specialConditions"
            label={t("Particularconditions")}
          >
            <TextArea rows={4}></TextArea>
          </Form.Item>
          <Form.Item
            name="modifier"
            className="collection-create-form_last-form-item"
          ></Form.Item>
        </Form>
      </Modal>
    );
  };

  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}>{t("Loanname")}</h1>,
      dataIndex: "type",
      width: "30%",
      align: "center",
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.name)}>{record.name}</div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}> {t("Creationdate")}</h1>,
      dataIndex: "date",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {dayjs(record.createdDate).format("YYYY / MM / DD")}
        </div>
      ),
    },
  ];

  if (canDelete || canUpdate) {
    columns.push({
      title: <h1 style={{ textAlign: "center" }}>Actions</h1>,
      align: "center",
      render: (_, record) => (
        <>
          {canDelete && (
            <Popconfirm
              title={t("Areyousuretodeletethisone")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={() => handleDelete(record.id)}
            >
              <Button type="link" danger>
                <DeleteOutlined />
                {t("Delete")}
              </Button>
            </Popconfirm>
          )}
          {canUpdate && (
            <Link
              to={{
                pathname: `/pretdetails/${record.id}`,
                state: { stateParam: record.id },
              }}
            >
              <EditOutlined />
              <span style={{ marginLeft: ".4rem" }}>{t("Update")}</span>
            </Link>
          )}
        </>
      ),
    });
  }
  return (
    <>
      <div>
        {contextHolder}
        <Card
          bordered={false}
          className="header-solid mb-24"
          title={
            <h3 className="font-semibold">
              <Divider>
                <NotificationOutlined style={{ color: "#3947C4" }} />{" "}
                <DollarOutlined style={{ color: "#3947C4" }} />{" "}
                {t("Loansmanagement")}{" "}
              </Divider>
            </h3>
          }
        >
          <Row justify="space-between">
            {ability && ability.can("create", "Loans") && (
              <Space style={{ marginBottom: 15 }}>
                <Button
                  className="Create-button"
                  type="primary"
                  icon={<PlusCircleFilled />}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t("Create")}
                </Button>
                <CollectionCreateForm
                  open={open}
                  onCreate={submite}
                  onCancel={() => {
                    setOpen(false);
                  }}
                />
              </Space>
            )}
          </Row>
<div style={{ overflowX: "auto",backgroundColor:'white' }}>
          <Table bordered columns={columns} dataSource={Object} />
          </div>
          <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
        </Card>
      </div>
    </>
  );
}
Pret.acl = {
  subject: "Loans",
  action: "read",
};
export default Pret;

