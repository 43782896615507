import React, { useContext, useEffect, useState } from "react";
import {
  AppstoreOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusSquareOutlined,
  RollbackOutlined,
  DollarOutlined,
  SaveOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Empty,
  Input,
  Popconfirm,
  Space,
  Table,
  message,
} from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useTranslation } from "react-i18next";
import axios from "axios";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Input
          defaultValue={record[dataIndex]}
          onChange={(e) => (record[dataIndex] = e.target.value)}
        />
      ) : (
        children
      )}
    </td>
  );
};

const Currency = () => {
  const [currencies, setCurrencies] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [loading, setLoading] = useState(true);
  let { t } = useTranslation();

  useEffect(() => {
    getCurrencies();
  }, []);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_JSON_API}/Currencys`);
      setCurrencies(response.data);
      setLoading(true);
    } catch (error) {
      message.error(t("ErrorFetchingCurrencies"));
      setLoading(false);
    }
  };

  const getCurrencies = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_JSON_API}/Currencys`);
      setCurrencies(response.data);
    } catch (error) {
      console.log(error);
      message.error(t("ErrorFetchingCurrencies"));
    }
  };

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      if (record.id === "new") {
        await axios.post(`${process.env.REACT_APP_JSON_API}/Currencys`, {
          label: record.label,
          shortLabel: record.shortLabel,
        });
        message.success(t("CurrencyAddedSuccessfully"));
      } else {
        await axios.put(`${process.env.REACT_APP_JSON_API}/Currencys`, {
          id: record.id,
          label: record.label,
          shortLabel: record.shortLabel,
        });
        message.success(t("CurrencyUpdatedSuccessfully"));
      }
      fetchCurrencies();
      setEditingKey("");
    } catch (error) {
      message.error(t("ErrorSavingCurrencies"));
    }
  };

  const addCurrency = () => {
    setCurrencies([{ id: "new", label: "", shortLabel: "" }, ...currencies]);
    setEditingKey("new");
  };

  const columns = [
    {
      title: t("Identifier"),
      dataIndex: "id",
      editable: false,
      align: "center",
    },
    {
      title: t("Label"),
      dataIndex: "label",
      editable: true,
      align: "center",
    },
    {
      title: t("ShortLabel"),
      dataIndex: "shortLabel",
      editable: true,
      align: "center",
    },
    {
      title: t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              icon={<SaveOutlined />}
              onClick={() => save(record)}
            >
              {t("SaveCurrency")}
            </Button>
            <Popconfirm
              title={t("CancelEditing")}
              onConfirm={cancel}
              okButtonProps={{
                type: "primary",
                danger: true,
                icon: <CheckOutlined />,
              }}
              cancelButtonProps={{
                icon: <CloseOutlined />,
              }}
            >
              <Button type="link" danger size="medium" icon={<CloseOutlined />}>
                {t("Cancel")}
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              type="link"
              icon={<EditOutlined />}
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              {t("EditCurrency")}
            </Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Card
        title={
          <span style={{ fontSize: "16px" }}>
            <DollarOutlined /> {t("Currencies")}
          </span>
        }
        extra={
          <Space>
            <Button
              type="primary"
              size="medium"
              style={{
                fontSize: "10px",
                textTransform: "uppercase",
              }}
              icon={<PlusCircleFilled />}
              onClick={addCurrency}
              disabled={editingKey !== ""}
            >
              {t("AddNewCurrency")}
            </Button>
          </Space>
        }
      >
        <Table
          locale={{
            emptyText: <Empty description={t("nodata")} />,
          }}
          dataSource={currencies}
          columns={mergedColumns}
          rowClassName="editable-row"
          rowKey="id"
          pagination={{
            pageSize: 10,
          }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          style={{ overflowX: "auto",backgroundColor:'white' }}
        />
      </Card>
    </>
  );
};

export default Currency;
