import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  Empty,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  message,
} from "antd";
import dayjs from "dayjs";
import { JSON_API } from "../../services/Constants";

import axios from "axios";
import { CompanyContext } from "../../contexts/CompanyContext";
// import "./Tableau.css";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useTranslation } from "react-i18next";

dayjs.extend(customParseFormat);
const { TextArea } = Input;

function EnterpriseDepartment() {
  let { t } = useTranslation();

  const [enterpriseDepartments, setEnterpriseDepartments] = useState([]);
  const [Departments, setDepartments] = useState([]);
  const [ChartAccounts, setChartAccounts] = useState([]);
  const [deptdata, setDeptdata] = useState([]);
  const [chartAccountId, setChartAccountId] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const { Company } = useContext(CompanyContext);
  const { Option } = Select;
  const [form] = Form.useForm();
  const date = new Date();
  const currenYear = date.getFullYear();

  //Get Steps
  const getEnterpriseDepartments = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments?enterpriseId=${Company.id}`)
      .then((res) => {
        setDeptdata(res.data);
        console.log(enterpriseDepartments);
        setEnterpriseDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Get departments
  const getDepartments = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Departments`)
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Get departments
  const getChartAccounts = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_JSON_API}/ChartAccounts?enterpriseId=${Company.id}&year=${currenYear}`
      )
      .then((res) => {
        setChartAccounts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getEnterpriseDepartments();
    getDepartments();
    getChartAccounts();
  }, [Company.id]);
  const chartAccounts =
    ChartAccounts == null
      ? []
      : ChartAccounts.map((chart) => ({
          value: chart.id,
          label: chart.financialStatementClass.label,
        }));
  const columns = [
    {
      title: <h3>{t("Id")}</h3>,
      dataIndex: "id",
      align: "center",
    },
    {
      title: <h3>{t("Department")}</h3>,
      dataIndex: "label",
      align: "center",
    },
    // {
    //   title: <h3>{t("Chartofaccount")}</h3>,
    //   dataIndex: "chartAccount",
    //   align: "center",
    //   render: (chartAccount, record) => {
    //     if (editingRow === record.id) {
    //       return (
    //         <Form.Item name="chartAccountId" initialValue="null">
    //           <Select
    //             onChange={onchangeChartAccount}
    //             style={{ width: 200, marginTop: 5, textAlign: "center" }}
    //             options={chartAccounts}
    //             initialValue="null"
    //             defaultValue="null"
    //           ></Select>
    //         </Form.Item>
    //       );
    //     } else {
    //       return (
    //         <div style={{ textAlign: "center" }}>
    //           {chartAccount == null ? "None" : chartAccount}
    //         </div>
    //       );
    //     }
    //   },
    // },
    {
      title: <h3>Actions</h3>,
      key: "operation",
      align: "center",

      render: (_, record) => (
        <Space size="middle">
          {editingRow === record.id ? (
            <>
              <Button type="link" onClick={() => setEditingRow(null)}>
                {t("cancel")}
              </Button>
              <Button type="link" htmlType="submit">
                {t("save")}
              </Button>
            </>
          ) : (
            <>
              {/* <Button
                type="link"
                onClick={() => {
                  setEditingRow(record.id);
                  form.setFieldsValue({
                    glNumber: record.glNumber,
                  });
                }}
              >
                <EditOutlined />
                {t("edit")}{" "}
              </Button> */}
              <Popconfirm
                title={t("deleterow")}
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link " danger>
                  <DeleteOutlined />
                  {t("Delete")}
                </Button>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];
  const filtereddepartmentOptions = Departments.filter((o) => {
    let notFound = true;
    deptdata.forEach((d) => {
      if (d.label == o.label) notFound = false;
    });
    return notFound;
  });
  const addShareholderdata = () => {
    const d = Departments.filter((e) => e.id === departmentId);
    if (d) {
      setDeptdata([
        ...deptdata,
        {
          id: d[0].id,
          label: d[0].label,
        },
      ]);
    }

    console.log("after add:", deptdata);
  };
  const onchangeChartAccount = (id) => {
    console.log("Chart Account Id " + id);
    setChartAccountId(id);
  };
  const onchangeDepartment = (id) => {
    console.log("Department Id " + id);
    setDepartmentId(id);
  };
  //Delete Entrprise Department
  const handleDelete = async (e) => {
    const newData = deptdata.filter((item) => item.id !== e);
    setDeptdata(newData);
    console.log(e);
    await axios
      .delete(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments/${e}`)
      .then((response) => {
        console.log(response);
        message.success("Enterprise Department was Deleted successfully", 3);
        getEnterpriseDepartments();
      });
  };
  //update Entrprise Department

  const onFinishEdit = async (values) => {
    const enterpriseDepartment = {
      id: editingRow,
      enterpriseId: Company.id,
      chartAccountId: values.chartAccountId,
    };
    console.log("edited values: ", enterpriseDepartment);
    await axios
      .put(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments`, enterpriseDepartment)
      .then((response) => {
        getEnterpriseDepartments();
        message.success(`${t("Updatedsuccessfully")}`);
      });
    setEditingRow(null);
  };
  const AddEnterpriseDepartment = async () => {
    addShareholderdata();
    // setSpinner(true);
    const enterpriseDepartment = {
      enterpriseId: Company.id,
      departmentId: departmentId,
      chartAccountId: chartAccountId,
    };
    console.log("Adding Enterprise Department", enterpriseDepartment);
    await axios
      .post(`${process.env.REACT_APP_JSON_API}/EnterpriseDepartments`, enterpriseDepartment)
      .then((res) => {
        message.success("Enterprise Department Added successfully", 3);
        //setSpinner(false);
        getEnterpriseDepartments();
      })
      .catch((err) => {
        console.log(err);
        message.error(
          `${err} this error occured when attenting adding new enterprise department `,
          4
        );
      });
  };

  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={t("entreprisedepartments")}
    >
      <Form name="basic" autoComplete="off">
        <Space
          style={{
            display: "flex",
            marginBottom: 8,
          }}
          align="baseline"
        >
          <Form.Item
            label={t("department")}
            name="label"
            rules={[
              {
                required: true,
                message: `Select Department`,
              },
            ]}
          >
            <Select
              notFoundContent={t("nodata")}
              onChange={onchangeDepartment}
              size="large"
              style={{ width: 300 }}
            >
              {filtereddepartmentOptions.map((d) => (
                <Option key={d.id} value={d.id}>
                  {d.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item label={t("Chartaccounts")} name="chartAccountId">
            <Select
              notFoundContent={t("nodata")}
              onChange={onchangeChartAccount}
              size="large"
              style={{ width: 200 }}
            >
              {ChartAccounts.map((chart) => (
                <Option key={chart.id} value={chart.id}>
                  {chart.glAccount.financialStatementTypeGIFI}
                </Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item>
            <Button
              onClick={() => AddEnterpriseDepartment()}
              // style={{
              //   width: 120,
              //   height: 35,
              //   marginLeft: "3rem",
              // }}
              type="primary"
            >
              <PlusOutlined />
              {t("add")}
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Form form={form} onFinish={onFinishEdit}>
        <Table
          locale={{
            emptyText: <Empty description={t("nodata")} />,
          }}
          bordered
          columns={columns}
          dataSource={enterpriseDepartments}
          
          style={{ overflowX: "auto",backgroundColor:'white' }}
        />
      </Form>
    </Card>
  );
}
export default EnterpriseDepartment;
