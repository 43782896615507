import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Row,
  Col,
  message,
  Select,
  Typography,
  Form,
  Card,
  Alert,
  Table,
  Space,
  Button,
  Divider,
  Input,
  DatePicker,
  Breadcrumb,
} from "antd";
import {
  UsergroupAddOutlined,
  PlusSquareOutlined,
  ArrowLeftOutlined,
  SyncOutlined,
  UserAddOutlined,
  SecurityScanOutlined,
  AppstoreAddOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CreateUser = () => {
  let { t } = useTranslation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const { Company } = useContext(CompanyContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [titles, setTitles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employees, setEmployees] = useState([]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const generateRandomPassword = () => {
    let companyName = Company.name;
    companyName = capitalizeFirstLetter(companyName);

    const specialCharacters = "@";
    const randomString = Math.random().toString(36).slice(-8);
    const newPassword = `${companyName}${specialCharacters}${randomString}`;

    setPassword(newPassword);
    setConfirmPassword(newPassword);
    setShowAlert(true);
  };

  const fetchTitles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/Titles?enterpriseId=${Company.id}`
      );
      setTitles(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/Roles/enterprise/${Company.id}`
      );
      setRoles(response.data);
      console.log(response.data);
    } catch (error) {
      message.error(t("ErrorFetchingRoles"));
      console.error(error);
    }
    setLoading(false);
  };

  const createEmployeeAccount = async (employeeAccountCreate) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_JSON_API}/EmployeeAccounts`,
        employeeAccountCreate
      );

      if (response.status === 201) {
        return response.data;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to create employee account:", error);
      throw error;
    }
  };

  const handleTitleChange = async (titleId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/EmployeeAccounts/by-title/${Company.id}/${titleId}`
      );

      if (response.data.length === 0) {
        setEmployees([]);
        message.info(t("NoEmployeesAssociatedWithSelectedTitle"));
      } else {
        setEmployees(response.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setEmployees([]);
        message.info(t("NoEmployeesAssociatedWithSelectedTitle"));
      } else {
        message.warning(t("ErrorFetchingEmployeesForSelectedTitle"));
        console.error(error);
      }
    }
    setLoading(false);
  };

  const handleFinish = async (values) => {
    if (password !== confirmPassword) {
      message.error(t("PasswordsDoNotMatch"));
      return;
    }

    let employeeAccountCreate = {
      EmployeeId: values.employee,
      Email: values.email,
      PhoneNumber: values.phoneNumber,
      Password: password,
    };

    try {
      await createEmployeeAccount(employeeAccountCreate);
      console.log("employeeAccountCreate", employeeAccountCreate);
      message.success(t("EmployeeAccountCreatedSuccessfully"));
      form.resetFields();
      setPassword("");
      setConfirmPassword("");

      //history.push("/users");
    } catch (error) {
      message.error(t("ErrorCreatingEmployeeAccount"));
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTitles();
    fetchRoles();
  }, [Company.id]);

  return (
    <Card>
      <Breadcrumb
        style={{
          marginBottom: "1rem",
          fontSize: "1.2rem",
          textShadow: "1px 1px 1px #aaa",
        }}
      >
        <Breadcrumb.Item style={{ hover: "none" }}>
          <Link to="/Accounts">
            <Typography.Text>
              <SecurityScanOutlined /> {t("Accounts")}
            </Typography.Text>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ hover: "none" }}>
          <Link to="/users">
            <Typography.Text>
              <AppstoreAddOutlined /> {t("Users")}
            </Typography.Text>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ hover: "none" }}>
          <Typography.Text>
            <UsergroupAddOutlined /> {t("NewUser")}
          </Typography.Text>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        name="register"
        style={{ margin: "0 auto", maxWidth: "1000px" }}
      >
        <Typography.Title
          level={2}
          style={{ textAlign: "center", marginBottom: "2rem" }}
        >
          {t("CreateAnewUser")}
        </Typography.Title>

        <Divider orientation="left">{t("EmployeeSelection")}</Divider>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="title"
              label={t("Title")}
              rules={[
                {
                  required: true,
                  message: t("PleaseSelectATitle"),
                },
              ]}
            >
              <Select onChange={handleTitleChange}>
                {titles.map((title) => (
                  <Select.Option key={title.id} value={title.id}>
                    {title.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="employee"
              label={t("Employee")}
              rules={[
                {
                  required: true,
                  message: t("PleaseSelectAnEmployee"),
                },
              ]}
            >
              <Select>
                {employees.map((employee) => (
                  <Select.Option key={employee.id} value={employee.id}>
                    {employee.name.toUpperCase() +
                      " " +
                      employee.firstName.toUpperCase()}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("AccountInfos")}</Divider>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: t("PleaseEnterAnEmail"),
                },
              ]}
            >
              <Input type="email" placeholder={t("EnterEmail")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="phoneNumber"
              label={t("PhoneNumber")}
              rules={[
                {
                  required: true,
                  message: t("PleaseEnterAPhoneNumber"),
                },
              ]}
            >
              <Input type="text" placeholder={t("EnterPhoneNumber")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("Password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("PleaseEnterPassword"),
                },
                {
                  pattern: /.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*/,
                  message: t("PasswordSpecialCharError"),
                },
              ]}
            >
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t("EnterPassword")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label={t("ConfirmPassword")}
              name="confirmPassword"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t("PleaseConfirmPassword"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("PasswordsDoNotMatch")));
                  },
                }),
              ]}
            >
              <Input.Password
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t("ConfirmPassword")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="center">
          <Col xs={24} sm={8}>
            <Form.Item>
              <Link to="/Users">
                <Button type="primary" danger block>
                  <ArrowLeftOutlined /> {t("GoBack")}
                </Button>
              </Link>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item>
              <Button
                type="secondary"
                icon={<SyncOutlined />}
                onClick={generateRandomPassword}
                block
              >
                {t("GeneratePassword")}
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                <UserAddOutlined /> {t("CreateAccount")}
              </Button>
            </Form.Item>
          </Col>
        </Row>

        {showAlert && (
          <Alert
            message={t("YourPasswordHasBeenGenerated")}
            type="warning"
            showIcon
            closable
            afterClose={() => setShowAlert(false)}
          />
        )}
      </Form>
    </Card>
  );
};
CreateUser.acl = {
  subject: "Accounts",
  action: "create",
};

export default CreateUser;
