import React, { useContext, useEffect, useState } from "react";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants.js";
import EnterpriseDepartment from "./Human Resources/EnterpriseDepartment";
import EnterpriseTitles from "./Human Resources/EnterpriseTitles";
import PostClassification from "./Human Resources/PostClassification";
dayjs.extend(customParseFormat);
const { Title } = Typography;
const CheckboxGroup = Checkbox.Group;
const Settings = () => {
  const { t } = useTranslation();
  const [openActivity, setOpenActivity] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [plainOptions, setPlainOptions] = useState([]);
  const [form] = Form.useForm();
  const [department, setDepartment] = useState({});
  const [departmentId, setDepartmentId] = useState(null);
  const [openEditActivity, setOpenEditActivity] = useState(false);

  const { Company } = useContext(CompanyContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_JSON_API}/Departments`)
      .then((response) => {
        console.log("data:", response.data);
        setPlainOptions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [Company]);

  const getDepartment = async (id) => {
    setDepartmentId(id);
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Departments/${id}`)
      .then((res) => {
        setDepartment(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const updateDepartment = async (values) => {
    const dept = {
      id: departmentId,
      label: values.label,
    };
    await axios
      .put(`${process.env.REACT_APP_JSON_API}/Departments`, dept)
      .then((res) => {
        setOpenEditActivity(false);
        message.success(`Department added successfully!`, 4);
        displayDepartments();
      })
      .catch(function (error) {
        console.log("Erreuuuuuuuuuuuuuur!" + error);
        setOpenEditActivity(true);
      });
  };
  const displayDepartments = async () => {
    axios
      .get(`${process.env.REACT_APP_JSON_API}/Departments`)
      .then((response) => {
        console.log("data:", response.data);
        setPlainOptions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const CollectionAddActivity = ({ openActivity, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={openActivity}
        title={t("CreateNewDepartment")}
        okText={t("create")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          <Form.Item name="label" label={t("Label")}>
            <Input
              style={{
                width: "95%",
                height: "31%",
              }}
              type="textarea"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const CollectionUpdateActivity = ({
    openEditActivity,
    onUpdateActivity,
    onCancel,
  }) => {
    return (
      <Modal
        open={openEditActivity}
        title={t("UpdateDepartment")}
        okText={t("Update")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onUpdateActivity(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item name="label" label={t("Label")}>
            <Input
              defaultValue={department.label}
              style={{ width: "95%", height: "31%" }}
              type="textarea"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const handleDelete = async (e) => {
    console.log(e);
    await axios.delete(`${process.env.REACT_APP_JSON_API}/Departments/${e}`).then((response) => {
      console.log(response);
      displayDepartments();
    });
  };
  const AddDepartment = async (e) => {
    //construction dobjet json
    console.log("AAdding");
    const formData = {
      label: e.label,
    };
    console.log(formData);
    await axios
      .post(`${process.env.REACT_APP_JSON_API}/Departments`, formData)
      .then((res) => {
        displayDepartments();
        message.success(`Department added successfully!`);
        setOpenActivity(false);
      })
      .catch(function (error) {
        console.log(error);
        setOpenActivity(true);
      });
    setOpenActivity(false);
  };

  return (
    <Card>
      <Card className="header-solid mb-24" title={t("departements")}>
        <CollectionAddActivity
          openActivity={openActivity}
          onCreate={AddDepartment}
          onCancel={() => {
            setOpenActivity(false);
          }}
        />
        <CollectionUpdateActivity
          openEditActivity={openEditActivity}
          onUpdateActivity={updateDepartment}
          onCancel={() => {
            setOpenEditActivity(false);
          }}
        />

        <Space size={[8, 16]} wrap>
          <Card
            hoverable={true}
            style={{ cursor: "pointer" }}
            bodyStyle={{ fontSize: 50 }}
            onClick={() => setOpenActivity(true)}
          >
            <PlusOutlined />{" "}
          </Card>
          {plainOptions.map((item) => (
            <Card title={item.label}>
              <Popconfirm
                title={t("deleterow")}
                onConfirm={() => handleDelete(item.id)}
                okText={t("Yes")}
                cancelText={t("No")}
              >
                <Button type="link " danger>
                  <DeleteOutlined />
                  {t("Delete")}
                </Button>
              </Popconfirm>
              <Button
                type="link "
                onClick={() => {
                  setOpenEditActivity(true);
                  getDepartment(item.id);
                }}
              >
                <EditOutlined />

                {t("edit")}
              </Button>
            </Card>
          ))}
        </Space>
      </Card>

      <EnterpriseDepartment />
      <EnterpriseTitles />
      <PostClassification />
    </Card>
  );
};

export default Settings;
