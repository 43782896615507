import {
  DeleteOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  message,
} from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import Budget from "./Tables/Budget";
import HyphothesisOfGl from "./Tables/HyphothesisOfGl";
import Performance from "./Tables/Performance";
import Reals from "./Tables/Reals";

import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAbility } from "../../contexts/abilityContext";
const { Option } = Select;

const { TextArea } = Input;
const { Text } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};

// ON CHANGE SELECT
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
// For tabs
const onChange = (key) => {
  console.log(key);
};

//for tabs Form
const onChangee = (key) => {
  console.log(key);
};

function AssetDetail() {
  //mode of tabs
  const history = useHistory();
  const { ability } = useAbility();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const canUpdate = ability.can("update", "Assets");
  const { Company } = useContext(CompanyContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const [balance, setbalance] = useState("");
  const { TextArea } = Input;
  const [Asset, setAsset] = useState([]);
  const [Real, setReal] = useState([]);
  const [performance, setperformance] = useState([]);
  const [Description, setDescription] = useState("");
  const [Category, setCategory] = useState();
  const [CategoryName, setCategoryName] = useState();
  const [Class, setClass] = useState();
  const [open, setOpen] = useState(false);
  const [realfrombudget, setrealfrombudget] = useState([]);
  const [gifi, setGifi] = useState("");
  const [glAccount, setglAccount] = useState(null);
  const [glAccountId, setglAccountId] = useState(null);
  const [Hypo, setHypo] = useState(null);
  const [statementcategory, setStatementCategory] = useState([{}]);
  const [statementtype, setStatementType] = useState([{}]);
  const [statementclass, setStatementClass] = useState([{}]);
  const [glAccountDescription, setGlAccountDescription] = useState("");
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    getAsset();
    console.log(CategoryName);
    getReals();
    getPerformance();
    getStatementClass();
    getHypothesis();
  }, [Company.id]);

  function handleredirection() {
    history.push("/Assets");
    getAsset();
  }

  const getAsset = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_JSON_API}/assets/AssetBudgets/${id}`);
      console.log("dataaaaa:", res.data);
      setAsset(res.data);

      if (res.data.financialStatementCategory) {
        setCategory(res.data.financialStatementCategory.id);
        setCategoryName(
          currentLang === "fr"
            ? res.data.financialStatementCategory.labelFr
            : res.data.financialStatementCategory.label
        );
      }

      if (res.data.financialStatementClass) {
        setClass(res.data.financialStatementClass.id);
        handleclass(res.data.financialStatementClass.id);
      }

      setGifi(res.data.financialStatementType.gifi);
      setglAccount(res.data.glAccount.glNumber);
      setGlAccountDescription(
        `(${res.data.glAccount.glNumber}) : ${res.data.glAccount.description}`
      );
      setDescription(res.data.glAccount.description);
      getHypothesis(res.data.glAccount.id);
    } catch (err) {
      console.error("Error fetching assets details:", err);
    }
  };

  const getPerformance = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/assets/AssetPerformance/${id}`)
      .then((res) => {
        console.log("test", res.data);
        setperformance(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReals = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/assets/AssetReal/${id}`)
      .then((res) => {
        console.log("test", res.data);
        setReal(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteFinancialStatement = async () => {
    await axios
      .delete(`${process.env.REACT_APP_JSON_API}/assets/${Asset.id}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const UpdateFinancialStatement = async () => {
    console.log(Asset);
    console.log(Description);
    const obj = {
      id: Asset.id,
      financialStatementClassId: Asset.financialStatementClass.id,
      financialStatementCategoryId: Category,
      description: Description,
      budgets: Asset.budgets,
      reals: Real.reals,
    };

    await axios
      .put(`${process.env.REACT_APP_JSON_API}/assets/AssetBudgetRealUpdate`, obj)
      .then((res) => {
        getAsset();
        message.success(t("UpdatedSuccessfully"));
        console.log("asset updated successfully :", obj);
        // history.push("/Assets");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const months = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];
  function handleBudgetChange(budget) {
    setAsset((prevState) => {
      return { ...prevState, budgets: budget };
    });
    Real.reals.map((e) => {
      budget.map((Budget) => {
        if (e.year == Budget.year) {
          const confirm = !Budget.confirmed;
          if (confirm) {
            const startingMonthIndex = Company.startPeriod - 1;
            for (let i = 0; i < months.length; i++) {
              const monthIndex = (i + startingMonthIndex) % months.length;
              const monthName = months[monthIndex];
              e.reals[monthIndex] = Budget.budgets[monthIndex];
            }
          }
        }
      });
    });
    console.log(Asset.budgets);
  }
  function handleRealChange(real) {
    setReal((prevState) => {
      return { ...prevState, reals: real };
    });
  }

  useEffect(() => {
    getAsset();
    getStatementClass();
    handleclass();
    getHypothesis();
    console.log(Asset);
  }, []);

  const getHypothesis = async (e) => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/GLAccount/${e}`)
      .then((res) => {
        console.log("hypothesis: ", res.data);
        setHypo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleclass = async (ClassId) => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementCategory/class/${ClassId}`)
      .then((res) => {
        console.log(res);
        setStatementCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatementClass = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/FinancialStatementClass`)
      .then((res) => {
        console.log(res);
        setStatementClass(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const itemsForm = [
    {
      key: "1",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Maininformation")}
        </span>
      ),
      children: Asset.budgets && (
        <div>
          <Form
            {...layout}
            name="nest-messages"
            style={{
              maxWidth: 800,
              margin: "auto",
            }}
          >
            <Form.Item label={t("Class")}>
              <Input
                value={
                  currentLang === "fr"
                    ? Asset.financialStatementClass?.labelFr
                    : Asset.financialStatementClass?.label
                }
                disabled
              />
            </Form.Item>

            <Form.Item label={t("Category")}>
              <Select
                disabled={!canUpdate}
                value={Category}
                onChange={(value) => setCategory(value)}
              >
                {statementcategory &&
                  statementcategory.map((e) => (
                    <Option key={e.id} value={e.id}>
                      {currentLang === "fr" ? e.labelFr : e.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label={"Type"}>
              <Input value={gifi} disabled />
            </Form.Item>

            <Form.Item label={t("glnumber")}>
              <Input value={glAccountDescription} disabled={true} />
            </Form.Item>

            <Form.Item label={t("Description")}>
              <Input.TextArea
                disabled={!canUpdate}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: 400,
                }}
                value={Description}
                rows={2}
              />
            </Form.Item>
            <Form.Item></Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>
          {t("Hyphothesis")}
        </span>
      ),
      children: (
        <div>{Hypo && <HyphothesisOfGl HypothesesDataSource={Hypo} />}</div>
      ),
    },
  ];

  // for table Buget ,Reals and Performance
  const items = [
    {
      key: "1",
      label: <span style={{ width: 300, textAlign: "center" }}>Budget</span>,
      children: (
        <div>
          {Asset.budgets && (
            <Budget
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              onBudgetChange={handleBudgetChange}
            />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>{t("Real")}</span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <Reals
              AssetReals={Real.reals}
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              onRealChange={handleRealChange}
            />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <span style={{ width: 300, textAlign: "center" }}>Performance</span>
      ),
      children: (
        <div>
          {Asset.budgets && (
            <Performance
              AssetReals={Real.reals}
              AssetBudgets={Asset.financialStatementClass && Asset.budgets}
              AssetPerformance={performance.performances}
              financialStatementCategory
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}

      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <h3 className="font-semibold">
            {t("AssetsDetails")} {t("for")} {Description}
          </h3>
        }
      >
        <Row justify="space-between">
          <ConfigProvider
            theme={{
              token: {},
            }}
          >
            <Space
              style={{
                display: "flex",
                marginLeft: "flex",
              }}
              align="baseline"
            >
              {ability && ability.can("update", "Assets") && (
                <Button block type="primary" onClick={UpdateFinancialStatement}>
                  <SaveOutlined />
                  {t("save")}
                </Button>
              )}
            </Space>
          </ConfigProvider>

          <Space>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FFA805",
                },
              }}
            >
              <Link to="/Assets">
                <Button
                  type="primary"
                  style={{
                    textAlign: "right",
                    marginLeft: "flex",
                  }}
                >
                  <InfoCircleOutlined />
                  {t("backtofinancialstatement")}
                </Button>
              </Link>
            </ConfigProvider>

            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FF0606",
                },
              }}
            >
              {ability && ability.can("delete", "Assets") && (
                <Button
                  type="primary"
                  style={{
                    textAlign: "right",
                    marginLeft: "flex",
                  }}
                  onClick={() => setOpen(true)}
                >
                  <DeleteOutlined />
                  {t("deletStatement")}
                </Button>
              )}
              <Modal
                title={t("Deletethestatment")}
                showIcon
                closable
                description={t("Areyousuretodeletethisone")}
                centered
                open={open}
                onOk={() => {
                  setOpen(false);
                  DeleteFinancialStatement(false);
                  handleredirection();
                }}
                okText={t("yes")}
                cancelText={t("Cancel")}
                onCancel={() => setOpen(false)}
                width={400}
              >
                <span>{t("Areyousuretodeletethisone")} </span>
              </Modal>
            </ConfigProvider>
          </Space>
        </Row>
        <br></br>

        {/* <Table columns={columns} dataSource={ChartofAccounts} bordered /> */}
        <Tabs
          style={{ marginBottom: 32, Color: "#059BFF" }}
          type="card"
          centered
          defaultActiveKey="1"
          items={itemsForm}
          onChange={onChangee}
        />
        <div>
          <Tabs
            style={{ marginBottom: 32, Color: "#059BFF" }}
            type="card"
            centered
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
          />
        </div>
      </Card>
    </div>
  );
}

AssetDetail.acl = {
  subject: "Assets",
  action: "read",
};

export default AssetDetail;
