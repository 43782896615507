import {
  DollarTwoTone,
  InfoCircleOutlined,
  UploadOutlined,
  EyeFilled,
} from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Row, Space, Table, Modal } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useAbility } from "../../contexts/abilityContext";

const onChangee = (date, dateString) => {
  console.log(date, dateString);
};

function Equity() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { Company } = useContext(CompanyContext);
  const [year, setYear] = useState(null);
  const [equity, setequity] = useState(null);
  const [form2] = Form.useForm();
  const { ability } = useAbility();
  const [open, setOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };
  var date;
  useEffect(() => {
    console.log("year" + date);
    getEquities();
  }, [Company.id, year]);
  const onChangeyear = (date, dateString) => {
    setequity(null);
    console.log(date, dateString);
    setYear(dateString);
  };

  const columns = [
    {
      title: `${t("glnumber")}`,
      // dataIndex: "glAccountId",
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.chartAccount.glAccount.glNumber}
        </div>
      ),
    },
    {
      title: `${t("Class")}`,
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {/* {record.financialStatementType.financialStatementCategory.financialStatementClass.label} */}
          {i18n.language === "en"
            ? record.financialStatementClass.label
            : record.financialStatementClass.labelFr}
        </div>
      ),
    },
    {
      title: t("Category"),
      align: "center",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {i18n.language === "en"
            ? record.financialStatementCategory.label
            : record.financialStatementCategory.labelFr}
        </div>
      ),
    },

    {
      title: `${t("Gifi")}`,
      // dataIndex: "financialStatementTypeId",
      align: "center",
      // render: (text) => <a>{text}</a>,
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.financialStatementType.gifi}
        </div>
      ),
    },
    {
      title: "Description",
      // dataIndex: "note",
      align: "center",
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.chartAccount.glAccount.description)}>
          {record.chartAccount.glAccount.description}
        </div>
      ),
    },
    {
      title: "Actions",
      align: "center",

      render: (_, record) => {
        return (
          <Link
            to={{
              pathname: `/equityDetail/${record.id}`,
              state: { stateParam: record.id },
            }}
          >
            <Button type="link" icon={<EyeFilled />}>
              {t("details")}
            </Button>
          </Link>
        );
      },
    },
  ];
  const getEquities = async () => {
    if (year == null) {
      await axios
        .get(`${process.env.REACT_APP_JSON_API}/Equity/filter/${Company.id}`)
        .then((res) => {
          console.log(res);
          setequity(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(`${process.env.REACT_APP_JSON_API}/Equity/filter/${Company.id}?year=${year}`)
        .then((res) => {
          console.log(res);
          setequity(res.data);
          console.log(equity);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={
        <h3 className="font-semibold">
          <DollarTwoTone /> {t("equity")}
        </h3>
      }
    >
      <Row
        justify="space-between"
        style={{
          display: "flex",
          marginBottom: 24,
          marginLeft: "flex",
        }}
      >
        <Space
          style={{
            display: "flex",
            marginLeft: "flex",
          }}
          align="baseline"
        >
          <DatePicker
            name="year"
            picker="year"
            placeholder={t("Selectyear")}
            style={{ width: 350, height: 35 }}
            onChange={onChangeyear}
          />
        </Space>

        <Space>
          <Link
            to={{
              pathname: `/summaryequity`,
            }}
          >
            <Button
              className="Create-button"
              type="primary"
              style={{
                textAlign: "right",
                marginLeft: "2rem",
              }}
            >
              <InfoCircleOutlined />
              {t("Summary")}
            </Button>
          </Link>

          {/* {ability && ability.can("create", "Retained Earnings") && (
            <Button
              className="Create-button"
              type="primary"
              // disabled
              style={{
                textAlign: "right",
                marginLeft: 5,
              }}
              onClick={() => {
                // setOpenupdate(true);
              }}
            >
              {" "}
              <UploadOutlined />
              {t("Upload")}
            </Button>
          )} */}
        </Space>
      </Row>
      <div style={{ overflowX: "auto",backgroundColor:'white' }}>
        <Table columns={columns} dataSource={equity} bordered />
      </div>
      <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
    </Card>
  );
}

Equity.acl = {
  subject: "Retained Earnings",
  action: "read",
};
export default Equity;
