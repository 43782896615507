import {
  PlusCircleFilled,
  CloseCircleFilled,
  SaveFilled,
  EditFilled,
  DeleteFilled,
  EyeFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { useAbility } from "../contexts/abilityContext";
import { JSON_API } from "../services/Constants";

dayjs.extend(customParseFormat);

const { Title } = Typography;
const { TextArea } = Input;

function StrategicPlanning() {
  const { t } = useTranslation();

  const { Company } = useContext(CompanyContext);
  const { ability } = useAbility();
  const [editingRow, setEditingRow] = useState(null);
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState(null);
  const [id, setId] = useState("");
  const [detail, setDetail] = useState("");
  const [type, setType] = useState("");
  const [count, setCount] = useState(2);
  const [targets, setTargets] = useState([]);
  const [year, setYear] = useState(dayjs().year());
  const [achievement, setAchievement] = useState(null);
  const [open, setOpen] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);

  }
  var date;
  useEffect(() => {
    date = new Date().getFullYear();

    console.log("year:", year);
    displayTargets();
    getDepartments();
  }, [Company.id, year]);

  const onChangeYear = (date, dateString) => {
    console.log(date.year());
    setYear(date.year());
  };

  const getDepartments = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_JSON_API}/EnterpriseDepartments/${Company.id}/${year}`
      );
      setDepartments(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const displayTargets = async () => {
    try {
      const targetRes = await axios.get(
        `${process.env.REACT_APP_JSON_API}/StrategicTargets/enterprise/${Company.id}/${year}`
      );
      setTargets(targetRes.data);
      const achievementRes = await axios.get(
        `${process.env.REACT_APP_JSON_API}/StrategicTargets/achievement/${Company.id}/${year}`
      );
      setAchievement(achievementRes.data);
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishEdit = async (values) => {
    const target = {
      id: editingRow,
      type: values.type,
      details: values.details,
    };

    try {
      await axios.put(`${process.env.REACT_APP_JSON_API}/StrategicTargets`, target);
      displayTargets();
      message.success(t("targeteditedsuccessfully"));
    } catch (error) {
      console.error(error);
      message.error(t("AnErrorOccurredWhileEditingTheTarget"));
    }

    setEditingRow(null);
  };

  const handleDelete = async (e) => {
    try {
      await axios.delete(`${process.env.REACT_APP_JSON_API}/StrategicTargets/${e}`);
      message.success(t("TargetDeletedSuccessfully"));
      displayTargets();
    } catch (err) {
      console.error(err.response.status);
      message.error(t("AnErrorOccurredWhileDeletingTheTarget"));
      if (err.response.status === 400) {
        message.warning(t("ThisTargetContainsUnfinishedSteps"));
      }
      displayTargets();
    }
  };

  const submitTarget = async (e) => {
    const formData = {
      year: year.toString(),
      type: e.type,
      num: e.num,
      details: e.details,
      enterpriseId: Company.id,
    };

    try {
      await axios.post(`${process.env.REACT_APP_JSON_API}/StrategicTargets`, formData);
      displayTargets();
      message.success(t("TargetAddedSuccessfully"));
    } catch (error) {
      console.error(error);
      message.error(t("AnErrorOccurredWhileAddingTheTarget"));
    }
    setOpen(false);
  };
  

  const columns = [
    {
      title: t("Type"),
      dataIndex: "type",
      align: "center",
      render: (text, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: t("pleaseinputthetype"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        } else {
          return <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(text)}>{text}</div>;
        }
      },
    },
    {
      title: t("detail"),
      dataIndex: "details",
      align: "center",
      width: 500,
      render: (text, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item name="details">
              <TextArea rows={2}></TextArea>
            </Form.Item>
          );
        } else {
          return <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(text)}>{text}</div>;
        }
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      render: (_, { status }) => {
        switch (status) {
          case 0:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#9d0208"}
              >
                {t("NotStarted")}
              </Tag>
            );
          case 25:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#ee9b00"}
              >
                {t("LowProgress")}
              </Tag>
            );
          case 50:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#f77f00"}
              >
                {t("MeduimProgress")}
              </Tag>
            );
          case 75:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#6096ba"}
              >
                {t("HighProgress")}
              </Tag>
            );
          case 100:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"#40916c"}
              >
                {t("Done")}
              </Tag>
            );
          default:
            return (
              <Tag
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                color={"red"}
              >
                {t("NotStarted")}
              </Tag>
            );
        }
      },
    },
    {
      title: t("progression"),
      dataIndex: "progress",
      align: "center",
      render: (_, { progress }) => {
        return (
          <Progress type="circle" percent={parseInt(progress, 10)} width={60} />
        );
      },
    },
  ];

  if (!ability.can("read", "Strategic Planning")) {
    columns.push({
      title: t("Actions"),
      key: "operation",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          {editingRow === record.id ? (
            <>
              <Button
                type="link"
                danger
                icon={<CloseCircleFilled />}
                onClick={() => setEditingRow(null)}
              >
                {t("cancel")}
              </Button>
              <Button type="link" icon={<SaveFilled />} htmlType="submit">
                {t("save")}
              </Button>
            </>
          ) : (
            <>
              {ability.can("update", "Strategic Planning") && (
                <Button
                  type="link"
                  onClick={() => {
                    setEditingRow(record.id);
                    form.setFieldsValue({
                      details: record.details,
                      type: record.type,
                    });
                  }}
                  icon={<EditFilled />}
                >
                  {t("edit")}
                </Button>
              )}

              {ability.can("delete", "Strategic Planning") && (
                <Popconfirm
                  title={t("Are you sure you want to delete this?")}
                  onConfirm={() => handleDelete(record.id)}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button type="link" danger icon={<DeleteFilled />}>
                    {t("Delete")}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </Space>
      ),
    });
  }

  if (
    ability.can("update", "Strategic Planning") ||
    ability.can("delete", "Strategic Planning")
  ) {
    columns.push({
      title: t("Actions"),
      key: "operation",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          {editingRow === record.id ? (
            <>
              <Button
                type="link"
                danger
                icon={<CloseCircleFilled />}
                onClick={() => setEditingRow(null)}
              >
                {t("cancel")}
              </Button>
              <Button type="link" icon={<SaveFilled />} htmlType="submit">
                {t("save")}
              </Button>
            </>
          ) : (
            <>
              {ability.can("update", "Strategic Planning") && (
                <Button
                  type="link"
                  onClick={() => {
                    setEditingRow(record.id);
                    form.setFieldsValue({
                      details: record.details,
                      type: record.type,
                    });
                  }}
                  icon={<EditFilled />}
                >
                  {t("edit")}
                </Button>
              )}

              {ability.can("delete", "Strategic Planning") && (
                <Popconfirm
                  title={t("Are you sure you want to delete this?")}
                  onConfirm={() => handleDelete(record.id)}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button type="link" danger icon={<DeleteFilled />}>
                    {t("Delete")}
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </Space>
      ),
    });
  }

  const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
    const [form] = Form.useForm();

    return (
      <Modal
        onClick={submitTarget}
        open={open}
        title={t("CreateanewStratigicTarget")}
        okText={t("create")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.error("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="type"
            label={t("Type")}
            rules={[
              {
                required: true,
                message: t("pleaseinputthetype"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="details" label={t("detail")}>
            <TextArea rows={4}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <>
      <Card bordered={false} className="header-solid mb-24 modern-card">
        <div className="card-header">
          <h3 className="font-semibold">{t("strategicplanning")}</h3>
        </div>

        <Row gutter={24} className="control-row">
          <Col span={8} className="date-picker-column">
            <DatePicker
              defaultValue={dayjs(date)}
              onChange={onChangeYear}
              picker="year"
              size="large"
            />
            {ability.can("create", "Strategic Planning") && (
              <Button
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => setOpen(true)}
              >
                {t("newtarget")}
              </Button>
            )}
            <CollectionCreateForm
              open={open}
              onCreate={submitTarget}
              onCancel={() => setOpen(false)}
            />
          </Col>
        </Row>

        <Progress
          className="achievement-progress"
          percent={achievement ? parseInt(achievement, 10) : 0}
          strokeColor={{ "0%": "#108ee9", "100%": "#FFDE00" }}
        />

        <Title level={4} className="strategic-targets-title">
          {t("strategictargets")}
        </Title>

        {year != null && (
          <>
            <Form form={form} onFinish={onFinishEdit} className="targets-form">
              <div style={{ overflowX: "auto" }}>
              <Table
                locale={{
                  emptyText: <Empty description={t("nodata")} />,
                }}
                bordered
                columns={columns}
                dataSource={targets}
                scroll={{ x: 1200, y: 500 }}
              />

              </div>
              <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
            </Form>

            <Title level={4} className="department-title">
              {t("Departement")}
            </Title>

            <Space size={16} wrap className="department-cards">
              {departments &&
                departments.map((d) => (
                  <Card
                    extra={
                      <Link
                        to={{
                          pathname: `/strategicplans/${d.id}/${year}`,
                          state: { year: year, departmentId: d.id },
                        }}
                      >
                        <Button type="link" icon={<EyeFilled />}>
                          {t("detail")}
                        </Button>
                      </Link>
                    }
                    title={d.label}
                    type="inner"
                    className="department-card"
                  >
                    {t("progression")}
                    <Progress
                      style={{ marginLeft: "6.6rem" }}
                      type="circle"
                      percent={parseInt(d.strategicProgress, 10)}
                      width={70}
                    />
                  </Card>
                ))}
            </Space>
          </>
        )}
      </Card>
    </>
  );
}

StrategicPlanning.acl = {
  subject: "Strategic Planning",
  action: "read",
};

export default StrategicPlanning;
