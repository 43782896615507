import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import {
  Card,
  Breadcrumb,
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Divider,
  Typography,
  notification,
  message,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { JSON_API } from "../services/Constants";
import { useTranslation } from "react-i18next";
import {
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  SecurityScanOutlined,
  ArrowLeftOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { CompanyContext } from "../contexts/CompanyContext";
const { Option } = Select;

const EditIntegrator = () => {
  let { t } = useTranslation();
  const history = useHistory();
  const { id: integratorId } = useParams();
  const [form] = Form.useForm();
  const integrator = history.location.state.integrator;

  useEffect(() => {
    const [firstName, lastName] = integrator.integratorFullName.split(" ");

    form.setFieldsValue({
      email: integrator.email,
      phoneNumber: integrator.phoneNumber,
      firstName: firstName,
      lastName: lastName,
      joinDate: moment(integrator.joinDate),
      departureDate: moment(integrator.departureDate),
    });
  }, [form, integrator]);

  const onFinish = async (values) => {
    const { email, phoneNumber, firstName, lastName, joinDate, departureDate } =
      values;

    const integratorToUpdate = {
      integratorId: integrator.integratorId,
      accountId: integrator?.id,
      email,
      phoneNumber,
      firstName,
      lastName,
      joinDate,
      departureDate,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_JSON_API}/IntegratorAccounts/${integratorToUpdate.integratorId}/${integratorToUpdate.accountId}`,
        integratorToUpdate
      );

      if (response.status === 200) {
        message.success(t("IntegratorAccountUpdated"));
        //history.push("/integrators");
      }
    } catch (error) {
      message.error(t("AnErrorOccuredWhileUpdatingIntegratorAccount"));
      console.log(error);
    }
  };

  return (
    <Card>
      <Breadcrumb
        style={{
          marginBottom: "1rem",
          fontSize: "1.2rem",
          textShadow: "1px 1px 1px #aaa",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/users">
            <Typography.Text>
              <AppstoreAddOutlined /> {t("Users")}
            </Typography.Text>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/update-integrator-account">
            <Typography.Text>
              <EditOutlined /> {t("UpdateEmployeeAccount")}
            </Typography.Text>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        name="update"
        style={{ margin: "0 auto", maxWidth: "1000px" }}
      >
        <Typography.Title
          level={2}
          style={{ textAlign: "center", marginBottom: "2rem" }}
        >
          {t("UpdateAccount")}
        </Typography.Title>

        <Divider orientation="left">{t("IntegratorInfos")}</Divider>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item name="firstName" label="FirstName">
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="lastName" label={t("LastName")}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item name="joinDate" label="Join Date">
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("SelectDate")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="departureDate" label={t("departureDate")}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder={t("SelectDate")}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("IntegratorInfos")}</Divider>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item name="email" label="Email">
              <Input type="email" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="phoneNumber" label={t("PhoneNumber")}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="center">
          <Col xs={24} sm={8}>
            <Form.Item>
              <Link to="/integrators">
                <Button type="primary" danger block>
                  <ArrowLeftOutlined /> {t("GoBack")}
                </Button>
              </Link>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                <EditOutlined /> {t("UpdateAccount")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default EditIntegrator;
