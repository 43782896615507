import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CheckSquareFilled,
  MinusSquareFilled,
} from "@ant-design/icons";
import {
  Space,
  message,
  Button,
  Card,
  Checkbox,
  Col,
  Row,
  Form,
  Input,
  Divider,
  Typography,
  Select,
  Collapse,
} from "antd";
import { Link } from "react-router-dom";
import { JSON_API } from "../services/Constants";

const { Panel } = Collapse;

const CreatePlan = () => {
  let { t } = useTranslation();
  const [form] = Form.useForm();
  const [sections, setSections] = useState([]);
  const history = useHistory();

  const onFinish = (values) => {
    const checkedSections = [];

    sections.forEach((section) => {
      if (section.checked) {
        checkedSections.push({ sectionId: section.id, included: true });
      }

      if (section.subSections) {
        section.subSections.forEach((subSection) => {
          if (subSection.checked) {
            checkedSections.push({ sectionId: subSection.id, included: true });
          }
        });
      }
    });

    // Construct the payload for the API request
    const payload = {
      label: values.label,
      description: values.description,
      currentPrice: parseFloat(values.standardPrice),
      monthly: values.frequency === "monthly",
      includesWhiteLabeling: values.includesWhiteLableing,
      standardUsersCount: parseInt(values.standardUsers, 10),
      costPerExtraUser: parseFloat(values.costPerExtraUser),
      createdAt: new Date().toISOString(),
      sections: checkedSections,
    };

    axios
      .post(`${process.env.REACT_APP_JSON_API}/Plans`, payload)
      .then((response) => {
        console.log(response.data);
        message.success(t("planCreatedSuccessfully"));
        form.resetFields();
        //history.push("/plans");
      })
      .catch((error) => {
        console.error(error);
        message.error(t("errorCreatingPlan"));
      });
  };

  const getParentWithSubsection = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_JSON_API}/sections`);

      if (response.status === 200) {
        const sections = response.data;

        const parentWithSubsection = sections.map((section) => {
          const { id, label, parentSectionId, subSections } = section;

          // Creer la liste de sous-sections avec les propriétés nécessaires et une propriété "checked" initialisée à false
          const subSectionsWithCheckbox = subSections.map((subSection) => ({
            ...subSection,
            checked: false,
          }));

          // Creer la section parent avec les propriétés nécessaires, la propriété "checked" initialisée à false et les sous-sections
          return {
            id,
            label,
            parentSectionId,
            checked: false,
            subSections: subSectionsWithCheckbox,
          };
        });

        return parentWithSubsection;
      } else {
        console.error(
          `Erreur lors de la récupération des sections : ${response.status}`
        );
      }
    } catch (error) {
      console.error(`Erreur lors de la récupération des sections : ${error}`);
    }
  };

  useEffect(() => {
    getParentWithSubsection().then(setSections);
  }, []);

  // const handleAllSubSectionsCheck = (parentId, isChecked) => {
  //   const newSections = [...sections];
  //   const parentIndex = newSections.findIndex(
  //     (section) => section.id === parentId
  //   );

  //   if (parentIndex > -1) {
  //     newSections[parentIndex].subSections = newSections[
  //       parentIndex
  //     ].subSections.map((subSection) => {
  //       return {
  //         ...subSection,
  //         checked: isChecked,
  //       };
  //     });
  //   }

  //   setSections(newSections);
  // };

  const handleParentCheck = (id, checked) => {
    setSections(
      sections.map((section) => {
        if (section.id === id) {
          return {
            ...section,
            checked,
            subSections: section.subSections.map((subSection) => ({
              ...subSection,
              checked,
            })),
          };
        }
        return section;
      })
    );
  };

  // const handleSubSectionCheck = (parentId, subSectionId, checked) => {
  //   setSections(
  //     sections.map((section) => {
  //       if (section.id === parentId) {
  //         return {
  //           ...section,
  //           subSections: section.subSections.map((subSection) =>
  //             subSection.id === subSectionId
  //               ? { ...subSection, checked }
  //               : subSection
  //           ),
  //         };
  //       }
  //       return section;
  //     })
  //   );
  // };

  const handleSelectAll = () => {
    const updatedSections = sections.map((section) => {
      return {
        ...section,
        checked: true,
        subSections: section.subSections.map((subSection) => {
          return {
            ...subSection,
            checked: true,
          };
        }),
      };
    });
    setSections(updatedSections);
  };

  const handleUnselectAll = () => {
    const updatedSections = sections.map((section) => {
      return {
        ...section,
        checked: false,
        subSections: section.subSections.map((subSection) => {
          return {
            ...subSection,
            checked: false,
          };
        }),
      };
    });
    setSections(updatedSections);
  };

  return (
    <Card
      title={
        <span style={{ fontSize: "16px" }}>
          <AppstoreOutlined /> {t("CreatePlan")}
        </span>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        name="register"
        style={{ margin: "0 auto", maxWidth: "1000px" }}
      >
        <Typography.Title
          level={2}
          style={{ textAlign: "center", marginBottom: "2rem" }}
        >
          {t("CreateANewPlan")}
        </Typography.Title>

        <Row
          align="middle"
          justify="space-between"
          gutter={[16, 16]}
          style={{
            marginBottom: "1.5rem",
            background: "#deebff",
            padding: "1rem",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
          }}
        >
          <Col>
            <Divider
              orientation="left"
              style={{ color: "#333", textTransform: "uppercase" }}
            >
              {t("PlanGeneralInfos")}
            </Divider>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              name="label"
              label={t("Label")}
              rules={[{ required: true, message: t("PleaseInputTheLabel") }]}
            >
              <Input placeholder={t("EnterLabel")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="standardPrice"
              label={t("standardPrice")}
              rules={[
                { required: true, message: t("PleaseInputTheStandardPrice") },
              ]}
            >
              <Input placeholder={t("EnterStandardPrice")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="includesWhiteLableing"
              label={t("IncludesWhiteLableing")}
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              name="frequency"
              label={t("Frequency")}
              rules={[
                { required: true, message: t("PleaseSelectTheFrequency") },
              ]}
            >
              <Select placeholder={t("SelectFrequency")}>
                <Select.Option value="monthly">{t("Monthly")}</Select.Option>
                <Select.Option value="yearly">{t("Yearly")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="costPerExtraUser"
              label={t("CostPerExtraUser")}
              rules={[
                {
                  required: true,
                  message: t("PleaseInputTheCostPerExtraUser"),
                },
              ]}
            >
              <Input type="number" placeholder={t("EnterCostPerExtraUser")} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item
              name="standardUsers"
              label={t("StandardUsers")}
              rules={[
                { required: true, message: t("PleaseInputTheStandardUsers") },
              ]}
            >
              <Input type="number" placeholder={t("EnterStandardUsers")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item name="description" label={t("Description")}>
              <Input.TextArea placeholder={t("EnterDescription")} />
            </Form.Item>
          </Col>
        </Row>

        <Row
          align="middle"
          justify="space-between"
          gutter={[16, 16]}
          style={{
            marginBottom: "1.5rem",
            background: "#deebff",
            padding: "1rem",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
          }}
        >
          <Col>
            <Divider
              orientation="left"
              style={{ color: "#333", textTransform: "uppercase" }}
            >
              {t("PlanModules")}
            </Divider>
          </Col>
          <Col>
            <Space>
              <Button
                onClick={handleSelectAll}
                type="primary"
                icon={<CheckSquareFilled />}
                style={{
                  background: "linear-gradient(45deg, #007bff, #0056b3)",
                  borderColor: "#0056b3",
                }}
              >
                {t("SelectAll")}
              </Button>

              <Button
                onClick={handleUnselectAll}
                type="danger"
                icon={<MinusSquareFilled />}
                style={{
                  background: "linear-gradient(45deg, #ff4d4f, #b32931)",
                  borderColor: "#b32931",
                  color: "#fff",
                }}
              >
                {t("UnselecteAll")}
              </Button>
            </Space>
          </Col>
        </Row>

        <Row gutter={[24, 24]}>
          {sections.map((section) => (
            <Col key={section.id} span={6}>
              <Card
                style={{
                  width: "100%",
                  borderRadius: "15px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  overflow: "hidden",
                  marginBottom: "20px",
                }}
                hoverable
              >
                <Row justify="space-between" align="middle">
                  <Col>
                    <Typography.Text strong>{section.label}</Typography.Text>
                  </Col>
                  <Col>
                    <Checkbox
                      checked={section.checked}
                      onChange={(e) =>
                        handleParentCheck(section.id, e.target.checked)
                      }
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>

        <Row
          gutter={16}
          justify="center"
          style={{
            marginTop: "2rem",
          }}
        >
          <Col xs={24} sm={8}>
            <Form.Item>
              <Link to="/plans">
                <Button type="primary" danger block>
                  <ArrowLeftOutlined /> {t("GoBack")}
                </Button>
              </Link>
            </Form.Item>
          </Col>

          <Col xs={24} sm={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                <CheckCircleOutlined /> {t("Create")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
CreatePlan.acl = {
  subject: "Plans",
  action: "create",
};
export default CreatePlan;
