import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import {
  Typography,
  Card,
  Tag,
  Table,
  Space,
  Button,
  Menu,
  Dropdown,
} from "antd";
import {
  UsergroupAddOutlined,
  PlusSquareOutlined,
  EditOutlined,
  UnlockOutlined,
  DownOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useAbility } from "../contexts/abilityContext";

const { Text, Title } = Typography;

const Integrators = () => {
  let { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { Company } = useContext(CompanyContext);
  const [integratorAccounts, setIntegratorAccounts] = useState([]);
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Accounts");

  const getIntegratorsAccounts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_JSON_API}/IntegratorAccounts`);
      setIntegratorAccounts(response.data);
      setLoading(true);
    } catch (error) {
      console.log(error);
      message.error(t("ErrorFetchingIntegratorAccounts"));
      setLoading(false);
    }
  };

  useEffect(() => {
    getIntegratorsAccounts();
  }, []);

  const handleDeactivate = async (accountId) => {
    console.log("Attempting to deactivate account:", accountId);
    const url = `${process.env.REACT_APP_JSON_API}/IntegratorAccounts/deactivate/${accountId}`;
    console.log("Deactivation URL:", url);

    try {
      const response = await axios.put(url);
      if (response.status === 204) {
        message.success(t("IntegratorAccountDeactivated"));
        console.log(response.data);
        getIntegratorsAccounts();
      }
    } catch (error) {
      message.error(t("AnErrorOccuredWhileDeactivatingIntegratorAccount"));
      console.log(error);
    }
  };

  const handleActivate = async (accountId) => {
    console.log("Attempting to activate account:", accountId);
    const url = `${process.env.REACT_APP_JSON_API}/IntegratorAccounts/activate/${accountId}`;
    console.log("activation URL:", url);

    try {
      const response = await axios.put(url);
      if (response.status === 204) {
        message.success(t("IntegratorAccountactivated"));
        console.log(response.data);
        getIntegratorsAccounts();
      }
    } catch (error) {
      message.error(t("AnErrorOccuredWhileactivatingIntegratorAccount"));
      console.log(error);
    }
  };

  const handleUpdateIntegratorAccount = (record) => {
    console.log(record);
    history.push({
      pathname: `/update-integrator-account/${record.integratorId}/${record.id}`,
      state: { integrator: record },
    });
  };

  const columns = [
    {
      title: t("IntegratorName"),
      dataIndex: "integratorFullName",
      key: "integratorFullName",
      align: "center",
      render: (integratorFullName) => (
        <strong style={{ textTransform: "uppercase" }}>
          {integratorFullName}
        </strong>
      ),
    },

    {
      title: t("DateJoined"),
      dataIndex: "joinDate",
      key: "joinDate",
      align: "center",
      render: (joinDate) =>
        joinDate ? moment(joinDate).format("DD/MM/YYYY") : null,
    },
    {
      title: t("DepartureDate"),
      dataIndex: "departureDate",
      key: "departureDate",
      align: "center",
      render: (departureDate) => {
        if (
          !departureDate ||
          departureDate === "0001-01-01T00:00:00" ||
          moment(departureDate).format("DD/MM/YYYY") === "01/01/0001"
        ) {
          return (
            <Tag
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "5px",
                padding: "5px 10px",
                textAlign: "center",
              }}
              color="red"
            >
              {t("NotYetDeparted")}
            </Tag>
          );
        } else {
          return moment(departureDate).format("DD/MM/YYYY");
        }
      },
    },

    {
      title: t("Email"),
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: t("PhoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
    },
    {
      title: t("LastLogin"),
      dataIndex: "lastLogin",
      key: "lastLogin",
      align: "center",
      render: (lastLogin) =>
        lastLogin ? (
          moment(lastLogin).format("DD/MM/YYYY HH:mm")
        ) : (
          <Tag
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
            }}
            color="red"
          >
            {t("NotYetConnected")}
          </Tag>
        ),
    },
    {
      title: t("AccountStatus"),
      dataIndex: "active",
      key: "active",
      align: "center",
      render: (active) =>
        active ? (
          <Tag
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            color="green"
          >
            {t("Activated")}
          </Tag>
        ) : (
          <Tag
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            color="red"
          >
            {t("Inactive")}
          </Tag>
        ),
    },
  ];

  if (canUpdate) {
    columns.push({
      title: t("Actions"),
      key: "actions",
      align: "center",
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              {canUpdate && (
                <Menu.Item
                  key="1"
                  icon={<EditOutlined />}
                  onClick={() => handleUpdateIntegratorAccount(record)}
                >
                  {t("Update")}
                </Menu.Item>
              )}
              {record.active
                ? canUpdate && (
                    <Menu.Item
                      key="2"
                      icon={<UnlockOutlined />}
                      danger
                      onClick={() => handleDeactivate(record.id)}
                    >
                      {t("Desactivate")}
                    </Menu.Item>
                  )
                : canUpdate && (
                    <Menu.Item
                      key="2"
                      icon={<UnlockOutlined />}
                      danger
                      onClick={() => handleActivate(record.id)}
                    >
                      {t("Activate")}
                    </Menu.Item>
                  )}
            </Menu>
          }
          trigger={["click"]}
        >
          <Button type="link" onClick={(e) => e.preventDefault()}>
            {t("Actions")} <DownOutlined />
          </Button>
        </Dropdown>
      ),
    });
  }

  return (
    <Card
      title={
        <span style={{ fontSize: "16px" }}>
          <UsergroupAddOutlined /> {t("Integrators")}
        </span>
      }
    >
      <div style={{ textAlign: "center", marginBottom: 15 }}>
        <h1>{t("Integrators")}</h1>
      </div>

      <Space style={{ marginBottom: 20, float: "right" }}>
        {ability && ability.can("create", "Accounts") && (
          <Link to="/addIntegrator">
            <Button className="custom-btn" size="medium" type="primary">
              <PlusCircleFilled /> {t("Create")}
            </Button>
          </Link>
        )}
      </Space>
      <Table dataSource={integratorAccounts} columns={columns} style={{ overflowX: "auto",backgroundColor:'white' }} />
    </Card>
  );
};
Integrators.acl = {
  subject: "Accounts",
  action: "read",
};

export default Integrators;
