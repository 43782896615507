import { useState } from "react";

import { Card, Col, Row } from "antd";

import axios from "axios";
import { useContext, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ColChart from "../components/chart/ColChart";
import LineChart from "../components/chart/LineChart";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

function InvestmentDashboard() {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [Dashboard, setDashboard] = useState({});
  const [YearBudgetDashboard, setYearBudgetDashboard] = useState([]);
  const [BudgetDashboard, setBudgetDashboard] = useState([]);
  const [RealDashboard, setRealDashboard] = useState([]);
  const [LabelDashboard, setLabelDashboard] = useState([]);
  const [ProductDashboard, setProductDashboard] = useState([]);
  const [RevenueRealDashboard, setRevenueRealDashboard] = useState([]);
  const [RevenuebudgetDashboard, setRevenuebudgetDashboard] = useState([]);
  const [RevenuerealDashboard, setRevenuerealDashboard] = useState([]);
  const [RevenueDashboard, setRevenueDashboard] = useState([]);
  const [startPeriod, setstartPeriod] = useState(1);

  useEffect(() => {
    getDashboard();
    console.log(Dashboard);
    getStartPeriode();
  }, [Company.id]);
  const getStartPeriode = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Enterprises/main-info`)
      .then((res) => {
        const filteredData = res.data.filter((item) => item.id === Company.id);

        if (filteredData.length > 0) {
          setstartPeriod(filteredData[0].startPeriod);
        } else {
          // Handle case when no matching item is found
          setstartPeriod(1); // Or any other default value
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDashboard = async () => {
    const array = [];
    const arraybudget = [];
    const arrayreal = [];
    const arrayLabel = [];
    const arrayProduct = [];
    const arrayRevenueReal = [];
    const arrayrevenuebudget = [];
    const arrayrevenuereal = [];
    const arrayrevenue = [];
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Investments/InvestmentDashboard/${Company.id}`)
      .then((res) => {
        console.log("test", res.data);
        setDashboard(res.data);
        res.data.investmentByCategory.map((e) => {
          arrayLabel.push(e.label);
          arrayProduct.push(e.value);
          console.log(array);
        });
        res.data.investmentByYear.map((e) => {
          array.push(e.year);
          arraybudget.push((e.budget / 1000).toFixed(2));
          arrayreal.push((e.real / 1000).toFixed(2));
          console.log(array);
        });
        /*res.data.currentYearRevenue.map(e=>{
          arrayRevenueReal.push((e.firstreal * 100 /(e.firstbudget)).toFixed(2));
          arrayRevenueReal.push((e.finalreal * 100 /(e.finalbudget)).toFixed(2));
          arrayRevenueReal.push(((e.firstreal + e.finalreal) * 100 /(e.firstbudget + e.finalbudget)).toFixed(2));
          console.log(array);
        })*/
        console.log(res.data.currentTime);
        var i = 0;
        res.data.investmentByMonths.map((e) => {
          i++;
          arrayrevenuebudget.push((e.budget / 1000).toFixed(2));
          if (res.data.currentTime <= i) {
            arrayrevenue.push((e.real / 1000).toFixed(2));
            arrayrevenuereal.push(0);
          } else {
            arrayrevenuereal.push((e.real / 1000).toFixed(2));
            arrayrevenue.push(0);
          }
          console.log(array);
        });
        setYearBudgetDashboard(array);
        setBudgetDashboard(arraybudget);
        setRealDashboard(arrayreal);
        setLabelDashboard(arrayLabel);
        setProductDashboard(arrayProduct);
        //setRevenueRealDashboard(arrayRevenueReal);
        setRevenuebudgetDashboard(arrayrevenuebudget);
        setRevenuerealDashboard(arrayrevenuereal);
        setRevenueDashboard(arrayrevenue);
        console.log(
          RevenueDashboard,
          RevenueRealDashboard,
          RevenuebudgetDashboard
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const months = [
    `${t("January")}`,
    `${t("February")}`,
    `${t("March")}`,
    `${t("April")}`,
    `${t("May")}`,
    `${t("June")}`,
    `${t("July")}`,
    `${t("August")}`,
    `${t("September")}`,
    `${t("October")}`,
    `${t("November")}`,
    `${t("December")}`,
  ];

  // Réorganiser les mois en fonction de la période de début
  const reorderedMonths = [
    ...months.slice(startPeriod - 1), // Commencez par le mois de la période de début
    ...months.slice(0, startPeriod - 1), // Puis ajoutez les mois avant la période de début
  ];
  const Chart = {
    series: [
      {
        name: `${t("Budget")}`,
        data: BudgetDashboard,
      },
      {
        name: `${t("RealEstimate")}`,
        data: RealDashboard,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: YearBudgetDashboard,
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"} ` + val + ` ${t("thousands")}`;
          },
        },
      },
    },
  };
  const BudgetChart = {
    series: [
      {
        name: "values",
        data: RevenueRealDashboard,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          `${t("Real")}`,
          `${t("Estimate")}`,
          `${t("budgetatteint")}`,
        ],
      },
      yaxis: {
        title: {
          text: `${t("Pourcentage")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "% " + val;
          },
        },
      },
    },
  };
  const oldBudgetChart = {
    series: [
      {
        name: "Réel",
        data: [450, 50, 550],
        color: "#fff",
      },
    ],

    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",

        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      xaxis: {
        categories: ["Réel", "Estimé", "%budget atteint"],
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#fff", "#fff", "#fff"],
          },
        },
      },
      yaxis: {
        title: {
          text: `${t("Pourcentage")}`,
          style: { color: "white" },
        },
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#fff", "#fff", "#fff"],
          },
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    },
  };

  const ProductChart = {
    series: ProductDashboard,
    options: {
      chart: {
        width: "100%",
        type: "pie",
      },
      labels: LabelDashboard,
      legend: {
        position: "left",
        itemMargin: {
          horizontal: 5,
          vertical: 10,
        },
        formatter: function (val, opts) {
          const index = opts.seriesIndex;
          const percentage = (
            (opts.w.globals.series[index] /
              opts.w.globals.seriesTotals.reduce((a, b) => a + b, 0)) *
            100
          ).toFixed(2);
          return `${val} (${percentage}%)`;
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return val > 5 ? `${val.toFixed(2)}%` : "";
        },
        style: {
          fontSize: "10px",
          colors: ["#ffffff"],
        },
      },
      colors: [
        "#FF6633",
        "#FFB399",
        "#FF33FF",
        "#FFFF99",
        "#00B3E6",
        "#E6B333",
        "#3366E6",
        "#999966",
        "#99E6E6",
        "#66664D",
        "#B34D4D",
        "#80B300",
        "#809900",
        "#E6B3B3",
        "#6680B3",
        "#66991A",
        "#FF99E6",
        "#CCFF1A",
        "#FF1A66",
        "#E6331A",
        "#33FFCC",
        "#66994D",
        "#B366CC",
        "#4D8000",
        "#B33300",
        "#CC80CC",
        "#6666FF",
        "#991AFF",
        "#E666FF",
        "#4DB3FF",
        "#1AB399",
        "#E666B3",
        "#33991A",
        "#CC9999",
        "#B3B31A",
        "#00E680",
        "#4D8066",
        "#809980",
        "#E6FF80",
        "#1AFF33",
        "#999933",
        "#FF3380",
        "#CCCC00",
        "#66E64D",
        "#4D80CC",
        "#9900B3",
        "#E64D66",
        "#4DB380",
        "#FF4D4D",
        "#99E6E6",
        "#6666FF",
      ],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 400,
            },
          },
        },
      ],
    },
  };

  const reorderedRevenuebudgetDashboard = [
    ...RevenuebudgetDashboard.slice(startPeriod - 1), // Commencez par le mois de la période de début
    ...RevenuebudgetDashboard.slice(0, startPeriod - 1), // Puis ajoutez les mois avant la période de début
  ];

  const reorderedRevenuerealDashboard = [
    ...RevenuerealDashboard.slice(startPeriod - 1), // Commencez par le mois de la période de début
    ...RevenuerealDashboard.slice(0, startPeriod - 1), // Puis ajoutez les mois avant la période de début
  ];

  const reorderedRevenueDashboard = [
    ...RevenueDashboard.slice(startPeriod - 1), // Commencez par le mois de la période de début
    ...RevenueDashboard.slice(0, startPeriod - 1), // Puis ajoutez les mois avant la période de début
  ];
  const RealChart = {
    series: [
      {
        name: `${t("Budget")}`,
        data: reorderedRevenuebudgetDashboard,
      },
      {
        name: `${t("Real")}`,
        data: reorderedRevenuerealDashboard,
      },
      {
        name: `${t("Estimate")}`,
        data: reorderedRevenueDashboard,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reorderedMonths,
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"} ` + val + ` ${t("thousands")}`;
          },
        },
      },
    },
  };
  const SalesChart = {
    series: [
      {
        name: `${t("Budget")}`,
        data: [44, 55, 450, 200, 100, 220, 500, 100, 400, 230, 500, 1],
      },
      {
        name: `${t("RealEstimate")}`,
        data: [76, 85, 450, 200, 100, 220, 500, 100, 400, 230, 500, 5],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          `${t("January")}`,
          `${t("February")}`,
          `${t("March")}`,
          `${t("April")}`,
          `${t("May")}`,
          `${t("June")}`,
          `${t("July")}`,
          `${t("August")}`,
          `${t("September")}`,
          `${t("October")}`,
          `${t("November")}`,
          `${t("December")}`,
        ],
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"} ` + val + `${t("thousands")}`;
          },
        },
      },
    },
  };
  return (
    <>
      <div className="layout-content">
        <Row gutter={[8, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("TotalInvestmentPerYear")}
              </h3>
              <ReactApexChart
                options={Chart.options}
                series={Chart.series}
                type="bar"
                height={250}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center", marginBottom: 59 }}>
                {t("InvestmentActivitiesByCategory")}
              </h3>
              <ReactApexChart
                options={ProductChart.options}
                series={ProductChart.series}
                type="pie"
              />
            </Card>
          </Col>
          {/*<Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
                <h3 style={{textAlign:"center"}}>{t("ChiffreDAffaireBudget")+new Date().getFullYear()}</h3>
              <ReactApexChart
              options={BudgetChart.options} 
              series={BudgetChart.series} 
              type="bar" 
              height={250} />
            </Card>
  </Col>*/}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("TotalInvestmentActivitiesPerMonth") +
                  " - " +
                  new Date().getFullYear()}
              </h3>
              <ReactApexChart
                options={RealChart.options}
                series={RealChart.series}
                type="bar"
                height={380}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default InvestmentDashboard;
