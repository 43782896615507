// import { Result } from "antd";
// import React, { useContext, useState } from "react";
// import { useTranslation } from "react-i18next";
// import { Redirect, Route } from "react-router-dom";
// import { CompanyContext } from "../../contexts/CompanyContext";
// import AclGuard from "./AclGuard";

// function RouteGuard({ children, ...rest }) {
//   let { t } = useTranslation();


//   const {
   
//     Company,
  
//   } = useContext(CompanyContext);

//   const [childrenname, setChildrenname] = useState(
//     React.Children.toArray(children).map((x) => x.type.name)
//   );
//   function decodeJwt(token) {
//     try {
//       const base64Url = token.split(".")[1];
//       const base64 = base64Url.replace("-", "+").replace("_", "/");
//       return JSON.parse(atob(base64));
//     } catch (error) {
//       console.error("Error decoding JWT token:", error);
//       return null;
//     }
//   }
//   function hasJWT() {
//     let flag = false;

//     //check user has JWT token
//     console.log("childrenname[0]:", childrenname[0]);
//     // localStorage.getItem("token") ? (flag = true) : (flag = false);

//     if (localStorage.getItem("token")) {
//       flag = true
//       const token = localStorage.getItem("token");

//       const decodedToken = decodeJwt(token);

//       console.log("decodedToken:", decodedToken);
//       // if (!decodedToken.FullName) {
//       //   CustomButton();
//       // } else {
//       //   setUserName(decodedToken.FullName);
//       // }
//     }else{
//       flag = false
//     }
//     // console.log("token:", flag);
//     return flag;
//   }


  
//     return (
//     <Route
//       {...rest}
//       render={() =>
//         hasJWT() ? (
//           Company ? (
//             <AclGuard>{children}</AclGuard>
//           ) : childrenname[0] == "AddCompany" || childrenname[0] == "sN" ? (
//             <AclGuard> {children}</AclGuard>
//           ) : (
//             <Result title={t("Selectacompanytodisplayitsdata")} />
//           )
//         ) : (
//           <Redirect from="*" to="/sign-in" />
//         )
//       }
//     />
//   );
// }

// export default RouteGuard;

import { Result } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";
import { CompanyContext } from "../../contexts/CompanyContext";
import AclGuard from "./AclGuard";

function RouteGuard({ children, ...rest }) {
  let { t } = useTranslation();

  const {
    Company,
  } = useContext(CompanyContext);

  const [childrenname, setChildrenname] = useState(
    React.Children.toArray(children).map((x) => x.type.name)
  );

  function decodeJwt(token) {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(atob(base64));
    } catch (error) {
      console.error("Error decoding JWT token:", error);
      return null;
    }
  }

  function hasJWT() {
    let flag = false;

    if (localStorage.getItem("token")) {
      flag = true;
      const token = localStorage.getItem("token");
      const decodedToken = decodeJwt(token);
      console.log("decodedToken:", decodedToken);
    } else {
      flag = false;
    }

    return flag;
  }

  return (
      <Route
        {...rest}
        render={() =>
          hasJWT() ? (
            Company ? (
              <AclGuard>{children}</AclGuard>
            ) : childrenname[0] === "AddCompany" || childrenname[0] === "sN" ? (
              <AclGuard>{children}</AclGuard>
            ) : (
              <Result title={t("Selectacompanytodisplayitsdata")} />
            )
          ) : (
            <Redirect from="*" to="/sign-in" />
          )
        }
      />
      
  );
}

export default RouteGuard;

