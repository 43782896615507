import {
  Button,
  Card,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAbility } from "../../../contexts/abilityContext";
import axios from "axios";
import { JSON_API } from "../../../services/Constants";
import { DeleteFilled, PlusCircleFilled } from "@ant-design/icons";
const { Option } = Select;

const HyphothesisOfGlRe = (props) => {
  let { t } = useTranslation();
  const { ability } = useAbility();
  const [open, setOpen] = useState(false);
  const [openHyphothese, setopenHyphothese] = useState(false);
  const [Hypotheses, setHypotheses] = useState(null);

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [form2] = Form.useForm();


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  useEffect(() => {
    getHypotheses();
  }, []);

  const getHypotheses = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/GLAccount/${props.HypothesesDataSource.id}`)
      .then((res) => {
        console.log("hypothesis: ", res.data);
        setHypotheses(
          res.data.hypotheses.sort((p1, p2) =>
            p1.beginsOnYear < p2.beginsOnYear
              ? 1
              : p1.beginsOnYear > p2.beginsOnYear
              ? -1
              : 0
          )
        );
        console.log(props.HypothesesDataSource);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnopenHyphothese = async (values) => {
    console.log("Received values of form: ", props);

    const obj = {
      glAccountId: props.HypothesesDataSource.id,
      annualIncrease: values.annualIncrease,
      beginsOnYear: values.beginsOnYear.$y + "",
      beginsOnMonth: values.beginsOnMonth,
      hypothesis: values.hypothesis,
    };

    console.log("obj:", obj);
    await axios
      .post(`${process.env.REACT_APP_JSON_API}/Hypothesis`, obj)
      .then((res) => {
        console.log(res);
        getHypotheses();
        setOpen(!open);
        message.success(t("HyphothesisCreatedSuccessfully"));
      })
      .catch((err) => {
        console.log(err);
        message.error(t("ErrorCreatingHyphothesis"));
      });
    setopenHyphothese(false);
  };

  const deleteHyphothese = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_JSON_API}/Hypothesis/${id}`);
      message.success(t("HyphothesisDeletedSuccessfully"));
      getHypotheses();
    } catch (error) {
      console.log(error);
      message.error(t("ErrorDeletingHyphothesis"));
    }
  };

  const columnsHyphothese = [
    {
      title: <span style={{ textAlign: "center" }}>{t("Year")}</span>,
      dataIndex: "beginsOnYear",
      align: "center",
      sorter: (a, b) => a.beginsOnYear - b.beginsOnYear,
      render: (text, record) => {
        return <div style={{ textAlign: "center" }}>{text}</div>;
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("AnnualIncrease")}</span>,
      dataIndex: "annualIncrease",
      align: "center",
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("BeginOn")}</span>,
      dataIndex: "beginsOnMonth",
      align: "center",
      render: (text, record) => {
        return record.beginsOnMonth + "/" + record.beginsOnYear;
      },
    },
    {
      title: <span style={{ textAlign: "center" }}>{t("Hyphothesis")}</span>,
      dataIndex: "hypothesis",
      align: "center",
      render: (text, record) => {
        return <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(text)}>{text}</div>;
      },
    },
  ];

  if (ability.can("read", "Hypothesis")) {
    columnsHyphothese.push({
      title: <span style={{ textAlign: "center" }}>{t("Actions")}</span>,
      dataIndex: "action",
      align: "center",
      render: (text, record) => {
        return (
          <div>
            {ability.can("delete", "Hypothesis") && (
              <Popconfirm
                title={t("Suretodelete")}
                onConfirm={() => deleteHyphothese(record.id)}
              >
                <Button
                  type="link"
                  danger
                  icon={<DeleteFilled />}
                  style={{
                    margin: "0 5px",
                  }}
                >
                  {t("Delete")}
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    });
  }
  const CollectionCreateHyphothesis = ({
    openHyphothese,
    OnopenHyphothese,
    onCancel,
  }) => {
    return (
      <Modal
        forceRender
        open={openHyphothese}
        title={t("CreateHypothesisss")}
        okText={t("save")}
        cancelText={t("cancel")}
        onCancel={onCancel}
        onOk={() => {
          form2
            .validateFields()
            .then((values) => {
              form2.resetFields();
              OnopenHyphothese(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form2}
          // layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            // value={nom}
            name="beginsOnYear"
            label={t("Year")}
            rules={[
              {
                required: true,
                message: `${t("Pleaseinputtheyear")}`,
              },
            ]}
          >
            <DatePicker
              placeholder={t("Selectyear")}
              picker="year"
              style={{
                width: "100%",
                textAlign: "center",
              }}
              size={"large"}
            />
          </Form.Item>
          <Form.Item
            name="annualIncrease"
            label={t("AnnualIncrease")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheAnnualIncrease")}`,
              },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              size={"large"}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>

          <Form.Item
            // value={nom}
            name="beginsOnMonth"
            label={t("BeginOnMonth")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheBeginMonth")}`,
              },
            ]}
          >
            <Select
              placeholder={t("Selectmonth")}
              // onChange={handleStartPeriodChange}
              size={"large"}
              // style={{
              //   width: "50%",
              // }}
            >
              <Option value={1}>{t("January")}</Option>
              <Option value={2}>{t("February")}</Option>
              <Option value={3}>{t("March")}</Option>
              <Option value={4}>{t("April")}</Option>
              <Option value={5}>{t("May")}</Option>
              <Option value={6}>{t("June")}</Option>
              <Option value={7}>{t("July")}</Option>
              <Option value={8}>{t("August")}</Option>
              <Option value={9}>{t("September")}</Option>
              <Option value={10}>{t("October")}</Option>
              <Option value={11}>{t("November")}</Option>
              <Option value={12}>{t("December")}</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="hypothesis"
            label={t("Hyphothesis")}
            rules={[
              {
                required: true,
                message: `${t("PleaseinputtheHyphothesis")}`,
              },
            ]}
          >
            <TextArea rows={2}></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  return (
    <div>
      <Row justify="end" gutter={[16, 16]}>
        <Space
          style={{
            display: "flex",
            marginBottom: 8,
          }}
          align="baseline"
        >
          {ability.can("create", "Hypothesis") && (
            <Button
              className="Create-button"
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => {
                setopenHyphothese(true);
              }}
              style={{
                textAlign: "right",
                marginTop: 14,
                marginBottom: 14,
              }}
            >
              {t("CreateanewHyphothesis")}
            </Button>
          )}
        </Space>
      </Row>

      <CollectionCreateHyphothesis
        openHyphothese={openHyphothese}
        OnopenHyphothese={OnopenHyphothese}
        onCancel={() => {
          setopenHyphothese(false);
          form2.resetFields();
        }}
      />

<div style={{ overflowX: "auto" }}>
      <Table
        locale={{
          emptyText: <Empty description={t("nodata")} />,
        }}
        style={{
          marginTop: 14,
          marginBottom: 14,
        }}
        bordered
        columns={columnsHyphothese}
        dataSource={Hypotheses}
      />
      </div>
            <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
    </div>
  );
};

HyphothesisOfGlRe.acl = {
  subject: "Hypothesis",
  action: "read",
};
export default HyphothesisOfGlRe;
