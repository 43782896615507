import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Card,
  Breadcrumb,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Divider,
  Typography,
  notification,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { JSON_API } from "../services/Constants";
import { useTranslation } from "react-i18next";
import {
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  SecurityScanOutlined,
  ArrowLeftOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { CompanyContext } from "../contexts/CompanyContext";
const { Option } = Select;

const UpdateEmployeeAccount = () => {
  let { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { Company } = useContext(CompanyContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const account = history.location.state.account;

  useEffect(() => {
    form.setFieldsValue({
      email: account.email,
      phoneNumber: account.phoneNumber,
    });
  }, [form, account]);

  const onFinish = async (values) => {
    const { email, phoneNumber } = values;

    const accountToUpdate = {
      email,
      phoneNumber,
      employeeId: account.employeeId,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_JSON_API}/EmployeeAccounts/${account.id}`,
        accountToUpdate
      );

      if (response.status === 200) {
        message.success(t("EmployeeAccountUpdated"));
        //history.push("/users");
      }
    } catch (error) {
      message.error(t("AnErrorOccuredWhileUpdatingEmployeeAccount"));
      console.log(error);
    }
  };

  return (
    <Card>
      <Breadcrumb
        style={{
          marginBottom: "1rem",
          fontSize: "1.2rem",
          textShadow: "1px 1px 1px #aaa",
        }}
      >
        <Breadcrumb.Item>
          <Link to="/users">
            <Typography.Text>
              <AppstoreAddOutlined /> {t("Users")}
            </Typography.Text>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/update-employee-account">
            <Typography.Text>
              <EditOutlined /> {t("UpdateEmployeeAccount")}
            </Typography.Text>
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        name="update"
        style={{ margin: "0 auto", maxWidth: "1000px" }}
      >
        <Typography.Title
          level={2}
          style={{ textAlign: "center", marginBottom: "2rem" }}
        >
          {t("UpdateAccount")}
        </Typography.Title>

        <Divider orientation="left">{t("AccountInfos")}</Divider>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item name="email" label="Email">
              <Input type="email" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item name="phoneNumber" label={t("PhoneNumber")}>
              <Input type="text" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="center">
          <Col xs={24} sm={8}>
            <Form.Item>
              <Link to="/users">
                <Button type="primary" danger block>
                  <ArrowLeftOutlined /> {t("GoBack")}
                </Button>
              </Link>
            </Form.Item>
          </Col>
          <Col xs={24} sm={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                <EditOutlined /> {t("UpdateAccount")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default UpdateEmployeeAccount;
