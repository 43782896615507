import React, { useState, useEffect } from "react";
import { Card, Table, Button, Space, message, Popconfirm } from "antd";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom"; // Import useHistory
import axios from "axios";
import { JSON_API } from "../../../services/Constants";
import { useTranslation } from "react-i18next";

const { Meta } = Card;
const CashFlowModels = () => {
  let { t } = useTranslation();
  const history = useHistory(); // Initialize useHistory
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_JSON_API}/abCashFlowModels`)
      .then((response) => {
        setModels(response.data);
        setLoading(false);
      })
      .catch((error) => {
        message.error(`Error fetching data: ${error}`);
        setLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_JSON_API}/abCashFlowModels/${id}`)
      .then(() => {
        setModels(models.filter((model) => model.id !== id));
        message.success(t("Modeldeletedsuccessfully"));
      })
      .catch((error) => {
        message.error(`Error deleting model: ${error}`);
      });
  };

  const handleViewDetails = (id) => {
    // Redirect to view details
    history.push(`/model-detail/${id}`); // Redirect to ModelDetail page with the model ID
  };

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: t("Enterprise"),
      dataIndex: "enterprise",
      key: "enterprise",
      align: "left",
    },
    {
      title: t("Complexity"),
      dataIndex: "complexity",
      key: "complexity",
      align: "left",
    },
    {
      title: t("Frequency"),
      dataIndex: "frequency",
      key: "frequency",
      align: "left",
    },
    {
      title: t("createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "left",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Action",
      key: "action",
      align: "left",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => handleViewDetails(record.id)}
          />
          <Popconfirm
            title={t("AreYouSureYouWantDelteThisModel")}
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={() => handleDelete(record.id)}
          >
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={`${t("CashflowModels")} (${models.length})`}
      extra={
        <Link to="/cash-flow">
          <Button type="primary" icon={<PlusCircleFilled />} size="middle">
            {t("Createcashflow")}
          </Button>
        </Link>
      }
      headStyle={{ fontSize: "16px" }}
    >
      <Table dataSource={models} columns={columns} loading={loading} style={{ overflowX: "auto",backgroundColor:'white' }}/>
    </Card>
  );
};

export default CashFlowModels;
