import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Progress,
  Select,
  Space,
  Table,
  Tag,
  icon,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  NotificationOutlined,
  PlusCircleFilled,
  CloseCircleFilled,
  EditOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";
import { useAbility } from "../../contexts/abilityContext";
import { Card, Col, Row } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import "./Tableau.css";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

function Marge() {
  const { Company } = useContext(CompanyContext);
  let { t } = useTranslation();
  const [Object, setObject] = useState([]);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [id, setid] = useState("");
  const [details, setdetails] = useState("");
  const [nom, setnom] = useState("");
  const { ability } = useAbility();
  const canUpdate = ability.can("update", "Margins");
  const canDelete = ability.can("delete", "Margins");
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  useEffect(() => getdata(), []);

  const getdata = async () => {
    await axios
      .get(`${process.env.REACT_APP_JSON_API}/Finance/Marge/${Company.id}`)

      .then((res) => {
        console.log("marge", res);

        setObject(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_JSON_API}/Finance/Marge/${id}`);
      message.success(t("MargeDeletedSuccessfully"));
      getdata();
    } catch (error) {
      message.error(t("ErrorWhileDeletingTheMarge"));
    }
  };

  const { TextArea } = Input;

  const submite = async (e) => {
    const formData = {
      enterpriseId: Company.id,
      name: e.name,
      creditor: e.creditor,
      interestRates: e.interestRates,
      authorizedAmount: e.authorizedAmount,
      specialConditions: e.specialConditions,
    };

    console.log(formData);
    axios
      .post(`${process.env.REACT_APP_JSON_API}/Finance`, formData)
      .then((res) => {
        console.log(res);
        getdata();
        console.log(res.data);
        setOpen(!open);
        message.success("MargeAddedSuccessfully.");
      })
      .catch(function (error) {
        console.log(error);
        message.error("ErrorWhileAddingTheMarge");
      });
  };

  const CollectionCreateForm = ({ open, onCreate, onCancel }) => {
    return (
      <Modal
        forceRender
        open={open}
        title={t("Addamargin")}
        okText={
          <>
            <PlusCircleFilled /> {t("Create")}
          </>
        }
        cancelText={
          <>
            <CloseCircleFilled /> {t("Cancel")}
          </>
        }
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="name"
            label={t("Institutionname")}
            rules={[
              {
                required: true,
                message: "Please input the title of collection!",
              },
            ]}
          >
            <Input
              style={{
                width: 470,
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            name="creditor"
            label={t("Creditor")}
            rules={[
              {
                required: true,
                message: "Please input the title of collection!",
              },
            ]}
          >
            <Input
              style={{
                width: 470,
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            // value={montant}
            name="authorizedAmount"
            label={t("Authorizedamount")}
            rules={[
              {
                required: true,
                message: "Please input the title of collection!",
              },
            ]}
          >
            <InputNumber
              type="number"
              addonAfter={(Company.currencies && Company.currencies.length > 0 && Company.currencies[0].shortLabel)?Company.currencies[0].shortLabel:"$"}
              style={{
                width: "100%",
                alignItems: "center",
              }}
            />
          </Form.Item>
          <Form.Item
            name="interestRates"
            label={t("Interestrates")}
            rules={[
              {
                required: true,
                message: "Please input the title of collection!",
              },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={onChange}
              style={{ textalign: "right" }}
            />
          </Form.Item>
          <Form.Item
            // value={conditions}
            name="specialConditions"
            label={t("Particularconditions")}
          >
            <TextArea rows={4}></TextArea>
          </Form.Item>
          <Form.Item
            name="modifier"
            className="collection-create-form_last-form-item"
          ></Form.Item>
        </Form>
      </Modal>
    );
  };

  const onChange = (value) => {
    console.log("changed", value);
  };

  const columns = [
    {
      title: <h1 style={{ textAlign: "center" }}> {t("Institutionname")}</h1>,
      dataIndex: "nom",
      width: "30%",
      align: "cener",
      render: (_, record) => (
        <div style={{
                textAlign: "left",
                maxWidth: "400px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}onClick={() => showNameModal(record.name)}>{record.name}</div>
      ),
    },
    {
      title: <h1 style={{ textAlign: "center" }}>{t("Creationdate")}</h1>,
      dataIndex: "date",
      align: "center",

      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {dayjs(record.createdDate).format("YYYY / MM / DD")}
        </div>
      ),
    },
  ];

  if (canUpdate || canDelete) {
    columns.push({
      title: <h1 style={{ textAlign: "center" }}>Actions</h1>,
      dataIndex: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <>
            {ability && ability.can("delete", "Margins") && (
              <Popconfirm
                title={t("Areyousuretodeletethisone")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => handleDelete(record.id)}
              >
                <Button type="link " danger>
                  <DeleteOutlined />
                  {t("Delete")}
                </Button>
              </Popconfirm>
            )}

            {ability && ability.can("update", "Margins") && (
              <Link
                to={{
                  pathname: `/margedetails/${record.id}`,
                  state: { stateParam: record.id },
                }}
              >
                <EditOutlined />
                <a style={{ marginLeft: ".4rem" }}>{t("Update")}</a>
              </Link>
            )}
          </>
        );
      },
    });
  }

  return (
    <>
      <div>
        {contextHolder}

        <Card
          bordered={false}
          className="header-solid mb-24"
          title={
            <h3 className="font-semibold">
              <Divider>
                <NotificationOutlined style={{ color: "#3947C4" }} />{" "}
                {/* <Icon type="usergroup-add" /> */}
                {t("Marginsmanagement")}
              </Divider>
            </h3>
          }
        >
          <Row justify="space-between">
            {ability && ability.can("create", "Margins") && (
              <Space style={{ marginBottom: 15 }}>
                <Button
                  className="Create-button"
                  type="primary"
                  icon={<PlusCircleFilled />}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t("Create")}
                </Button>
                <CollectionCreateForm
                  open={open}
                  onCreate={submite}
                  onCancel={() => {
                    setOpen(false);
                    // onAddChamp();
                  }}
                />
              </Space>
            )}
          </Row>
          <div style={{ overflowX: "auto",backgroundColor:'white' }}>
          <Table bordered columns={columns} dataSource={Object} />
          </div>
          <Modal
          title={t("detail")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
          <br></br>
        </Card>
      </div>
    </>
  );
}
Marge.acl = {
  subject: "Margins",
  action: "read",
};
export default Marge;

