import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  message,
  Tag,
} from "antd";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  EyeFilled,
  DeleteFilled,
  PlusCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import axios from "axios";
import { CompanyContext } from "../../contexts/CompanyContext";
import { JSON_API } from "../../services/Constants";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAbility } from "../../contexts/abilityContext";
dayjs.extend(customParseFormat);

const { Option } = Select;

const modalStyle = {
  borderRadius: "10px",
  overflow: "hidden",
  padding: "20px 40px",
};

const titleContainerStyle = {
  display: "flex",
  alignItems: "center",
  padding: "10px 20px",
};

const titleStyle = {
  fontSize: "22px",
  fontWeight: "700",
  color: "#2c3e50",
  marginBottom: "0px",
};

const formStyle = {
  maxWidth: "850px",
  margin: "0 auto",
};

const rowStyle = {
  marginBottom: "1.8rem",
  background: "#FBFDFF",
  padding: "1.0rem",
};

const labelStyle = {
  fontWeight: "400",
  fontSize: "15px",
  color: "#34495e",
};

const inputStyle = {
  borderRadius: "5px",
  borderColor: "#e0e0e0",
  padding: "12px 15px",
  fontSize: "15px",
  transition: "border 0.3s ease",
  "&:focus": {
    borderColor: "#2980b9",
  },
};

const dividerStyle = {
  fontWeight: "bold",
  color: "#333",
  textTransform: "uppercase",
  background: "#deebff",
  padding: "1.0rem",
  borderRadius: "6px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
};

const colStyle = {
  padding: "0 30px",
};

function EmployeesManagment() {
  const { ability } = useAbility();
  const { Company } = useContext(CompanyContext);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Employees, setEmployees] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [year, setYear] = useState(null);
  const [enterpriseDepartmentId, setEnterpriseDepartmentId] = useState(0);
  const [enterpriseDepartments, setEnterpriseDepartments] = useState([]);
  const [titles, setTitles] = useState([]);
  const [postClassifications, setPostClassifications] = useState([]);
  const [departmentColors, setDepartmentColors] = useState({});

  const [salaryBreakDown, setSalaryBreakDown] = useState(false);
  const [visibleMinority, setVisibleMinority] = useState(false);
  const [insuranceContribution, setInsuranceContribution] = useState(false);

  const { t } = useTranslation();

  const getEnterpriseDepartments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/EnterpriseDepartments/Filter/${Company.id}`
      );
      console.log(response);
      setEnterpriseDepartments(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getPostClassifications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_JSON_API}/PostClassifications`);
      console.log(response);
      setPostClassifications(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTitles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_JSON_API}/Titles?enterpriseId=${Company.id}`
      );
      console.log(response);
      setTitles(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEnterpriseDepartments();
    getPostClassifications();
    getTitles();
    getData();
  }, [Company, enterpriseDepartmentId, open]);

  useEffect(() => {
    const newDepartmentColors = {};
    enterpriseDepartments.forEach((ed) => {
      newDepartmentColors[ed.label] = getRandomColor();
    });
    setDepartmentColors(newDepartmentColors);
  }, [enterpriseDepartments]);

  const getData = async () => {
    console.log("enterpriseDepartmentId:", enterpriseDepartmentId);

    const baseURL = `${process.env.REACT_APP_JSON_API}/Employees/enterprise/filter?enterpriseId=${Company.id}`;
    const url =
      enterpriseDepartmentId !== 0
        ? `${baseURL}&departmentId=${enterpriseDepartmentId}`
        : baseURL;

    setLoading(true);

    try {
      const response = await axios.get(url);
      setEmployees(response.data);
      console.log("Employee Informations : ", response.data);
    } catch (error) {
      handleAxiosError(error);
      setEmployees(null);
    } finally {
      setLoading(false);
    }
  };

  const handleAxiosError = (error) => {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
  };

  const deleteEmployee = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_JSON_API}/Employees/${id}`);
      message.success(t("EmployeeDeletedSuccessfully"));
      getData();
    } catch (error) {
      console.log(error);
      message.error(t("ErrorDeletingEmployee"));
    }
  };

  const Departments =
    enterpriseDepartments == null
      ? []
      : enterpriseDepartments.map((ed) => ({ value: ed.id, label: ed.label }));

  const Titles =
    titles == null
      ? []
      : titles.map((ed) => ({ value: ed.id, label: ed.label }));

  const PostClassifications =
    postClassifications == null
      ? []
      : postClassifications.map((ed) => ({ value: ed.id, label: ed.label }));

  const Submit = async (e) => {
    const formData = {
      name: e.name,
      firstName: e.firstName,
      hiringDate: e.hiringDate,
      sexe: e.gender,
      enterpriseId: Company.id,
      titleId: e.titleId,
      visibleMinority: visibleMinority,
      annualSalary: e.annualSalary,
      socialAdvantagePercent: e.socialAdvantage,
      salaryBreakDown: salaryBreakDown,
      departureDate: e.departureDate,
      payPeriod: e.payPeriod,
      //completeSalaryTotal: e.completeSalaryTotal,
      yearsOfExperienceTotal: e.yearsOfExperienceTotal,
      recognizedYearsOfExperience: e.recognizedYearsOfExperience,
      vacationWeeks: e.vacationWeeks,
      bonus: e.bonus,
      otherSocialAdvantages: e.otherSocialAdvantages,
      jobPostingStartDate: e.jobPostingStartDate,
      insuranceContribution: insuranceContribution,
      enterpriseDepartmentId: e.enterpriseDepartmentId,
      superiorId: e.immediateBoss == "None" ? 0 : e.immediateBoss,
      postClassificationId: e.postClassificationId,
      jobStatus: e.jobStatus,
      employeeStatus: e.employeeStatus,
      postStatus: e.postStatus,
      averageAge: e.averageAge,
      titles: [0],
    };
    console.log(formData);
    await axios
      .post(`${process.env.REACT_APP_JSON_API}/Employees`, formData)
      .then((res) => {
        getData();
        console.log("Data of employees sent to backend :" + res.data);
        setOpen(!open);
        message.success(t("EmployeeAddedSuccessfully"));
      })
      .catch(function (error) {
        console.log(error);
        message.error(t("ErrorAddingEmployee"));
      });
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function getStatusTag(value) {
    const statusOptions = {
      Active: { label: t("Active"), color: "green" },
      UnpaidLeave: { label: t("UnpaidLeave"), color: "orange" },
      Illness: { label: t("Illness"), color: "blue" },
      InvoluntaryDeparture: { label: t("InvoluntaryDeparture"), color: "red" },
      VoluntaruDeparture: { label: t("VoluntaruDeparture"), color: "yellow" },
      Invalidity: { label: t("Invalidity"), color: "gray" },
    };

    return statusOptions[value] || { label: "Unknown", color: "black" };
  }

  function getJobStatusTag(value) {
    const jobStatusOptions = {
      FullTime: { label: t("FullTime"), color: "green" },
      PartTime: { label: t("PartTime"), color: "blue" },
      Temporary: { label: t("Temporary"), color: "yellow" },
      Contractual: { label: t("Contractual"), color: "orange" },
    };

    return jobStatusOptions[value] || { label: "Unknown", color: "black" };
  }

  const columns = [
    {
      title: `${t("Name")}`,
      align: "center",
      render: (record) => {
        return `${record.name} ${record.firstName}`;
      },
    },
    {
      title: `${t("Department")}`,
      align: "center",
      dataIndex: "department",
      render: (department) => {
        const color = departmentColors[department] || "gray";
        return (
          <Tag
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            color={color}
          >
            {department}
          </Tag>
        );
      },
    },
    {
      title: `${t("statut")}`,
      align: "center",
      dataIndex: "status",
      render: (status) => {
        const { label, color } = getStatusTag(status);
        return (
          <Tag
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            color={color}
          >
            {label}
          </Tag>
        );
      },
    },
    {
      title: `${t("JobTitle")}`,
      align: "center",
      dataIndex: "jobTitle",
    },
    {
      title: `${t("PostStatus")}`,
      align: "center",
      dataIndex: "jobStatus",
      render: (jobStatus) => {
        const { label, color } = getJobStatusTag(jobStatus);
        return (
          <Tag
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px 10px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
            color={color}
          >
            {label}
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      align: "center",
      key: "id",
      render: (_, record) => (
        <>
          <Link
            style={{ color: "#0D73CC", textUnderlinePosition: "under" }}
            to={{
              pathname: `/employee_detail/${record.id}`,
              state: { stateParam: record.id },
            }}
          >
            <Button type="link" icon={<EyeFilled />}>
              {t("details")}
            </Button>
          </Link>
          {ability.can("delete", "Employees Management") && (
            <Popconfirm
              title={t("AreYouSureToDeleteEmployee")}
              onConfirm={() => deleteEmployee(record.id)}
            >
              <Button type="link" icon={<DeleteFilled />} danger>
                {t("Delete")}
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];
  return (
    <Card
      bordered={false}
      className="header-solid mb-24"
      title={<h3 className="font-semibold"> {t("EmployeesManagement")}</h3>}
    >
      <Row justify="space-between" style={{ marginBottom: 25 }}>
        <Col>
          <Select
            notFoundContent={t("nodata")}
            showSearch
            placeholder={t("SelectDepartment")}
            onChange={(val) => setEnterpriseDepartmentId(val)}
            style={{ width: 300 }}
            size="large"
            defaultValue={t("Alldepts")}
          >
            <Option value={0}>{t("Alldepts")}</Option>
            {enterpriseDepartments.map((o) => {
              return <Option value={o.id}>{o.label}</Option>;
            })}
          </Select>
          <Space>
            {ability.can("create", "Employees Management") && (
              <Button
                type="primary"
                onClick={() => {
                  setOpen(true);
                }}
                style={{
                  marginLeft: "15%",
                  textAlign: "left",
                  alignItems: "left",
                }}
                icon={<PlusCircleFilled />}
              >
                {t("AddEmployee")}
              </Button>
            )}
            <Modal
              open={open}
              width={1100}
              style={modalStyle}
              title={
                <div style={titleContainerStyle}>
                  <h2 style={titleStyle}>
                    {t("CreateNewEmployeeFor") + " " + Company.name}
                  </h2>
                </div>
              }
              okText={
                <>
                  <PlusCircleFilled /> {t("create")}
                </>
              }
              cancelText={
                <>
                  <CloseCircleFilled /> {t("cancel")}
                </>
              }
              onCancel={() => {
                form.resetFields();
                setOpen(false);
              }}
              onOk={() => {
                form
                  .validateFields()
                  .then((values) => {
                    form.resetFields();
                    Submit(values);
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              <Form
                form={form}
                style={formStyle}
                name="form_in_modal"
                layout="vertical"
                initialValues={{
                  modifier: "public",
                }}
              >
                <Divider orientation="left" style={dividerStyle}>
                  {t("GeneralIdentification")}
                </Divider>
                <Row gutter={[16, 16]} style={rowStyle}>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="firstName"
                      label={
                        <span style={labelStyle}>{t("EmployeeFirstName")}</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("EmployeeFirstNameErrorMessage")}`,
                        },
                      ]}
                    >
                      <Input style={inputStyle} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="name"
                      label={
                        <span style={labelStyle}>{t("EmployeeLastName")}</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("EmployeeLastNameErrorMessage")}`,
                        },
                      ]}
                    >
                      <Input style={inputStyle} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="gender"
                      label={
                        <span style={labelStyle}>{t("EmployeeGender")}</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("EmployeeGenderErrorMessage")}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        options={[
                          {
                            value: "M",
                            label: `${t("Male")}`,
                          },
                          {
                            value: "F",
                            label: `${t("Female")}`,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="employeeStatus"
                      label={
                        <span style={labelStyle}>{t("EmployeeStatuss")}</span>
                      }
                      rules={[
                        {
                          required: true,
                          message: `${t("EmployeeStatusErrorMessage")}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        options={[
                          {
                            value: 0,
                            label: `${t("Active")}`,
                          },
                          {
                            value: 1,
                            label: `${t("UnpaidLeave")}`,
                          },
                          {
                            value: 2,
                            label: `${t("Illness")}`,
                          },
                          {
                            value: 3,
                            label: `${t("InvoluntaryDeparture")}`,
                          },
                          {
                            value: 4,
                            label: `${t("VoluntaruDeparture")}`,
                          },
                          {
                            value: 5,
                            label: `${t("Invalidity")}`,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="averageAge"
                      label={<span style={labelStyle}>{t("AverageAge")}</span>}
                      rules={[
                        {
                          required: true,
                          message: `${t("AverageAgeErrorMessage")}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        options={[
                          {
                            value: 0,
                            label: `${t("Under20")}`,
                          },
                          {
                            value: 1,
                            label: `${t("Between20And25")}`,
                          },
                          {
                            value: 2,
                            label: `${t("Between26And30")}`,
                          },
                          {
                            value: 3,
                            label: `${t("Between31And39")}`,
                          },
                          {
                            value: 4,
                            label: `${t("Between40And50")}`,
                          },
                          {
                            value: 5,
                            label: `${t("Beyound50")}`,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="visibleMinority"
                      label={
                        <span style={labelStyle}>
                          {t("VisibilityMinority")}
                        </span>
                      }
                    >
                      <Checkbox
                        checked={visibleMinority}
                        onChange={(e) => setVisibleMinority(e.target.checked)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      initialValue={0}
                      name="recognizedYearsOfExperience"
                      label={
                        <span style={labelStyle}>
                          {t("RecognizedExeperienceYears")}
                        </span>
                      }
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      initialValue={0}
                      name="yearsOfExperienceTotal"
                      label={t("YearsOfExperienceTotal")}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation="left" style={dividerStyle}>
                  {t("BasicConditions")}
                </Divider>
                <Row gutter={[16, 16]} style={rowStyle}>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="annualSalary"
                      label={t("AnnualSalary")}
                      rules={[
                        {
                          required: true,
                          message: `${t("AnnualSalaryErrorMessage")}`,
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="000.00"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      initialValue={0}
                      name="socialAdvantage"
                      label={t("SocialAdvantages")}
                    >
                      <InputNumber
                        placeholder="000.00"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item name="salaryBreakDown" label={t("BreakDown")}>
                      <Checkbox
                        checked={salaryBreakDown}
                        onChange={(e) => setSalaryBreakDown(e.target.checked)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      initialValue={0}
                      name="vacationWeeks"
                      label={t("Vaccation")}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item initialValue={0} name="bonus" label={t("Bonus")}>
                      <InputNumber
                        placeholder="000.00"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="otherSocialAdvantages"
                      label={t("OtherAdvantages")}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="jobPostingStartDate"
                      label={t("JobPostingStartDate")}
                    >
                      <DatePicker
                        name="year"
                        placeholder={t("selectdate")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="insuranceContribution"
                      label={t("InsuranceContribution")}
                    >
                      <Checkbox
                        checked={insuranceContribution}
                        onChange={(e) =>
                          setInsuranceContribution(e.target.checked)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation="left" style={dividerStyle}>
                  {t("Job")}
                </Divider>
                <Row gutter={[16, 16]} style={rowStyle}>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      initialValue={"None"}
                      name="immediateBoss"
                      label={t("ImmediateSuperior")}
                    >
                      <Select placeholder=" " style={{ width: "100%" }}>
                        {Employees &&
                          Employees.map((emp) => (
                            <Option key={emp.id} value={emp.id}>
                              {emp.name} {emp.firstName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="titleId"
                      label={t("Title")}
                      rules={[
                        {
                          required: true,
                          message: `${t("SlectATitle")}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        options={Titles}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="postClassificationId"
                      label={t("JobClassification")}
                      rules={[
                        {
                          required: true,
                          message: `${t("SlectAJobClassification")}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        options={PostClassifications}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="hiringDate"
                      label={t("HiringDate")}
                      rules={[
                        {
                          required: true,
                          message: `${t("HiringDateErrorMessage")}`,
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder={t("selectdate")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      name="enterpriseDepartmentId"
                      label={t("Department")}
                      rules={[
                        {
                          required: true,
                          message: `${t("SelectDepartment")}`,
                        },
                      ]}
                    >
                      <Select
                        placeholder={t("SelectDepartment")}
                        style={{ width: "100%" }}
                        options={Departments}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      initialValue="Monthly"
                      name="payPeriod"
                      label={t("PayPeriod")}
                    >
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        options={[
                          {
                            value: "Weekly",
                            label: `${t("Weekly")}`,
                          },
                          {
                            value: "15 Days",
                            label: `${t("In15Days")}`,
                          },
                          {
                            value: "Monthly",
                            label: `${t("Monthly")}`,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      initialValue={0}
                      name="jobStatus"
                      label={t("JobStatus")}
                    >
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        options={[
                          {
                            value: 0,
                            label: `${t("FullTime")}`,
                          },
                          {
                            value: 1,
                            label: `${t("PartTime")}`,
                          },
                          {
                            value: 2,
                            label: `${t("Temporary")}`,
                          },
                          {
                            value: 3,
                            label: `${t("Contractual")}`,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item
                      initialValue={0}
                      name="postStatus"
                      label={t("PostStatus")}
                    >
                      <Select
                        placeholder=""
                        style={{ width: "100%" }}
                        options={[
                          {
                            value: 0,
                            label: `${t("Temporary")}`,
                          },
                          {
                            value: 1,
                            label: `${t("Completed")}`,
                          },
                          {
                            value: 2,
                            label: `${t("Eliminated")}`,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} style={colStyle}>
                    <Form.Item name="departureDate" label={t("DepartureDate")}>
                      <DatePicker
                        placeholder={t("selectdate")}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Space>
        </Col>
      </Row>
<div style={{ overflowX: "auto",backgroundColor:'white' }}>
      <Table columns={columns} dataSource={Employees} bordered />
      </div>
    </Card>
  );
}
EmployeesManagment.acl = {
  subject: "Employees Management",
  action: "read",
};
export default EmployeesManagment;
